import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { MUSICAL_GROUP_REGISTER } from "../../../../constants/routes";
import { ButtonBack, ButtonEdit } from "../../../../components";
import MusicalGroupTemplate from "../../../../components/Templates/Confirmation/MusicalGroupTemplate";
import {
  MUSICAL_GROUP_TITULAR,
  MUSICAL_GROUP_REGISTER_CHECKOUT,
  DASHBOARD_SERVICES,
} from "../../../../constants/routes";
import { musicalGroupActions } from "../../../../store/slices/musicalGroup";

const ConfirmationRgm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const storeMusicalGroup = useSelector((state) => state.musicalGroup);
  const storePayment = useSelector((state) => state.payment);

  const EDITBRAND = MUSICAL_GROUP_TITULAR;
  const EDITBRAND_IS_NEW_BRAND = MUSICAL_GROUP_REGISTER;
  const EDITBRAND_CHECKOUT = MUSICAL_GROUP_REGISTER_CHECKOUT;

  useEffect(() => {
    if (
      !storeMusicalGroup.isNewBrand &&
      storeMusicalGroup.studyBelongsId === -1
    ) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeMusicalGroup.isNewBrand, storeMusicalGroup.studyBelongsId]);

  const gotoBrandInfo = () => {
    if (storeMusicalGroup.isNewBrand) {
      props.history.replace(EDITBRAND_IS_NEW_BRAND);
    } else {
      props.history.replace(EDITBRAND);
    }
  };

  const gotoPayment = () => {
    props.history.replace(EDITBRAND_CHECKOUT);
  };

  const redirectReceipt = useCallback(() => {
    dispatch(musicalGroupActions.createMusicalGroup());
  }, [dispatch]);

  return (
    <div className="container-modulo">
      <ButtonBack url={EDITBRAND_CHECKOUT} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">REGISTRO DE NOMBRE DE GRUPOS MUSICALES</h2>
      </div>

      <div className="description-confirm">
        <p>Protege tu Grupo Musical o tu Nombre Artístico de manera práctica y sencilla.</p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DEL GRUPO MUSICAL</div>
        <ButtonEdit onClick={gotoBrandInfo} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE PAGO</div>
        <ButtonEdit onClick={gotoPayment} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
      <div>
        <MusicalGroupTemplate
          registerData={storeMusicalGroup}
          paymentData={storePayment}
          onConfirm={redirectReceipt}
          scope="reg-std-musical-group"
        />
      </div>
    </div>
  );
};

export default ConfirmationRgm;
