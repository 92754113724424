import PropTypes from "prop-types";
import SimpleModal from "../../../../components/UI/Modal/SimpleModal";
import { Button, Spinner } from "../../../../components";
import classes from "../MyRequest.module.css";

const SuccessfulRequest = ({
  closeModalResultRequestInvoice,
  loadingRequestInvoice,
}) => (
  <SimpleModal
    close={closeModalResultRequestInvoice}
    title="SOLICITUD DE FACTURA EXITOSA"
  >
    <div className={classes.mainContentModal}>
      {loadingRequestInvoice ? (
        <Spinner />
      ) : (
        <>
          <div className={classes.descriptionModal}>
            La solicitud se realizó exitosamente
            <br /> <br />
            Los archivos correspondientes a la factura llegarán a su correo
            electrónico
            <br />
          </div>
          <div className={classes.modalCtrl}>
            <div className={classes.modalBtn}>
              <Button
                className="btnConfirm"
                onClick={closeModalResultRequestInvoice}
              >
                Aceptar
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  </SimpleModal>
);

SuccessfulRequest.propTypes = {
  closeModalResultRequestInvoice: PropTypes.func.isRequired,
  loadingRequestInvoice: PropTypes.bool.isRequired,
};

export default SuccessfulRequest;
