import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import PropTypes from "prop-types";
import { getFileName } from "../../../utils/scripts/file";
import EntityTemplate from "../Entity/EntityTemplate";
import { Button, Spinner } from "../../../components";
import { catObras } from "../../../utils/cat-obras";
import classes from "./ConfirmationTemplate.module.css";

const ObrasConfirmation = (props) => {
  const [accepted, setAccepted] = useState(false);
  const [typeObra, setObraType] = useState("");
  const [titular, setTitular] = useState();
  const [author, setAuthor] = useState();
  const [coauthor, setCoauthor] = useState([]);
  const [sendConfirm, setSendConfirm] = useState(false);

  const confirmation = (callback) => {
    setSendConfirm(true);
    callback();
  };

  useState(() => {
    let type = catObras.filter(
      (element) => element.id === props.registerData.brandInfo.type
    );
    setObraType(type[0]?.name);
  }, [props.registerData.type]);

  useState(() => {
    if (props.registerData.owner_id !== "-1") {
      let dataTitular = props.userProfile.holders.filter(
        (element) => element.id === +props.registerData.owner_id
      );
      setTitular(dataTitular[0]);
    } else {
      setTitular(props.registerData.owner_info);
    }
  }, [props.registerData.owner_id]);

  useState(() => {
    if (props.registerData.authorSelect !== "3") {
      if (props.registerData.author_id !== "-1") {
        let dataAuthor = props.userProfile.authors.filter(
          (element) => element.id === +props.registerData.author_id
        );
        setAuthor(dataAuthor[0]);
      } else {
        setAuthor(props.registerData.author_info);
      }
    } else {
      setAuthor(false);
    }
  }, [props.registerData.author_id]);

  useState(() => {
    let coauthorslist = [];
    if (props.registerData.coauthor_info.length) {
      props.registerData.coauthor_info.forEach((item) => {
        if (!item.isRegistered) {
          coauthorslist.push(item);
        }
      });
    }

    if (props.registerData.coauthor_id.length) {
      props.registerData.coauthor_id.forEach((item) => {
        if (item !== -1) {
          coauthorslist.push(
            props.userProfile.coauthors.filter(
              (element) => element.id === +item
            )
          );
        }
      });
    }
    setCoauthor(coauthorslist);
  }, [props.registerData.coauthor_id, props.registerData.coauthor_info]);

  const showCoauthorList = () => {
    if (coauthor.length) {
      const listCouahtor = coauthor.map((item) => (
        <EntityTemplate title="DATOS DEL CO-AUTOR" key={Math.random()}>
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>NOMBRE</div>
              <div className={classes.itemEntity}>
                {typeof item[0] !== "undefined" ? item[0].name : item.name}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>RFC</div>
              <div className={classes.itemEntity}>
                {typeof item[0] !== "undefined" ? item[0].rfc : item.rfc}
              </div>
            </div>
          </div>
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>CURP</div>
              <div className={classes.itemEntity}>
                {typeof item[0] !== "undefined" ? item[0].curp : item.curp}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>CALLE</div>
              <div className={classes.itemEntity}>
                {typeof item[0] !== "undefined" ? item[0].street : item.street}
              </div>
            </div>
          </div>
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>NÚMERO EXTERIOR</div>
              <div className={classes.itemEntity}>
                {typeof item[0] !== "undefined"
                  ? item[0].ext_number
                  : item.ext_number}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>NÚMERO INTERIOR</div>
              <div className={classes.itemEntity}>
                {typeof item[0] !== "undefined"
                  ? item[0].int_number
                  : item.int_number}
              </div>
            </div>
          </div>
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>CORREO ELECTRÓNICO</div>
              <div className={classes.itemEntity}>
                {typeof item[0] !== "undefined" ? item[0].email : item.email}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>TELÉFONO</div>
              <div className={classes.itemEntity}>
                {typeof item[0] !== "undefined" ? item[0].phone : item.phone}
              </div>
            </div>
          </div>
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>ESTADO</div>
              <div className={classes.itemEntity}>
                {typeof item[0] !== "undefined" ? item[0].state : item.state}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>CIUDAD</div>
              <div className={classes.itemEntity}>
                {typeof item[0] !== "undefined" ? item[0].city : item.city}
              </div>
            </div>
          </div>
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>CÓDIGO POSTAL</div>
              <div className={classes.itemEntity}>
                {typeof item[0] !== "undefined"
                  ? item[0].postal_code
                  : item.postal_code}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>COLONIA</div>
              <div className={classes.itemEntity}>
                {typeof item[0] !== "undefined"
                  ? item[0].neighborhood
                  : item.neighborhood}
              </div>
            </div>
          </div>
        </EntityTemplate>
      ));
      return listCouahtor;
    } else {
      return null;
    }
  };

  return (
    <div className={classes.confirmation}>
      <div className={classes.confirmationItem}>
        <h3>TIPO DE OBRA</h3>
        <p>{typeObra}</p>
      </div>
      <div className={classes.confirmationItem}>
        <h3>NOMBRE DE LA OBRA</h3>
        <p>{props.registerData.brandInfo.name}</p>
      </div>
      <div className={classes.confirmationItem}>
        <h3>DESCRIPCIÓN DE LA OBRA</h3>
        <p>{props.registerData.brandInfo.description}</p>
      </div>
      <div className={classes.confirmationItem}>
        <h3>ARCHIVO DE LA OBRA</h3>
        <p>{getFileName(props.registerData.brandInfo.file)}</p>
      </div>

      <EntityTemplate title="DATOS DEL TITULAR">
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>NOMBRE</div>
            <div className={classes.itemEntity}>
              {typeof titular !== "undefined" && titular.name}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>RFC</div>
            <div className={classes.itemEntity}>
              {typeof titular !== "undefined" && titular.rfc}
            </div>
          </div>
        </div>
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>CURP</div>
            <div className={classes.itemEntity}>
              {typeof titular != "undefined" && titular.curp}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>CALLE</div>
            <div className={classes.itemEntity}>
              {typeof titular != "undefined" && titular.street}
            </div>
          </div>
        </div>
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>NÚMERO EXTERIOR</div>
            <div className={classes.itemEntity}>
              {typeof titular !== "undefined" && titular.ext_number}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>NÚMERO INTERIOR</div>
            <div className={classes.itemEntity}>
              {typeof titular != "undefined" && titular.int_number}
            </div>
          </div>
        </div>
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>CORREO ELECTRÓNICO</div>
            <div className={classes.itemEntity}>
              {typeof titular !== "undefined" && titular.email}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>TELÉFONO</div>
            <div className={classes.itemEntity}>
              {typeof titular != "undefined" && titular.phone}
            </div>
          </div>
        </div>
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>ESTADO</div>
            <div className={classes.itemEntity}>
              {typeof titular !== "undefined" && titular.state}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>CIUDAD</div>
            <div className={classes.itemEntity}>
              {typeof titular != "undefined" && titular.city}
            </div>
          </div>
        </div>
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>CÓDIGO POSTAL</div>
            <div className={classes.itemEntity}>
              {typeof titular !== "undefined" && titular.postal_code}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>COLONIA</div>
            <div className={classes.itemEntity}>
              {typeof titular != "undefined" && titular.neighborhood}
            </div>
          </div>
        </div>
      </EntityTemplate>
      {author && (
        <EntityTemplate title="DATOS DEL AUTOR">
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>NOMBRE</div>
              <div className={classes.itemEntity}>
                {typeof author !== "undefined" && author.name}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>CALLE</div>
              <div className={classes.itemEntity}>
                {typeof author != "undefined" && author.street}
              </div>
            </div>
          </div>
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>NÚMERO EXTERIOR</div>
              <div className={classes.itemEntity}>
                {typeof author !== "undefined" && author.ext_number}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>NÚMERO INTERIOR</div>
              <div className={classes.itemEntity}>
                {typeof author != "undefined" && author.int_number}
              </div>
            </div>
          </div>
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>CORREO ELECTRÓNICO</div>
              <div className={classes.itemEntity}>
                {typeof author !== "undefined" && author.email}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>TELÉFONO</div>
              <div className={classes.itemEntity}>
                {typeof author != "undefined" && author.phone}
              </div>
            </div>
          </div>
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>ESTADO</div>
              <div className={classes.itemEntity}>
                {typeof author !== "undefined" && author.state}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>CIUDAD</div>
              <div className={classes.itemEntity}>
                {typeof author != "undefined" && author.city}
              </div>
            </div>
          </div>
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>CÓDIGO POSTAL</div>
              <div className={classes.itemEntity}>
                {typeof author !== "undefined" && author.postal_code}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>COLONIA</div>
              <div className={classes.itemEntity}>
                {typeof author != "undefined" && author.neighborhood}
              </div>
            </div>
          </div>
        </EntityTemplate>
      )}

      {showCoauthorList()}

      <div className={classes.confirmationItem}>
        <h3>TOTAL</h3>
        <div>
          <span className={classes.total}>
            <CurrencyFormat
              value={props.paymentData.purchaseAmount}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              renderText={(value) => (
                <div>
                  {value} <span className={classes.currency}>MXM</span>
                </div>
              )}
            />
          </span>
          <label htmlFor="terms" className={classes.terms}>
            (Incluye impuestos y comisiones)
          </label>
        </div>
      </div>

      <div className={classes.termsAndConditions}>
        <label className={classes.checkitem}>
          <label htmlFor="terms" className={classes.terms}>
            Acepto todos los &nbsp;
            <NavLink to="/terminos-y-condiciones" className={classes.linkText}>
              TÉRMINOS Y CONDICIONES
            </NavLink>
          </label>
          <input
            type="checkbox"
            id="terms"
            name="terms"
            onChange={(event) => setAccepted(event.target.checked)}
          />
          <span className={classes.checkmark}></span>
        </label>
      </div>

      <div className={classes.textDescription}>
        <p>
          Al presionar "confirmar solicitud" usted acepta todos los términos y
          condiciones de <b>A tu alcance.</b>
        </p>
        <p>El pago de esta solicitud no garantiza la aprobación de la misma.</p>
      </div>
      <div className={classes.btnConfirm}>
        {sendConfirm ? (
          <Spinner />
        ) : (
          <Button
            className={"btnConfirm"}
            onClick={() => confirmation(props.onConfirm)}
            disabled={!accepted}
          >
            CONFIRMAR SOLICITUD
          </Button>
        )}
      </div>
    </div>
  );
};

ObrasConfirmation.propTypes = {
  registerData: PropTypes.object.isRequired,
  paymentData: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default React.memo(ObrasConfirmation);
