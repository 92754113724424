import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ModalClases,
  ModalDrag,
  SelectedClasses,
  Button,
  ButtonBack,
  InputSelect,
  InputSimpleFloatLabel,
  BtnCallToAction,
  InputRadio,
  InputDayPicker,
  HolderForm,
} from "../../../../components";
import { getFileName } from "../../../../utils/scripts/file";
import { tradeMarkActions } from "../../../../store/slices/tradeMark";
import { userProfileActions } from "../../../../store/slices/userProfile";
import { paymentActions } from "../../../../store/slices/payment";
import {
  DASHBOARD_SERVICES,
  BRANDS_REGISTER_CHECKOUT,
  BRANDS_REGISTER_CONFIRMATION,
} from "../../../../constants/routes";
import { services as SERVICES } from "../../../../constants/services";
import { calculateClassesPrice } from "../../../../utils/scripts/calculatePrice";
import classes from "./RegisterTradeMark.module.css";
import { USER_PDLC } from "../../../../constants";

const RegisterTradeMark = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getHolders());
    dispatch(
      tradeMarkActions.setIsAdditionalBrand({ isAdditionalBrand: true })
    );
  }, [dispatch]);

  const tradeMark = useSelector((state) => state.tradeMark);
  const isRegisteredHolder = useSelector(
    (state) => state.tradeMark.isRegisteredHolder
  );
  const holders = useSelector((state) => state.userProfile.holders);

  const [calsesProdOpen, setCalsesProdOpen] = useState(false);
  const [dragModal, setDragModal] = useState(false);
  const [typeModal, setTypeModal] = useState("productos");
  const [userData, setUserData] = useState();

  const storeUserProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
  }, []);

  const openModalClases = (type) => {
    setCalsesProdOpen(true);
    setTypeModal(type);
  };

  const closeDragModal = () => {
    setDragModal(false);
  };

  const openDragModal = () => {
    setDragModal(true);
  };

  const clearFileUploaded = () => {
    dispatch(tradeMarkActions.setFile({ file: "" }));
  };

  const closeModalClases = () => {
    setCalsesProdOpen(false);
  };

  const handleChangeName = (event) => {
    dispatch(tradeMarkActions.setName({ name: event.target.value }));
  };

  const onChangeType = (event) => {
    dispatch(tradeMarkActions.setType({ type: event.target.value }));
  };
  const hanldleChangeTitularSelect = (event) => {
    dispatch(
      tradeMarkActions.setTitularId({
        titularId: event.target.value,
      })
    );
  };
  const handleChangeRegisterHolder = (event) => {
    dispatch(
      tradeMarkActions.setIsRegisteredHolder({
        isRegisteredHolder: event.target.value === "si",
      })
    );
  };
  const clearSelectedClass = (id, type) => {
    let list = [],
      newList = [];
    if (type === "productos") {
      list = [...tradeMark.productClasses];
      newList = list.filter((item) => item.id !== id);
      dispatch(tradeMarkActions.setProductClasses({ productClasses: newList }));
    } else if (type === "servicios") {
      list = [...tradeMark.servicesClasses];
      newList = list.filter((item) => item.id !== id);
      dispatch(
        tradeMarkActions.setServicesClasses({ servicesClasses: newList })
      );
    }
  };

  const handleRadio = (event) => {
    dispatch(
      tradeMarkActions.setIsUsed({
        isUsed: event.target.value === "si" ? 1 : 0,
      })
    );
  };

  const changeDatePicker = (date) => {
    if (typeof date !== "undefined") {
      const dateString = `${date.getFullYear()}-${
        date.getMonth() + 1 < 10 ? "0" : ""
      }${date.getMonth() + 1}-${
        date.getDate() < 10 ? "0" : ""
      }${date.getDate()}`;
      dispatch(tradeMarkActions.setDateUse({ dateUsed: String(dateString) }));
    } else {
      dispatch(tradeMarkActions.setDateUse({ dateUsed: "" }));
    }
  };

  const getPrice = () => {
    const marcas = storeUserProfile.services.services.filter(
      (item) => item.service_id === SERVICES.MARCAS
    );
    const estudio_reg = marcas[0].service_sub.filter(
      (item) => item.service_id === SERVICES.MARCAS_REGISTRO
    );

    let price =
      (tradeMark.productClasses.length + tradeMark.servicesClasses.length) *
      +estudio_reg[0]?.service_price + +estudio_reg[0].service_transact;

    price = price + +estudio_reg[0].service_transact;
    const totalprice = calculateClassesPrice(price);

    return totalprice.toFixed(2);
  };

  const registerNext = () => {
    //Calculate purchase amount
    let amount = getPrice();
    console.log("amount", amount)
    dispatch(
      paymentActions.setPurchaseAmount({ amount: Number(amount).toFixed(2) })
    );
    if (userData.user_id === USER_PDLC) {
      props.history.replace(BRANDS_REGISTER_CONFIRMATION);
    } else {
      props.history.replace(BRANDS_REGISTER_CHECKOUT);
    }
  };

  return (
    <div className="container-modulo">
      {calsesProdOpen && (
        <ModalClases
          show={calsesProdOpen}
          type={typeModal}
          closed={closeModalClases}
          scope="tradeMark"
        />
      )}
      {dragModal && (
        <ModalDrag
          show={dragModal}
          closed={closeDragModal}
          scope="brand-register"
          formatFile="zip"
        />
      )}

      <ButtonBack url={DASHBOARD_SERVICES} text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">REGISTRO DE MARCA</h2>
      </div>
      <div className="text-description">
        <p>
          Proporciona la información para realizar el estudio de
          registrabilidad.
        </p>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DE LA MARCA</div>
      </div>

      <div className="container-desc-controls">
        <div className="form-informacion-marca">
          <div>
            <InputSimpleFloatLabel
              id="name"
              name="name"
              defaultValue={tradeMark.name}
              valid={tradeMark.name !== ""}
              placeholder="NOMBRE DE LA MARCA"
              onChange={handleChangeName}
            />
          </div>
          <div>
            <InputSelect
              id="typeBrand"
              name="typeBrand"
              onChange={onChangeType}
              value={tradeMark.type}
              options={[
                { value: "1", name: "NOMINATIVA" },
                { value: "2", name: "MIXTA" },
                { value: "3", name: "SONORA" },
                { value: "4", name: "DISEÑO" },
              ]}
              fieldIdOption="value"
            />
          </div>
        </div>
        <div className="text-description">
          <p>
            <strong>Normativa</strong> Si tu marca se encuentra conformada de
            solo palabras, deberás elegir esta opción.
          </p>
          <p>
            <strong>Mixta</strong> Si tu marca además de estar conformada de
            palabras, se encuentra compuesta de un diseño, deberás elegir esta
            opción y compartirnos tu diseño.
          </p>
          <p>
            <strong>Sonora</strong> Si tu marca es un sonido, deberás elegir
            esta opción y compartirnos el archivo de audio de tu marca.
          </p>
          <p>
            <strong>Diseño</strong> Si tu marca esta compuesta solo de un diseño
            y no esta acompañada de palabras, deberás elegir esta opción y
            compartirnos tu diseño.
          </p>
          <p>Elige los servicios y/o productos que protegerás con tu marca.</p>
        </div>
      </div>

      {tradeMark.type > 1 && (
        <div className="updaload-file">
          {tradeMark.file === "" ? (
            <BtnCallToAction onClick={openDragModal} icon="faUpload">
              ARCHIVO DE DISEÑO DE LA MARCA
            </BtnCallToAction>
          ) : (
            <BtnCallToAction
              type="showfile"
              onClick={clearFileUploaded}
              icon="faTimesCircle"
              className="btnGreen"
              fileName={getFileName(tradeMark.file)}
            >
              ARCHIVO DE DISEÑO DE LA MARCA
            </BtnCallToAction>
          )}
          <p>Esté archivo deberá ser formato ZIP.</p>
        </div>
      )}

      <div className={classes.contentRadioSelect}>
        <InputRadio
          id="si"
          name="isRegisteredHolder"
          value="si"
          onChange={handleChangeRegisterHolder}
          label="SELECCIONAR UN TITULAR DE MI LISTA DE TITULARES"
          checked={isRegisteredHolder}
        />
        {isRegisteredHolder && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="- ELIGE UN TITULAR -"
            options={holders}
            fieldIdOption="id"
            value={tradeMark.titularId}
            onChange={hanldleChangeTitularSelect}
          />
        )}

        <InputRadio
          id="no"
          name="isRegisteredHolder"
          value="no"
          onChange={handleChangeRegisterHolder}
          label="REGISTRAR NUEVO TITULAR"
          checked={!isRegisteredHolder}
        />
      </div>
      {!isRegisteredHolder && (
        <div className={classes.contentForm}>
          <HolderForm
            entity="titular-brand"
            recordTypeFile="brand-owner-info"
          />
        </div>
      )}
      <div className="text-description">
        <p>
          Por favor especifica todos los tipos de productos y/o servicios que
          estarán asociados con tu marca:
        </p>
      </div>

      <div className="add-controls">
        {tradeMark.productClasses.length > 0 && (
          <SelectedClasses
            selectedClasses={tradeMark.productClasses}
            type="productos"
            onClear={clearSelectedClass}
          />
        )}

        <BtnCallToAction
          onClick={() => openModalClases("productos")}
          icon="faPlusCircle"
        >
          AGREGAR CLASES DE PRODUCTOS
        </BtnCallToAction>

        {tradeMark.servicesClasses.length > 0 && (
          <SelectedClasses
            selectedClasses={tradeMark.servicesClasses}
            type="servicios"
            onClear={clearSelectedClass}
          />
        )}
        <BtnCallToAction
          onClick={() => openModalClases("servicios")}
          icon="faPlusCircle"
        >
          AGREGAR CLASES DE SERVICIOS
        </BtnCallToAction>
      </div>

      <div className="radio-option-controls">
        <h5 className="title-select">¿HAS USADO TU MARCA?</h5>
        <InputRadio
          id="datesi"
          value="si"
          name="used_brand"
          onChange={handleRadio}
          checked={tradeMark.isUsed === 1}
          label="SI"
        />
        {tradeMark.isUsed ? (
          <div className="dateUseBrand">
            <p>
              Por favor indica la fecha en la que comenzaste a hacer uso de la
              misma:
            </p>
            <InputDayPicker
              placeholder="FECHA DE USO DE LA MARCA"
              value={tradeMark.dateUsed}
              onDayChange={(date) => changeDatePicker(date)}
            />
          </div>
        ) : null}
        <InputRadio
          id="dateno"
          value="no"
          name="used_brand"
          onChange={handleRadio}
          checked={tradeMark.isUsed === 0}
          label="NO"
        />
        <div className={classes.contentButton}>
          <Button
            onClick={registerNext}
            className={"btnConfirm"}
            disabled={!tradeMark.statusFormBrandInfo}
          >
            CONTINUAR
          </Button>
        </div>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DE LA PAGO</div>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default RegisterTradeMark;
