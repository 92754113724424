import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import classes from './SelectedClasses.module.css'

const SelectedClasses = props => {
  const total = props.selectedClasses.length;
  let count = 0;
  return (
    <div className={classes.module}>
      {total > 0 &&
        <h3 className={classes.title}>
          CLASES DE {props.type === 'productos' ? 'PRODUCTOS' : 'SERVICIOS'} ({total})
        </h3>
      }
      {
        props.selectedClasses.map(item => {
          count++;
          return (
            <div className={classes.item} key={item.id}>
              <div
                className={classes.closeIcon}
                onClick={() => props.onClear(item.id, props.type)}>
                <FontAwesomeIcon icon={faTimes} className={classes.icon} />
              </div>
              <div className={classes.nameItem}>{item.class_id}</div>
              <div className={classes.descItem}>{count}. {item.name}</div>
              <div className={classes.estimacion}>{item?.estimation}</div>
            </div>
          )
        }
        )
      }
    </div >
  );
}

export default SelectedClasses;