import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ButtonBack, PaymentStripe, ButtonEdit } from "./../../../components";
import {
  DASHBOARD_SERVICES,
  OBRAS_REGISTER,
  OBRAS_REGISTER_CONFIRMATION,
} from "../../../constants/routes";
import classes from "./CheckOutObras.module.css";

const CheckOutObras = (props) => {
  const history = useHistory();
  const storeObras = useSelector((state) => state.obras);

  useEffect(() => {
    if (storeObras.brandInfo.name === "") {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeObras.brandInfo.name]);

  const cameBack = () => {
    props.history.replace(OBRAS_REGISTER);
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <div className="container-modulo">
      <ButtonBack url={OBRAS_REGISTER} text="REGRESAR" />

      <div className={classes.title}>
        <h2 className="subtitle">REGISTRO DE OBRAS</h2>
      </div>

      <div className={classes.description}>
        <p>
          Proporciona la información para realizar el estudio de
          registrabilidad.
        </p>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE LA OBRA</div>
        <ButtonEdit onClick={cameBack} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>

      <div>
        <Elements stripe={stripePromise}>
          <PaymentStripe redirect={OBRAS_REGISTER_CONFIRMATION} />
        </Elements>
      </div>

      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default CheckOutObras;
