import React, { useState } from "react";
import PropTypes from "prop-types";

import classes from "./InputSimpleFloatLabel.module.css";

const InputSimpleFloatLabel = (props) => {
  const [focused, setFocused] = useState(
    props.defaultValue !== "" && props.defaultValue !== undefined
  );
  const [touched, setTouched] = useState(false);

  const handleOnFocus = () => {
    setFocused(true);
  };

  const handleOnBlur = (event) => {
    if (event.target.value === "") {
      setFocused(false);
      setTouched(true);
    }
  };
  const handleOnChange = (event, fnc) => {
    event.target.value === "" ? setFocused(false) : setFocused(true);
    fnc(event);
  };

  return (
    <div className={classes.groupForm}>
      <label
        htmlFor={props.id}
        className={`${classes.floatLabel} ${
          focused ? classes.inputActive : null
        }`}
      >
        {props.placeholder}
      </label>

      <input
        id={props.id}
        name={props.name}
        type={props.type || "text"}
        maxLength={props.maxLength || ""}
        className={`${classes.inputForm} ${
          !props.valid && (props.touched || touched) ? classes.invalid : ""
        }`}
        onFocus={handleOnFocus}
        onBlurCapture={handleOnBlur}
        onChange={(event) => handleOnChange(event, props.onChange)}
        value={props.value}
        defaultValue={props.defaultValue}
      />
    </div>
  );
};

InputSimpleFloatLabel.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  valid: PropTypes.bool,
  touched: PropTypes.bool,
  defaultValue: PropTypes.any,
  mayus: PropTypes.bool
};

export default React.memo(InputSimpleFloatLabel);
