import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RowBack } from "../../../../assets/images/icons/icons";
import {
  InputSelect,
  Button,
  InputRadio,
  ErrorMessage,
} from "../../../../components";
import { magazinesActions } from "../../../../store/slices/magazines";
import { userProfileActions } from "../../../../store/slices/userProfile";
import {
  MAGAZINES_SERVICES,
  MAGAZINES_REGISTER,
  MAGAZINES_REGISTER_TITULAR,
} from "../../../../constants/routes";
import classes from "../RegMagazines.module.css";

const SERVICES = MAGAZINES_SERVICES;
const REGISTER = MAGAZINES_REGISTER;
const REGISTER_TITULAR = MAGAZINES_REGISTER_TITULAR;

const StudySelectionMgz = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [validForm, setValidForm] = useState(true);
  const storeMagazines = useSelector((state) => state.magazines);

  useEffect(() => {
    dispatch(userProfileActions.getHolders());
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getUserServices());
    dispatch(magazinesActions.getRegistrabilityStudies());
  }, [dispatch]);

  const redirect = () => {
    history.replace(SERVICES);
  };
  const handleChangeRadio = (event) => {
    dispatch(
      magazinesActions.setIsNewBrand({
        isNewBrand: event.target.value === "true",
      })
    );
    setValidForm(true);
  };

  const handleSelect = (event) => {
    dispatch(
      magazinesActions.setStudyBelongsId({
        studyId: event.target.value,
      })
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (storeMagazines.isNewBrand) {
      setValidForm(true);
      history.replace(REGISTER);
    } else {
      if (event.target.musicGroup.value === "-1") {
        setValidForm(false);
      } else {
        setValidForm(true);
        history.replace(REGISTER_TITULAR);
      }
    }
  };

  return (
    <div className="container-modulo">
      <div className="link-back" onClick={redirect}>
        <img src={RowBack} alt="regresar" />
        &nbsp;&nbsp;REGRESAR
      </div>
      <div className="section-title">
        <h2 className="subtitle">REGRISTRO DE NOMBRE DE REVISTAS</h2>
      </div>
      <div className="text-description">
        <p>Protege tu Revista de manera práctica y sencilla.</p>
      </div>
      <div className="label-information">
        <div>SELECCIÓN DE LA REVISTA</div>
      </div>
      <div className="">
        <p>
          Hemos identificado que has realizado uno o más estudios de
          registrabilidad de nombre de revistas previamente con nosotros.
        </p>
        <p>
          Por favor indica si la revista que deseas registrar corresponde a
          alguna de estas, o bien si es un nombre de revista adicional.
        </p>
      </div>
      <div className={classes.contentRadioSelect}>
        <form onSubmit={handleSubmit}>
          <InputRadio
            id="isAdditional"
            value="false"
            name="isAdditional"
            onChange={handleChangeRadio}
            checked={storeMagazines.isNewBrand === false}
            label="MI REVISTA CORRESPONDE A:"
          />
          {storeMagazines.isNewBrand === false && (
            <div className={classes.formElement}>
              <InputSelect
                id="musicGroup"
                name="musicGroup"
                placeholder="-- ELIGE TU REVISTA --"
                options={storeMagazines.regSudies}
                fieldIdOption="service_order_id"
                value={storeMagazines.studyBelongsId}
                onChange={handleSelect}
              />
            </div>
          )}
          <InputRadio
            id="isAditional"
            value="true"
            name="isAdditional"
            onChange={handleChangeRadio}
            checked={storeMagazines.isNewBrand === true}
            label="REGISTRAR UNA REVISTA ADICIONAL"
          />
          {!validForm && (
            <ErrorMessage message="Elige un estudio de registrabilidad" />
          )}
          <div className={classes.contentButtonNext}>
            <Button type="submit" className="btnConfirm">
              CONTINUAR
            </Button>
          </div>
        </form>
      </div>

      <div className="label-information">
        <div>INFORMACIÓN DE LA REVISTA</div>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default StudySelectionMgz;
