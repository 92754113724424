import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ButtonBack, PaymentStripe, ButtonEdit } from "../../../../components";
import {
  RIGHTS_RENOVATIONS_CONFIRM,
  RIGHTS_RENOVATIONS_REGISTER,
  RIGHTS_RENOVATIIONS_SELECT,
  DASHBOARD_SERVICES
} from "../../../../constants/routes";

const CheckOutRenewal = (props) => {
  const history = useHistory();
  const storeRights = useSelector((state) => state.rights);

  useEffect(() => {
    if (
      !storeRights.isValid &&
      !storeRights.isValidAssignee &&
      !storeRights.isValidRenewal
    ) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [
    history,
    storeRights.isValid,
    storeRights.isValidAssignee,
    storeRights.isValidRenewal,
  ]);
  
  const cameBack = () => {
    if(storeRights.isRegisteredRight){
      history.replace(RIGHTS_RENOVATIIONS_SELECT);
    } else {
      props.history.replace(RIGHTS_RENOVATIONS_REGISTER);
    }
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <div className="container-modulo">
      <ButtonBack url={RIGHTS_RENOVATIONS_REGISTER} text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">RENOVACIÓN DE DERECHOS</h2>
      </div>
      <div className="text-description">
        <p>
          Renovamos tus derechos de propiedad intelectual, de manera expedita.
        </p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DEL DERECHO</div>
        <ButtonEdit onClick={cameBack} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>

      <div>
        <Elements stripe={stripePromise}>
          <PaymentStripe redirect={RIGHTS_RENOVATIONS_CONFIRM} />
        </Elements>
      </div>

      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default CheckOutRenewal;
