import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  ButtonBack,
  InputSelect,
  SelectedClasses,
  InputRadio,
  BtnCallToAction,
  ModalClases,
  HolderForm,
  ButtonEdit,
  ErrorMessage,
} from "../../../../components";
import { calculateClassesPrice } from "../../../../utils/scripts/calculatePrice";
import { tradeMarkActions } from "../../../../store/slices/tradeMark";
import { userProfileActions } from "../../../../store/slices/userProfile";
import { paymentActions } from "../../../../store/slices/payment";
import {
  BRANDS_SELECTION_REGISTERED_MARK,
  BRANDS_REGISTER_CHECKOUT,
  DASHBOARD_SERVICES,
} from "../../../../constants/routes";
import { services as SERVICES } from "../../../../constants/services";
import classes from "./HolderSelect.module.css";

const HolderSelect = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [calsesProdOpen, setCalsesProdOpen] = useState(false);
  const [typeModal, setTypeModal] = useState("productos");
  const [validForm, setValidForm] = useState(false);
  const [submited, setSubmited] = useState(false);

  const tradeMark = useSelector((state) => state.tradeMark);
  const holders = useSelector((state) => state.userProfile.holders);
  const isRegisteredHolder = useSelector(
    (state) => state.tradeMark.isRegisteredHolder
  );
  const storeUserProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (tradeMark.brandBelongsId === -1) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, tradeMark.brandBelongsId]);

  useEffect(() => {
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getHolders());
  }, [dispatch]);

  const handleChangeRegisterHolder = (event) => {
    dispatch(
      tradeMarkActions.setIsRegisteredHolder({
        isRegisteredHolder: event.target.value === "si",
      })
    );
  };

  const hanldleChangeTitularSelect = (event) => {
    dispatch(
      tradeMarkActions.setTitularId({
        titularId: event.target.value,
      })
    );
  };

  const openModalClases = (type) => {
    setCalsesProdOpen(true);
    setTypeModal(type);
  };
  const closeModalClases = () => {
    setCalsesProdOpen(false);
  };

  const clearSelectedClass = (id, type) => {
    let list = [],
      newList = [];
    if (type === "productos") {
      list = [...tradeMark.productClasses];
      newList = list.filter((item) => item.id !== id);
      dispatch(tradeMarkActions.setProductClasses({ productClasses: newList }));
    } else if (type === "servicios") {
      list = [...tradeMark.servicesClasses];
      newList = list.filter((item) => item.id !== id);
      dispatch(
        tradeMarkActions.setServicesClasses({ servicesClasses: newList })
      );
    }
  };

  const getPrice = () => {
    const marcas = storeUserProfile.services.services.filter(
      (item) => item.service_id === SERVICES.MARCAS
    );

    const estudio_reg = marcas[0].service_sub.filter(
      (item) => item.service_id === SERVICES.MARCAS_REGISTRO
    );    
    
    const price =
      (tradeMark.productClasses.length + tradeMark.servicesClasses.length) *
      +estudio_reg[0].service_price + +estudio_reg[0].service_transact;

    const totalprice = calculateClassesPrice(price);

    return totalprice.toFixed(2);
  };

  const validateFile = (file, isRegisteredHolder) => {
    if (!isRegisteredHolder) {
      if (file === "") {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleSubmitButton = () => {
    setSubmited(true);

    let amount = getPrice();
    dispatch(
      paymentActions.setPurchaseAmount({ amount: Number(amount).toFixed(2) })
    );

    const validationForm = validateFile(
      tradeMark.owner_info.file,
      tradeMark.isRegisteredHolder
    );
    setValidForm(validationForm);

    if (validationForm) {
      props.history.replace(BRANDS_REGISTER_CHECKOUT);
    }
  };

  const editBrand = () => {
    props.history.replace(BRANDS_SELECTION_REGISTERED_MARK);
  };

  return (
    <div className="container-modulo">
      <ModalClases
        show={calsesProdOpen}
        type={typeModal}
        closed={closeModalClases}
        scope="tradeMark"
      />
      <ButtonBack url={BRANDS_SELECTION_REGISTERED_MARK} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">REGISTRO DE MARCA</h2>
      </div>

      <div className="text-description">
        <p>
          Protege el nombre, sonido, logotipo o frase comercial con el que
          deseas distinguir tus productos o servicios.
        </p>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>SELECCIÓN DE LA MARCA</div>
        <ButtonEdit onClick={editBrand} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information">
        <div>INFORMACIÓN DE LA MARCA</div>
      </div>

      <div className={classes.descriptionSub}>
        <p>
          Proporciona la información complementaria requerida para realizar el
          registro del nombre de tu marca.
        </p>
      </div>

      <div className={classes.contentRadioSelect}>
        <InputRadio
          id="si"
          name="isRegisteredHolder"
          value="si"
          onChange={handleChangeRegisterHolder}
          label="SELECCIONAR UN TITULAR DE MI LISTA DE TITULARES"
          checked={isRegisteredHolder}
        />
        {isRegisteredHolder && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="- ELIGE UN TITULAR -"
            options={holders}
            fieldIdOption="id"
            value={tradeMark.titularId}
            onChange={hanldleChangeTitularSelect}
          />
        )}

        <InputRadio
          id="no"
          name="isRegisteredHolder"
          value="no"
          onChange={handleChangeRegisterHolder}
          label="REGISTRAR NUEVO TITULAR"
          checked={!isRegisteredHolder}
        />
      </div>
      {!isRegisteredHolder && (
        <div className={classes.contentForm}>
          <HolderForm
            entity="titular-brand"
            recordTypeFile="brand-owner-info"
            textTypeEntity="- ELIGE UN TITULAR -"
          />
        </div>
      )}

      <div className={classes.descriptionSub}>
        <p>
          Por favor especifica todos los tipos de productos y/o servicios que
          estarán asociados con tu marca:
        </p>
      </div>

      <div className="add-controls">
        {tradeMark.productClasses.length > 0 && (
          <SelectedClasses
            selectedClasses={tradeMark.productClasses}
            type="productos"
            onClear={clearSelectedClass}
          />
        )}

        <BtnCallToAction
          onClick={() => openModalClases("productos")}
          icon="faPlusCircle"
        >
          AGREGAR CLASES DE PRODUCTOS
        </BtnCallToAction>

        {tradeMark.servicesClasses.length > 0 && (
          <SelectedClasses
            selectedClasses={tradeMark.servicesClasses}
            type="servicios"
            onClear={clearSelectedClass}
          />
        )}
        <BtnCallToAction
          onClick={() => openModalClases("servicios")}
          icon="faPlusCircle"
        >
          AGREGAR CLASES DE SERVICIOS
        </BtnCallToAction>
      </div>

      <div className={classes.contentButton}>
        {submited && !validForm && <ErrorMessage message="Archivo requerido" />}
        <Button
          onClick={handleSubmitButton}
          className="btnConfirm"
          disabled={!tradeMark.statusFormBrandInfo}
        >
          CONTINUAR
        </Button>
      </div>

      <div className="label-information">
        <div>INFORMACIÓN DE PAGO</div>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default HolderSelect;
