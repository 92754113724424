import classes from "./MyRequest.module.css";

const PaymentImpediment = ({ dudate, amount, transaction }) => {
  return (
    <div className={classes.paymentContent}>
      <div className={classes.paymentSuccessHeader}>PAGO REALIZADO</div>
      <div className={classes.contentPaymentSuccess}>
        <div>
          Fecha: <span className={classes.dataPayment}>{dudate}</span>
        </div>
        <div>
          Monto: <span className={classes.dataPayment}>${amount}</span>
        </div>
        <div>
          Transacción:{" "}
          <span className={classes.dataPayment}>{transaction}</span>
        </div>
      </div>
    </div>
  );
};

export default PaymentImpediment;
