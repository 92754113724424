import { BigProfile } from "../../assets/images/icons/icons";
import { ItemMenuLight } from "../../components";
import {
  MY_REQUEST,
  PROFILE_CHANGE_PASS,
  BUSINESS_NAME,
} from "../../constants/routes";
import { useAuth } from "../../context/auth";
import "./Profile.css";

const Profile = () => {
  const { signOut } = useAuth();
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="container-modulo">
      <div className="section-title">
        <h2 className="subtitle">MI PERFIL</h2>
        <img src={BigProfile} alt="marcas" className="item-servicios" />
        <p className="user-name">{user.full_name}</p>
        <a href={`mailto:${user.email}`} className="user-mail">
          {user.email}
        </a>
      </div>

      <div className="grid-container">
        <ItemMenuLight
          icon="DocumentBlue"
          iconAlt="Mis Solicitudes"
          textLink="Mis Solicitudes"
          path={MY_REQUEST}
        />
        <ItemMenuLight
          icon="KeyBlue"
          iconAlt="Cambiar contraseña"
          textLink="Cambiar contraseña"
          path={PROFILE_CHANGE_PASS}
        />
        <ItemMenuLight
          icon="ProfileBlue"
          iconAlt="Razones sociales para facturación"
          textLink="Razones sociales para facturación"
          path={BUSINESS_NAME}
        />
        <ItemMenuLight
          icon="SignOffBlue"
          iconAlt="Cerrar Sesión"
          textLink="Cerrar Sesión"
          onClick={signOut}
        />
      </div>
    </div>
  );
};

export default Profile;
