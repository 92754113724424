import React from 'react';
import classes from './Footer.module.css';

const footer = () => {
  return (
    <footer className={classes.footer}>
      <p>&copy; 2021 A tu alcance, todos los derechos reservados.</p>
    </footer>
  );
}


export default footer;