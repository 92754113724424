import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faArrowLeft, faEye } from "@fortawesome/free-solid-svg-icons";

import classes from "./ButtonRound.module.css";

const ButtonRound = (props) => {
  let icon =
    {
      faPen: faPen,
      faArrowLeft: faArrowLeft,
      faEye: faEye,
    }[props.icon] || faPen;

  return (
    <button onClick={props.onClick} className={classes.btnRound}>
      <FontAwesomeIcon icon={icon} className={classes.btnIcon} />
      {props.children}
    </button>
  );
};

export default ButtonRound;
