import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import EntityTemplate from "../Entity/EntityTemplate";
import { Button, Spinner } from "../../../components";
import { AtaCurrencyFormat } from "../../Common";
import { cataTipoDerecho } from "../../../utils/cat-tipo-derecho";
import classes from "./ConfirmationTemplate.module.css";

const RightsRenewalTemplate = (props) => {
  const [accepted, setAccepted] = useState(false);
  const [sendConfirm, setSendConfirm] = useState(false);
  const [dataTitular, setDataTitular] = useState([]);
  const [brandInfo, setBrandInfo] = useState([]);

  const storeEntity = useSelector((state) => state.entity);
  const storeHolder = useSelector((state) => state.userProfile.holders);

  useEffect(() => {
    if (props.registerData.isRegisteredRight) {
      setDataTitular(props.registerData.registerdRights[0]?.owner_info);
    } else if (props.registerData.isRegisteredHolder) {
      const titular = storeHolder.filter(
        (element) => element.id === +props.registerData.owner_id
      );
      setDataTitular(titular[0]);
    } else {
      setDataTitular([]);
    }
  }, [
    props.registerData.isRegisteredHolder,
    props.registerData.isRegisteredRight,
    props.registerData.owner_id,
    props.registerData.registerdRights,
    storeHolder,
  ]);

  useEffect(() => {
    if (props.registerData.isRegisteredRight) {
      const type = cataTipoDerecho.filter(
        (item) => +item.id === +props.registerData.registerdRights[0]?.brand_info.right_type
      );

      setBrandInfo({
        right_type: type[0]?.name,
        registration_number: props.registerData.registerdRights[0]?.brand_info.registration_number,
        name: props.registerData.registerdRights[0]?.brand_info.name,
      });
    } else {
      const type = cataTipoDerecho.filter(
        (item) => item.id === props.registerData.brand_info.right_type
      );
      setBrandInfo({
        right_type: type[0]?.name,
        registration_number: props.registerData.brand_info.number,
        name: props.registerData.brand_info.name,
      });
    }
  }, [
    props.registerData.brand_info.name,
    props.registerData.brand_info.number,
    props.registerData.brand_info.right_type,
    props.registerData.isRegisteredRight,
    props.registerData.registerdRights,
  ]);

  const confirmation = (callback) => {
    setSendConfirm(true);
    callback();
  };

  return (
    <div className={classes.confirmation}>
      <div className={classes.confirmationItem}>
        <h3>TIPO DE DERECHO</h3>
        <p>{brandInfo.right_type}</p>
      </div>
      <div className={classes.confirmationItem}>
        <h3>NÚMERO DE REGISTRO DEL DERECHO</h3>
        <p>{brandInfo.registration_number}</p>
      </div>
      <div className={classes.confirmationItem}>
        <h3>NOMBRE DEL DERECHO</h3>
        <p>{brandInfo.name}</p>
      </div>

      <EntityTemplate key="titular" title="DATOS DEL TITULAR">
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>NOMBRE</div>
            <div className={classes.itemEntity}>
              {dataTitular.name || storeEntity.titular.titularName.value}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>CALLE</div>
            <div className={classes.itemEntity}>
              {dataTitular.street || storeEntity.titular.street.value}
            </div>
          </div>
        </div>
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>NÚMERO EXTERIOR</div>
            <div className={classes.itemEntity}>
              {dataTitular.ext_number || storeEntity.titular.extNumber.value}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>NÚMERO INTERIOR</div>
            <div className={classes.itemEntity}>
              {dataTitular.int_number || storeEntity.titular.intNumber.value}
            </div>
          </div>
        </div>
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>CORREO ELECTRÓNICO</div>
            <div className={classes.itemEntity}>
              {dataTitular.email || storeEntity.titular.email.value}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>TELÉFONO</div>
            <div className={classes.itemEntity}>
              {dataTitular.phone || storeEntity.titular.phone.value}
            </div>
          </div>
        </div>
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>ESTADO</div>
            <div className={classes.itemEntity}>
              {dataTitular.state || storeEntity.titular.state.value}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>CIUDAD</div>
            <div className={classes.itemEntity}>
              {dataTitular.city || storeEntity.titular.city.value}
            </div>
          </div>
        </div>
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>CÓDIGO POSTAL</div>
            <div className={classes.itemEntity}>
              {dataTitular.postal_code || storeEntity.titular.postalCode.value}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>COLONIA</div>
            <div className={classes.itemEntity}>
              {dataTitular.neighborhood ||
                storeEntity.titular.neighborhood.value}
            </div>
          </div>
        </div>
      </EntityTemplate>

      <div className={classes.confirmationItem}>
        <h3>METÓDO DE PAGO</h3>
        <p>{props.paymentData.paymentMethod}</p>
      </div>
      <div className={classes.confirmationItem}>
        <h3>TOTAL</h3>
        <div>
          <span className={classes.total}>
            <AtaCurrencyFormat value={props.paymentData.purchaseAmount} />
          </span>
        </div>
      </div>
      <div className={classes.termsAndConditions}>
        <label className={classes.checkitem}>
          <label htmlFor="terms" className={classes.terms}>
            Acepto todos los &nbsp;
            <NavLink to="/terminos-y-condiciones" className={classes.linkText}>
              TÉRMINOS Y CONDICIONES
            </NavLink>
          </label>
          <input
            type="checkbox"
            id="terms"
            name="terms"
            onChange={(event) => setAccepted(event.target.checked)}
          />
          <span className={classes.checkmark}></span>
        </label>
      </div>
      <div className={classes.textDescription}>
        <p>
          Al presionar "confirmar solicitud" usted acepta todos los términos y
          condiciones de <b>A tu alcance.</b>
        </p>
        <p>El pago de esta solicitud no garantiza la aprobación de la misma.</p>
      </div>
      <div className={classes.btnConfirm}>
        {sendConfirm ? (
          <Spinner />
        ) : (
          <Button
            className={"btnConfirm"}
            onClick={() => confirmation(props.onConfirm)}
            disabled={!accepted}
          >
            CONFIRMAR SOLICITUD
          </Button>
        )}
      </div>
    </div>
  );
};

RightsRenewalTemplate.propTypes = {
  registerData: PropTypes.object.isRequired,
  paymentData: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  userProfile: PropTypes.object,
  scope: PropTypes.string.isRequired,
};

export default RightsRenewalTemplate;
