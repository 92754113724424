import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus
} from "@fortawesome/free-solid-svg-icons";
import classes from "./Collapsible.module.css";

const CommentsColap = ({title, children}) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={classes.containerCollap}>
      <button
        className={`${classes.collapsible} ${collapsed && classes.active} `}
        onClick={handleCollapsed}
      >
        <div className={classes.title}>
          <div>
            {title}
          </div>
          <div className={classes.collapsibleIcon}>
            {collapsed ? (
              <FontAwesomeIcon icon={faPlus} />
            ) : (
              <FontAwesomeIcon icon={faMinus} />
            )}
          </div>
        </div>
      </button>
      <div
        className={classes.contentComments}
        style={collapsed ? { maxHeight: "0", padding: "0" } : {}}
      >
        {children}
      </div>
    </div>
  );
};

CommentsColap.propTypes = {
  title: PropTypes.string.isRequired
}

export default React.memo(CommentsColap);
