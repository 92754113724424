import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ButtonEdit, RenewalTemplate } from "../../../../components";

const RenewalConfirmation = ({
  renewal,
  price,
  handleSubmit,
  handleEditInfo,
  handleCheckOut,
  loading,
  error,
}) => {
  const paymentData = useSelector((state) => state.payment);
  return (
    <>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DEL PAGO</div>
        <ButtonEdit onClick={handleCheckOut} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
      <RenewalTemplate
        renewal={renewal}
        onSubmit={handleSubmit}
        paymentData={paymentData}
        price={price}
        loading={loading}
        error={error}
      />
    </>
  );
};

RenewalConfirmation.prototype = {
  renewal: PropTypes.array.isRequired,
  price: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleEditInfo: PropTypes.func.isRequired,
  handleCheckOut: PropTypes.func.isRequired,
};

export default RenewalConfirmation;
