import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  ButtonEdit,
  InputRadio,
  InputSelect,
  EntityForm,
  Button,
  ErrorMessage,
} from "../../../../components";
import { caculateAmountPrice } from "../../../../utils/scripts/calculatePrice";
import { publicationsActions } from "../../../../store/slices/publications";
import { userProfileActions } from "../../../../store/slices/userProfile";
import { paymentActions } from "../../../../store/slices/payment";
import {
  PUBLICATIONS_REGISTER_CHECKOUT,
  PUBLICATIONS_REGISTER_CONFIRMATION,
  PUBLICATIONS_REGISTER_SELECT_STUDY,
  DASHBOARD_SERVICES,
} from "../../../../constants/routes";
import classes from "../RegPublications.module.css";
import { USER_PDLC } from "../../../../constants";

const CHECKOUT_MG = PUBLICATIONS_REGISTER_CHECKOUT;
const STUDY_URL = PUBLICATIONS_REGISTER_SELECT_STUDY;

const HolderSelectPublications = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [validForm, setValidForm] = useState(true);
  const [validFiles, setValidFiles] = useState(true);
  const [submited, setSubmited] = useState(false);
  const [userData, setUserData] = useState();

  const storePublications = useSelector((state) => state.publications);
  const storeUserProfile = useSelector((state) => state.userProfile);
  const storeEntity = useSelector((state) => state.entity);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
  }, []);

  useEffect(() => {
    if (storePublications.studyBelongsId === -1) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storePublications.studyBelongsId]);

  useEffect(() => {
    dispatch(userProfileActions.getHolders());
  }, [dispatch]);

  useEffect(() => {
    if (storePublications.isRegisteredHolder) {
      if (storePublications.titularId === "-1") {
        setValidForm(false);
      } else {
        setValidForm(true);
      }
    } else {
      if (storeEntity.titular.valid) {
        setValidForm(true);
      } else {
        setValidForm(false);
      }
    }
  }, [
    storeEntity.titular.valid,
    storePublications.isRegisteredHolder,
    storePublications.titularId,
  ]);

  useEffect(() => {
    if (storePublications.isRegisteredHolder) {
      setValidFiles(true);
    } else {
      if (storeEntity.titular.file === "") {
        setValidFiles(false);
      } else {
        setValidFiles(true);
      }
    }
  }, [storeEntity.titular.file, storePublications.isRegisteredHolder]);

  const getPrice = () => {
    const publications = storeUserProfile.services.services.filter(
      (item) => item.service_id === 14
    );
    const EdrMusicalGroups = publications[0].service_sub.filter(
      (item) => item.service_id === 16
    );

    const price =
      +EdrMusicalGroups[0].service_price +
      +EdrMusicalGroups[0].service_transact;

    const totalprice = caculateAmountPrice(price);
    return totalprice.toFixed(2);
  };

  const btnEdit = () => {
    history.replace(STUDY_URL);
  };

  const handleChangeRegisterHolder = (event) => {
    dispatch(
      publicationsActions.setIsRegisteredHolder({
        isRegisteredHolder: event.target.value === "si",
      })
    );
  };

  const handleSubmitButton = () => {
    setSubmited(true);
    if (validFiles) {
      const price = getPrice();
      dispatch(paymentActions.setPurchaseAmount({ amount: price }));
      if (userData.user_id === USER_PDLC) {
        history.replace(PUBLICATIONS_REGISTER_CONFIRMATION);
      } else {
        history.replace(CHECKOUT_MG);
      }
    }
  };
  const hanldleChangeTitularSelect = (event) => {
    dispatch(
      publicationsActions.setTitularId({
        titularId: event.target.value,
      })
    );
  };

  return (
    <div className="container-modulo">
      <div className="section-title">
        <h2 className="subtitle">
          REGISTRO DE NOMBRE DE PUBLICACIONES PERIÓDICAS VÍA REDES DE CÓMPUTO
        </h2>
      </div>
      <div className="text-description">
        <p>Protege tu Publicación de manera práctica y sencilla.</p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>SELECCIÓN DE LA PUBLICACIÓN</div>
        <ButtonEdit onClick={btnEdit} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information">
        <div>INFORMACIÓN DE LA PUBLICACIÓN</div>
      </div>
      <div className="sub-description">
        <p>
          Proporciona la información complementaria requerida para realizar el
          registro del nombre de tu publicación.
        </p>
      </div>

      <div className="container-select">
        <InputRadio
          id="si"
          name="isRegisteredHolder"
          value="si"
          onChange={handleChangeRegisterHolder}
          label="SELECCIONAR UN TITULAR DE MI LISTA DE TITULARES"
          checked={storePublications.isRegisteredHolder}
        />
        {storePublications.isRegisteredHolder && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="- ELIGE UN TITULAR -"
            options={storeUserProfile.holders}
            fieldIdOption="id"
            value={storePublications.titularId}
            onChange={hanldleChangeTitularSelect}
          />
        )}
        <InputRadio
          id="no"
          name="isRegisteredHolder"
          value="no"
          onChange={handleChangeRegisterHolder}
          label="REGISTRAR NUEVO TITULAR"
          checked={!storePublications.isRegisteredHolder}
        />
      </div>
      {!storePublications.isRegisteredHolder && (
        <div className={classes.contentForm}>
          <EntityForm
            typeEntity="titular"
            entity="titular-musical-group"
            recordTypeFile="brand-owner-info"
          />
        </div>
      )}
      <div className="container-select">
        {submited && !validFiles && (
          <ErrorMessage message="Sube la identificación oficial" />
        )}
      </div>

      <div className={classes.contentButton}>
        <Button
          onClick={handleSubmitButton}
          className="btnConfirm"
          disabled={!validForm}
        >
          CONTINUAR
        </Button>
      </div>

      <div className="label-information">
        <div>INFORMACIÓN DE PAGO</div>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default HolderSelectPublications;
