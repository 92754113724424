import {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import axios from "axios";

const defaultUser = {
  user_id: null,
  user_name: "",
  last_name_mom: "",
  full_name: "",
  email: "",
  idToken: "",
  refreshtoken: "",
};

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    setLoading(false);
  }, []);

  const getAuth = (email, password) => {
    return axios
      .post(
        process.env.REACT_APP_URL_BASE + "auth/signin",
        {
          username: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response)
      .catch((error) => {
        setError(error);
        return error;
      });
  };

  const logIn = useCallback(async (user, password) => {
    const user_auth = await getAuth(user, password);
    // console.log(user_auth.response.status);
    if (user_auth.data) {
      defaultUser.user_id = user_auth.data.data_user[0].user_id;
      defaultUser.user_name = user_auth.data.data_user[0].user_name;
      defaultUser.last_name_mom = user_auth.data.data_user[0].last_name_mom;
      defaultUser.full_name = user_auth.data.data_user[0].full_name;
      defaultUser.email = user_auth.data.data_user[0].email;
      defaultUser.idToken = user_auth.data.cognito.idToken;
      defaultUser.refreshtoken = user_auth.data.cognito.refreshtoken;
      localStorage.setItem("user", JSON.stringify(defaultUser));
      setUser(localStorage.getItem("user"));
      setLoading(false);
    } else {
      setLoading(false);
    }
    return user_auth;
  }, []);

  const signOut = useCallback(() => {
    localStorage.clear("user");
    setUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, logIn, signOut, loading, error }}
      {...props}
    />
  );
}

export { AuthProvider, useAuth };
