import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import basicAuthRequest from "../../../../utils/axios/basicAuthRequest";
import { caculateAmountPrice } from "../../../../utils/scripts/calculatePrice";
import { paymentActions } from "../../../../store/slices/payment";
import { GET_SERVICE_RECURRENT } from "../../../../constants/atualcanceApi";
import { services as SERVICES } from "../../../../constants/services";
import { CREATE_ORDER } from "../../../../constants/atualcanceApi";
import { DASHBOARD_SERVICES, TICKECT } from "../../../../constants/routes";

const useTradeMark = (order_id) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [tradeMark, setTradeMark] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [price, setPrice] = useState(0);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState("");

  const paymentId = useSelector((state) => state.payment.paymentMethodid);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const getOrder = async () => {
      setLoading(true);
      const response = await basicAuthRequest({
        method: "GET",
        url:
          GET_SERVICE_RECURRENT +
          user.user_id +
          "/service/" +
          SERVICES.DECLARACION_USO_MARCA +
          "/serviceorder/" +
          order_id,
      }).catch((error) => {
        setTradeMark([]);
        setError(error);
        setLoading(false);
      });

      if (response?.status === 200) {
        if (response.data.length) {
          const price = caculateAmountPrice(
            +response.data[0].transact_amount +
              +response.data[0].honorarium_amount
          );
          setPrice(price);
          setTradeMark(response.data);
          setLoading(false);
        } else {
          setLoading(false);
          history.replace(DASHBOARD_SERVICES);
        }
      }
    };
    getOrder();

    return () => {
      setTradeMark([]);
      setLoading(false);
      setError(null);
    };
  }, [history, order_id]);

  const savePayment = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    setSaveLoading(true);

    const bodyRequest = {
      user_id: user.user_id,
      service_id: SERVICES.DECLARACION_USO_MARCA,
      service_order_id: +order_id,
      service_recurrent_id: tradeMark[0]?.service_recurrent_id,
      brand_info: {},
      billing_info: {
        payment_method_id: paymentId,
        price_total: +Number(price).toFixed(2),
      },
    };

    try {
      const response = await basicAuthRequest({
        url: CREATE_ORDER,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: bodyRequest,
      })
        
      dispatch(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      if (response.error === undefined) {
        history.replace(TICKECT);
      } else {
        setSaveError(response.error);
      }
      setSaveLoading(false);
    } catch (error) {
      setSaveError(error);
    } finally {
      setSaveLoading(false);
    }
  };

  return {
    tradeMark,
    loading,
    price,
    error,
    savePayment,
    saveLoading,
    saveError
  };
};

export default useTradeMark;
