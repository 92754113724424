import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  InputSimpleFloatLabel,
  InputRadio,
  InputSelect,
  EntityForm,
  Button,
  ItemList,
  ErrorMessage,
  InputDayPicker,
} from "../../../components";
import { caculateAmountPrice } from "../../../utils/scripts/calculatePrice";
import { catPeriod } from "../../../utils/cat-period";
import { formatDate } from "../../../utils/scripts/formatDate";
import { userProfileActions } from "../../../store/slices/userProfile";
import { rightsActions } from "../../../store/slices/rights";
import { paymentActions } from "../../../store/slices/payment";
import {
  RIGHTS_LICENSE_CHECKOUT,
  RIGHTS_LICENSE_CONFIRMATION,
} from "../../../constants/routes";
import { USER_PDLC } from "../../../constants";

const CHECKOUT_RIGHTS_LICENCE = RIGHTS_LICENSE_CHECKOUT;

const License = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [registerNumber, setRegisterNumber] = useState("");
  const [showNumberError, setShowNumberError] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [validFiles, setValidFiles] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [userData, setUserData] = useState();

  const storeUserProfile = useSelector((state) => state.userProfile);
  const storeTitular = useSelector((state) => state.entity.titular);
  const storeLicensee = useSelector((state) => state.entity.licenciatario);
  const storeRights = useSelector((state) => state.rights);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
  }, []);

  useEffect(() => {
    dispatch(userProfileActions.getEntitiesObras());
    dispatch(userProfileActions.getServices());
  }, [dispatch]);

  useEffect(() => {
    let valid = true;
    if (storeRights.isValid) {
      if (!storeRights.isRegisteredHolder) {
        if (!storeTitular.valid) {
          valid = false;
        }
      }

      if (!storeRights.isRegisteredLicense) {
        if (!storeLicensee.valid) {
          valid = false;
        }
      }
      setValidForm(valid);
    } else {
      setValidForm(false);
    }
  }, [
    storeLicensee.valid,
    storeRights.isRegisteredHolder,
    storeRights.isRegisteredLicense,
    storeRights.isValid,
    storeTitular.valid,
  ]);

  useEffect(() => {
    let valid = true;
    if (!storeRights.isRegisteredHolder) {
      if (storeTitular.file === "") {
        valid = false;
      }
    }
    if (!storeRights.isRegisteredLicense) {
      if (storeLicensee.file === "") {
        valid = false;
      }
    }

    setValidFiles(valid);
  }, [
    storeLicensee.file,
    storeRights.isRegisteredHolder,
    storeRights.isRegisteredLicense,
    storeTitular.file,
  ]);

  const handleChangeInputNumber = (event) => {
    setRegisterNumber(event.target.value);
  };

  const addNumber = () => {
    setShowNumberError(false);
    const existNumber = storeRights.brand_info.registration_number.find(
      (item) => item === +registerNumber
    );

    if (typeof existNumber === "undefined") {
      dispatch(
        rightsActions.setBrandRegistrationNumber({
          registration_number: +registerNumber,
        })
      );
    } else {
      setShowNumberError(true);
    }
    setRegisterNumber("");
  };

  const deleteNumber = (number) => {
    const items = { ...storeRights.brand_info.registration_number };
    const aItems = Object.values(items);
    const index = aItems.indexOf(number);
    if (index > -1) {
      aItems.splice(index, 1);
      setShowNumberError(false);
    }

    dispatch(
      rightsActions.replaceRegistrationNumbers({
        registration_number: aItems,
      })
    );
  };

  const handleChangeRegisterHolder = (event) => {
    dispatch(
      rightsActions.setIsRegisteredHolder({
        isRegisteredHolder: event.target.value === "si",
      })
    );
  };

  const handleChangeRegisterLicense = (event) => {
    dispatch(
      rightsActions.setIsRegisteredLicense({
        isRegisteredLicense: event.target.value === "si",
      })
    );
  };

  const handleChangePrivacy = (event) => {
    dispatch(
      rightsActions.setPrivacy({
        privacy: event.target.value === "si",
      })
    );
  };

  const hanldleChangeTitularSelect = (event) => {
    dispatch(
      rightsActions.setOwnerId({
        owner_id: event.target.value,
      })
    );
  };

  const hanldleChangeLicenseeSelect = (event) => {
    dispatch(
      rightsActions.setLicenseeId({
        licensee_id: event.target.value,
      })
    );
  };

  //TODO: Validar que la fecha inicial sea menor que la fecha final
  const handleChangefrom = (date) => {
    const fromDate = formatDate(date);
    dispatch(
      rightsActions.setInitialDate({
        initial_date: fromDate,
      })
    );
  };

  const handleChangeTo = (date) => {
    const toDate = formatDate(date);
    dispatch(
      rightsActions.setFinalDate({
        final_date: toDate,
      })
    );
  };

  const handleChangeAmount = (event) => {
    dispatch(
      rightsActions.setAmount({
        amount: event.target.value,
      })
    );
  };

  const handleChangePeriod = (event) => {
    dispatch(
      rightsActions.setPeriod({
        period: event.target.value,
      })
    );
  };

  const getPrice = () => {
    const rightsLicense = storeUserProfile.services.services.filter(
      (item) => item.service_id === 20
    );

    const price =
      +rightsLicense[0].service_transact +
      +rightsLicense[0].service_price *
        storeRights.brand_info.registration_number.length;

    const totalprice = caculateAmountPrice(price);

    return totalprice.toFixed(2);
  };

  const handleButtonNext = () => {
    setSubmittedForm(true);
    if (validFiles) {
      const price = getPrice();
      dispatch(paymentActions.setPurchaseAmount({ amount: price }));
      if (userData.user_id === USER_PDLC) {
        history.replace(RIGHTS_LICENSE_CONFIRMATION);
      } else {
        history.replace(CHECKOUT_RIGHTS_LICENCE);
      }
    }
  };

  return (
    <div className="container-modulo">
      <div className="section-title">
        <h2 className="subtitle">LICENCIA DE DERECHOS</h2>
      </div>
      <div className="text-description">
        <p>
          Elaboración e Inscripción ante el IMPI del Contrato de Licencia de Uso
          de derechos de Propiedad Intelectual, entre tu y tu socio comercial,
          de manera práctica y sencilla.
        </p>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DEL DERECHO</div>
      </div>
      <div className="content-form-main">
        <InputSimpleFloatLabel
          id="number"
          name="number"
          value={registerNumber}
          valid={true}
          placeholder="NÚMERO DE REGISTRO"
          type="number"
          onChange={handleChangeInputNumber}
        />
        {showNumberError && (
          <ErrorMessage message="El número ya ha sido agregado" />
        )}

        {storeRights.brand_info.registration_number.map((item) => (
          <ItemList
            key={item}
            number={item}
            deleteItem={() => deleteNumber(item)}
          />
        ))}

        <div className="contentainer-button">
          <Button
            onClick={addNumber}
            className="btnConfirm"
            disabled={!registerNumber.length > 0}
          >
            ADICIONAR DERECHO
          </Button>
        </div>
        <div className="descripction-action">
          Todos los números de registro deberán pertenecer al mismo titular.
        </div>

        <InputRadio
          id="si"
          name="isRegisteredHolder"
          value="si"
          onChange={handleChangeRegisterHolder}
          label="SELECCIONAR UN TITULAR DE MI LISTA DE TITULARES"
          checked={storeRights.isRegisteredHolder}
        />
        {storeRights.isRegisteredHolder && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="- ELIGE UN TITULAR -"
            options={storeUserProfile.holders}
            fieldIdOption="id"
            value={storeRights.owner_id}
            onChange={hanldleChangeTitularSelect}
          />
        )}

        <InputRadio
          id="no"
          name="isRegisteredHolder"
          value="no"
          onChange={handleChangeRegisterHolder}
          label="REGISTRAR NUEVO TITULAR"
          checked={!storeRights.isRegisteredHolder}
        />
      </div>
      {!storeRights.isRegisteredHolder && (
        <EntityForm
          typeEntity="titular"
          entity="titular-musical-group"
          recordTypeFile="brand-owner-info"
        />
      )}
      <div className="container-input-text-description">
        <p>
          Señala el inicio y fin del periodo de licencia de uso de marca que
          estas otorgando a tu Licenciatario
        </p>
      </div>

      <div className="form-fields">
        <div className="container-input-form">
          <InputDayPicker
            placeholder="DESDE"
            value={storeRights.brand_info.initial_date}
            onDayChange={(date) => handleChangefrom(date)}
          />
        </div>
        <div className="container-input-form">
          <InputDayPicker
            placeholder="HASTA"
            value={storeRights.brand_info.final_date}
            onDayChange={(date) => handleChangeTo(date)}
          />
        </div>
      </div>

      <div className="container-input-text-description">
        <p>
          Insertar la cantidad mensual o anual que pagará el Licenciatario por
          el uso de los derechos licenciados.
        </p>
      </div>
      <div className="form-fields">
        <div className="container-input-form">
          <InputSimpleFloatLabel
            id="amount"
            name="amount"
            placeholder="CANTIDAD"
            onChange={handleChangeAmount}
            defaultValue={storeRights.brand_info.amount}
            valid={true}
          />
        </div>
        <div className="container-input-form">
          <InputSelect
            id="period"
            name="period"
            placeholder="- PERIODO DE PAGO -"
            options={catPeriod}
            fieldIdOption="id"
            value={storeRights.brand_info.payment_period_amount}
            onChange={handleChangePeriod}
          />
        </div>
      </div>

      <div className="content-form-main">
        <InputRadio
          id="licenceSi"
          name="isRegisteredLicense"
          value="si"
          onChange={handleChangeRegisterLicense}
          label="SELECCIONAR UN LICENCIATARIO"
          checked={storeRights.isRegisteredLicense}
        />
        {storeRights.isRegisteredLicense && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="- ELIGE UN LICENCIATARIO -"
            options={storeUserProfile.licensee}
            fieldIdOption="id"
            value={storeRights.licensee_id}
            onChange={hanldleChangeLicenseeSelect}
          />
        )}
        <InputRadio
          id="licenceNo"
          name="isRegisteredLicense"
          value="no"
          onChange={handleChangeRegisterLicense}
          label="REGISTRAR UN NUEVO LICENCIATARIO"
          checked={!storeRights.isRegisteredLicense}
        />
      </div>
      {!storeRights.isRegisteredLicense && (
        <EntityForm
          typeEntity="licenciatario"
          entity="titular-musical-group"
          recordTypeFile="brand-owner-info"
        />
      )}

      <div className="container-input-text-description">
        <p>
          Si deseas otorgar a tu Licenciatario la facultad de iniciar
          procedimientos de piratería en tu nombre y representación relacionados
          con la defensa de los derechos, elige SI, en caso de que desees
          reservarte ese derecho, elige NO.
        </p>
      </div>
      <div className="content-form-main">
        <InputRadio
          id="privacySi"
          name="privacy"
          value="si"
          onChange={handleChangePrivacy}
          label="SI"
          checked={storeRights.brand_info.privacy}
        />
        <InputRadio
          id="privacyNo"
          name="privacy"
          value="no"
          onChange={handleChangePrivacy}
          label="NO"
          checked={!storeRights.brand_info.privacy}
        />
        {submittedForm && !validFiles && (
          <ErrorMessage message="Archivo identificación oficial es requerido" />
        )}
        <div className="contentainer-button">
          <Button
            onClick={handleButtonNext}
            className="btnConfirm"
            disabled={!validForm}
          >
            CONTINUAR
          </Button>
        </div>
      </div>
    </div>
  );
};

export default License;
