import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  ButtonBack,
  InputSimpleFloatLabel,
  InputSelect,
  Button,
  Spinner,
  ErrorMessage,
} from "../../../components";
import basicAuthRequest from "../../../utils/axios/basicAuthRequest";
import { listStates } from "../../../utils/states";
import { listCity } from "../../../utils/states-city";
import { BUSINESS_NAME } from "../../../constants/routes";
import { MESSAGES, REGEXP } from "../../../constants/form";
import { CREATE_BUSINESS_NAME } from "../../../constants/atualcanceApi";
import classes from "./FormBusinessName.module.css";

const FormBusinessName = () => {
  const history = useHistory();
  const [cities, setCities] = useState([]);
  const [rfc, setRfc] = useState("");
  const [error, setError] = useState("");
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    return () => {
      setCities([]);
      setRfc("");
      setError("");
      setSubmit(false);
    };
  }, []);

  const initialValues = {
    name: "",
    rfc: "",
    street: "",
    ext_number: "",
    int_number: "",
    email: "",
    phone: "",
    state: "",
    city: "",
    postal_code: "",
    neighborhood: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .required(MESSAGES.REQUIRED)
      .max(200)
      .matches(REGEXP.RAZON_SOCIAL, MESSAGES.NOT_MATCH),
    rfc: Yup.string()
      .required(MESSAGES.REQUIRED)
      .min(12, MESSAGES.MIN)
      .max(13, MESSAGES.MAX)
      .matches(REGEXP.RFC, MESSAGES.NOT_MATCH_RFC),
    street: Yup.string()
      .required(MESSAGES.REQUIRED)
      .max(100, MESSAGES.MAX)
      .matches(REGEXP.STREET, MESSAGES.NOT_MATCH),
    ext_number: Yup.string()
      .required(MESSAGES.REQUIRED)
      .max(30)
      .matches(REGEXP.NUMBER, MESSAGES.NOT_MATCH),
    int_number: Yup.string()
      .max(30, MESSAGES.MAX)
      .matches(REGEXP.NUMBER, MESSAGES.NOT_MATCH),
    email: Yup.string()
      .required(MESSAGES.REQUIRED)
      .email(MESSAGES.EMAIL_INVALID)
      .max(70, MESSAGES.MAX),
    phone: Yup.string()
      .required(MESSAGES.REQUIRED)
      .max(10, MESSAGES.MAX)
      .matches(REGEXP.PHONE, MESSAGES.NOT_MATCH),
    state: Yup.string()
      .required(MESSAGES.REQUIRED)
      .min(2, MESSAGES.MIN)
      .max(3, MESSAGES.MAX)
      .matches(REGEXP.STATE, MESSAGES.REQUIRED),
    city: Yup.string()
      .required(MESSAGES.REQUIRED)
      .max(250, MESSAGES.MAX)
      .matches(REGEXP.CITY, MESSAGES.REQUIRED),
    postal_code: Yup.string()
      .required(MESSAGES.REQUIRED)
      .max(10, MESSAGES.MAX)
      .matches(REGEXP.POSTAL_CODE, MESSAGES.NOT_MATCH),
    neighborhood: Yup.string()
      .required(MESSAGES.REQUIRED)
      .max(220, MESSAGES.MAX)
      .matches(REGEXP.NEIGHBORHOOD, MESSAGES.NOT_MATCH),
  });

  const setCityValue = (event) => {
    event.target.value !== "-1"
      ? setCities(listCity[event.target.value])
      : setCities([]);
  };

  const mayusRfc = (event) => {
    setRfc(("", event.target.value).toUpperCase());
  };

  const onSubmit = (values) => {
    setSubmit(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const body = {
      user_id: user.user_id,
      business_info: {
        name: values.name,
        rfc: values.rfc,
        street: values.street,
        ext_number: values.ext_number,
        int_number: values.int_number,
        email: values.email,
        phone: values.phone,
        state: values.state,
        city: values.city,
        postal_code: values.postal_code,
        neighborhood: values.neighborhood,
      },
    };

    try {
      basicAuthRequest
        .request({
          url: CREATE_BUSINESS_NAME,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: body,
        })
        .then((response) => {
          if (response.message === "Request failed with status code 409") {
            setError(MESSAGES.RFC_ALREADY_EXISTS);
            setSubmit(false);
          } else {
            if (response === undefined || response.status !== 200) {
              setError(MESSAGES.BAD_REQUEST);
            } else {
              history.replace(BUSINESS_NAME);
            }
            setSubmit(false);
          }
        })
        .catch((error) => {
          setError(MESSAGES.BAD_REQUEST);
        });
    } catch (error) {
      setError(MESSAGES.BAD_REQUEST);
      setSubmit(false);
    }
  };

  return (
    <div className="container-modulo">
      <ButtonBack url={BUSINESS_NAME} text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">NUEVA RAZÓN SOCIAL</h2>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          handleChange,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className={classes.formFields}>
              <div>
                <InputSimpleFloatLabel
                  id="name"
                  name="name"
                  placeholder="RAZÓN SOCIAL"
                  onChange={handleChange}
                  defaultValue={values.rfc}
                  valid={!Boolean(errors.name && touched.name)}
                  touched={touched.name}
                  maxLength={200}
                />
                {errors.name && touched.name && (
                  <div className="erros-form">{errors.name}</div>
                )}
              </div>
              <div>
                <InputSimpleFloatLabel
                  id="rfc"
                  name="rfc"
                  placeholder="RFC"
                  onChange={(e) => {
                    mayusRfc(e);
                    handleChange(e);
                  }}
                  value={rfc}
                  valid={!Boolean(errors.rfc && touched.rfc)}
                  touched={touched.rfc}
                  maxLength={13}
                />
                {errors.rfc && touched.rfc && (
                  <div className="erros-form">{errors.rfc}</div>
                )}
              </div>
            </div>
            <div className={classes.formFields}>
              <div>
                <InputSimpleFloatLabel
                  id="street"
                  name="street"
                  placeholder="CALLE"
                  onChange={handleChange}
                  valid={!Boolean(errors.street && touched.street)}
                  touched={touched.street}
                  maxLength={100}
                />
                {errors.street && touched.street && (
                  <div className="erros-form">{errors.street}</div>
                )}
              </div>
              <div>
                <InputSimpleFloatLabel
                  id="ext_number"
                  name="ext_number"
                  placeholder="NÚMERO EXTERIOR"
                  onChange={handleChange}
                  valid={!Boolean(errors.ext_number && touched.ext_number)}
                  touched={touched.ext_number}
                  maxLength={30}
                />
                {errors.ext_number && touched.ext_number && (
                  <div className="erros-form">{errors.ext_number}</div>
                )}
              </div>
            </div>
            <div className={classes.formFields}>
              <div>
                <InputSimpleFloatLabel
                  id="int_number"
                  name="int_number"
                  placeholder="NÚMERO INTERIOR"
                  onChange={handleChange}
                  valid={!Boolean(errors.int_number && touched.int_number)}
                  touched={touched.int_number}
                  maxLength={30}
                />
                {errors.int_number && touched.int_number && (
                  <div className="erros-form">{errors.int_number}</div>
                )}
              </div>
              <div>
                <InputSimpleFloatLabel
                  id="email"
                  name="email"
                  placeholder="CORREO ELECTRÓNICO"
                  onChange={handleChange}
                  valid={!Boolean(errors.email && touched.email)}
                  touched={touched.email}
                  maxLength={70}
                />
                {errors.email && touched.email && (
                  <div className="erros-form">{errors.email}</div>
                )}
              </div>
            </div>
            <div className={classes.formFields}>
              <div>
                <InputSimpleFloatLabel
                  id="phone"
                  name="phone"
                  placeholder="TELÉFONO"
                  onChange={handleChange}
                  valid={!Boolean(errors.phone && touched.phone)}
                  touched={touched.phone}
                  maxLength={10}
                />
                {errors.phone && touched.phone && (
                  <div className="erros-form">{errors.phone}</div>
                )}
              </div>
              <div>
                <InputSelect
                  id="state"
                  name="state"
                  placeholder="- ESTADO -"
                  options={listStates}
                  fieldIdOption="id"
                  valid={Boolean(errors.state && touched.state)}
                  value={values.state}
                  onChange={(e) => {
                    setCityValue(e);
                    handleChange(e);
                  }}
                />
                {errors.state && touched.state && (
                  <div className="erros-form">{errors.state}</div>
                )}
              </div>
            </div>
            <div className={classes.formFields}>
              <div>
                <InputSelect
                  id="city"
                  name="city"
                  placeholder="- CIUDAD -"
                  options={cities}
                  optionsType="array"
                  fieldIdOption="id"
                  valid={Boolean(errors.city && touched.city)}
                  onChange={handleChange}
                />
                {errors.city && touched.city && (
                  <div className="erros-form">{errors.city}</div>
                )}
              </div>
              <div>
                <InputSimpleFloatLabel
                  id="postal_code"
                  name="postal_code"
                  placeholder="CÓDIGO POSTAL"
                  onChange={handleChange}
                  valid={!Boolean(errors.postal_code && touched.postal_code)}
                  touched={touched.postal_code}
                  maxLength={10}
                />
                {errors.postal_code && touched.postal_code && (
                  <div className="erros-form">{errors.postal_code}</div>
                )}
              </div>
            </div>
            <div className={classes.formFields}>
              <div>
                <InputSimpleFloatLabel
                  id="neighborhood"
                  name="neighborhood"
                  placeholder="COLONIA"
                  onChange={handleChange}
                  valid={!Boolean(errors.neighborhood && touched.neighborhood)}
                  touched={touched.neighborhood}
                  maxLength={220}
                />
                {errors.neighborhood && touched.neighborhood && (
                  <div className="erros-form">{errors.neighborhood}</div>
                )}
              </div>
            </div>
            {error !== "" && <ErrorMessage message={error} />}
            <div className={classes.btnForm}>
              {submit ? (
                <Spinner />
              ) : (
                <Button type="submit" className="btnConfirm">
                  Agregar
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormBusinessName;
