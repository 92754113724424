export const services = {
  MARCAS: 1,
  MARCAS_ESTUDIO_REG: 2,
  MARCAS_REGISTRO: 3,
  OBRAS: 4,
  GRUPOS_MUSICALES: 5,
  GRUPOS_MUSICALES_ESTUDIO_REG: 6,
  GRUPOS_MUSICALES_REGISTRO: 7,
  REVISTAS: 8,
  REVISTAS_ESTUDIO_REG: 9,
  REVISTAS_REGISTRO: 10,
  PERIODICOS: 11,
  PERIODICOS_ESTUDIO_REG: 12,
  PERIODICOS_REGISTRO: 13,
  PUBLICACIONES: 14,
  PUBLICACIONES_ESTUDIO_REG: 15,
  PUBLICACIONES_REGISTRO: 16,
  DERECHOS_RENOVACION: 17,
  DERECHOS_CESION: 18,
  DISENOS_INDUSTRIALES: 19,
  DERECHOS_LICENCIA: 20,
  PAGO_ANUALIDAD: 21,
  PAGO_IMPEDIMENTO: 22,
  DECLARACION_USO_MARCA: 23,
};

export const SERVICES_NAMES = {
  1: "MARCAS",
  2: "ESTUDIO DE REGISTRABILIDAD",
  3: "REGISTRO DE MARCA",
  4: "OBRAS",
  5: "GRUPOS MUSICALES",
  6: "ESTUDIO DE REGISTRBILIDAD DE NOMBRE DE GRUPOS MUSICALES",
  7: "REGISTRO DE NOMBRE DE GRUPOS MUSICALES",
  8: "REVISTAS",
  9: "ESTUDIO DE REGISTRABILIDAD DE NOMBRE DE REVISTAS",
  10: "REGISTRO DE NOMBRE DE REVISTAS",
  11: "PERIÓDICOS",
  12: "ESTUDIO DE REGISTRABILIDAD DE NOMBRE DE PERIÓDICOS",
  13: "REGISTRO DE NOMBRE DE PERIODICOS",
  14: "PUBLICACIONES PERIÓDICAS VÍA REDES DE CÓMPUTO",
  15: "ESTUDIO DE REGISTRABILIDAD DE NOMBRE DE PUBLICACIONES PERIÓDICAS VÍA REDES DE CÓMPUTO",
  16: "REGISTRO DE NOMBRE DE PUBLICACIONES PERIÓDICAS VÍA REDES DE CÓMPUTO",
  17: "RENOVACIÓN DE DERECHOS",
  18: "CESIÓN DE DERECHOS",
  19: "DISEÑOS INDUSTRIALES",
  20: "LICENCIA DE DERECHOS",
  21: "PAGO DE ANUALIDAD DE DISEÑOS INDUSTRIALES",
  22: "PAGO DE REQUERIMIENTO POR IMPEDIMETO DE REGISTRO",
  23: "DECLRACIÓN DE USO DE MARCA",
};

export const STATUS_NOTIFICATIONS = {
  1: "ANTES PAGO",
  2: "NUEVA",
  3: "EN PROCESO",
  4: "ARCHIVOS REQUERIDOS",
  5: "CON IMPEDIMENTO",
  6: "PROCESADA",
  7: "RECHAZADA",
};

export const SERVICE_RECURRENT = {
  DECLARACION_USO_MARCA: "declaration_use_brand",
  PAGO_ANUALIDAD: "pay_anual",
  RENOVATIONS: "renovation",
  PAGO_IMPEDIMENTO: "pay_impediment",
};
