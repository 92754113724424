export const MESSAGES = {
  REQUIRED: "El campo es requerido.",
  NOT_MATCH: "EL campo contiene caracteres invalidos.",
  NOT_MATCH_RFC: "El RFC es invalido.",
  MIN: "Caracteres insuficientes.",
  MAX: "Excede el número de caracteres permitidos.",
  EMAIL_INVALID: "Correo electrónico inválido.",
  BAD_REQUEST:
    "Ocurrió un problema intenta de nuevo o contacta a un administrador",
  RECORD_ALREADY_EXISTS: "El registro ya existe",
  RFC_ALREADY_EXISTS: "El RFC ya fue registrado",
};

export const REGEXP = {
  RAZON_SOCIAL: /^[,.@\-_' áéíóúÁÉÍÓÚñÑa-zA-Z0-9]+$/,
  RFC: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))?(?:- ?)?([A-Z\d]{2})([A\d])$/,
  STREET: /^[,.@\-_'áéíóúÁÉÍÓÚñÑ a-zA-Z0-9]+$/,
  NUMBER: /^[-# a-zA-Z0-9]+$/,
  PHONE: /^[0-9]+$/,
  STATE: /^[a-zA-Z]+$/,
  CITY: /^[áéíóúÁÉÍÓÚñÑ a-zA-Z-,.]+$/,
  POSTAL_CODE: /^[0-9]+$/,
  NEIGHBORHOOD: /^[áéíóúÁÉÍÓÚñÑ a-zA-Z-,.]+$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const FORMAT_FILE = {
  PDF: "pdf",
  ZIP: "zip",
};

export const TYPE_FILE = {
  SERVISE: 1,
  TITULAR: 2,
  AUTHOR: 3,
  COAHUTOR: 4,
};
