import React from 'react';
import Transition from 'react-transition-group/Transition';

import { IconXBlack } from '../../../assets/images/icons/icons';
import './Modal.css';

const modal = (props) => (
  <Transition
    in={props.show}
    timeout={300}
    mountOnEnter
    unmountOnExit
  >
    {state => {
      const cssClasses = [
        'custom-modal',
        state === 'entering'
          ? 'modal-open '
          : state === 'exiting' ? 'modal-closed' : null
      ]
      return (
        <div className={cssClasses.join(' ')}>
          <div className="ct-modal-header">
            <img src={IconXBlack} alt="close" className="modal-close-icon" onClick={props.closed} />
          </div>
          {props.children}
        </div>
      );
    }}
  </Transition>
);




export default modal;