import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentStripe } from "../../../../components";

const RenewalCheckOut = ({ handleSubmit }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <>
      <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>
      <div>
        <Elements stripe={stripePromise}>
          <PaymentStripe redirect="" onSubmit={handleSubmit} />
        </Elements>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </>
  );
};

RenewalCheckOut.prototype = {
  handleSubmit: PropTypes.func.isRequired
};

export default RenewalCheckOut;
