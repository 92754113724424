import { USER_PDLC } from "../../../constants";
import basicAuthRequest from "../../../utils/axios/basicAuthRequest";

export function requestCreateTradeMark(storeTradeMark, storePayment) {
  const user = JSON.parse(localStorage.getItem("user"));
  const prod_classes = storeTradeMark.productClasses.map((item) => +item.id);
  const serv_classes = storeTradeMark.servicesClasses.map((item) => +item.id);

  const bodyTradeMark = {
    user_id: user.user_id,
    service_id: 3,
    service_order_id:
      storeTradeMark.brandBelongsId === -1
        ? null
        : +storeTradeMark.brandBelongsId,
    brand_info: {
      name: storeTradeMark.name,
      type: storeTradeMark.type,
      file: storeTradeMark.file,
      product_classes: prod_classes,
      service_classes: serv_classes,
      prev_use: storeTradeMark.isAdditionalBrand
        ? storeTradeMark.isUsed === 1
        : false,
      prev_use_date:
        storeTradeMark.dateUsed === "" ? null : storeTradeMark.dateUsed,
    },
    owner_id: storeTradeMark.isRegisteredHolder
      ? storeTradeMark.titularId
      : null,
    owner_info: {
      type: 1,
      name: storeTradeMark.owner_info.name,
      rfc: storeTradeMark.owner_info.rfc,
      curp: storeTradeMark.owner_info.curp,
      street: storeTradeMark.owner_info.street,
      ext_number: storeTradeMark.owner_info.ext_number,
      int_number: storeTradeMark.owner_info.int_number,
      email: storeTradeMark.owner_info.email,
      phone: storeTradeMark.owner_info.phone,
      state:
        storeTradeMark.owner_info.state === -1
          ? null
          : storeTradeMark.owner_info.state,
      city:
        storeTradeMark.owner_info.city === -1
          ? null
          : storeTradeMark.owner_info.city,
      postal_code: storeTradeMark.owner_info.postal_code,
      neighborhood: storeTradeMark.owner_info.neighborhood,
      file: storeTradeMark.owner_info.file,
    },
    billing_info: {
      payment_method_id:
        user.user_id === USER_PDLC
          ? "pm_1jbyzUyGRrBBY1dp8rWqpr0fp"
          : storePayment.paymentMethodid,
      price_total: +storePayment.purchaseAmount,
    },
  };

  try {
    return basicAuthRequest
      .request({
        url: "feed/createorder",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: bodyTradeMark,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => err);
  } catch (error) {
    console.log("Error requestCreateTradeMark", error);
  }
}
