import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ErrorMessage, Button, InputIcon, Spinner } from "../../components";
import { useAuth } from "../../context/auth";
import { Play } from "../../assets/images/icons/icons";
import logo from "../../assets/images/logo-aTuAlcance.svg";
import classes from "./LoginForm.module.css";
import "./notifications.css";
import { validateUser } from "../../api/auth";
import { userProfileActions } from "../../store/slices/userProfile";

const UNVERIFIED_USER = "User is not confirmed.";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [showErrorLogin, setShowErrorLogin] = useState(false);
  const { logIn } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const formLogin = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Correo electrónico inválido.")
        .required("Correo eléctronico requerido"),
      password: Yup.string().required("Contraseña invalida"),
    }),

    onSubmit: async (values) => {
      setLoading(true);
      const response = await logIn(values.email, values.password);
      setLoading(false);

      if (response.response.data.message === UNVERIFIED_USER) {
        dispatch(
          userProfileActions.setDataRegisterUser({
            data: { email: values.email, name: "unverifierd_user" },
          })
        );
        await validateUser(values.email);
        history.push("/verificacion");
      } else {
        setShowErrorLogin(true);
      }
    },
  });

  return (
    <div className="container-simple">
      <img src={logo} className={classes.mainLogo} alt="A tu alcance" />
      <div className={classes.containerForm}>
        <form onSubmit={formLogin.handleSubmit}>
          <div className={classes.contentInput}>
            <InputIcon
              id="email"
              name="email"
              type="email"
              placeholder="Correo electrónico"
              onChange={formLogin.handleChange}
              icon="envelope"
              valid={formLogin.errors.email && formLogin.touched.email}
            />
          </div>
          <div className={classes.contentInput}>
            <InputIcon
              id="password"
              type="password"
              placeholder="Contraseña"
              onChange={formLogin.handleChange}
              autoComplete="on"
              icon="key"
              valid={formLogin.errors.password && formLogin.touched.password}
            />
          </div>
          <div className={classes.contentErrors}>
            {showErrorLogin && !loading && (
              <ErrorMessage message="Usuario y/o contraseña incorrecta" />
            )}
          </div>
          <div className={classes.btnLogin}>
            {loading ? (
              <Spinner />
            ) : (
              <Button type="submit" className="btnConfirm">
                Iniciar sesión
              </Button>
            )}
          </div>
        </form>
      </div>

      <div className="assets-box">
        <p className="subtitle-small">
          <NavLink to="/resetear-contrasena" style={{ color: "#121f61" }}>
            RECUPERAR CONTRASEÑA
          </NavLink>
        </p>
        <div className="parting-line" />
        <p className="no-account">¿Aún no tienes cuenta?</p>
        <p className="register">
          <NavLink to="/registro">REGISTRATE</NavLink>{" "}
          <span style={{ color: "#96e8b2" }}>
            <img alt="registrate" src={Play} width="13" height="13" />
          </span>
        </p>
      </div>
    </div>
  );
};

export default LoginForm;
