import PropTypes from "prop-types";
import classes from "./MyRequest.module.css";

const Classes = ({ productClasses, serviceClasses }) => {
  return (
    <div>
      <div className="parting-line" />
      <div className={classes.headerSection}>
        <h2 className="subtitle-section">CLASES ASOCIADAS</h2>
      </div>
      <div className={classes.sectionClass}>
        <div>
          <div className={classes.itemHead}>
            CLASES DE PRODUCTOS ASOCIADAS ({productClasses.length})
          </div>
          {productClasses.map((item) => (
            <div key={Math.random() + item.id} className={classes.itemClass}>
              <div className={classes.classId}>{item.class_id}</div>
              <div>
                <b>{item.id}</b> {item.name}
              </div>
            </div>
          ))}
        </div>

        <div>
          <div className={classes.itemHead}>
            CLASES DE SERVICIOS ASOCIADAS ({serviceClasses.length})
          </div>
          {serviceClasses.map((item) => (
            <div key={Math.random() + item.id} className={classes.itemClass}>
              <div className={classes.classId}>{item.class_id}</div>
              <div>
                <b>{item.id}</b> {item.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Classes.prototype = {
  productClasses: PropTypes.array.isRequired,
  serviceClasses: PropTypes.array.isRequired,
};

export default Classes;
