import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { Publicaciones } from "../../../assets/images/icons/icons";
import { ButtonBack, Button, ButtonRound } from "../../../components";
import { publicationsActions } from "../../../store/slices/publications";
import { userProfileActions } from "../../../store/slices/userProfile";
import {
  PUBLICATIONS_STUDY_REGISTER,
  PUBLICATIONS_REGISTER_SELECT_STUDY,
  DASHBOARD_SERVICES,
} from "../../../constants/routes";
import { services } from "../../../constants/services";

const STUDY = PUBLICATIONS_STUDY_REGISTER;
const REGISTER = PUBLICATIONS_REGISTER_SELECT_STUDY;
const SERVICIOS = DASHBOARD_SERVICES;

const ServicesPublications = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [publications, setPublications] = useState();
  const storeUserProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getUserServices());
  }, [dispatch]);

  useEffect(() => {
    const result = storeUserProfile?.services?.services?.filter(
      (item) => item.service_id === services.PUBLICACIONES
    );
    setPublications(result);
  }, [storeUserProfile?.services?.services]);

  const goBack = () => {
    props.history.replace(SERVICIOS);
  };
  const handleRegistrabilityStudy = () => {
    dispatch(
      publicationsActions.setServiceId({
        service_id: services.PUBLICACIONES_ESTUDIO_REG,
      })
    );
    history.push(STUDY);
  };
  const handlerRegister = () => {
    dispatch(
      publicationsActions.setServiceId({
        service_id: services.PUBLICACIONES_REGISTRO,
      })
    );
    history.push(REGISTER);
  };
  return (
    <div className="container-modulo">
      <ButtonBack url={SERVICIOS} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">SERVICIOS</h2>
      </div>

      <div className="item-marcas">
        <img src={Publicaciones} alt="icon-marcas" />
        &nbsp;&nbsp; PUBLICACIONES PERIÓDICAS VÍA REDES DE CÓMPUTO
      </div>

      <div className="parting-line" />

      <div className="text-description">
        <p>
          Registra el nombre de tu publicación periódica vía redes de cómputo.
        </p>
        <h2 className="subtitle-modle">
          {publications && publications[0]?.service_sub[0]?.service_name}
        </h2>
        <p>
          {publications && publications[0]?.service_sub[0]?.service_description}
        </p>
        <div className="price">
          <CurrencyFormat
            value={
              publications &&
              Number(publications[0]?.service_sub[0]?.service_price).toFixed(2)
            }
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            renderText={(value) => (
              <div>
                {value} <span className="currency">MXM</span>
              </div>
            )}
          />
        </div>
        <div className="content-btn">
          <Button onClick={handleRegistrabilityStudy} className="btnConfirm">
            SOLICITAR
          </Button>
        </div>
      </div>

      <br />
      <br />
      <div className="parting-line" />
      <br />
      <br />

      <div className="text-description">
        <h2 className="subtitle-modle">
          {publications && publications[0]?.service_sub[1]?.service_name}
        </h2>
        <p>
          {publications && publications[0]?.service_sub[1]?.service_description}
        </p>
        <div className="price">
          <CurrencyFormat
            value={
              publications &&
              Number(publications[0]?.service_sub[1]?.service_price).toFixed(2)
            }
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            renderText={(value) => (
              <div>
                {value} <span className="currency">MXM</span>
              </div>
            )}
          />
        </div>
        <div className="content-btn">
          <Button onClick={handlerRegister} className="btnConfirm">
            SOLICITAR
          </Button>
        </div>
      </div>

      <br />
      <br />
      <div className="parting-line" />
      <div className="content-btn btn-back-margin">
        <ButtonRound onClick={goBack} icon="faArrowLeft">
          REGRESAR
        </ButtonRound>
      </div>
    </div>
  );
};

export default ServicesPublications;
