import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  InputSimpleFloatLabel,
  InputRadio,
  InputSelect,
  EntityForm,
  Button,
  ErrorMessage,
} from "../../../components";
import { caculateAmountPrice } from "../../../utils/scripts/calculatePrice";
import { cataTipoDerecho } from "../../../utils/cat-tipo-derecho";
import { rightsActions } from "../../../store/slices/rights";
import { paymentActions } from "../../../store/slices/payment";
import { userProfileActions } from "../../../store/slices/userProfile";
import {
  RIGHTS_RENOVATIONS_CHECKOUT,
  RIGHTS_RENOVATIONS_CONFIRM,
} from "../../../constants/routes";
import { services } from "../../../constants/services";
import { DASHBOARD_SERVICES } from "../../../constants/routes";
import { USER_PDLC } from "../../../constants";

const Renewal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [validForm, setValidForm] = useState(false);
  const [validFiles, setValidFiles] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [userData, setUserData] = useState();

  const storeUserProfile = useSelector((state) => state.userProfile);
  const storeTitular = useSelector((state) => state.entity.titular);
  const storeRights = useSelector((state) => state.rights);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
  }, []);

  useEffect(() => {
    if (storeRights.isRegisteredRight) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeRights.isRegisteredRight]);

  useEffect(() => {
    dispatch(userProfileActions.getHolders());
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getUserServices());
  }, [dispatch]);

  useEffect(() => {
    let valid = true;

    if (storeRights.isValidRenewal) {
      if (!storeRights.isRegisteredHolder) {
        if (!storeTitular.valid) {
          valid = false;
        }
      }
      setValidForm(valid);
    } else {
      setValidForm(false);
    }
  }, [
    storeRights.isRegisteredHolder,
    storeRights.isValidRenewal,
    storeTitular.valid,
  ]);

  useEffect(() => {
    let valid = true;
    if (!storeRights.isRegisteredHolder) {
      if (storeTitular.file === "") {
        valid = false;
      }
    }
    setValidFiles(valid);
  }, [storeRights.isRegisteredHolder, storeTitular.file]);

  const handleChangeType = (event) => {
    dispatch(rightsActions.setRightType({ right_type: event.target.value }));
  };
  const handleChangeInputNumber = (event) => {
    dispatch(rightsActions.setBrandNumber({ number: event.target.value }));
  };
  const handleChangeName = (event) => {
    dispatch(rightsActions.setBrandName({ name: event.target.value }));
  };
  const handleChangeRegisterHolder = (event) => {
    dispatch(
      rightsActions.setIsRegisteredHolder({
        isRegisteredHolder: event.target.value === "si",
      })
    );
  };
  const hanldleChangeTitularSelect = (event) => {
    dispatch(
      rightsActions.setOwnerId({
        owner_id: event.target.value,
      })
    );
  };

  const getPrice = () => {
    const rightsLicense = storeUserProfile.services.services.filter(
      (item) => item.service_id === services.DERECHOS_RENOVACION
    );

    const price =
      +rightsLicense[0].service_price + +rightsLicense[0].service_transact;

    const totalprice = caculateAmountPrice(price);

    return totalprice.toFixed(2);
  };

  const handleButtonSubmit = () => {
    setSubmittedForm(true);
    const price = getPrice();
    if (validFiles) {
      dispatch(paymentActions.setPurchaseAmount({ amount: price }));
      if (userData.user_id === USER_PDLC) {
        history.replace(RIGHTS_RENOVATIONS_CONFIRM);
      } else {
        history.replace(RIGHTS_RENOVATIONS_CHECKOUT);
      }
    }
  };

  return (
    <div className="container-modulo">
      <div className="section-title">
        <h2 className="subtitle">RENOVACIÓN DE DERECHOS</h2>
      </div>
      <div className="text-description">
        <p>
          Renovamos tus derechos de propiedad intelectual, de manera expedita.
        </p>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DEL DERECHO</div>
      </div>
      <div className="container-desc-controls">
        <div className="form-informacion-marca">
          <div>
            <InputSelect
              id="type"
              name="type"
              placeholder="- TIPO DE DERECHO -"
              options={cataTipoDerecho}
              fieldIdOption="id"
              value={storeRights.brand_info.right_type}
              onChange={handleChangeType}
            />
          </div>
          <div>
            <InputSimpleFloatLabel
              id="number"
              name="number"
              value={storeRights.brand_info.number}
              valid={true}
              placeholder="NÚMERO DE REGISTRO"
              defaultValue="number"
              onChange={handleChangeInputNumber}
            />
          </div>
        </div>
      </div>
      <div className="container-desc-controls">
        <div className="form-informacion-marca">
          <div>
            <InputSimpleFloatLabel
              id="name"
              name="name"
              defaultValue={storeRights.brand_info.name}
              valid={true}
              placeholder="NOMBRE"
              onChange={handleChangeName}
            />
          </div>
          <div></div>
        </div>
      </div>

      <div className="content-form-main">
        <InputRadio
          id="si"
          name="isRegisteredHolder"
          value="si"
          onChange={handleChangeRegisterHolder}
          label="SELECCIONAR UN TITULAR DE MI LISTA DE TITULARES"
          checked={storeRights.isRegisteredHolder}
        />
        {storeRights.isRegisteredHolder && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="- ELIGE UN TITULAR -"
            options={storeUserProfile.holders}
            fieldIdOption="id"
            value={storeRights.owner_id}
            onChange={hanldleChangeTitularSelect}
          />
        )}
        <InputRadio
          id="no"
          name="isRegisteredHolder"
          value="no"
          onChange={handleChangeRegisterHolder}
          label="REGISTRAR NUEVO TITULAR"
          checked={!storeRights.isRegisteredHolder}
        />
      </div>
      {!storeRights.isRegisteredHolder && (
        <EntityForm
          typeEntity="titular"
          entity="titular-musical-group"
          recordTypeFile="brand-owner-info"
        />
      )}
      <div className="content-form-main">
        {submittedForm && !validFiles && (
          <ErrorMessage message="Archivo identificación oficial es requerido" />
        )}
        <div className="contentainer-button">
          <Button
            onClick={handleButtonSubmit}
            className="btnConfirm"
            disabled={!validForm}
          >
            CONTINUAR
          </Button>
        </div>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DE PAGO</div>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default Renewal;
