import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';

import { Button } from '../../../components';
import { Visa, Mastercard, Amex } from '../../../assets/images/icons/icons';
import { paymentActions } from '../../../store/slices/payment';
import classes from './PaymentStripe.module.css';

const PaymentStripe = props => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const history = useHistory();

  const [dataForm, setDataForm] = useState([{
    card: false,
    cvc: false,
    expiry: false,
    titular: '',
    email: '',
    phone: ''
  }]);
  const [validForm, setValidForm] = useState(false);
  const [validRequest, setValidRequest] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name: event.target.titular.value,
        email: event.target.email.value,
        phone: event.target.phone.value
      }
    });

    if (error) {
      setValidRequest(false);
      setErrorMessage(error.message);

    } else {
      setValidRequest(true);
      dispatch(paymentActions.setPaymentMethod({
        paymentMethodId: paymentMethod.id,
        paymentMethod: paymentMethod.card.brand
      }));

      if(props.redirect !== ""){
        history.push(props.redirect);
      } else {
        props.onSubmit();
      }
    }
  }

  const confirmValidForm = _ => {
    if (dataForm[0].card === true
      && dataForm[0].cvc === true
      && dataForm[0].expiry === true
      && dataForm[0].titular !== ''
      && dataForm[0].email !== ''
      && dataForm[0].phone !== '') {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }

  const handleChangeCard = event => {
    let form = [...dataForm];
    if (event.empty === false) {
      form[0].card = true;
      setDataForm(form);
      confirmValidForm();
    } else {
      form[0].card = false;
      setDataForm(form);
      confirmValidForm();
    }
  }

  const handleChangeCvc = event => {
    let form = [...dataForm];
    if (event.empty === false) {
      form[0].cvc = true;
      setDataForm(form);
      confirmValidForm();
    } else {
      form[0].cvc = false;
      setDataForm(form);
      confirmValidForm();
    }
  }

  const handleChangeExpiry = event => {
    let form = [...dataForm];
    if (event.empty === false) {
      form[0].expiry = true;
      setDataForm(form);
      confirmValidForm();
    } else {
      form[0].expiry = false;
      setDataForm(form);
      confirmValidForm();
    }
  }

  const handleChangeTitular = event => {
    let form = [...dataForm];
    if (event.target.value !== '') {
      form[0].titular = event.target.value;
      setDataForm(form);
      confirmValidForm();
    } else {
      form[0].titular = '';
      setDataForm(form);
      confirmValidForm();
    }
  }

  const handleChangeEmail = event => {
    let form = [...dataForm];
    if (event.target.value !== '') {
      form[0].email = event.target.value;
      setDataForm(form);
      confirmValidForm();
    } else {
      form[0].email = '';
      setDataForm(form);
      confirmValidForm();
    }
  }

  const handleChangePhone = event => {
    let form = [...dataForm];
    if (event.target.value !== '') {
      form[0].phone = event.target.value;
      setDataForm(form);
      confirmValidForm();
    } else {
      form[0].phone = '';
      setDataForm(form);
      confirmValidForm();
    }
  }

  return (
    <div>
      <div className={classes.cardImages}>
        <img src={Visa} alt="visa" />
        <img src={Mastercard} alt="mastercard" />
        <img src={Amex} alt="amex" />
      </div>
      <div className={classes.containerForm}>
        <form onSubmit={handleSubmit} className={classes.formFields}>
          <CardNumberElement className={classes.inputForm} onChange={handleChangeCard} />

          <div className={classes.groupForm}>
            <FontAwesomeIcon icon={faQuestionCircle} className={classes.iconHelp} />
            <CardCvcElement className={classes.inputForm} onChange={handleChangeCvc} />
          </div>

          <CardExpiryElement className={classes.inputForm} onChange={handleChangeExpiry} />

          <input
            id="titular"
            name="titular"
            type="text"
            placeholder="NOMBRE DEL TITULAR"
            onChange={handleChangeTitular}
            className={classes.inputForm}
          />

          <input
            id="email"
            name="email"
            type="email"
            placeholder="CORREO ELECTRÓNICO"
            onChange={handleChangeEmail}
            className={classes.inputForm} />

          <input
            id="phone"
            name="phone"
            type="text"
            placeholder="TELÉFONO"
            onChange={handleChangePhone}
            className={classes.inputForm} />

          {
            !validRequest &&
            <div className={`${classes.msgError} ${classes.fullRow}`}>
              <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: '19px' }} /> &nbsp;
              {errorMessage}
            </div>
          }
          <div className={classes.fullRow}>
            <Button
              type="submit"
              className={'btnConfirm'}
              disabled={!validForm}
            >CONTINUAR</Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default React.memo(PaymentStripe);