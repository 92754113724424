import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import * as yup from "yup";
import { getFileName } from "../../../utils/scripts/file";
import { typePerson } from "../../../utils/typePerson";
import { listStates } from "../../../utils/states";
import { listCity } from "../../../utils/states-city";
import {
  InputSelect,
  InputSimpleFloatLabel,
  BtnCallToAction,
  ModalDrag,
} from "../../../components";
import { entityActions } from "../../../store/slices/entity";
import classes from "./EntityForm.module.css";

const typeOfEntity = (entity) => {
  const TYPEENTITY = {
    titular: 1,
    author: 2,
    coauthor: 3,
    licenciatario: 4,
    cesionario: 5,
  };

  return TYPEENTITY[entity];
};

const EntityForm = (props) => {
  const dispatch = useDispatch();
  const [dragModal, setDragModal] = useState(false);
  const [stateListCities, setStateListCities] = useState([]);
  const storeEntity = useSelector((state) => state.entity);

  useEffect(() => {
    setStateListCities(
      typeof listCity[storeEntity[props.typeEntity].state.value] === "undefined"
        ? []
        : listCity[storeEntity[props.typeEntity].state.value]
    );
  }, [props.typeEntity, storeEntity]);

  useEffect(() => {
    let valid = true;
    const schema = { ...storeEntity[props.typeEntity] };

    schema.type.valid || (valid = false);
    schema.rfc.valid || (valid = false);
    schema.curp.valid || (valid = false);
    schema.titularName.valid || (valid = false);
    schema.street.valid || (valid = false);
    schema.extNumber.valid || (valid = false);
    schema.phone.valid || (valid = false);
    schema.state.valid || (valid = false);
    schema.city.valid || (valid = false);
    schema.postalCode.valid || (valid = false);
    schema.neighborhood.valid || (valid = false);
    schema.email.valid || (valid = false);

    dispatch(
      entityActions.setIsValid({
        valid,
        idEntity: typeOfEntity(props.typeEntity),
      })
    );
  }, [dispatch, props.typeEntity, storeEntity]);

  const openDragModal = () => {
    setDragModal(true);
  };

  const closeDragModal = () => {
    setDragModal(false);
  };

  const clearFileUploaded = () => {
    dispatch(
      entityActions.setFile({
        file: "",
        idEntity: typeOfEntity(props.typeEntity),
      })
    );
  };

  const onchangeHandler = async (event) => {
    const idEntity = typeOfEntity(props.typeEntity);
    switch (event.target.id) {
      case "type":
        dispatch(
          entityActions.setType({
            idEntity,
            value: event.target.value,
            touched: true,
            valid: await yup
              .number()
              .required()
              .min(1)
              .max(2)
              .isValid(event.target.value),
          })
        );
        break;
      case "titularName":
        dispatch(
          entityActions.setName({
            idEntity,
            value: event.target.value,
            touched: true,
            valid: await yup
              .string()
              .required()
              .isValid(event.target.value)
              .catch((err) => err),
          })
        );
        break;
      case "rfc":
        dispatch(
          entityActions.setRfc({
            idEntity,
            value: event.target.value,
            touched: true,
            valid: await yup
              .string()
              .required()
              .min(12)
              .max(13)
              .matches(
                /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
              )
              .isValid(event.target.value),
          })
        );
        break;
      case "curp":
        dispatch(
          entityActions.setCurp({
            idEntity,
            value: event.target.value,
            touched: true,
            valid: await yup
              .string()
              .required()
              .min(18)
              .max(18)
              .matches(
                /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/
              )
              .isValid(event.target.value),
          })
        );
        break;
      case "street":
        dispatch(
          entityActions.setStreet({
            idEntity,
            value: event.target.value,
            touched: true,
            valid: await yup
              .string()
              .required()
              .matches(/^[,.@\-_'áéíóúÁÉÍÓÚñÑ a-zA-Z0-9]+$/)
              .isValid(event.target.value),
          })
        );
        break;
      case "extNumber":
        dispatch(
          entityActions.setExtNumber({
            idEntity,
            value: event.target.value,
            touched: true,
            valid: await yup
              .string()
              .matches(/^[-# a-zA-Z0-9]+$/)
              .required()
              .isValid(event.target.value),
          })
        );
        break;
      case "intNumber":
        dispatch(
          entityActions.setIntNumber({
            idEntity,
            value: event.target.value,
            touched: true,
            valid: await yup.string().isValid(event.target.value),
          })
        );
        break;
      case "state":
        dispatch(
          entityActions.setState({
            idEntity,
            value: event.target.value,
            touched: true,
            valid: await yup
              .string()
              .required()
              .matches(/^[áéíóúÁÉÍÓÚñÑ a-zA-Z-,.]+$/)
              .isValid(event.target.value),
          })
        );
        break;
      case "city":
        dispatch(
          entityActions.setCity({
            idEntity,
            value: event.target.value,
            touched: true,
            valid: await yup
              .string()
              .required()
              .matches(/^[áéíóúÁÉÍÓÚñÑ a-zA-Z-,.]+$/)
              .isValid(event.target.value),
          })
        );
        break;
      case "neighborhood":
        dispatch(
          entityActions.setNeighborhood({
            idEntity,
            value: event.target.value,
            touched: true,
            valid: await yup
              .string()
              .required()
              .matches(/^[áéíóúÁÉÍÓÚñÑ a-zA-Z]+$/)
              .isValid(event.target.value),
          })
        );
        break;
      case "postalCode":
        dispatch(
          entityActions.setPostalaCode({
            idEntity,
            value: event.target.value,
            touched: true,
            valid: await yup
              .string()
              .required()
              .matches(/^[0-9]+$/)
              .max(5)
              .isValid(event.target.value),
          })
        );
        break;
      case "phone":
        dispatch(
          entityActions.setPhone({
            idEntity,
            value: event.target.value,
            touched: true,
            valid: await yup
              .string()
              .required()
              .matches(/^[0-9]+$/)
              .isValid(event.target.value),
          })
        );
        break;
      case "email":
        dispatch(
          entityActions.setEmail({
            idEntity,
            value: event.target.value,
            touched: true,
            valid: await yup
              .string()
              .email()
              .required()
              .isValid(event.target.value),
          })
        );
        break;
      default:
        break;
    }
  };
  return (
    <div>
      {dragModal && (
        <ModalDrag
          show={dragModal}
          closed={closeDragModal}
          scope="entity-form"
          formatFile="pdf"
          recordIndex={typeOfEntity(props.typeEntity)}
        />
      )}
      <div className={classes.oneColumnForm}>
        <InputSelect
          id="type"
          name="type"
          placeholder={`- TIPO DE ${props.typeEntity.toUpperCase()} -`}
          options={typePerson}
          fieldIdOption="id"
          value={storeEntity[props.typeEntity].type.value}
          onChange={onchangeHandler}
        />
        <InputSimpleFloatLabel
          id="titularName"
          name="titularName"
          placeholder={`NOMBRE DEL ${props.typeEntity.toUpperCase()} O RAZÓN SOCIAL`}
          onChange={onchangeHandler}
          defaultValue={storeEntity[props.typeEntity].titularName.value}
          valid={storeEntity[props.typeEntity].titularName.valid}
          touched={storeEntity[props.typeEntity].titularName.touched}
        />
        {storeEntity[props.typeEntity].file === "" ? (
          <>
            <BtnCallToAction onClick={openDragModal} icon="faUpload">
              {storeEntity[props.typeEntity].type.value === "2"
                ? "ACTA CONSTITUTIVA"
                : "IDENTIFICACIÓN OFICIAL"}
            </BtnCallToAction>
            <div className={classes.formatTextDescription}>
              Esté archivo deberá ser en formato PDF
            </div>
          </>
        ) : (
          <BtnCallToAction
            type="showfile"
            onClick={clearFileUploaded}
            icon="faTimesCircle"
            className="btnGreen"
            fileName={getFileName(storeEntity[props.typeEntity].file)}
          >
            {storeEntity[props.typeEntity].type.value === "2"
              ? "ACTA CONSTITUTIVA"
              : "IDENTIFICACIÓN OFICIAL"}
          </BtnCallToAction>
        )}
        {storeEntity[props.typeEntity].type.value === "2" && (
          <div className={classes.formatTextDescription} style={{ color: "red" }}>
            <b>IMPORTANTE:</b> Si eres una persona moral, en el campo de CURP
            por favor colocar el siguiente generico: XEXX010101HNEXXXA4
          </div>
        )}
      </div>
      <div className={classes.formFields}>
        <InputSimpleFloatLabel
          id="rfc"
          name="rfc"
          placeholder="RFC"
          onChange={onchangeHandler}
          defaultValue={storeEntity[props.typeEntity].rfc.value}
          valid={storeEntity[props.typeEntity].rfc.valid}
          touched={storeEntity[props.typeEntity].rfc.touched}
        />
        <InputSimpleFloatLabel
          id="curp"
          name="curp"
          placeholder="CURP"
          onChange={onchangeHandler}
          defaultValue={storeEntity[props.typeEntity].curp.value}
          valid={storeEntity[props.typeEntity].curp.valid}
          touched={storeEntity[props.typeEntity].curp.touched}
        />
        <InputSimpleFloatLabel
          id="street"
          name="street"
          placeholder="CALLE"
          onChange={onchangeHandler}
          defaultValue={storeEntity[props.typeEntity].street.value}
          valid={storeEntity[props.typeEntity].street.valid}
          touched={storeEntity[props.typeEntity].street.touched}
        />
        <InputSimpleFloatLabel
          id="extNumber"
          name="extNumber"
          placeholder="NÚMERO EXTERIOR"
          onChange={onchangeHandler}
          defaultValue={storeEntity[props.typeEntity].extNumber.value}
          valid={storeEntity[props.typeEntity].extNumber.valid}
          touched={storeEntity[props.typeEntity].extNumber.touched}
        />
        <InputSimpleFloatLabel
          id="intNumber"
          name="intNumber"
          placeholder="NÚMERO INTERIOR"
          onChange={onchangeHandler}
          defaultValue={storeEntity[props.typeEntity].intNumber.value}
          valid={true}
          touched={storeEntity[props.typeEntity].intNumber.touched}
        />
        <InputSelect
          id="state"
          name="state_"
          placeholder="- ESTADO -"
          options={listStates}
          fieldIdOption="id"
          value={storeEntity[props.typeEntity].state.value}
          onChange={onchangeHandler}
        />
        <InputSelect
          id="city"
          name="city"
          placeholder="- CIUDAD -"
          options={stateListCities}
          optionsType="array"
          fieldIdOption="id"
          onChange={onchangeHandler}
          value={storeEntity[props.typeEntity].city.value}
        />
        <InputSimpleFloatLabel
          id="neighborhood"
          name="neighborhood"
          placeholder="COLONIA "
          onChange={onchangeHandler}
          defaultValue={storeEntity[props.typeEntity].neighborhood.value}
          valid={storeEntity[props.typeEntity].neighborhood.valid}
          touched={storeEntity[props.typeEntity].neighborhood.touched}
        />
        <InputSimpleFloatLabel
          id="postalCode"
          name="postalCode"
          placeholder="CÓDIGO POSTAL"
          onChange={onchangeHandler}
          defaultValue={storeEntity[props.typeEntity].postalCode.value}
          valid={storeEntity[props.typeEntity].postalCode.valid}
          touched={storeEntity[props.typeEntity].postalCode.touched}
        />
        <InputSimpleFloatLabel
          id="phone"
          name="phone"
          placeholder="TELÉFONO"
          onChange={onchangeHandler}
          defaultValue={storeEntity[props.typeEntity].phone.value}
          valid={storeEntity[props.typeEntity].phone.valid}
          touched={storeEntity[props.typeEntity].phone.touched}
        />
        <InputSimpleFloatLabel
          id="email"
          name="email"
          type="email"
          placeholder="CORREO ELECTRÓNICO"
          onChange={onchangeHandler}
          defaultValue={storeEntity[props.typeEntity].email.value}
          valid={storeEntity[props.typeEntity].email.valid}
          touched={storeEntity[props.typeEntity].email.touched}
        />
      </div>
    </div>
  );
};

EntityForm.propTypes = {
  /** TYPE OF ENTITY */
  typeEntity: PropTypes.oneOf([
    "titular",
    "autor",
    "coautor",
    "licenciatario",
    "cesionario",
  ]).isRequired,
  entity: PropTypes.string.isRequired,
  recordTypeFile: PropTypes.string.isRequired,
  recordIndex: PropTypes.number,
};

export default EntityForm;
