import { call, put, select } from "redux-saga/effects";
import { replace } from "connected-react-router";

import { paymentActions } from "../../slices/payment";
import {
  requestCreatePublicationStudy,
  requestGetStudiesPublications,
  requestCreatePublication,
} from "../requests/publicationsRequest";
import { publicationsActions } from "../../slices/publications";
import { TICKECT } from "../../../constants/routes";

export function* handlerCreatePublication() {
  try {
    const storePublication = yield select((store) => store.publications);
    const storePayment = yield select((store) => store.payment);
    const storeEntity = yield select((store) => store.entity);

    const response = yield call(
      requestCreatePublication,
      storePublication,
      storePayment,
      storeEntity
    );

    if (response.status === 200) {
      yield put(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      yield put(replace(TICKECT));
      yield put(paymentActions.cleanStorePayment());
      yield put(publicationsActions.cleanStoragePublications());
    } else {
      // TODO: generar pantalla de error
    }
  } catch (error) {
    console.log("Error handlerCreatePublication" + error);
  }
}

export function* handlerCreatePublicationStudy() {
  try {
    const storePublication = yield select((store) => store.publications);
    const storePayment = yield select((store) => store.payment);
    const response = yield call(
      requestCreatePublicationStudy,
      storePublication,
      storePayment
    );

    if (response.status === 200) {
      yield put(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      yield put(replace(TICKECT));
      yield put(paymentActions.cleanStorePayment());
      yield put(publicationsActions.cleanStoragePublications());
    } else {
      // TODO: generar pantalla de error
    }
  } catch (error) {
    console.log("Errow handlerCreatePublicationStudy");
  }
}

/**
 * Get RegStudiesMusicalGroup
 */
export function* handlerGetRegStudiesPublications() {
  try {
    const studies = yield call(requestGetStudiesPublications, { service: "15" });
    if (studies.data.length) {
      yield put(publicationsActions.setRegStudies({ regStudies: studies.data }));
    } else {
      // yield put(replace("/publicaciones/servicios"));
    }
  } catch (error) {
    console.log(error);
  }
}
