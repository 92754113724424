import { createSlice } from "@reduxjs/toolkit";

const initialTradeMarkState = {
  statusFormBrandInfo: false, // Estatus formulario de información de la marca con estudio de registrabilidad
  isRegisteredHolder: true, // Seleccion de titular de la lista de titulares registrados
  isAdditionalBrand: false, // El registro de marca no tiene asociado un estudio de registrabilidad
  nameSelectedBrand: "",
  brandBelongsId: -1, // Id del registro de marca al que pertenece
  titularId: -1, // Id del titular registrado
  owner_info: {
    type: -1, // PERSONA FISICA, PERSONA MORAL
    name: "",
    rfc: "",
    curp: "",
    street: "",
    ext_number: "",
    int_number: "",
    email: "",
    phone: "",
    state: -1,
    city: -1,
    postal_code: "",
    neighborhood: "",
    file: "",
  }, // Datos del titular
  name: "", // Nombre de la marca
  type: "1", // Tipo de marca Nominativa
  file: "", // Path de documentos archivo zip
  productClasses: [], // Lista de clases de productos
  servicesClasses: [], // Lista de clases de servicios
  isUsed: 1, // La marca ya ha sido usada
  dateUsed: "", // Fecha en la que se empezo a usar
  isValid: false, // Validador del formulario de registro de marca
  paymentMethodId: "", // ID regresado por stripe de metodo de pago
  paymentMethod: "AMEX", // Nombde del metodo de pago regresado por stripe
  purchaseAmount: 600, // Cantidad total a pagar por el registro de la marca
};

/**
 * Validate the trademark information
 * @param object state
 * @returns
 */
const validateStatusMakr = (state) => {
  let valid = true;
  if (state.isAdditionalBrand) {
    if (state.name === "") {
      valid = false;
    }
    if (state.type !== "1" && state.file === "") {
      valid = false;
    }
    if (state.isUsed === 1 && state.dateUsed === "") {
      valid = false;
    }
  }

  if (state.isRegisteredHolder && state.titularId === -1) {
    valid = false;
  }

  if (!state.isRegisteredHolder) {
    if (state.owner_info.name === "") {
      valid = false;
    }
    if (state.owner_info.street === "") {
      valid = false;
    }
    if (state.owner_info.extNumber === "") {
      valid = false;
    }
    if (state.owner_info.state === "-1") {
      valid = false;
    }
    if (state.owner_info.city === "-1") {
      valid = false;
    }
    if (state.owner_info.neighborhood === "") {
      valid = false;
    }
    if (state.owner_info.postal_code === "") {
      valid = false;
    }
    if (state.owner_info.phone === "") {
      valid = false;
    }
    if (state.owner_info.email === "") {
      valid = false;
    }
  }

  if (state.productClasses.length === 0 && state.servicesClasses.length === 0) {
    valid = false;
  }

  return valid;
};

const tradeMarkSlice = createSlice({
  name: "tradeMark",
  initialState: initialTradeMarkState,
  reducers: {
    cleanStoreTradeMark(state) {
      state.statusFormBrandInfo = false;
      state.isRegisteredHolder = true;
      state.isAdditionalBrand = false;
      state.nameSelectedBrand = "";
      state.brandBelongsId = -1;
      state.titularId = -1;
      state.owner_info = {
        type: -1,
        name: "",
        rfc: "",
        curp: "",
        street: "",
        ext_number: "",
        int_number: "",
        email: "",
        phone: "",
        state: -1,
        city: -1,
        postal_code: "",
        neighborhood: "",
        file: "",
      };
      state.name = "";
      state.type = "1";
      state.file = "";
      state.productClasses = [];
      state.servicesClasses = [];
      state.isUsed = 1;
      state.dateUsed = "";
      state.isValid = false;
      state.paymentMethodId = "";
      state.paymentMethod = "AMEX";
      state.purchaseAmount = 600;
    },
    setIsAdditionalBrand(state, action) {
      state.isAdditionalBrand = action.payload.isAdditionalBrand;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setIsRegisteredHolder(state, action) {
      state.isRegisteredHolder = action.payload.isRegisteredHolder;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setBrandId(state, action) {
      state.brandBelongsId = action.payload.brandBelongsId;
    },
    setSelectedBrandName(state, action) {
      state.nameSelectedBrand = action.payload.nameSelectedBrand;
    },
    setName(state, action) {
      state.name = action.payload.name;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setType(state, action) {
      state.type = action.payload.type;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setProductClasses(state, action) {
      state.productClasses = action.payload.productClasses;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setServicesClasses(state, action) {
      state.servicesClasses = action.payload.servicesClasses;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setTitularId(state, action) {
      state.titularId = +action.payload.titularId;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setDataTitular(state, action) {
      state.titular = action.payload.titular;
    },
    setIsUsed(state, action) {
      state.isUsed = action.payload.isUsed;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setDateUse(state, action) {
      state.dateUsed = action.payload.dateUsed;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setPurchaseAmount(state, action) {
      state.purchaseAmount = action.payload.amount;
    },
    setFile(state, action) {
      state.file = action.payload.file;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },

    /** Titular */
    setHolderType(state, action) {
      state.owner_info.type = action.payload.type;
    },
    setHolderName(state, action) {
      state.owner_info.name = action.payload.name;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setHolderRfc(state, action) {
      state.owner_info.rfc = action.payload.rfc;
    },
    setHolderCurp(state, action) {
      state.owner_info.curp = action.payload.curp;
    },
    setHolderStreetName(state, action) {
      state.owner_info.street = action.payload.streetName;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setHolderExtNumber(state, action) {
      state.owner_info.ext_number = action.payload.extNumber;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setHolderIntNumber(state, action) {
      state.owner_info.int_number = action.payload.intNumber;
    },
    setHolderEmail(state, action) {
      state.owner_info.email = action.payload.email;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setHolderPhone(state, action) {
      state.owner_info.phone = action.payload.phone;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setHolderState(state, action) {
      state.owner_info.state = action.payload.state;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setHolderCity(state, action) {
      state.owner_info.city = action.payload.city;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setHolderPostalCode(state, action) {
      state.owner_info.postal_code = action.payload.postalCode;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setHolderNeighborhood(state, action) {
      state.owner_info.neighborhood = action.payload.neighborhood;
      state.statusFormBrandInfo = validateStatusMakr(state);
    },
    setHolderFile(state, action) {
      state.owner_info.file = action.payload.file;
    },
    /** Billing Transactions */
    createTradeMark() {},
  },
});

export const tradeMarkActions = tradeMarkSlice.actions;

export default tradeMarkSlice;
