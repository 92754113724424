import { useState } from "react";
import PropTypes from "prop-types";
import { BtnCallToAction, Button, Spinner } from "../../../components";
import SuccessfulRequest from "./BusinessName/SuccessfulRequest";
import BusinessNameModal from "./BusinessName/BusinessNameModal";
import classes from "./MyRequest.module.css";
import NewBusinessNameModal from "./BusinessName/NewBusinessNameModal";
const Billing = ({
  serviceId,
  invoicingInfo,
  getBusinessName,
  requestInvoice,
  loadingbn,
  loadingRequestInvoice,
  saveNewBusinessName,
  loadingSaveNewBN,
  errorSaveNewBN,
  clearErrorMessageSaveBN,
}) => {
  const [modalBN, setModalBN] = useState(false);
  const [modalResultRequest, setModalResultRequest] = useState(false);
  const [mpdalNewBN, setModalNewBN] = useState(false);
  const [businessNames, setBusinessNames] = useState([]);

  const openModalBN = async () => {
    const result = await getBusinessName();
    setBusinessNames(result);
    setModalBN(true);
  };

  const closeModalBN = () => {
    setModalBN(false);
  };

  const openModalResultRequestInvoice = () => {
    setModalResultRequest(true);
  };

  const closeModalResultRequestInvoice = () => {
    setModalResultRequest(false);
  };

  const openModalNewBN = () => {
    setModalNewBN(true);
  };

  const closeModalNewBN = () => {
    setModalNewBN(false);
  };

  return (
    <div>
      {modalBN && (
        <BusinessNameModal
          serviceId={serviceId}
          closeModalBN={closeModalBN}
          businessNameList={businessNames}
          requestInvoice={requestInvoice}
          loadingRequestInvoice={loadingRequestInvoice}
          openModalResultRequestInvoice={openModalResultRequestInvoice}
          openModalNewBN={openModalNewBN}
        />
      )}

      {mpdalNewBN && (
        <NewBusinessNameModal
          serviceId={serviceId}
          saveNewBusinessName={saveNewBusinessName}
          loadingSaveNewBN={loadingSaveNewBN}
          errorSaveNewBN={errorSaveNewBN}
          closeModalNewBN={closeModalNewBN}
          clearErrorMessageSaveBN={clearErrorMessageSaveBN}
          openModalResultRequestInvoice={openModalResultRequestInvoice}
        />
      )}

      {modalResultRequest && (
        <SuccessfulRequest
          closeModalResultRequestInvoice={closeModalResultRequestInvoice}
          loadingRequestInvoice={loadingRequestInvoice}
        />
      )}

      <div className="parting-line" />
      <div className={classes.headerSectionComponent}>
        <h2 className="subtitle-section">FACTURACIÓN</h2>
      </div>
      <div className={classes.fileItem}>
        {invoicingInfo.pdf && invoicingInfo.xml ? (
          <>
            <BtnCallToAction
              onClick={() => {}}
              icon="faDownload"
              className="btnDownload"
            >
              {invoicingInfo.pdf}
            </BtnCallToAction>
            <BtnCallToAction
              onClick={() => {}}
              icon="faDownload"
              className="btnDownload"
            >
              {invoicingInfo.xml}
            </BtnCallToAction>
          </>
        ) : (
          <div className={classes.contentButton}>
            {loadingbn ? (
              <Spinner />
            ) : (
              <Button onClick={openModalBN} className="btnConfirm">
                SOLICITAR FACTURA
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Billing.prototype = {
  serviceId: PropTypes.number.isRequired,
  invoicingInfo: PropTypes.array.isRequired,
  getBusinessName: PropTypes.func.isRequired,
  requestInvoice: PropTypes.func.isRequired,
  loadingbn: PropTypes.bool.isRequired,
  loadingRequestInvoice: PropTypes.bool.isRequired,
  saveNewBusinessName: PropTypes.func.isRequired,
  loadingSaveNewBN: PropTypes.bool.isRequired,
  errorSaveNewBN: PropTypes.string.isRequired,
  clearErrorMessageSaveBN: PropTypes.func.isRequired,
};

export default Billing;
