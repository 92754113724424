import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import {
  ButtonBack,
  InputSimpleFloatLabel,
  BtnCallToAction,
  Button,
  ModalDrag,
  ErrorMessage,
} from "../../../components";
import { getFileName } from "../../../utils/scripts/file";
import { caculateAmountPrice } from "../../../utils/scripts/calculatePrice";
import { musicalGroupActions } from "../../../store/slices/musicalGroup";
import { userProfileActions } from "../../../store/slices/userProfile";
import { paymentActions } from "../../../store/slices/payment";
import {
  MUSICAL_GROUP_STUDY_CHECKOUT,
  MUSICAL_GROUP_STUDY_CONFIRMATION,
} from "../../../constants/routes";
import { USER_PDLC } from "../../../constants";
import classes from "./RegStdMusicalGroups.module.css";

const RegStdMusicalGroups = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [uploadFile, setUploadFile] = useState(false);
  const [error, setError] = useState(false);
  const [userData, setUserData] = useState();

  const storeMusicalGroup = useSelector((state) => state.musicalGroup);
  const storeUserProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
  }, []);

  useEffect(() => {
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getUserServices());
  }, [dispatch]);

  const getPrice = () => {
    const musicalGrops = storeUserProfile.services.services.filter(
      (item) => item.service_id === 5
    );
    const EdrMusicalGroups = musicalGrops[0].service_sub.filter(
      (item) => item.service_id === 6
    );

    const price =
      +EdrMusicalGroups[0].service_price +
      +EdrMusicalGroups[0].service_transact;

    const totalprice = caculateAmountPrice(price);
    return totalprice.toFixed(2);
  };

  const formMusicalGroups = useFormik({
    initialValues: {
      name: storeMusicalGroup.brand_info.name,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("El campo nombre es requerido")
        .max(250, "El nombre debe contener máximo 250 caracteres")
        .matches(
          /^[,.@\-_' áéíóúÁÉÍÓÚñÑa-zA-Z0-9]+$/,
          "Nombre contiene caracteres inválidos"
        ),
    }),
    onSubmit: (values) => {
      if (storeMusicalGroup.brand_info.file === "") {
        setError(true);
      } else {
        const price = getPrice();
        dispatch(musicalGroupActions.setBrandName({ name: values.name }));
        dispatch(paymentActions.setPurchaseAmount({ amount: price }));

        if (userData.user_id === USER_PDLC) {
          history.push(MUSICAL_GROUP_STUDY_CONFIRMATION);
        } else {
          history.push(MUSICAL_GROUP_STUDY_CHECKOUT);
        }
      }
    },
  });

  const clearFileUploaded = () => {
    dispatch(musicalGroupActions.setFile({ file: "" }));
  };
  const closeUploadFile = () => {
    setUploadFile(false);
  };
  const openDragModal = () => {
    setError(false);
    setUploadFile(true);
  };

  return (
    <div className="container-modulo">
      {uploadFile && (
        <ModalDrag
          show={uploadFile}
          closed={closeUploadFile}
          scope="reg-std-musical-group"
          formatFile="zip"
        />
      )}
      <ButtonBack url="/grupos-musicales/servicios" text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">
          ESTUDIO DE REGISTRABILIDAD DE NOMBRE DE GRUPOS MUSICALES
        </h2>
      </div>

      <div className="text-description">
        <p>
          Proporciona la información para realizar el estudio de
          registrabilidad.
        </p>
      </div>

      <div className="label-information">
        <span>INFORMACIÓN DEL GRUPO MUSICAL</span>
      </div>
      <div className="main-content-form">
        <form onSubmit={formMusicalGroups.handleSubmit}>
          <InputSimpleFloatLabel
            id="name"
            name="name"
            placeholder="NOMBRE DEL GRUPO MUSICAL"
            onChange={formMusicalGroups.handleChange}
            valid={typeof formMusicalGroups.errors.name === "undefined"}
            touched={formMusicalGroups.touched.name}
            defaultValue={storeMusicalGroup.brand_info.name}
          />

          <ErrorMessage
            message={
              typeof formMusicalGroups.errors.name !== "undefined"
                ? formMusicalGroups.errors.name
                : ""
            }
          />

          <div className="updaload-file">
            {storeMusicalGroup.brand_info.file === "" ? (
              <BtnCallToAction onClick={openDragModal} icon="faUpload">
                LOGOTIPO DEL GRUPO MUSICAL
              </BtnCallToAction>
            ) : (
              <BtnCallToAction
                type="showfile"
                onClick={clearFileUploaded}
                icon="faTimesCircle"
                className="btnGreen"
                fileName={getFileName(storeMusicalGroup.brand_info.file)}
              >
                LOGOTIPO DEL GRUPO MUSICAL
              </BtnCallToAction>
            )}
            <p className="description-file">
              Esté archivo deberá ser formato ZIP.
            </p>
          </div>
          {error && <ErrorMessage message="Archivo requerido" />}
          <div className={classes.cntButton}>
            <Button
              disabled={!formMusicalGroups.isValid}
              type="submit"
              className={"btnConfirm"}
            >
              CONTINUAR
            </Button>
          </div>
        </form>
      </div>

      <div className="label-information">
        <span>INFORMACIÓN DE PAGO</span>
      </div>
      <div className="label-information">
        <span>CONFIRMACIÓN</span>
      </div>
    </div>
  );
};

export default RegStdMusicalGroups;
