import { useState } from "react";
import PropTypes from "prop-types";
import { InputSelect, Spinner } from "../../../components";
import { requestType } from "../../../utils/cat-requests";
import Collapsible from "../../../components/Templates/Requests/Collapsible";

const MyRequestList = ({ loading, orders, handleShowDetail, filterOrders }) => {
  const [requestTypeId, setRequestTypeId] = useState(0);
  let count = 0;

  const getFilterData = (event, callBack) => {
    setRequestTypeId(event.target.value);
    callBack(event);
  };

  return (
    <div className="container-modulo">
      <div className="section-title">
        <h2 className="subtitle">
          MIS SOLICITUDES {!loading && <> ({orders.length}) </>}
        </h2>
      </div>
      <div className="text-description">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et nulla
          massa. Nunc sodales dignissim enim eget efficitur.
        </p>

        <div className="container-select" style={{ paddingBottom: "10px" }}>
          <InputSelect
            id="request"
            name="request"
            options={requestType}
            fieldIdOption="id"
            onChange={(event) => {
              getFilterData(event, filterOrders);
            }}
            value={requestTypeId}
          />
        </div>

        {loading ? (
          <Spinner />
        ) : (
          orders.map((item) => {
            count++;
            return (
              <Collapsible
                key={item.service_order_id + Math.random()}
                serviceOrderId={item.service_order_id}
                serviceId={item.service_id}
                finalDate={item.final_date}
                brandName={item.brand_name}
                serviceStatusId={item.service_status_id}
                servicerecurrent={item.service_recurrent}
                isCollapsed={count > 4}
                handleShowDetail={handleShowDetail}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

MyRequestList.prototype = {
  loading: PropTypes.bool.isRequired,
  orders: PropTypes.array.isRequired,
  handleShowDetail: PropTypes.func.isRequired,
};

export default MyRequestList;
