

export const calculateClassesPrice = (sumprice) => {
    let Ffixed = (process.env.REACT_APP_FFIXED * process.env.REACT_APP_IVA);
    let Fpercent = (process.env.REACT_APP_FPERCCENT * process.env.REACT_APP_IVA);
    let Pgoal = sumprice * process.env.REACT_APP_IVA;

    return (Pgoal + Ffixed) / (1 - Fpercent);
  }

export const caculateAmountPrice = (amount) => {
  let Ffixed = (process.env.REACT_APP_FFIXED * process.env.REACT_APP_IVA);
  let Fpercent = (process.env.REACT_APP_FPERCCENT * process.env.REACT_APP_IVA);
  let Pgoal = (amount * process.env.REACT_APP_IVA);

  return (Pgoal + Ffixed) / (1 - Fpercent);
}