import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { magazinesActions } from "../../../store/slices/magazines";
import { ButtonBack, Button, ButtonRound } from "../../../components";
import { services } from "../../../constants/services";
import { userProfileActions } from "../../../store/slices/userProfile";
import { MAGAZINES_STUDY_REGISTER } from "../../../constants/routes";
import { Revistas } from "../../../assets/images/icons/icons";

const STUDY = MAGAZINES_STUDY_REGISTER;
const REGISTER = "/revistas/seleccion-estudio";
const SERVICIOS = "/servicios";

const ServicesMagazine = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const storeUserProfile = useSelector((state) => state.userProfile);
  const [magazines, setMagazines] = useState([]);
  const [servicesMagazine, setServicesMagazine] = useState([]);
  const [estudioRegMagazine, setEstudioRegMagazine] = useState();
  const [registroMagazine, setRegistroMagazine] = useState();

  useEffect(() => {
    const resMagazines = storeUserProfile?.services?.services?.find(
      (item) => item.service_id === services.REVISTAS
    );
    setServicesMagazine(resMagazines);
    const estudioMagazine = servicesMagazine?.service_sub?.find(
      (item) => item.service_id === services.REVISTAS_ESTUDIO_REG
    );
    const regRevistas = servicesMagazine?.service_sub?.find(
      (item) => item.service_id === services.REVISTAS_REGISTRO
    );
    setRegistroMagazine(regRevistas);
    setEstudioRegMagazine(estudioMagazine);
  }, [servicesMagazine?.service_sub, storeUserProfile?.services?.services]);

  useEffect(() => {
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getUserServices());
  }, [dispatch]);

  useEffect(() => {
    const result = storeUserProfile?.services?.services?.filter(
      (item) => item.service_id === services.REVISTAS
    );
    setMagazines(result);
  }, [storeUserProfile?.services?.services]);

  const goBack = () => {
    props.history.replace(SERVICIOS);
  };

  const handlerRegister = (id) => {
    dispatch(magazinesActions.setServiceId({ service_id: id }));
    if (id === 10) {
      history.push(REGISTER);
    }
    if (id === 9) {
      history.push(STUDY);
    }
  };

  return (
    <div className="container-modulo">
      <ButtonBack url={SERVICIOS} text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">SERVICIOS</h2>
      </div>
      <div className="item-marcas">
        <img src={Revistas} alt="icon-marcas" />
        &nbsp;&nbsp; REVISTAS
      </div>

      {servicesMagazine?.service_sub?.find(
        (item) => item.service_id === services.REVISTAS_REGISTRO
      ) && (
        <>
          <div className="parting-line" />
          <div className="text-description">
            <p>Registra el nombre de tu revista</p>
            <h2 className="subtitle-modle">{registroMagazine?.service_name}</h2>
            <p>{registroMagazine?.service_description}</p>
            <div className="price">
              <CurrencyFormat
                value={
                  magazines &&
                  Number(registroMagazine?.service_price).toFixed(2)
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value) => (
                  <div>
                    {value} <span className="currency">MXM</span>
                  </div>
                )}
              />
            </div>
            <div className="content-btn">
              <Button
                onClick={() => handlerRegister(registroMagazine.service_id)}
                className="btnConfirm"
              >
                SOLICITAR
              </Button>
            </div>
          </div>
          <br />
          <br />
          <div className="parting-line" />
          <br />
          <br />
        </>
      )}

      {servicesMagazine?.service_sub?.find(
        (item) => item.service_id === services.REVISTAS_ESTUDIO_REG
      ) && (
        <>
          <div className="text-description">
            <h2 className="subtitle-modle">
              {estudioRegMagazine?.service_name}
            </h2>
            <p>{estudioRegMagazine?.service_description}</p>

            <div className="price">
              <CurrencyFormat
                value={
                  magazines &&
                  Number(estudioRegMagazine?.service_price).toFixed(2)
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value) => (
                  <div>
                    {value} <span className="currency">MXM</span>
                  </div>
                )}
              />
            </div>
            <div className="content-btn">
              <Button onClick={() => handlerRegister(estudioRegMagazine.service_id)} className="btnConfirm">
                SOLICITAR
              </Button>
            </div>
          </div>
          <br />
          <br />
          <div className="parting-line" />
        </>
      )}
      <div className="content-btn btn-back-margin">
        <ButtonRound onClick={goBack} icon="faArrowLeft">
          REGRESAR
        </ButtonRound>
      </div>
    </div>
  );
};

export default ServicesMagazine;
