import { useState } from "react";
import { useParams } from "react-router-dom";
import RenewalCheckOut from "./RenewalCheckOut";
import RenewalConfirmation from "./RenewalConfirmation";
import useRenewal from "./useRenewal";

const screens = ["CheckOut", "Confirmation"];

const TradeMark = () => {
  const { orden } = useParams();
  const [currentScreen, setCurrentScreen] = useState(screens[0]);
  const { renewal, loading, price, saveError, saveLoading, saveRenewal } =
    useRenewal(orden);

  const handleCheckOut = () => {
    setCurrentScreen(screens[0]);
  };
  const handleConfirmation = () => {
    setCurrentScreen(screens[1]);
  };

  const screenObj = {
    [screens[0]]: (
      <RenewalCheckOut handleSubmit={handleConfirmation} loading={loading} />
    ),
    [screens[1]]: (
      <RenewalConfirmation
        renewal={renewal}
        price={price}
        handleSubmit={saveRenewal}
        handleCheckOut={handleCheckOut}
        loading={saveLoading}
        error={saveError}
      />
    ),
  };

  return (
    <div className="container-modulo">
      <div className="section-title">
        <h2 className="subtitle">RENOVACIÓN DE DERECHOS</h2>
      </div>
      <div className="text-description">
        <p>
          Renovamos tus derechos de propiedad intelectual, de manera expedita.
        </p>
      </div>

      {screenObj[currentScreen]}
    </div>
  );
};

export default TradeMark;
