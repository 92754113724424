import PropTypes from "prop-types";

import classes from "./InputCheckBox.module.css";

const InputCheckBox = ({id, label, value, name, onChange, checked}) => {
  return (
    <div className={classes.contentCheckBox}>
      <label className={classes.checkitem}>
        <label htmlFor={id} className={classes.checkLabel}>
          {label}
        </label>
        <input
          type="checkbox"
          id={id}
          value={value}
          name={name}
          onChange={onChange}
          checked={checked}
        />
        <span className={classes.checkmark}></span>
      </label>
    </div>
  );
};

InputCheckBox.defaultProps = {
  onChange: () => {},
};

InputCheckBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default InputCheckBox;
