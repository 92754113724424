import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { userProfileActions } from "../../store/slices/userProfile";
import InputFloatLabel from "../UI/Input/InputFloatLabel/InputFloatLabel";
import Spinner from "../UI/Spinner/Spinner";
import Button from "../UI/Button/Button";
import logo from "../../assets/images/logo-aTuAlcance.svg";
import { Play } from "../../assets/images/icons/icons";

import "./create-account-form.css";

const CreateAccountForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const formAccount = useFormik({
    initialValues: {
      name: "",
      apaterno: "",
      amaterno: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("El campo nombre es requerido"),
      apaterno: Yup.string().required("El campo apellido paterno es requerido"),
      amaterno: Yup.string().required("El campo apellido materno es requerido"),
      email: Yup.string()
        .email("Correo electrónico inválido")
        .required("Correo eléctronico requerido"),
      password: Yup.string().required("Password es requerido"),
      confirmPassword: Yup.string()
        .required("Confimar password es requerido")
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "El password no coinside"
          ),
        }),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const bodyUser = {
        name: values.name,
        last_name: values.apaterno,
        last_name_mom: values.amaterno,
        email: values.email,
        password: values.password,
        confirm_password: values.password,
      };

      await axios
        .request({
          url: process.env.REACT_APP_URL_BASE + "auth/signup",
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          data: bodyUser,
        })
        .then((response) => {
          if (response.data.code === 201) {
            dispatch(
              userProfileActions.setDataRegisterUser({ data: bodyUser })
            );
            history.push("/verificacion");
          }
          setLoading(false);
        })
        .catch((err) => {
          setErrorMessage(err.response.data.message);
          setLoading(false);
        });
    },
  });

  return (
    <div className="container-simple">
      <img src={logo} className="logo" alt="A tu alcance" />
      <h2 className="subtitle">REGISTRO</h2>

      <div className="container-form">
        <form onSubmit={formAccount.handleSubmit} className="form-register">
          <div className="form-row-element">
            <InputFloatLabel
              id="name"
              name="name"
              placeholder="NOMBRE"
              onChange={formAccount.handleChange}
              valid={formAccount.errors.name && formAccount.touched.name}
            />
          </div>
          <div className="form-row-element">
            <InputFloatLabel
              id="apaterno"
              name="apaterno"
              placeholder="APELLIDO PATERNO"
              onChange={formAccount.handleChange}
              valid={
                formAccount.errors.apaterno && formAccount.touched.apaterno
              }
            />
          </div>
          <div className="form-row-element">
            <InputFloatLabel
              id="amaterno"
              name="amaterno"
              placeholder="APELLIDO MATERNO"
              onChange={formAccount.handleChange}
              valid={
                formAccount.errors.amaterno && formAccount.touched.amaterno
              }
            />
          </div>
          <div className="form-row-element">
            <InputFloatLabel
              id="email"
              name="email"
              placeholder="CORREO ELECTRONICO"
              onChange={formAccount.handleChange}
              valid={formAccount.errors.email && formAccount.touched.email}
            />
          </div>
          <div className="form-row-element">
            <InputFloatLabel
              id="password"
              name="password"
              type="password"
              placeholder="CONTRASEÑA"
              onChange={formAccount.handleChange}
              valid={
                formAccount.errors.password && formAccount.touched.password
              }
            />
          </div>
          <div className="form-row-element">
            <InputFloatLabel
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              placeholder="CONFIRMAR CONTRASEÑA"
              onChange={formAccount.handleChange}
              valid={
                formAccount.errors.confirmPassword &&
                formAccount.touched.confirmPassword
              }
            />
          </div>
          <div className="content-button">
            <label className="error-message">
              {errorMessage ===
              "Password did not conform with policy: Password not long enough"
                ? "La contraseña no es lo suficientemente larga"
                : errorMessage === "User already exists"
                ? "El usuario ya está registrado"
                : errorMessage}
            </label>
          </div>
          <div className="content-button">
            {loading ? (
              <Spinner />
            ) : (
              <Button type="submit" className="btnConfirm">
                CONTINUAR
              </Button>
            )}
          </div>
        </form>
      </div>

      <div className="assets-box">
        <p className="subtitle-small">
          <NavLink to="/aviso-privacidad" style={{ color: "#121f61" }}>
            AVISO DE PRIVACIDAD
          </NavLink>
        </p>
        <div className="parting-line" />
        <p className="no-account">¿Ya tienes cuenta?</p>
        <p className="register">
          <NavLink to="/login">INICIA SESIÓN</NavLink>{" "}
          <span style={{ color: "#96e8b2" }}>
            <img alt="registrate" src={Play} width="13" height="13" />
          </span>
        </p>
      </div>
    </div>
  );
};

export default CreateAccountForm;
