import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useRenewal from "../useRenewals";
import { caculateAmountPrice } from "../../../../utils/scripts/calculatePrice";
import {
  InputRadio,
  InputSelect,
  Button,
  Spinner,
} from "../../../../components";
import {
  RIGHTS_RENOVATIONS_REGISTER,
  RIGHTS_RENOVATIONS_CHECKOUT,
} from "../../../../constants/routes";
import { services } from "../../../../constants/services";
import { rightsActions } from "../../../../store/slices/rights";
import { paymentActions } from "../../../../store/slices/payment";
import { userProfileActions } from "../../../../store/slices/userProfile";

const SelectRight = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [validForm, setValidForm] = useState(false);
  const storeRights = useSelector((state) => state.rights);
  const { loading, orders, getInformationOrder } = useRenewal();

  const storeUserProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    dispatch(userProfileActions.getHolders());
    dispatch(userProfileActions.getServices());
  }, [dispatch]);

  useEffect(() => {
    let valid = true;
    if (storeRights.isRegisteredRight) {
      if (storeRights.service_order_id === "-1") {
        valid = false;
      }
    }
    setValidForm(valid);
  }, [storeRights.isRegisteredRight, storeRights.service_order_id]);

  const handleChangeRadioRight = (event) => {
    dispatch(
      rightsActions.setIsRegisteredRight({
        isRegisteredRight: event.target.value === "si",
      })
    );
  };

  const hanldleChangeRegisteredRight = (event) => {
    const orderInfo = getInformationOrder(event.target.value);
    dispatch(
      rightsActions.setregisterdRights({
        registerdRights: orderInfo,
      })
    );
    dispatch(
      rightsActions.setServiceOrderId({
        service_order_id: event.target.value,
      })
    );
  };

  const getPrice = () => {
    const rightsLicense = storeUserProfile.services.services.filter(
      (item) => item.service_id === services.DERECHOS_RENOVACION
    );

    const price =
      +rightsLicense[0].service_price + +rightsLicense[0].service_transact;

    const totalprice = caculateAmountPrice(price);

    return totalprice.toFixed(2);
  };

  const handleButtonSubmit = () => {
    if (storeRights.isRegisteredRight) {
      const price = getPrice();
      dispatch(rightsActions.setIsValidRenewal({ valid: true }));
      dispatch(paymentActions.setPurchaseAmount({ amount: price }));
      history.replace(RIGHTS_RENOVATIONS_CHECKOUT);
    } else {
      history.replace(RIGHTS_RENOVATIONS_REGISTER);
    }
  };

  return (
    <div className="container-modulo">
      <div className="section-title">
        <h2 className="subtitle">RENOVACIÓN DE DERECHOS</h2>
      </div>
      <div className="text-description">
        <p>
          Renovamos tus derechos de propiedad intelectual, de manera expedita.
        </p>
      </div>
      <div className="label-information">
        <div>SELECCIÓN DEL DERECHO</div>
      </div>
      <div className="contentainer-description-text">
        <p>
          Hemos identificado que has registrado uno o más derechos previamente
          con nosotros que requieren renovarse.
        </p>
        <p>
          Por favor indica si el derecho que deseas renovar corresponde a alguno
          de estos, o bien si es un derecho adicional.
        </p>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="content-form-main">
            <InputRadio
              id="si"
              name="isRegisteredRight"
              value="si"
              onChange={handleChangeRadioRight}
              label="EL DERECHO CORRESPONDE A:"
              checked={storeRights.isRegisteredRight}
            />
            {storeRights.isRegisteredRight && (
              <InputSelect
                id="renovation"
                name="renovation"
                placeholder="- ELIGE UN DERECHO -"
                options={orders}
                fieldIdOption="id"
                value={storeRights.service_order_id}
                onChange={hanldleChangeRegisteredRight}
              />
            )}
            <InputRadio
              id="no"
              name="isRegisteredRight"
              value="no"
              onChange={handleChangeRadioRight}
              label="DERECHO ADICIONAL"
              checked={!storeRights.isRegisteredRight}
            />
            <div className="contentainer-description-text">
              <p>
                Si el derecho a renovar ha sido transferido a un tercero, favor
                de
                <span className="text-blue"> CONTACTARNOS</span>
              </p>
            </div>
          </div>
          <div className="content-form-main">
            <div className="contentainer-button">
              <Button
                onClick={handleButtonSubmit}
                className="btnConfirm"
                disabled={!validForm}
              >
                CONTINUAR
              </Button>
            </div>
          </div>
        </>
      )}

      <div className="label-information">
        <div>INFORMACIÓN DEL DERECHO</div>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DE PAGO</div>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default SelectRight;
