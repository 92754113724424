import { USER_PDLC } from "../../../constants";
import basicAuthRequest from "../../../utils/axios/basicAuthRequest";

export function requestCreateRightsRenewal(
  storeRights,
  storePayment,
  storeEntity
) {
  const user = JSON.parse(localStorage.getItem("user"));
  const entity = {
    type: 1,
    name: "",
    rfc: "",
    curp: "",
    street: "",
    ext_number: "",
    int_number: "",
    email: "",
    phone: "",
    state: "",
    city: "",
    postal_code: "",
    neighborhood: "",
    file: "",
  };

  const titular_full = {
    type: 1,
    name: storeEntity.titular.titularName.value,
    rfc: storeEntity.titular.rfc.value,
    curp: storeEntity.titular.curp.value,
    street: storeEntity.titular.street.value,
    ext_number: storeEntity.titular.extNumber.value,
    int_number: storeEntity.titular.intNumber.value,
    email: storeEntity.titular.email.value,
    phone: storeEntity.titular.phone.value,
    state: storeEntity.titular.state.value,
    city: storeEntity.titular.city.value,
    postal_code: storeEntity.titular.postalCode.value,
    neighborhood: storeEntity.titular.neighborhood.value,
    file: storeEntity.titular.file,
  };

  const bodyRightRenewal = {
    user_id: user.user_id,
    service_id: 17,
    service_order_id:
      storeRights.service_order_id === null
        ? null
        : +storeRights.service_order_id,
    service_recurrent_id: 0,
    brand_info: {
      right_type: storeRights.brand_info.right_type,
      registration_number: +storeRights.brand_info.number,
      name: storeRights.brand_info.name,
    },
    owner_id:
      storeRights.isRegisteredHolder && +storeRights.owner_id !== -1
        ? +storeRights.owner_id
        : null,
    owner_info: storeRights.isRegisteredHolder
      ? { ...entity }
      : { ...titular_full },
    billing_info: {
      payment_method_id:
        user.user_id === USER_PDLC
          ? "pm_1jbyzUyGRrBBY1dp8rWqpr0fp"
          : storePayment.paymentMethodid,
      price_total: +storePayment.purchaseAmount,
    },
  };

  try {
    return basicAuthRequest
      .request({
        url: "feed/createorder",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: bodyRightRenewal,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => err);
  } catch (error) {
    console.log("Error", error);
  }
}

export function requestCreateRightsCession(
  storeRights,
  storePayment,
  storeEntity
) {
  const user = JSON.parse(localStorage.getItem("user"));

  const entity = {
    type: 1,
    name: "",
    rfc: "",
    curp: "",
    street: "",
    ext_number: "",
    int_number: "",
    email: "",
    phone: "",
    state: "",
    city: "",
    postal_code: "",
    neighborhood: "",
    file: "",
  };

  const titular_full = {
    type: 1,
    name: storeEntity.titular.titularName.value,
    rfc: storeEntity.titular.rfc.value,
    curp: storeEntity.titular.curp.value,
    street: storeEntity.titular.street.value,
    ext_number: storeEntity.titular.extNumber.value,
    int_number: storeEntity.titular.intNumber.value,
    email: storeEntity.titular.email.value,
    phone: storeEntity.titular.phone.value,
    state: storeEntity.titular.state.value,
    city: storeEntity.titular.city.value,
    postal_code: storeEntity.titular.postalCode.value,
    neighborhood: storeEntity.titular.neighborhood.value,
    file: storeEntity.titular.file,
  };

  const assignee_full = {
    type: 1,
    name: storeEntity.cesionario.titularName.value,
    rfc: storeEntity.cesionario.rfc.value,
    curp: storeEntity.cesionario.curp.value,
    street: storeEntity.cesionario.street.value,
    ext_number: storeEntity.cesionario.extNumber.value,
    int_number: storeEntity.cesionario.intNumber.value,
    email: storeEntity.cesionario.email.value,
    phone: storeEntity.cesionario.phone.value,
    state: storeEntity.cesionario.state.value,
    city: storeEntity.cesionario.city.value,
    postal_code: storeEntity.cesionario.postalCode.value,
    neighborhood: storeEntity.cesionario.neighborhood.value,
    file: storeEntity.cesionario.file,
  };

  const bodyRightCession = {
    user_id: user.user_id,
    service_id: 18,
    brand_info: {
      right_type: storeRights.brand_info.right_type,
      registration_number: storeRights.brand_info.registration_number,
      amount: +storeRights.brand_info.amount,
    },
    owner_id: storeRights.isRegisteredHolder ? +storeRights.owner_id : null,
    owner_info: storeRights.isRegisteredHolder
      ? { ...entity }
      : { ...titular_full },
    assignee_id: storeRights.isRegisteredAssignee
      ? +storeRights.assignee_id
      : null,
    assignee_info: storeRights.isRegisteredAssignee
      ? { ...entity }
      : { ...assignee_full },
    billing_info: {
      payment_method_id:
        user.user_id === USER_PDLC
          ? "pm_1jbyzUyGRrBBY1dp8rWqpr0fp"
          : storePayment.paymentMethodid,
      price_total: +storePayment.purchaseAmount,
    },
  };
  try {
    return basicAuthRequest
      .request({
        url: "feed/createorder",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: bodyRightCession,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => err);
  } catch (error) {
    console.log("Error", error);
  }
}

export function requestCreateRightsLicense(
  storeRights,
  storePayment,
  storeEntity
) {
  const user = JSON.parse(localStorage.getItem("user"));

  const entity = {
    type: 1,
    name: "",
    rfc: "",
    curp: "",
    street: "",
    ext_number: "",
    int_number: "",
    email: "",
    phone: "",
    state: "",
    city: "",
    postal_code: "",
    neighborhood: "",
    file: "",
  };

  const titular_full = {
    type: 1,
    name: storeEntity.titular.titularName.value,
    rfc: storeEntity.titular.rfc.value,
    curp: storeEntity.titular.curp.value,
    street: storeEntity.titular.street.value,
    ext_number: storeEntity.titular.extNumber.value,
    int_number: storeEntity.titular.intNumber.value,
    email: storeEntity.titular.email.value,
    phone: storeEntity.titular.phone.value,
    state: storeEntity.titular.state.value,
    city: storeEntity.titular.city.value,
    postal_code: storeEntity.titular.postalCode.value,
    neighborhood: storeEntity.titular.neighborhood.value,
    file: storeEntity.titular.file,
  };

  const licensee_full = {
    type: 1,
    name: storeEntity.licenciatario.titularName.value,
    rfc: storeEntity.licenciatario.rfc.value,
    curp: storeEntity.licenciatario.curp.value,
    street: storeEntity.licenciatario.street.value,
    ext_number: storeEntity.licenciatario.extNumber.value,
    int_number: storeEntity.licenciatario.intNumber.value,
    email: storeEntity.licenciatario.email.value,
    phone: storeEntity.licenciatario.phone.value,
    state: storeEntity.licenciatario.state.value,
    city: storeEntity.licenciatario.city.value,
    postal_code: storeEntity.licenciatario.postalCode.value,
    neighborhood: storeEntity.licenciatario.neighborhood.value,
    file: storeEntity.licenciatario.file,
  };

  const bodyRightLicence = {
    user_id: user.user_id,
    service_id: 20,
    brand_info: {
      registration_number: storeRights.brand_info.registration_number,
      initial_date: storeRights.brand_info.initial_date,
      final_date: storeRights.brand_info.final_date,
      amount: +storeRights.brand_info.amount,
      payment_period_amount: +storeRights.brand_info.payment_period_amount,
      piracy: storeRights.brand_info.privacy,
    },
    owner_id: storeRights.isRegisteredHolder ? +storeRights.owner_id : null,
    owner_info: storeRights.isRegisteredHolder
      ? { ...entity }
      : { ...titular_full },
    licensee_id: storeRights.isRegisteredLicense
      ? +storeRights.licensee_id
      : null,
    licensee_info: storeRights.isRegisteredLicense
      ? { ...entity }
      : { ...licensee_full },
    billing_info: {
      payment_method_id:
        user.user_id === USER_PDLC
          ? "pm_1jbyzUyGRrBBY1dp8rWqpr0fp"
          : storePayment.paymentMethodid,
      price_total: +storePayment.purchaseAmount,
    },
  };

  try {
    return basicAuthRequest
      .request({
        url: "feed/createorder",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: bodyRightLicence,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => err);
  } catch (error) {
    console.log("Error", error);
  }
}
