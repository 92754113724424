import { createSlice } from "@reduxjs/toolkit";

const initialNewspaper = {
  user_id: null,
  isNewBrand: false,
  service_id: 6,
  regSudies: [],
  studyBelongsId: -1,
  isRegisteredHolder: true,
  titularId: -1,
  brand_info: {
    name: "",
    file: "",
  },
  billing_info: {
    payment_method_id: "",
    price_total: null,
  },
  /** Valid Registro Grupo Musical */
  isValidRGM: false,
};

const validateRGM = (state) => {
  let valid = true;

  if (state.brand_info.name === "") {
    valid = false;
  }

  if (state.isRegisteredHolder && state.titularId === "-1") {
    valid = false;
  }

  return valid;
};

const newspaperSlice = createSlice({
  name: "magazines",
  initialState: initialNewspaper,
  reducers: {
    cleanStorageNewspaper(state) {
      state.user_id = null;
      state.isNewBrand = false;
      state.service_id = 6;
      state.regSudies = [];
      state.studyBelongsId = -1;
      state.isRegisteredHolder = true;
      state.titularId = -1;
      state.brand_info = {
        name: "",
        file: "",
      };
      state.billing_info = {
        payment_method_id: "",
        price_total: null,
      };
      state.isValidRGM = false;
    },
    /** Save new registrability studies magazine */
    createNewspaperStudy() {},
    /** Save new register magazine */
    createNewspaper() {},
    /** Obtain registrability studies  */
    getRegistrabilityStudies() {},
    setTitularId(state, action) {
      state.titularId = action.payload.titularId;
      state.isValidRGM = validateRGM(state);
    },
    setIsRegisteredHolder(state, action) {
      state.isRegisteredHolder = action.payload.isRegisteredHolder;
      state.isValidRGM = validateRGM(state);
    },
    setRegStudies(state, action) {
      state.regSudies = action.payload.regStudies;
    },
    setStudyBelongsId(state, action) {
      state.studyBelongsId = action.payload.studyId;
    },
    setIsNewBrand(state, action) {
      state.isNewBrand = action.payload.isNewBrand;
    },
    setBrandName(state, action) {
      state.brand_info.name = action.payload.name;
      state.isValidRGM = validateRGM(state);
    },
    setFile(state, action) {
      state.brand_info.file = action.payload.file;
    },
    setServiceId(state, action) {
      state.service_id = action.payload.service_id;
    },
  },
});

export const newspaperActions = newspaperSlice.actions;

export default newspaperSlice;
