import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getFileName } from "../../../utils/scripts/file";
import EntityTemplate from "../Entity/EntityTemplate";
import { Button, Spinner } from "../../../components";
import classes from "./ConfirmationTemplate.module.css";

const MagazineTemplate = (props) => {
  const [accepted, setAccepted] = useState(false);
  const [sendConfirm, setSendConfirm] = useState(false);
  const [dataTitular, setDataTitular] = useState([]);
  const [dataGroup, setDataGroup] = useState([]);

  const storeMagazine = useSelector((state) => state.magazines);
  const storeEntity = useSelector((state) => state.entity);
  const storeUserProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (storeMagazine.isRegisteredHolder) {
      const titular = storeUserProfile?.holders?.filter(
        (element) => element.id === +storeMagazine.titularId
      );
      setDataTitular(titular[0]);
    } else {
      setDataTitular([]);
    }
  }, [
    storeMagazine.isRegisteredHolder,
    storeMagazine.titularId,
    storeUserProfile.holders,
  ]);

  useEffect(() => {
    if (!storeMagazine.isNewBrand) {
      const study = storeMagazine.regSudies.filter(
        (item) => +item.service_order_id === +storeMagazine.studyBelongsId
      );
      setDataGroup(study);
    } else {
      setDataGroup([]);
    }
  }, [
    storeMagazine.isNewBrand,
    storeMagazine.regSudies,
    storeMagazine.studyBelongsId,
  ]);

  const confirmation = (callback) => {
    setSendConfirm(true);
    callback();
  };

  return (
    <div className={classes.confirmation}>
      <div className={classes.confirmationItem}>
        <h3>NOMBRE DE LA REVISTA</h3>
        <p>{dataGroup[0]?.name || props.registerData.brand_info.name}</p>
      </div>
      <div className={classes.confirmationItem}>
        <h3>LOGOTIPO DE LA REVISTA</h3>
        <p>
          {getFileName(dataGroup[0]?.file) ||
            getFileName(props.registerData.brand_info.file)}
        </p>
      </div>
      {storeMagazine.service_id === 10 && (
        <EntityTemplate title="DATOS DEL AUTOR">
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>NOMBRE</div>
              <div className={classes.itemEntity}>
                {dataTitular?.name || storeEntity.titular.titularName.value}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>CALLE</div>
              <div className={classes.itemEntity}>
                {dataTitular?.street || storeEntity.titular.street.value}
              </div>
            </div>
          </div>
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>NÚMERO EXTERIOR</div>
              <div className={classes.itemEntity}>
                {dataTitular?.ext_number || storeEntity.titular.extNumber.value}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>NÚMERO INTERIOR</div>
              <div className={classes.itemEntity}>
                {dataTitular?.int_number || storeEntity.titular.intNumber.value}
              </div>
            </div>
          </div>
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>CORREO ELECTRÓNICO</div>
              <div className={classes.itemEntity}>
                {dataTitular?.email || storeEntity.titular.email.value}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>TELÉFONO</div>
              <div className={classes.itemEntity}>
                {dataTitular?.phone || storeEntity.titular.phone.value}
              </div>
            </div>
          </div>
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>ESTADO</div>
              <div className={classes.itemEntity}>
                {dataTitular?.state || storeEntity.titular.state.value}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>CIUDAD</div>
              <div className={classes.itemEntity}>
                {dataTitular?.city || storeEntity.titular.city.value}
              </div>
            </div>
          </div>
          <div className="form-informacion-marca">
            <div>
              <div className={classes.labelEntity}>CÓDIGO POSTAL</div>
              <div className={classes.itemEntity}>
                {dataTitular?.postal_code ||
                  storeEntity.titular.postalCode.value}
              </div>
            </div>
            <div>
              <div className={classes.labelEntity}>COLONIA</div>
              <div className={classes.itemEntity}>
                {dataTitular?.neighborhood ||
                  storeEntity.titular.neighborhood.value}
              </div>
            </div>
          </div>
        </EntityTemplate>
      )}

      <div className={classes.confirmationItem}>
        <h3>METÓDO DE PAGO</h3>
        <p>{props.paymentData.paymentMethod}</p>
      </div>
      <div className={classes.confirmationItem}>
        <h3>TOTAL</h3>
        <div>
          <span className={classes.total}>
            <CurrencyFormat
              value={props.paymentData.purchaseAmount}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              renderText={(value) => (
                <div>
                  {value} <span className={classes.currency}>MXM</span>
                </div>
              )}
            />
          </span>
          <label htmlFor="terms" className={classes.terms}>
            (Incluye impuestos y comisiones)
          </label>
        </div>
      </div>
      <div className={classes.termsAndConditions}>
        <label className={classes.checkitem}>
          <label htmlFor="terms" className={classes.terms}>
            Acepto todos los &nbsp;
            <NavLink to="/terminos-y-condiciones" className={classes.linkText}>
              TÉRMINOS Y CONDICIONES
            </NavLink>
          </label>
          <input
            type="checkbox"
            id="terms"
            name="terms"
            onChange={(event) => setAccepted(event.target.checked)}
          />
          <span className={classes.checkmark}></span>
        </label>
      </div>
      <div className={classes.textDescription}>
        <p>
          Al presionar "confirmar solicitud" usted acepta todos los términos y
          condiciones de <b>A tu alcance.</b>
        </p>
        <p>El pago de esta solicitud no garantiza la aprobación de la misma.</p>
      </div>
      <div className={classes.btnConfirm}>
        {sendConfirm ? (
          <Spinner />
        ) : (
          <Button
            className={"btnConfirm"}
            onClick={() => confirmation(props.onConfirm)}
            disabled={!accepted}
          >
            CONFIRMAR SOLICITUD
          </Button>
        )}
      </div>
    </div>
  );
};

MagazineTemplate.propTypes = {
  registerData: PropTypes.object.isRequired,
  paymentData: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  userProfile: PropTypes.object,
  scope: PropTypes.string.isRequired,
};

export default MagazineTemplate;
