import { createSlice } from "@reduxjs/toolkit";

const initialIndustrialDesigns = {
  user_id: null,
  service_id: 19,
  isRegisteredHolder: true,
  titularId: -1,
  brand_info: {
    name: "",
    file: "",
  },
  billing_info: {
    payment_method_id: "",
    price_total: null,
  },
  isValidRGM: false,
};

const validate = (state) => {
  let valid = true;

  if (state.brand_info.name === "") {
    valid = false;
  }

  if (state.setIsRegisteredHolder && state.titularId === "-1") {
    valid = false;
  }

  return valid;
};

const industrialDesignSlice = createSlice({
  name: "industrialDesigns",
  initialState: initialIndustrialDesigns,
  reducers: {
    cleanStorageIndustrialDesign(state) {
      state.user_id = null;
      state.service_id = 19;
      state.isRegisteredHolder = true;
      state.titularId = -1;
      state.brand_info = {
        name: "",
        file: "",
      };
      state.billing_info = {
        payment_method_id: "",
        price_total: null,
      };
      state.isValidRGM = false;
    },
    createIndustrialDesign() {},
    setTitularId(state, action) {
      state.titularId = action.payload.titularId;
      state.isValidRGM = validate(state);
    },
    setIsRegisteredHolder(state, action) {
      state.isRegisteredHolder = action.payload.isRegisteredHolder;
      state.isValidRGM = validate(state);
    },
    setBrandName(state, action) {
      state.brand_info.name = action.payload.name;
      state.isValidRGM = validate(state);
    },
    setFile(state, action) {
      state.brand_info.file = action.payload.file;
    },
    setServiceId(state, action) {
      state.service_id = action.payload.service_id;
    },
  },
});

export const industrialDActions = industrialDesignSlice.actions;

export default industrialDesignSlice;
