import classes from "./MyRequest.module.css";

const RejectedImpediment = ({ dudate }) => {
  return (
    <div className={classes.paymentContent}>
      <div className={classes.paymentRjected}>PAGO RECHAZADO</div>
      <div className={classes.contentPaymentRejected}>
        <div>
          Fecha: <span className={classes.dataPayment}>{dudate}</span>
        </div>
      </div>
    </div>
  );
};

export default RejectedImpediment;
