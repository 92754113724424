import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import {
  Marcas,
  Obras,
  Grupos,
  Revistas,
  Periodicos,
  Publicaciones,
  Disenos,
  Licencia,
  Cesion,
  Renovaciones,
} from "../../../assets/images/icons/icons";
import classes from "./ItemMenu.module.css";

const ItemMenu = ({ icon, iconAlt, textLink, path }) => {
  const history = useHistory();

  let iconItem =
    {
      Marcas: Marcas,
      Obras: Obras,
      Grupos: Grupos,
      Revistas: Revistas,
      Periodicos: Periodicos,
      Publicaciones: Publicaciones,
      Disenos: Disenos,
      Licencia: Licencia,
      Cesion: Cesion,
      Renovaciones: Renovaciones,
    }[icon] || Marcas;

  const handleOnClick = () => {
    history.push(path);
  };

  return (
    <button
      href="#"
      className={classes.serviciosButton}
      onClick={handleOnClick}
    >
      <img src={iconItem} alt={iconAlt} />
      {textLink}
    </button>
  );
};

ItemMenu.propTypes = {
  icon: PropTypes.string.isRequired,
  iconAlt: PropTypes.string.isRequired,
  textLink: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default ItemMenu;
