import { USER_PDLC } from "../../../constants";
import basicAuthRequest from "../../../utils/axios/basicAuthRequest";

export function requestCreatePublication(
  storePublication,
  storePayment,
  storeEntity
) {
  const user = JSON.parse(localStorage.getItem("user"));

  const entity = {
    type: 1,
    name: "",
    rfc: "",
    curp: "",
    street: "",
    ext_number: "",
    int_number: "",
    email: "",
    phone: "",
    state: "",
    city: "",
    postal_code: "",
    neighborhood: "",
    file: "",
  };
  const entity_full = {
    type: 1,
    name: storeEntity.titular.titularName.value,
    rfc: storeEntity.titular.rfc.value,
    curp: storeEntity.titular.curp.value,
    street: storeEntity.titular.street.value,
    ext_number: storeEntity.titular.extNumber.value,
    int_number: storeEntity.titular.intNumber.value,
    email: storeEntity.titular.email.value,
    phone: storeEntity.titular.phone.value,
    state: storeEntity.titular.state.value,
    city: storeEntity.titular.city.value,
    postal_code: storeEntity.titular.postalCode.value,
    neighborhood: storeEntity.titular.neighborhood.value,
    file: storeEntity.titular.file,
  };

  const brand_info = {
    name: "",
    file: "",
  };
  const brand_info_full = {
    name: storePublication.brand_info.name,
    file: storePublication.brand_info.file,
  };

  const bodyPublication = {
    user_id: user.user_id,
    service_id: 16,
    service_order_id: storePublication.isNewBrand
      ? null
      : +storePublication.studyBelongsId,
    brand_info: storePublication.isNewBrand
      ? { ...brand_info_full }
      : { ...brand_info },
    owner_id: storePublication.isRegisteredHolder
      ? +storePublication.titularId
      : null,
    owner_info: storePublication.isRegisteredHolder
      ? { ...entity }
      : { ...entity_full },
    billing_info: {
      payment_method_id:
        user.user_id === USER_PDLC
          ? "pm_1jbyzUyGRrBBY1dp8rWqpr0fp"
          : storePayment.paymentMethodid,
      price_total: +storePayment.purchaseAmount,
    },
  };

  try {
    return basicAuthRequest
      .request({
        url: "feed/createorder",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: bodyPublication,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => err);
  } catch (error) {
    console.log("Error", error);
  }
}

export function requestCreatePublicationStudy(storePublication, storePayment) {
  const user = JSON.parse(localStorage.getItem("user"));

  const bodyPublication = {
    user_id: user.user_id,
    service_id: 15,
    brand_info: {
      name: storePublication.brand_info.name,
      file: storePublication.brand_info.file,
    },
    billing_info: {
      payment_method_id:
        user.user_id === USER_PDLC
          ? "pm_1jbyzUyGRrBBY1dp8rWqpr0fp"
          : storePayment.paymentMethodid,
      price_total: +storePayment.purchaseAmount,
    },
  };

  try {
    return basicAuthRequest
      .request({
        url: "feed/createorder",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: bodyPublication,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => err);
  } catch (error) {
    console.log("Error", error);
  }
}

/**
 * Get list of services of the user
 * 9 Estudios de registrabilidad Revistas
 *
 * @returns promise
 */
export function requestGetStudiesPublications(params) {
  const user = JSON.parse(localStorage.getItem("user"));
  return basicAuthRequest.request({
    method: "GET",
    url: `feed/getuserservice/user/${user.user_id}/service/${params.service}`,
  });
}
