import React from 'react';

import logo from '../../assets/images/logo-aTuAlcance.svg';

const noticeOfPrivacy = () => {
  return (
    <div className="container-simple">
      <img src={logo} className="logo" alt="A tu alcance" />
      <h2 className="subtitle">AVISO DE PRIVACIDAD</h2>
      <p className="description"></p>
    </div>
  );
}

export default noticeOfPrivacy;