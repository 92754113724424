import { useState } from "react";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import basicAuthRequest from "../../../utils/axios/basicAuthRequest";
import { REPORT } from "../../../constants/atualcanceApi";

const useRport = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const generateReport = (entity_id, service_id, renovation) => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    basicAuthRequest
      .request({
        url: REPORT,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          user_id: user.user_id,
          entity_id: +entity_id,
          service_id: service_id,
          requires_renovation: renovation,
        },
      })
      .then((response) => {
        if (response?.data?.url !== undefined) {
          saveAs(
            response?.data?.url,
            `Reporte_${dayjs("2019-01-25").format("DD_MM_YYYY")}.pdf`
          );
        }
      })
      .catch((err) => setError(err));
    setLoading(false);
  };

  return {
    generateReport,
    loading,
    error,
  };
};

export default useRport;
