import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RowBack } from "../../../../assets/images/icons/icons";
import {
  InputSelect,
  Button,
  InputRadio,
  ErrorMessage,
} from "../../../../components";
import { newspaperActions } from "../../../../store/slices/newspaper";
import { userProfileActions } from "../../../../store/slices/userProfile";
import {
  NEWSPAPERS_SERVICES,
  NEWSPAPERS_REGISTER,
  NEWSPAPERS_REGISTER_TITULAR,
} from "../../../../constants/routes";
import classes from "../RegNewspaper.module.css";

const SERVICES = NEWSPAPERS_SERVICES;
const REGISTER = NEWSPAPERS_REGISTER;
const REGISTER_TITULAR = NEWSPAPERS_REGISTER_TITULAR;

const StudySelectionNews = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [validForm, setValidForm] = useState(true);

  const storeNewspaper = useSelector((state) => state.newspaper);

  useEffect(() => {
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getUserServices());
    dispatch(newspaperActions.getRegistrabilityStudies());
  }, [dispatch]);

  const redirect = () => {
    history.replace(SERVICES);
  };
  const handleChangeRadio = (event) => {
    dispatch(
      newspaperActions.setIsNewBrand({
        isNewBrand: event.target.value === "true",
      })
    );
    setValidForm(true);
  };

  const handleSelect = (event) => {
    dispatch(
      newspaperActions.setStudyBelongsId({
        studyId: event.target.value,
      })
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (storeNewspaper.isNewBrand) {
      setValidForm(true);
      history.replace(REGISTER);
    } else {
      if (event.target.musicGroup.value === "-1") {
        setValidForm(false);
      } else {
        setValidForm(true);
        history.replace(REGISTER_TITULAR);
      }
    }
  };

  return (
    <div className="container-modulo">
      <div className="link-back" onClick={redirect}>
        <img src={RowBack} alt="regresar" />
        &nbsp;&nbsp;REGRESAR
      </div>
      <div className="section-title">
        <h2 className="subtitle">REGRISTRO DE NOMBRE DE PERIÓDICOS</h2>
      </div>
      <div className="text-description">
        <p>Protege tu Periódico de manera práctica y sencilla.</p>
      </div>
      <div className="label-information">
        <div>SELECCIÓN DEL PERIÓDICO</div>
      </div>
      <div className="">
        <p>
          Hemos identificado que has realizado uno o más estudios de
          registrabilidad de nombre de periódicos previamente con nosotros.
        </p>
        <p>
          Por favor indica si el periódico que deseas registrar corresponde a
          alguno de estos, o bien si es un nombre de periódico adicional.
        </p>
      </div>
      <div className={classes.contentRadioSelect}>
        <form onSubmit={handleSubmit}>
          <InputRadio
            id="isAdditional"
            value="false"
            name="isAdditional"
            onChange={handleChangeRadio}
            checked={storeNewspaper.isNewBrand === false}
            label="MI PERIÓDICO CORRESPONDE A:"
          />
          {storeNewspaper.isNewBrand === false && (
            <div className={classes.formElement}>
              <InputSelect
                id="musicGroup"
                name="musicGroup"
                placeholder="- ELIGE TU PERIÓDICO -"
                options={storeNewspaper.regSudies}
                fieldIdOption="service_order_id"
                value={storeNewspaper.studyBelongsId}
                onChange={handleSelect}
              />
            </div>
          )}
          <InputRadio
            id="isAditional"
            value="true"
            name="isAdditional"
            onChange={handleChangeRadio}
            checked={storeNewspaper.isNewBrand === true}
            label="REGISTRAR UN PERIÓDICO ADICIONAL"
          />
          {!validForm && (
            <ErrorMessage message="Elige un estudio de registrabilidad" />
          )}
          <div className={classes.contentButtonNext}>
            <Button type="submit" className="btnConfirm">
              CONTINUAR
            </Button>
          </div>
        </form>
      </div>

      <div className="label-information">
        <div>INFORMACIÓN DEL GRUPO MUSICAL</div>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default StudySelectionNews;
