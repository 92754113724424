import React from 'react';
import Transition from 'react-transition-group/Transition';

import { IconXBlack } from '../../../assets/images/icons/icons';
import classes from './FullModal.module.css';

const FullModal = (props) => (
  <Transition
    in={props.show}
    timeout={300}
    mountOnEnter
    unmountOnExit
  >
    {state => {
      const cssClasses = [classes.fullModal,
      state === 'entering'
        ? classes.modalOpen
        : state === 'exiting' && classes.modalClosed
      ];

      return (
        <div className={cssClasses.join(' ')}>
          <div className={classes.modalHeader}>
            <img
              src={IconXBlack}
              alt="close"
              className={classes.closeIcon}
              onClick={props.closed} />
          </div>
          {props.children}
        </div>
      )
    }}

  </Transition>
);

export default FullModal;