import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  ButtonEdit,
  InputRadio,
  InputSelect,
  EntityForm,
  Button,
  ErrorMessage,
} from "../../../../components";
import { caculateAmountPrice } from "../../../../utils/scripts/calculatePrice";
import { musicalGroupActions } from "../../../../store/slices/musicalGroup";
import { userProfileActions } from "../../../../store/slices/userProfile";
import { paymentActions } from "../../../../store/slices/payment";
import {
  DASHBOARD_SERVICES,
  MUSICAL_GROUP_REGISTER_CHECKOUT,
  MUSICAL_GROUP_SELECT_STUDY
} from "../../../../constants/routes";
import classes from "../RegMusicalGroup.module.css";

const CHECKOUT_MG = MUSICAL_GROUP_REGISTER_CHECKOUT;

const HolderSelect = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [validForm, setValidForm] = useState(true);
  const [validFiles, setValidFiles] = useState(true);
  const [submited, setSubmited] = useState(false);

  const storeMusicalGroup = useSelector((state) => state.musicalGroup);
  const storeUserProfile = useSelector((state) => state.userProfile);
  const storeEntity = useSelector((state) => state.entity);

  useEffect(() => {
    if (storeMusicalGroup.studyBelongsId === -1) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeMusicalGroup.studyBelongsId]);

  useEffect(() => {
    dispatch(userProfileActions.getHolders());
  }, [dispatch]);

  useEffect(() => {
    if (storeMusicalGroup.isRegisteredHolder) {
      if (storeMusicalGroup.titularId === "-1") {
        setValidForm(false);
      } else {
        setValidForm(true);
      }
    } else {
      if (storeEntity.titular.valid) {
        setValidForm(true);
      } else {
        setValidForm(false);
      }
    }
  }, [
    storeEntity.titular.valid,
    storeMusicalGroup.isRegisteredHolder,
    storeMusicalGroup.titularId,
  ]);

  useEffect(() => {
    if (storeMusicalGroup.isRegisteredHolder) {
      setValidFiles(true);
    } else {
      if (storeEntity.titular.file === "") {
        setValidFiles(false);
      } else {
        setValidFiles(true);
      }
    }
  }, [storeEntity.titular.file, storeMusicalGroup.isRegisteredHolder]);

  const getPrice = () => {
    const musicalGrops = storeUserProfile?.services.services?.filter(
      (item) => item.service_id === 5
    );
    const EdrMusicalGroups = musicalGrops[0]?.service_sub?.filter(
      (item) => item.service_id === 7
    );

    const price =
      +EdrMusicalGroups[0].service_price +
      +EdrMusicalGroups[0].service_transact;

    const totalprice = caculateAmountPrice(price);
    return totalprice.toFixed(2);
  };

  const btnEdit = () => {
    history.replace(MUSICAL_GROUP_SELECT_STUDY);
  };

  const handleChangeRegisterHolder = (event) => {
    dispatch(
      musicalGroupActions.setIsRegisteredHolder({
        isRegisteredHolder: event.target.value === "si",
      })
    );
  };

  const handleSubmitButton = () => {
    setSubmited(true);
    if (validFiles) {
      const price = getPrice();
      dispatch(paymentActions.setPurchaseAmount({ amount: price }));
      history.replace(CHECKOUT_MG);
    }
  };
  const hanldleChangeTitularSelect = (event) => {
    dispatch(
      musicalGroupActions.setTitularId({
        titularId: event.target.value,
      })
    );
  };

  return (
    <div className="container-modulo">
      <div className="section-title">
        <h2 className="subtitle">REGRISTRO DE NOMBRE DE GRUPOS MUSICALES</h2>
      </div>
      <div className="text-description">
        <p>Protege tu Grupo Musical de manera práctica y sencilla.</p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>SELECCIÓN DEL GRUPO MUSICAL</div>
        <ButtonEdit onClick={btnEdit} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information">
        <div>INFORMACIÓN DEL GRUPO MUSICAL</div>
      </div>
      <div className="sub-description">
        <p>
          Proporciona la información complementaria requerida para realizar el
          registro del nombre de tu grupo musical.
        </p>
      </div>

      <div className="container-select">
        <InputRadio
          id="si"
          name="isRegisteredHolder"
          value="si"
          onChange={handleChangeRegisterHolder}
          label="SELECCIONAR UN TITULAR DE MI LISTA DE TITULARES"
          checked={storeMusicalGroup.isRegisteredHolder}
        />
        {storeMusicalGroup.isRegisteredHolder && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="- ELIGE UN TITULAR -"
            options={storeUserProfile.holders}
            fieldIdOption="id"
            value={storeMusicalGroup.titularId}
            onChange={hanldleChangeTitularSelect}
          />
        )}
        <InputRadio
          id="no"
          name="isRegisteredHolder"
          value="no"
          onChange={handleChangeRegisterHolder}
          label="REGISTRAR NUEVO TITULAR"
          checked={!storeMusicalGroup.isRegisteredHolder}
        />
      </div>
      {!storeMusicalGroup.isRegisteredHolder && (
        <div className={classes.contentForm}>
          <EntityForm
            typeEntity="titular"
            entity="titular-musical-group"
            recordTypeFile="brand-owner-info"
          />
        </div>
      )}
      <div className="container-select">
        {submited && !validFiles && (
          <ErrorMessage message="Sube la identificación oficial" />
        )}
      </div>

      <div className={classes.contentButton}>
        <Button
          onClick={handleSubmitButton}
          className="btnConfirm"
          disabled={!validForm}
        >
          CONTINUAR
        </Button>
      </div>

      <div className="label-information">
        <div>INFORMACIÓN DE PAGO</div>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default HolderSelect;
