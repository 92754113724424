import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { ButtonEdit } from "../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  SERVICES_NAMES,
  STATUS_NOTIFICATIONS,
} from "../../../constants/services";
import {
  REQUEST_PAYMENTS_IMPEDIMENTS,
  REQUEST_DECLARATION_TRADEMARK,
  REQUEST_PAYMENTS_ANNIUTY,
  REQUEST_PAYMENTS_RENEWAL,
} from "../../../constants/routes";
import {
  faChevronUp,
  faChevronDown,
  faCog,
  faFile,
  faCheck,
  faHandPaper,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  Marcas,
  Obras,
  Grupos,
  Revistas,
  Periodicos,
  Publicaciones,
  Disenos,
  Licencia,
  Cesion,
  Renovaciones,
} from "../../../assets/images/icons/icons";
import classes from "./Collapsible.module.css";

const Services = ({ servicerecurrent }) => {
  const history = useHistory();

  const actionService = (serviceId, orderId, service_recurrent_id) => {
    switch (serviceId) {
      case 22:
        history.replace(`${REQUEST_PAYMENTS_IMPEDIMENTS}/${orderId}`);
        break;
      case 23:
        history.replace(`${REQUEST_DECLARATION_TRADEMARK}/${orderId}`);
        break;
      case 21:
        history.replace(`${REQUEST_PAYMENTS_ANNIUTY}/${orderId}`);
        break;
      case 17:
        history.replace(`${REQUEST_PAYMENTS_RENEWAL}/${service_recurrent_id}`);
        break;

      default:
        break;
    }
  };

  const servicesName = {
    17: "RENOVAR",
    21: "PAGO ANUALIDAD",
    22: "PAGO POR IMPEDIMENTO",
    23: "DECLARACIÓN DE USO",
  };
  const servicesIcon = {
    17: "faRedoAlt",
    21: "faCheck",
    22: "faHandPaper",
    23: "faCheck",
  };
  return (
    <>
      {servicerecurrent.map((item) => (
        <ButtonEdit
          key={Math.random()}
          onClick={() => {
            actionService(
              item.service_id,
              item.service_order_id,
              item.service_recurrent_id
            );
          }}
          icon={servicesIcon[item.service_id]}
          size="large"
        >
          {servicesName[item.service_id]}
        </ButtonEdit>
      ))}
    </>
  );
};

const Collapsible = ({
  serviceOrderId,
  serviceId,
  finalDate,
  brandName,
  serviceStatusId,
  servicerecurrent,
  isCollapsed,
  handleShowDetail,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    setCollapsed(isCollapsed);
  }, [isCollapsed]);

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const iconItem =
    {
      1: Marcas,
      2: Marcas,
      3: Marcas,
      4: Obras,
      5: Grupos,
      6: Grupos,
      7: Grupos,
      8: Revistas,
      9: Revistas,
      10: Revistas,
      11: Periodicos,
      12: Periodicos,
      13: Periodicos,
      14: Publicaciones,
      15: Publicaciones,
      16: Publicaciones,
      17: Renovaciones,
      18: Cesion,
      19: Disenos,
      20: Licencia,
      21: Disenos,
      22: Marcas,
      23: Marcas,
    }[serviceId] || Marcas;

  const iconStatus =
    {
      1: faCog,
      2: faPlus,
      3: faCog,
      4: faFile,
      5: faHandPaper,
      6: faCheck,
      7: faHandPaper,
    }[serviceStatusId] || 2;

  return (
    <div className={classes.containerCollap}>
      {/* {console.log("SERVICE RECURRENT", servicerecurrent)} */}
      <button
        className={`${classes.collapsible} ${collapsed && classes.active} `}
        onClick={handleCollapsed}
      >
        <div className={classes.title}>
          <div>
            <img src={iconItem} alt="Title" />
          </div>
          <div>
            <div>{SERVICES_NAMES[serviceId]}</div>
            <div className={classes.limitDate}>{finalDate}</div>
          </div>
          <div className={classes.collapsibleIcon}>
            {collapsed ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronUp} />
            )}
          </div>
        </div>
      </button>
      <div
        className={classes.content}
        style={collapsed ? { maxHeight: "0", padding: "0" } : {}}
      >
        <div className={classes.contentInformation}>
          <div className={classes.labelItem}>
            <h3>ESTATUS DE LA SOLICITUD</h3>
            <p>
              <FontAwesomeIcon icon={iconStatus} className={classes.btnIcon} />
              &nbsp;{STATUS_NOTIFICATIONS[serviceStatusId]}
            </p>
          </div>
          <div className={classes.labelItem}>
            <h3>NOMBRE DE LA MARCA</h3>
            <p>{brandName}</p>
          </div>
        </div>
        <div className={classes.actions}>
          <ButtonEdit
            onClick={() => handleShowDetail(serviceOrderId)}
            icon="faEye"
            size="large"
          >
            Ver Detalle
          </ButtonEdit>
          <Services servicerecurrent={servicerecurrent} />
        </div>
      </div>
    </div>
  );
};

Collapsible.propTypes = {
  serviceOrderId: PropTypes.number.isRequired,
  serviceId: PropTypes.number.isRequired,
  finalDate: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  serviceStatusId: PropTypes.number.isRequired,
  servicerecurrent: PropTypes.array.isRequired,
  isCollapsed: PropTypes.bool,
  handleShowDetail: PropTypes.func.isRequired,
};

Collapsible.defaultProps = {
  isCollapsed: false,
};

export default React.memo(Collapsible);
