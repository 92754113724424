import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userProfileActions } from "../../../store/slices/userProfile";
import {
  Button,
  InputCheckbox,
  InputRadio,
  InputSelect,
} from "../../../components";
import { typeRights } from "../../../utils/cat-type-rights";
import useReport from "./useReport";

const Report = () => {
  const dispatch = useDispatch();
  const [typePerson, setTypePerson] = useState("");
  const [person, setPerson] = useState();
  const [typeRightsId, setTypeRightsId] = useState("");
  const [pending, setPending] = useState(false);
  const { generateReport } = useReport();

  useEffect(() => {
    dispatch(userProfileActions.getHolders());
  }, [dispatch]);

  const holders = useSelector((state) => state.userProfile.holders);
  const storeUserProfile = useSelector((state) => state.userProfile);
  console.log(storeUserProfile);

  const handleChangeRadio = (event) => {
    setTypePerson(event.target.value);
  };

  const handlePending = () => {
    setPending((value) => !value);
  };

  const handleTypeRights = (event) => {
    setTypeRightsId(event.target.value);
  };

  const handleChangePerson = (event) => {
    setPerson(event.target.value);
  };

  const handleCreateReport = () => {
    generateReport(person, typeRightsId, pending);
  };

  return (
    <div className="container-modulo">
      <div className="section-title">
        <h2 className="subtitle">RELACIÓN DE DERECHOS</h2>
      </div>

      <div className="text-description">
        <p>
          Descarga por titular las marcas propietarias de este de forma practica
          y sencilla.
        </p>
      </div>

      <div className="content-form-main">
        <InputRadio
          id="typeTitular"
          value="titular"
          name="personType"
          onChange={handleChangeRadio}
          checked={typePerson === "titular"}
          label="POR TITULAR"
        />
        {typePerson === "titular" && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="ELIGE UN TITULAR"
            options={holders}
            fieldIdOption="id"
            value={person}
            onChange={handleChangePerson}
          />
        )}
        <InputRadio
          id="typeAutor"
          value="autor"
          name="personType"
          onChange={handleChangeRadio}
          checked={typePerson === "autor"}
          label="POR AUTOR"
        />
        {typePerson === "autor" && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="ELIGE UN AUTOR"
            options={storeUserProfile.authors}
            fieldIdOption="id"
            value={0}
            onChange={handleChangePerson}
          />
        )}
        <InputRadio
          id="typeLicenciatario"
          value="licenciatario"
          name="personType"
          onChange={handleChangeRadio}
          checked={typePerson === "licenciatario"}
          label="POR LICENCIATARIO"
        />
        {typePerson === "licenciatario" && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="ELIGE UN LICENCIATARIO"
            options={storeUserProfile.licensee}
            fieldIdOption="id"
            value={person}
            onChange={handleChangePerson}
          />
        )}
        <InputRadio
          id="typeCesionario"
          value="cesionario"
          name="personType"
          onChange={handleChangeRadio}
          checked={typePerson === "cesionario"}
          label="POR CESIONARIO"
        />
        {typePerson === "cesionario" && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="ELIGE UN CESIONARIO"
            options={[]}
            fieldIdOption="service_order_id"
            value={0}
            onChange={handleChangePerson}
          />
        )}

        <InputSelect
          id="brands"
          name="brands"
          placeholder="TIPO DE DERECHO"
          options={typeRights}
          fieldIdOption="id"
          value={typeRightsId}
          onChange={handleTypeRights}
        />

        <InputCheckbox
          id="pending"
          name="pending"
          value="pending"
          label="Pendientes de renovación"
          onChange={handlePending}
          checked={pending}
        />
        <Button className="btnConfirm" onClick={handleCreateReport}>
          CONSULTAR.
        </Button>
      </div>
    </div>
  );
};

export default Report;
