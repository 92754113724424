import PropTypes from "prop-types";

import classes from "./InputRadio.module.css";

const InputRadio = (props) => {
  return (
    <div className={classes.contentRadio}>
      <label htmlFor={props.id} className={classes.checkLabel}>
        <input
          type="radio"
          id={props.id}
          value={props.value}
          name={props.name}
          onChange={props.onChange}
          checked={props.checked}
        />
        <span className={classes.checkmark}></span>
        {props.label}
      </label>
    </div>
  );
};

InputRadio.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default InputRadio;
