import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ObrasConfirmation, ButtonBack, ButtonEdit } from "../../../components";
import {
  DASHBOARD_SERVICES,
  OBRAS_CHECKOUT,
  OBRAS_REGISTER,
} from "../../../constants/routes";
import { obrasActions } from "../../../store/slices/obras";

const ConfirmationObras = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const storeObras = useSelector((state) => state.obras);
  const storePayment = useSelector((state) => state.payment);
  const storeUserProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (storeObras.brandInfo.name === "") {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeObras.brandInfo.name]);

  const gotoBack = (_) => {
    props.history.replace(OBRAS_REGISTER);
  };

  const gotoPayment = () => {
    props.history.replace(OBRAS_CHECKOUT);
  };

  const handlerPayment = useCallback(() => {
    dispatch(obrasActions.createOrderObras());
  }, [dispatch]);

  return (
    <div className="container-modulo">
      <ButtonBack url={OBRAS_CHECKOUT} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">REGISTRO DE OBRAS </h2>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE LA OBRA</div>
        <ButtonEdit onClick={gotoBack} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DEL PAGO</div>
        <ButtonEdit onClick={gotoPayment} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
      <div>
        <ObrasConfirmation
          registerData={storeObras}
          paymentData={storePayment}
          userProfile={storeUserProfile}
          onConfirm={handlerPayment}
        />
      </div>
    </div>
  );
};

export default ConfirmationObras;
