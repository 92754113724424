import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import basicAuthRequest from "../../../utils/axios/basicAuthRequest";
import SimpleModal from "../../UI/Modal/SimpleModal";
import { Button, Spinner } from "../../../components";
import { DELETE_BUSINESS_NAME } from "../../../constants/atualcanceApi";
import classes from "./BusinessNameTemplate.module.css";

const BusinessNameTemplate = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    return () => {
      setUser(null);
    };
  }, []);

  useEffect(() => {
    if (props.selected) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
    return () => {
      setCollapsed(false);
    };
  }, [props.selected]);

  const deleteBn = (business_name_id) => {
    setLoading(true);
    try {
      basicAuthRequest
        .request({
          url: `${DELETE_BUSINESS_NAME}${user.user_id}/businessname/${business_name_id}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          setLoading(false);
          setShowModal(false);
          setDeleted(true);
        })
        .catch(() => {
          setLoading(false);
          setShowModal(false);
        });
    } catch (error) {
      setLoading(false);
      setShowModal(false);
    }
  };

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      {!deleted && (
        <div className={classes.containerCollap}>
          <button
            className={`${classes.collapsible}  ${
              !collapsed ? classes.active : ''
            } ${!collapsed ? classes.selected : ''} `}
            onClick={handleCollapsed}
          >
            {props.data.name}
          </button>
          <div
            className={classes.content}
            style={collapsed ? { maxHeight: "0" } : {}}
          >
            {showModal && (
              <SimpleModal close={closeModal} title="ELIMINAR RAZÓN SOCIAL">
                <div>
                  <p>¿Realmente deseas eliminar esté registro?</p>
                  <div className={classes.modalCtrl}>
                    {loading && <Spinner />}
                  </div>
                  <div className={classes.modalCtrl}>
                    <div className={classes.modalBtn}>
                      <Button
                        className="btnConfirm"
                        onClick={() => deleteBn(props.data.business_name_id)}
                      >
                        Aceptar
                      </Button>
                    </div>
                    <div className={classes.modalBtn}>
                      <Button className="btnCancel" onClick={closeModal}>
                        Cancelar
                      </Button>
                    </div>
                  </div>
                </div>
              </SimpleModal>
            )}
            <div className="form-informacion-marca">
              <div>
                <div className={classes.labelEntity}>RFC</div>
                <div className={classes.itemEntity}>{props.data.rfc}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>CALLE</div>
                <div className={classes.itemEntity}>{props.data.street}</div>
              </div>
            </div>
            <div className="form-informacion-marca">
              <div>
                <div className={classes.labelEntity}>No. Exterior</div>
                <div className={classes.itemEntity}>
                  {props.data.ext_number}
                </div>
              </div>
              <div>
                <div className={classes.labelEntity}>No. Interior</div>
                <div className={classes.itemEntity}>
                  {props.data.int_number}
                </div>
              </div>
            </div>
            <div className="form-informacion-marca">
              <div>
                <div className={classes.labelEntity}>Email</div>
                <div className={classes.itemEntity}>{props.data.email}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>Teléfono</div>
                <div className={classes.itemEntity}>{props.data.phone}</div>
              </div>
            </div>
            <div className="form-informacion-marca">
              <div>
                <div className={classes.labelEntity}>Estado</div>
                <div className={classes.itemEntity}>{props.data.state}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>Ciudad</div>
                <div className={classes.itemEntity}>{props.data.city}</div>
              </div>
            </div>
            <div className="form-informacion-marca">
              <div>
                <div className={classes.labelEntity}>Código Postal</div>
                <div className={classes.itemEntity}>
                  {props.data.postal_code}
                </div>
              </div>
              <div>
                <div className={classes.labelEntity}>Colonia</div>
                <div className={classes.itemEntity}>
                  {props.data.neighborhood}
                </div>
              </div>
            </div>
            <div className={classes.contentButton}>
              <Button className="btnCancel" onClick={openModal}>
                Eliminar
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

BusinessNameTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  selected: PropTypes.bool,
};

BusinessNameTemplate.defaultProps = {
  selected: true,
};

export default React.memo(BusinessNameTemplate);
