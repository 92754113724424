import PropTypes from "prop-types";
import { ButtonBack } from "../../../components";
import CommentsColap from "../../../components/Templates/Requests/CommentsColap";
import StatusBox from "../../../components/UI/StatusBox/StatusBox";
import BrandInfo from "./BrandInfo";
import Classes from "./Classes";
import RequestDetail from "./RequestDetail";
import Impediment from "./Impediment";
import Resolution from "./Resolution";
import Billing from "./Billing";
import classes from "./MyRequest.module.css";
const MyRequestDetail = ({
  serviceId,
  requestDetail,
  getBusinessName,
  requestInvoice,
  saveNewBusinessName,
  loadingbn,
  loadingRequestInvoice,
  loadingSaveNewBN,
  errorSaveNewBN,
  clearErrorMessageSaveBN,
  updateFile,
  loadingUpdateFile,
  errorUpdateFile,
}) => {
  return (
    <div className="container-modulo">
      <ButtonBack url="/servicios" text="REGRESAR" />
      <div className={classes.headerSection}>
        <div className={classes.headerLabel}>SOLICITUD DE </div>
        <div className={classes.headerService}>
          {requestDetail?.data?.service_name}
        </div>
      </div>

      <StatusBox status={requestDetail?.data?.service_status_name} />

      {requestDetail?.data?.comments?.length > 0 && (
        <>
          <div className={classes.commentsSection}>
            <CommentsColap
              title={`COMENTARIOS (${requestDetail?.data?.comments?.length})`}
            >
              {requestDetail.data.comments.map((item) => (
                <div key={Math.random() + item.date}>
                  <div className={classes.commentDate}>{item.date}</div>
                  <div className={classes.comment}>{item.comment}</div>
                </div>
              ))}
            </CommentsColap>
          </div>
          <div className="parting-line" />
        </>
      )}
      <BrandInfo
        serviceOrderId={serviceId}
        serviceId={requestDetail.data.service_id}
        brandInfo={requestDetail.data.brand_info}
        ownerInfo={requestDetail.data.owner_info}
        authorInfo={requestDetail.data.author_info}
        coAuthorInfo={requestDetail.data.coauthor_info}
        updateFile={updateFile}
        loadingUpdateFile={loadingUpdateFile}
        errorUpdateFile={errorUpdateFile}
      />
      {requestDetail.data.product_classes.length &&
      requestDetail.data.service_classes ? (
        <Classes
          productClasses={requestDetail.data.product_classes}
          serviceClasses={requestDetail.data.service_classes}
        />
      ) : null}

      <RequestDetail requestDetail={requestDetail.data.request_details} />
      {requestDetail.data.impediments.map((item, index) => (
        <Impediment
          key={`impediment-${index}`}
          serviceId={serviceId}
          dataImpediment={item}
        />
      ))}

      <Resolution
        resolutionInfo={requestDetail.data.resolution_info}
        serviceId={serviceId}
      />

      <Billing
        serviceId={serviceId}
        invoicingInfo={requestDetail.data.invoicing_info}
        getBusinessName={getBusinessName}
        loadingbn={loadingbn}
        requestInvoice={requestInvoice}
        loadingRequestInvoice={loadingRequestInvoice}
        saveNewBusinessName={saveNewBusinessName}
        loadingSaveNewBN={loadingSaveNewBN}
        errorSaveNewBN={errorSaveNewBN}
        clearErrorMessageSaveBN={clearErrorMessageSaveBN}
      />
    </div>
  );
};

MyRequestDetail.prototype = {
  serviceId: PropTypes.number.isRequired,
  requestDetail: PropTypes.array.isRequired,
  getBusinessName: PropTypes.func.isRequired,
  requestInvoice: PropTypes.func.isRequired,
  saveNewBusinessName: PropTypes.func.isRequired,
  loadingbn: PropTypes.bool.isRequired,
  loadingRequestInvoice: PropTypes.bool.isRequired,
  loadingSaveNewBN: PropTypes.bool.isRequired,
  errorSaveNewBN: PropTypes.string.isRequired,
  clearErrorMessageSaveBN: PropTypes.func.isRequired,
  updateFile: PropTypes.func.isRequired,
  loadingUpdateFile: PropTypes.bool.isRequired,
  errorUpdateFile: PropTypes.bool.isRequired,
};

export default MyRequestDetail;
