export const RENOVATIONS = "/feed/getorderrenovation/user/";
export const BUSINESS_NAME = "/feed/getbusinessname/user/";
export const CREATE_BUSINESS_NAME = "/feed/createbusinessname";
export const DELETE_BUSINESS_NAME = "/feed/deletebusinessname/user/";
export const GET_SERVICE_RECURRENT = "/feed/getservicerecurrent/user/";
export const CREATE_ORDER = "/feed/createorder";
export const GET_USER_ORDER = "/feed/getuserorder/user/";
export const GET_ORDER_DETAIL = "/feed/getorder/user/";
export const UPLOAD_FILE = "/feed/uploadfile";
export const UPDATE_FILE = "/feed/updatefile";
export const CREATE_INVOICE = "/feed/createinvoice";
export const DONLOAD_FILE = "feed/downloadfile";
export const REJECT_IMPEDIMENT = "feed/rejectimpediment";
export const REPORT = "/feed/getrigthrelation";
export const DELETE_ACCOUNT = "/feed/cancelaccount";
export const SEND_CONFIRMATION = "/auth/resendconfirmation";