import { useState } from "react";
import { useParams } from "react-router-dom";

import AnualitiesData from "./AnualitiesData";
import AnnuitiesCheckOut from "./AnnuitiesCheckout";
import AnnuitiesConfirmation from "./AnualitiesConfirmation";
import useAnualities from "./useAnualities";

const screens = ["AnnuityData", "CheckOut", "Confirmation"];

const Anualities = () => {
  const { orden } = useParams();
  const [currentScreen, setCurrentScreen] = useState(screens[0]);

  const { loading, annuity, price, savePayment, saveLoading, saveError } =
    useAnualities(orden);

  const handleEditInfo = () => {
    setCurrentScreen(screens[0]);
  };

  const handleCheckOut = () => {
    setCurrentScreen(screens[1]);
  };

  const handleConfirmation = () => {
    setCurrentScreen(screens[2]);
  };

  const screenObj = {
    [screens[0]]: (
      <AnualitiesData
        loading={loading}
        annuity={annuity}
        handleSubmit={handleCheckOut}
      />
    ),
    [screens[1]]: (
      <AnnuitiesCheckOut
        handleSubmit={handleConfirmation}
        handleEditInfo={handleEditInfo}
      />
    ),
    [screens[2]]: (
      <AnnuitiesConfirmation
        annuity={annuity}
        price={price}
        handleSubmit={savePayment}
        handleEditInfo={handleEditInfo}
        handleCheckOut={handleCheckOut}
        loading={saveLoading}
        error={saveError}
      />
    ),
  };

  return (
    <div className="container-modulo">
      <div className="section-title">
        <h2 className="subtitle">
          DERECHO DE ANUALIDAD DE <br /> DISEÑOS INDUSTRIALES
        </h2>
      </div>
      <div className="text-description">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
          finibus porta sapien ut accumsan. Morbi vel nisi consequat.
        </p>
      </div>
      {screenObj[currentScreen]}
    </div>
  );
};

export default Anualities;
