import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios"

import InputFloatLabel from "../UI/Input/InputFloatLabel/InputFloatLabel";
import Spinner from "../UI/Spinner/Spinner";
import Button from "../UI/Button/Button";
import { ErrorMessage } from '../../components';
import logo from "../../assets/images/logo-aTuAlcance.svg";
import { Play } from "../../assets/images/icons/icons";
import classes from './VerificationCode.module.css'

const VerificationCode = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const storeUserProfile = useSelector(state => state.userProfile)

  const formVerfication = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.string().required("El campo código es requerido"),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      const bodyConfirm = {
        email: storeUserProfile.dataRegister.email,
        confirm_code: values.code
      }
      await axios.request({
        url: process.env.REACT_APP_URL_BASE + 'auth/confirmregistration',
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        data: bodyConfirm
      }).then ((response) => { 
        if (response.data.code === 200) {
          history.push("/login");
        }
        setLoading(false);
      }).catch(_=> {
        setLoading(false);
        setErrorMsg(true);
      }) 

    },
  });

  return (
    <div className={`container-simple ${classes.containerModule}`}>
      <img src={logo} className="logo" alt="A tu alcance" />
      <h2 className="subtitle">REGISTRO</h2>
      <p className="description">
        Por favo ingresa el código de verificación que recibiste en tu cuenta de
        correo electrónico.
      </p>
      <form onSubmit={formVerfication.handleSubmit} className={classes.containerForm}>
        <div className="form-row">
          <InputFloatLabel
            id="code"
            name="code"
            placeholder="CÓDIGO DE VERIFICACIÓN"
            onChange={formVerfication.handleChange}
            valid={formVerfication.errors.code && formVerfication.touched.code}
          />
        </div>
        <div>
          {errorMsg 
          && <ErrorMessage message="Código Invalido" />}
        </div>
        <div>
          {loading ? (
            <Spinner />
          ) : (
            <Button type="submit" className="btnConfirm">
              CONTINUAR
            </Button>
          )}
        </div>
      </form>
      <div className="assets-box">
        <p className="subtitle-small">
          <NavLink to="/aviso-privacidad" style={{ color: "#121f61" }}>
            AVISO DE PRIVACIDAD
          </NavLink>
        </p>
        <div className="parting-line" />
        <p className="no-account">¿Ya tienes cuenta?</p>
        <p className="register">
          <NavLink to="/login">INICIA SESIÓN</NavLink>{" "}
          <span style={{ color: "#96e8b2" }}>
            <img alt="registrate" src={Play} width="13" height="13" />
          </span>
        </p>
      </div>
    </div>
  );
};

export default VerificationCode;
