import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonBack, ButtonEdit } from "../../../../components";
import NewspaperTemplate from "../../../../components/Templates/Confirmation/NewspaperTemplate";
import { newspaperActions } from "../../../../store/slices/newspaper";
import {
  NEWSPAPERS_REGISTER_TITULAR,
  NEWSPAPERS_REGISTER,
  NEWSPAPERS_REGISTER_CHECKOUT,
  DASHBOARD_SERVICES,
} from "../../../../constants/routes";

const ConfirmationRegNews = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const storeNewspaper = useSelector((state) => state.newspaper);
  const storePayment = useSelector((state) => state.payment);

  useEffect(() => {
    if (!storeNewspaper.isNewBrand && storeNewspaper.studyBelongsId === -1) {
      history.replace(DASHBOARD_SERVICES);
    } else if (
      storeNewspaper.isNewBrand &&
      storeNewspaper.brand_info.name === ""
    ) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [
    history,
    storeNewspaper.brand_info.name,
    storeNewspaper.isNewBrand,
    storeNewspaper.studyBelongsId,
  ]);

  const EDITBRAND = NEWSPAPERS_REGISTER_TITULAR;
  const EDITBRAND_IS_NEW_BRAND = NEWSPAPERS_REGISTER;
  const EDITBRAND_CHECKOUT = NEWSPAPERS_REGISTER_CHECKOUT;

  const gotoBrandInfo = () => {
    if (storeNewspaper.isNewBrand) {
      props.history.replace(EDITBRAND_IS_NEW_BRAND);
    } else {
      props.history.replace(EDITBRAND);
    }
  };

  const gotoPayment = () => {
    props.history.replace(EDITBRAND_CHECKOUT);
  };

  const redirectReceipt = useCallback(() => {
    dispatch(newspaperActions.createNewspaper());
  }, [dispatch]);

  return (
    <div className="container-modulo">
      <ButtonBack url={EDITBRAND_CHECKOUT} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">REGRISTRO DE NOMBRE DE PERIÓDICOS</h2>
      </div>

      <div className="description-confirm">
        <p>Protege tu Periódico de manera práctica y sencilla.</p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DEL PERIÓDICO</div>
        <ButtonEdit onClick={gotoBrandInfo} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE PAGO</div>
        <ButtonEdit onClick={gotoPayment} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
      <div>
        <NewspaperTemplate
          registerData={storeNewspaper}
          paymentData={storePayment}
          onConfirm={redirectReceipt}
          scope="reg-std-newspaper"
        />
      </div>
    </div>
  );
};

export default ConfirmationRegNews;
