import axios from "axios";

const basicAuthRequest = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE,
});

basicAuthRequest.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user.idToken) {
      config.headers["Authorization"] = `Bearer ${user.idToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

basicAuthRequest.interceptors.response.use(
  (response) => response,
  async function error(error) {
    const originalRequest = error?.response?.config;
    const user = JSON.parse(localStorage.getItem("user"));
    if (
      error?.response?.status === 403 &&
      originalRequest?._retry === undefined
    ) {
      originalRequest._retry = true;
      return await axios
        .post(`${process.env.REACT_APP_URL_BASE}auth/refreshtoken`, {
          username: user.email,
          refresh_token: user.refreshtoken,
        })
        .then((res) => {
          if (res.status === 200) {
            user.idToken = res.data.cognito.idToken;
            user.refreshtoken = res.data.cognito.refreshtoken;
            localStorage.setItem("user", JSON.stringify(user));
            basicAuthRequest.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${res.data.cognito.idToken}`;
            originalRequest.headers.Authorization = `Bearer ${res.data.cognito.idToken}`;
            return axios(originalRequest);
          }
        })
        .catch((error) => {
          return { error };
        });
    } else {
      // localStorage.clear();
      // window.location.href = "/login";
      return { error };
    }
  }
);

export default basicAuthRequest;
