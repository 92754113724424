import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { root } from "./sagas";

import userProfile from "./slices/userProfile";
import registerSlice from "./slices/brandRegistrabilityStudy";
import tradeMarkSlice from "./slices/tradeMark";
import paymentSlice from "./slices/payment";
import obrasSlice from "./slices/obras";
import musicalGroupSlice from "./slices/musicalGroup";
import magazinesSlice from "./slices/magazines";
import newspaperSlice from "./slices/newspaper";
import publicationsSlice from "./slices/publications";
import industrialDesignSlice from "./slices/industrialDesigns";
import entity from "./slices/entity";
import rigths from "./slices/rights";

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

const store = configureStore({
  reducer: {
    entity: entity.reducer,
    rights: rigths.reducer,
    industrialDesign: industrialDesignSlice.reducer,
    publications: publicationsSlice.reducer,
    newspaper: newspaperSlice.reducer,
    magazines: magazinesSlice.reducer,
    musicalGroup: musicalGroupSlice.reducer,
    tradeMark: tradeMarkSlice.reducer,
    register: registerSlice.reducer,
    userProfile: userProfile.reducer,
    payment: paymentSlice.reducer,
    obras: obrasSlice.reducer,
    router: connectRouter(history),
  },
  middleware: [
    ...getDefaultMiddleware({ thunk: false }),
    sagaMiddleware,
    routerMiddleware(history),
  ],
});

sagaMiddleware.run(root);

export default store;
