import React from 'react';
import { Header, Footer } from '../../components';

import './SimplePage.css';

const simplePage = ({ children }) => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="main">
          <Header />
          {children}
        </div>
      </div>
      <Footer />
    </React.Fragment>


  );
}

export default simplePage;