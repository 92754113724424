export const requestType = [
  {
    "id": 0,
    "name": "TODOS"
  },
  {
    "id": 2,
    "name": "ESTUDIO DE REGISTRABILIDAD"
  },
  {
    "id": 3,
    "name": "REGISTRO DE MARCA" 
  },
  {
    "id": 4,
    "name": "OBRAS" 
  },
  {
    "id": 6,
    "name": "ESTUDIO DE REGISTRBILIDAD DE NOMBRE DE GRUPOS MUSICALES" 
  },
  {
    "id": 7,
    "name": "REGISTRO DE NOMBRE DE GRUPOS MUSICALES" 
  },
  {
    "id": 9,
    "name": "ESTUDIO DE REGISTRABILIDAD DE NOMBRE DE REVISTAS" 
  },
  {
    "id": 10,
    "name": "REGISTRO DE NOMBRE DE REVISTAS" 
  },
  {
    "id": 12,
    "name": "ESTUDIO DE REGISTRABILIDAD DE NOMBRE DE PERIÓDICOS" 
  },
  {
    "id": 13,
    "name": "REGISTRO DE NOMBRE DE PERIODICOS" 
  },
  {
    "id": 15,
    "name": "ESTUDIO DE REGISTRABILIDAD DE NOMBRE DE PUBLICACIONES PERIÓDICAS VÍA REDES DE CÓMPUTO" 
  },
  {
    "id": 16,
    "name": "REGISTRO DE NOMBRE DE PUBLICACIONES PERIÓDICAS VÍA REDES DE CÓMPUTO" 
  },
  {
    "id": 17,
    "name": "RENOVACIÓN DE DERECHOS" 
  },
  {
    "id": 18,
    "name": "CESIÓN DE DERECHOS" 
  },
  {
    "id": 19,
    "name": "DISEÑOS INDUSTRIALES" 
  },
  {
    "id": 20,
    "name": "LICENCIA DE DERECHOS" 
  },
  {
    "id": 21,
    "name": "PAGO DE ANUALIDAD DE DISEÑOS INDUSTRIALES" 
  },
  {
    "id": 22,
    "name": "PAGO DE REQUERIMIENTO POR IMPEDIMETO DE REGISTRO" 
  },
  {
    "id": 23,
    "name": "DECLRACIÓN DE USO DE MARCA" 
  },
]