import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import basicAuthRequest from "../../../../utils/axios/basicAuthRequest";
import { paymentActions } from "../../../../store/slices/payment";
import { caculateAmountPrice } from "../../../../utils/scripts/calculatePrice";
import { services as SERVICES } from "../../../../constants/services";
import { RENOVATIONS, CREATE_ORDER } from "../../../../constants/atualcanceApi";
import { DASHBOARD_SERVICES, TICKECT } from "../../../../constants/routes";

const useRenewal = (service_id) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [renewal, setRenewal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [error, setError] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState("");

  const paymentId = useSelector((state) => state.payment.paymentMethodid);

  useEffect(() => {
    const getRenew = async () => {
      setLoading(true);
      try {
        const response = await basicAuthRequest({
          method: "GET",
          url: `${RENOVATIONS}/${service_id}/filter/0`,
        });

        if (response?.status === 200) {
          const price = caculateAmountPrice(
            +response.data[0].brand_info.service_price +
              +response.data[0].brand_info.service_transact
          );
          setPrice(price);
          setRenewal(response.data);
        } else {
          setLoading(false);
          history.replace(DASHBOARD_SERVICES);
        }
        setLoading(false);
      } catch (error) {
        setError(error);
      }
    };
    getRenew();

    return () => {
      setRenewal([]);
      setLoading(false);
      setError(null);
    };
  }, [history, service_id]);

  const saveRenewal = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    setSaveLoading(true);

    const bodyRequest = {
      user_id: user.user_id,
      service_id: SERVICES.DERECHOS_RENOVACION,
      service_order_id: renewal[0]?.service_order_id,
      brand_info: {
        right_type: null,
        registration_number: 0,
        name: "",
      },
      owner_id: null,
      owner_info: {
        type: 1,
        name: "",
        rfc: "",
        curp: "",
        street: "",
        ext_number: "",
        int_number: "",
        email: "",
        phone: "",
        state: "",
        city: "",
        postal_code: "",
        neighborhood: "",
        file: "",
      },
      billing_info: {
        payment_method_id: paymentId,
        price_total: +Number(price).toFixed(2),
      },
    };

    try {
      const response = await basicAuthRequest({
        url: CREATE_ORDER,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: bodyRequest,
      });

      dispatch(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      

      if (response.error === undefined) {
        history.replace(TICKECT);
      } else {
        setSaveError(response.error);
      }
      setSaveLoading(false);
    } catch (e) {
      setSaveError(error);
    }
  };

  return {
    renewal,
    loading,
    price,
    error,
    saveRenewal,
    saveLoading,
    saveError,
  };
};

export default useRenewal;
