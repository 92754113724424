import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ModalClases,
  ModalDrag,
  SelectedClasses,
  Button,
  ButtonBack,
  InputSelect,
  InputSimpleFloatLabel,
  BtnCallToAction,
  InputRadio,
  InputDayPicker,
} from "../../../components";
import { calculateClassesPrice } from "../../../utils/scripts/calculatePrice";
import { registerActions } from "../../../store/slices/brandRegistrabilityStudy";
import { paymentActions } from "../../../store/slices/payment";
import { userProfileActions } from "../../../store/slices/userProfile";
import {
  BRANDS_REGISTRABILITY_STUDY_CHECKOUT,
  BRANDS_REGISTRABILITY_STUDY_CONFIRMATION,
} from "../../../constants/routes";
import { getFileName } from "../../../utils/scripts/file";
import { services as SERVICES } from "../../../constants/services";
import { USER_PDLC } from "../../../constants";
import "./EstudioRegistrabilidad.css";

const EstudioRegistrabilidad = (props) => {
  const dispatch = useDispatch();

  const [calsesProdOpen, setCalsesProdOpen] = useState(false);
  const [dragModal, setDragModal] = useState(false);
  const [typeModal, setTypeModal] = useState("productos");
  const [userData, setUserData] = useState();

  const register = useSelector((state) => state.register);
  const storeUserProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    dispatch(userProfileActions.getServices());
  }, [dispatch]);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
  }, []);

  const openDragModal = () => {
    setDragModal(true);
  };

  const closeDragModal = () => {
    setDragModal(false);
  };

  const openModalClases = (type) => {
    setCalsesProdOpen(true);
    setTypeModal(type);
  };

  const closeModalClases = () => {
    setCalsesProdOpen(false);
  };

  const handleChangeName = (event) => {
    dispatch(registerActions.setName({ name: event.target.value }));
  };
  const onChangeType = (event) => {
    dispatch(registerActions.setType({ type: event.target.value }));
  };

  const handleRadio = (event) => {
    dispatch(
      registerActions.setIsUsed({ isUsed: event.target.value === "si" ? 1 : 0 })
    );
  };

  const clearFileUploaded = () => {
    dispatch(registerActions.setFile({ file: "" }));
  };

  const clearSelectedClass = (id, type) => {
    let list = [],
      newList = [];
    if (type === "productos") {
      list = [...register.productClasses];
      newList = list.filter((item) => item.id !== id);
      dispatch(registerActions.setProductClasses({ productClasses: newList }));
    } else if (type === "servicios") {
      list = [...register.servicesClasses];
      newList = list.filter((item) => item.id !== id);
      dispatch(
        registerActions.setServicesClasses({ servicesClasses: newList })
      );
    }
  };

  const changeDatePicker = (date) => {
    if (typeof date !== "undefined") {
      const dateString = `${date.getFullYear()}-${
        date.getMonth() + 1 < 10 ? "0" : ""
      }${date.getMonth() + 1}-${
        date.getDate() < 10 ? "0" : ""
      }${date.getDate()}`;
      dispatch(registerActions.setDateUse({ dateUsed: String(dateString) }));
    } else {
      dispatch(registerActions.setDateUse({ dateUsed: "" }));
    }
  };

  const getPrice = () => {
    const marcas = storeUserProfile.services.services.filter(
      (item) => item.service_id === SERVICES.MARCAS
    );
    const estudio_reg = marcas[0].service_sub.filter(
      (item) => item.service_id === SERVICES.MARCAS_ESTUDIO_REG
    );

    let price =
      (register?.productClasses?.length + register?.servicesClasses?.length) *
      +estudio_reg[0]?.service_price;
    price = price + +estudio_reg[0].service_transact;
    const totalprice = calculateClassesPrice(price);
    return totalprice.toFixed(2);
  };

  const registerNext = () => {
    let amount = getPrice();
    dispatch(
      paymentActions.setPurchaseAmount({ amount: Number(amount).toFixed(2) })
    );
    if (userData.user_id === USER_PDLC) {
      props.history.replace(BRANDS_REGISTRABILITY_STUDY_CONFIRMATION);
    } else {
      props.history.replace(BRANDS_REGISTRABILITY_STUDY_CHECKOUT);
    }
  };

  return (
    <div className="container-modulo">
      {calsesProdOpen && (
        <ModalClases
          show={calsesProdOpen}
          type={typeModal}
          closed={closeModalClases}
          scope="registrabilityStudy"
        />
      )}
      {dragModal && (
        <ModalDrag
          show={dragModal}
          closed={closeDragModal}
          scope="study-brand-register"
          formatFile="zip"
        />
      )}

      <ButtonBack url="/marcas/servicios" text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">ESTUDIO DE REGISTRABILIDAD </h2>
      </div>

      <div className="text-description">
        <p>
          Proporciona la información para realizar el estudio de
          registrabilidad.
        </p>
      </div>

      <div className="label-information">
        <div>INFORMACIÓN DE LA MARCA</div>
      </div>

      <div className="container-desc-controls">
        <div className="form-informacion-marca">
          <div>
            <InputSimpleFloatLabel
              id="name"
              name="name"
              defaultValue={register.name}
              valid={register.name !== ""}
              placeholder="NOMBRE DE LA MARCA"
              onChange={handleChangeName}
            />
          </div>
          <div>
            <InputSelect
              id="typeBrand"
              name="typeBrand"
              onChange={onChangeType}
              value={register.type}
              fieldIdOption="value"
              options={[
                { value: "1", name: "NOMINATIVA" },
                { value: "2", name: "MIXTA" },
                { value: "3", name: "SONORA" },
                { value: "4", name: "DISEÑO" },
              ]}
            />
          </div>
        </div>

        <div className="text-description">
          <p>
            <strong>Normativa</strong> Si tu marca se encuentra conformada de
            solo palabras, deberás elegir esta opción.
          </p>
          <p>
            <strong>Mixta</strong> Si tu marca además de estar conformada de
            palabras, se encuentra compuesta de un diseño, deberás elegir esta
            opción y compartirnos tu diseño.
          </p>
          <p>
            <strong>Sonora</strong> Si tu marca es un sonido, deberás elegir
            esta opción y compartirnos el archivo de audio de tu marca.
          </p>
          <p>
            <strong>Diseño</strong> Si tu marca esta compuesta solo de un diseño
            y no esta acompañada de palabras, deberás elegir esta opción y
            compartirnos tu diseño.
          </p>
        </div>
      </div>

      {register.type > 1 && (
        <div className="updaload-file">
          {register.file === "" ? (
            <BtnCallToAction onClick={openDragModal} icon="faUpload">
              ARCHIVO DE DISEÑO DE LA MARCA
            </BtnCallToAction>
          ) : (
            <BtnCallToAction
              type="showfile"
              onClick={clearFileUploaded}
              icon="faTimesCircle"
              className="btnGreen"
              fileName={getFileName(register.file)}
            >
              ARCHIVO DE DISEÑO DE LA MARCA
            </BtnCallToAction>
          )}
          <p>Esté archivo deberá ser formato ZIP.</p>
        </div>
      )}

      <div className="parting-line" />

      <div className="add-controls">
        <div className="text-description">
          <p>Elige los servicios y/o productos que protegerás con tu marca.</p>
        </div>
        {register.productClasses.length > 0 && (
          <SelectedClasses
            selectedClasses={register.productClasses}
            type="productos"
            onClear={clearSelectedClass}
          />
        )}

        <BtnCallToAction
          onClick={() => openModalClases("productos")}
          icon="faPlusCircle"
        >
          AGREGAR CLASES DE PRODUCTOS
        </BtnCallToAction>

        {register.servicesClasses.length > 0 && (
          <SelectedClasses
            selectedClasses={register.servicesClasses}
            type="servicios"
            onClear={clearSelectedClass}
          />
        )}
        <BtnCallToAction
          onClick={() => openModalClases("servicios")}
          icon="faPlusCircle"
        >
          AGREGAR CLASES DE SERVICIOS
        </BtnCallToAction>
      </div>

      <div className="radio-option-controls">
        <div className="title-select">¿HAS USADO TU MARCA?</div>
        <InputRadio
          id="si"
          value="si"
          name="used_brand"
          onChange={handleRadio}
          checked={register.isUsed === 1}
          label="SI"
        />
        {register.isUsed ? (
          <div className="dateUseBrand">
            <div className="desDatePicker">
              Por favor indica la fecha en la que comenzaste a hacer uso de la
              misma:
            </div>
            <InputDayPicker
              placeholder="FECHA DE USO DE LA MARCA"
              value={register.dateUsed}
              onDayChange={(date) => changeDatePicker(date)}
            />
          </div>
        ) : null}
        <InputRadio
          id="no"
          value="no"
          name="used_brand"
          onChange={handleRadio}
          checked={register.isUsed === 0}
          label="NO"
        />
        <div className="radio-button-next">
          <Button
            onClick={registerNext}
            disabled={!register.isValid}
            className={"btnConfirm"}
          >
            CONTINUAR
          </Button>
        </div>
      </div>

      <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default EstudioRegistrabilidad;
