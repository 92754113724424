import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { DASHBOARD_SERVICES } from "../../../../constants/routes";
import { ButtonBack, PaymentStripe, ButtonEdit } from "../../../../components";
import {
  RIGHTS_LICENSE_CONFIRMATION,
  RIGHTS_LICENSE,
} from "../../../../constants/routes";

const REDIRECT = RIGHTS_LICENSE_CONFIRMATION;
const REGISTRO = RIGHTS_LICENSE;

const CheckOutNews = (props) => {
  const history = useHistory();
  const urlBack = REGISTRO;
  const storeRights = useSelector((state) => state.rights);

  useEffect(() => {
    if (storeRights.brand_info.registration_number.length === 0) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeRights.brand_info.registration_number.length]);

  const cameBack = () => {
    props.history.replace(urlBack);
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <div className="container-modulo">
      <ButtonBack url={urlBack} text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">LICENCIA DE DERECHOS</h2>
      </div>
      <div className="text-description">
        <p>
          Elaboración e Inscripción ante el IMPI del Contrato de Licencia de Uso
          de derechos de Propiedad Intelectual, entre tu y tu socio comercial,
          de manera práctica y sencilla
        </p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DEL DERECHO</div>
        <ButtonEdit onClick={cameBack} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>

      <div>
        <Elements stripe={stripePromise}>
          <PaymentStripe redirect={REDIRECT} />
        </Elements>
      </div>

      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default CheckOutNews;
