import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faDownload,
  faPlusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import classes from "./BtnCallToAction.module.css";

const BtnCallToAction = (props) => {
  let icon =
    {
      faUpload: faUpload,
      faPlusCircle: faPlusCircle,
      faTimesCircle: faTimesCircle,
      faDownload: faDownload,
    }[props.icon] || faUpload;

  const showfile = (
    <div>
      <div>{props.children}</div>
      <div className={classes.fileName}>{props.fileName}</div>
    </div>
  );

  return (
    <div
      className={`${classes.btnIconRoundBlue} ${classes[props.className]}`}
      onClick={props.onClick}
    >
      <div className={classes.fileName}>{props.type === "showfile" ? showfile : props.children}</div>
      <FontAwesomeIcon icon={icon} className={classes.styleIcon} />
    </div>
  );
};

BtnCallToAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  type: PropTypes.string,
  fileName: PropTypes.string,
  className: PropTypes.string,
};

export default React.memo(BtnCallToAction);
