import { createSlice } from "@reduxjs/toolkit";

const initialRegisterState = {
  name: "",
  type: "1",
  file: "",
  productClasses: [],
  servicesClasses: [],
  isUsed: null,
  dateUsed: "",
  isValid: false, //Status Fomulario Estudio de registrabilidad
  errorMessage: "",
};

const validateRegister = (state) => {
  let valid = true;

  if (state.name === "") {
    valid = false;
  }

  if (state.type > 1 && state.file === "") {
    valid = false;
  }

  if (state.productClasses.length === 0 && state.servicesClasses.length === 0) {
    valid = false;
  }

  if (state.isUsed === null) {
    valid = false;
  }

  if (state.isUsed === 1 && state.dateUsed === "") {
    valid = false;
  }

  return valid;
};

const registerSlice = createSlice({
  name: "register",
  initialState: initialRegisterState,
  reducers: {
    cleanStoreStudy(state) {
      state.name = "";
      state.type = "1";
      state.file = "";
      state.productClasses = [];
      state.servicesClasses = [];
      state.isUsed = null;
      state.dateUsed = "";
      state.isValid = false;
    },
    setName(state, action) {
      state.name = action.payload.name;
      state.isValid = validateRegister(state);
    },
    setType(state, action) {
      state.type = action.payload.type;
      state.isValid = validateRegister(state);
    },
    setFile(state, action) {
      state.file = action.payload.file;
      state.isValid = validateRegister(state);
    },
    setProductClasses(state, action) {
      state.productClasses = action.payload.productClasses;
      state.isValid = validateRegister(state);
    },
    setServicesClasses(state, action) {
      state.servicesClasses = action.payload.servicesClasses;
      state.isValid = validateRegister(state);
    },
    setIsUsed(state, action) {
      state.isUsed = action.payload.isUsed;
      state.isValid = validateRegister(state);
    },
    setDateUse(state, action) {
      state.dateUsed = action.payload.dateUsed;
      state.isValid = validateRegister(state);
    },
    setError(state, action) {
      state.errorMessage = action.payload.error;
    },
    /** billing transactions */
    brandRegistrabilityStudy() {},
  },
});

export const registerActions = registerSlice.actions;

export default registerSlice;
