import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonBack, ButtonEdit } from "../../../../components";
import RightsRenewalTemplate from "../../../../components/Templates/Confirmation/RightsRenewalTemplate";
import { rightsActions } from "../../../../store/slices/rights";
import {
  RIGHTS_RENOVATIONS_REGISTER,
  RIGHTS_RENOVATIONS_CHECKOUT,
  RIGHTS_RENOVATIIONS_SELECT,
  DASHBOARD_SERVICES
} from "../../../../constants/routes";

const ConfirmationRenewal = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const storeRights = useSelector((state) => state.rights);
  const storePayment = useSelector((state) => state.payment);

  useEffect(() => {
    if (
      !storeRights.isValid &&
      !storeRights.isValidAssignee &&
      !storeRights.isValidRenewal
    ) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [
    history,
    storeRights.isValid,
    storeRights.isValidAssignee,
    storeRights.isValidRenewal,
  ]);

  const gotoBrandInfo = () => {
    if(storeRights.isRegisteredRight){
      history.replace(RIGHTS_RENOVATIIONS_SELECT);
    } else {
      props.history.replace(RIGHTS_RENOVATIONS_REGISTER);
    }
  };

  const gotoPayment = () => {
    props.history.replace(RIGHTS_RENOVATIONS_CHECKOUT);
  };

  const redirectReceipt = useCallback(() => {
    dispatch(rightsActions.createRightsRenewal());
  }, [dispatch]);

  return (
    <div className="container-modulo">
      <ButtonBack url={RIGHTS_RENOVATIONS_CHECKOUT} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">RENOVACIÓN DE DERECHOS</h2>
      </div>

      <div className="description-confirm">
        <p>
          Renovamos tus derechos de propiedad intelectual, de manera expedita.
        </p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DEL DERECHO</div>
        <ButtonEdit onClick={gotoBrandInfo} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE PAGO</div>
        <ButtonEdit onClick={gotoPayment} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
      <div>
        <RightsRenewalTemplate
          registerData={storeRights}
          paymentData={storePayment}
          onConfirm={redirectReceipt}
          scope="reg-std-rights"
        />
      </div>
    </div>
  );
};

export default ConfirmationRenewal;
