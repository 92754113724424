import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import basicAuthRequest from "../../../utils/axios/basicAuthRequest";
import { RENOVATIONS } from "../../../constants/atualcanceApi";
import { RIGHTS_RENOVATIONS_REGISTER } from "../../../constants/routes";
import { rightsActions } from "../../../store/slices/rights";

const useRenewal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [dataOrders, setDataOrders] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const getOrders = async () => {
      setLoading(true);
      const response = await basicAuthRequest({
        method: "GET",
        url: RENOVATIONS + user.user_id +'/filter/0',
      }).catch(error => {
        setDataOrders([]);
        setOrders([]);
        setLoading(false);
        setError({ fail: error });
      });

      if (response?.status === 200) {
        const arrayOrders = [];

        if (response.data.length === 0) {
          dispatch(
            rightsActions.setIsRegisteredRight({
              isRegisteredRight: false,
            })
          );
          history.replace(RIGHTS_RENOVATIONS_REGISTER);
        }

        response.data.forEach((item) => {
          arrayOrders.push({
            id: item.service_order_id,
            name: item.brand_info.name,
          });
        });

        setDataOrders(response.data);
        setOrders(arrayOrders);
        setLoading(false);
      } else {
        setDataOrders([]);
        setOrders([]);
        setLoading(false);
        setError({ fail: "Fail to load data" });
      }
    };
    getOrders();
    return () => {
      setDataOrders([]);
      setOrders([]);
      setLoading(null);
    }
  }, [dispatch, history]);

  const getInformationOrder = (order_id) => {
    const data = dataOrders.filter(
      (order) => +order?.service_order_id === +order_id
    );
    return data;
  };

  return {
    loading,
    orders,
    getInformationOrder,
    error,
  };
};

export default useRenewal;
