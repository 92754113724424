import PropTypes from "prop-types";
import { Spinner, Button } from "../../../../components";
import { AtaCurrencyFormat } from "../../../../components/Common";

const ImpedimentData = ({ loading, price, impediment, handleSubmit }) => {
  return (
    <>
      <div className="label-information">
        <div>INFORMACIÓN DE LA MARCA</div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="confirmation-item">
            <h3>NOMBRE DE LA MARCA</h3>
            <p>{impediment[0]?.name_brand}</p>
          </div>
          <div className="confirmation-item">
            <h3>NOMBRE DEL TITULAR</h3>
            <p>{impediment[0]?.name_owner}</p>
          </div>
          <div className="confirmation-item">
            <h3>FECHA LIMITE DE PAGO</h3>
            <p>{impediment[0]?.final_date}</p>
          </div>
          <div className="confirmation-item">
            <h3>CONCEPTO</h3>
            <p>{impediment[0]?.concept}</p>
          </div>
          <div className="confirmation-item">
            <h3>MONTO REQUERIDO</h3>
            <span className="ammount">
              <AtaCurrencyFormat value={impediment[0]?.transact_amount} />
            </span>
          </div>

          <div className="contentainer-button">
            <Button className="btnConfirm" onClick={handleSubmit}>
              CONTINUAR
            </Button>
          </div>
        </>
      )}
      <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </>
  );
};

ImpedimentData.prototype = {
  loading: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  impediment: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ImpedimentData;
