import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import classes from "./ItemList.module.css";

const ItemList = ({ number, deleteItem }) => (
  <div className={classes.mainContent}>
    <div>{number}</div>
    <div onClick={deleteItem}>
      <FontAwesomeIcon icon={faTimes} />
    </div>
  </div>
);

ItemList.propTypes = {
  number: PropTypes.number.isRequired,
  deleteItem: PropTypes.func.isRequired
};

export default ItemList;
