import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';

import classes from './InputIcon.module.css';

const InputIcon = props => {

  let iconInput = ({
    'envelope': faEnvelope,
    'key': faKey
  })[props.icon];

  return (
    <div className={classes.inputContainer}>
      <FontAwesomeIcon icon={iconInput} className={classes.iconStyle} />
      <input
        id={props.id}
        name={props.name}
        type={props.type}
        className={`${classes.inputIcon} ${props.className} ${props.valid ? classes.invalid : null}`}
        placeholder={props.placeholder}
        onChange={props.onChange}
        autoComplete={props.autoComplete}
      />
    </div>
  )
}

InputIcon.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  icon: PropTypes.string,
  autoComplete: PropTypes.string,
  valid: PropTypes.bool
}

export default React.memo(InputIcon);

