import { createSlice } from "@reduxjs/toolkit";

const initialPaymentState = {
  paymentMethodid: "",
  paymentMethod: "",
  purchaseAmount: "",
  billing_result: {
    order_number: "",
    confirmation_message: "",
  },
};

const paymentSlice = createSlice({
  name: "payment",
  initialState: initialPaymentState,
  reducers: {
    cleanStorePayment(state) {
      state.paymentMethodid = "";
      state.paymentMethod = "";
      state.purchaseAmount = "";
    },
    setPaymentMethod(state, action) {
      state.paymentMethodid = action.payload.paymentMethodId;
      state.paymentMethod = action.payload.paymentMethod;
    },
    setPurchaseAmount(state, action) {
      state.purchaseAmount = action.payload.amount;
    },
    setBillingResult(state, action) {
      state.billing_result = action.payload.billing_result;
    },
  },
});

export const paymentActions = paymentSlice.actions;

export default paymentSlice;
