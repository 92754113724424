import { saveAs } from "file-saver";
import { BtnCallToAction, Button } from "../../../components";
import CommentsColap from "../../../components/Templates/Requests/CommentsColap";
import { getFileName } from "../../../utils/scripts/file";
import { TYPE_FILE, FORMAT_FILE } from "../../../constants/form";
import classes from "./MyRequest.module.css";
import { downloadFile } from "../../../utils/axios/downloadFile";

const DetailBrand = ({
  serviceOrderId,
  brandInfo,
  ownerInfo,
  authorInfo,
  coAuthorInfo,
  serviceId,
  openModalFile,
  brandFileName,
  titularFileName,
  authorFileName,
  coauthorFileName,
}) => {
  const labels = {
    1: {
      name: "NOMBRE DE LA MARCA",
      type: "TIPO DE MARCA",
      file: "ARCHIVO DE LA MARCA",
    },
    2: {
      name: "NOMBRE DE LA MARCA",
      type: "TIPO DE MARCA",
      file: "ARCHIVO DE LA MARCA",
    },
    3: {
      name: "NOMBRE DE LA MARCA",
      type: "TIPO DE MARCA",
      file: "ARCHIVO DE LA MARCA",
    },
    4: {
      name: "NOMBRE DE LA OBRA",
      type: "TIPO DE OBRA",
      file: "ARCHIVO DE LA OBRA",
    },
    5: {
      name: "NOMBRE DEL GRUPO MUSICAL",
      type: "TIPO DE GRUPO MUSICAL",
      file: "ARCHIVO DEL GRUPO MUSICAL",
    },
    6: {
      name: "NOMBRE DEL GRUPO MUSICAL",
      type: "TIPO DE GRUPO MUSICAL",
      file: "ARCHIVO DEL GRUPO MUSICAL",
    },
    7: {
      name: "NOMBRE DEL GRUPO MUSICAL",
      type: "TIPO DE GRUPO MUSICAL",
      file: "ARCHIVO DEL GRUPO MUSICAL",
    },
    8: {
      name: "NOMBRE DE LA REVISTA",
      type: "TIPO DE REVISTA",
      file: "ARCHIVO DE LA REVISTA",
    },
    9: {
      name: "NOMBRE DE LA REVISTA",
      type: "TIPO DE REVISTA",
      file: "ARCHIVO DE LA REVISTA",
    },
    10: {
      name: "NOMBRE DE LA REVISTA",
      type: "TIPO DE REVISTA",
      file: "ARCHIVO DE LA REVISTA",
    },
    11: {
      name: "NOMBRE DEL PERIÓDICO",
      type: "TIPO DE PERIÓDICO",
      file: "ARCHIVO DEL PERIÓDICO",
    },
    12: {
      name: "NOMBRE DEL PERIÓDICO",
      type: "TIPO DE PERIÓDICO",
      file: "ARCHIVO DEL PERIÓDICO",
    },
    13: {
      name: "NOMBRE DEL PERIÓDICO",
      type: "TIPO DE PERIÓDICO",
      file: "ARCHIVO DEL PERIÓDICO",
    },
    14: {
      name: "NOMBRE DE LA PUBLICACIÓN",
      type: "TIPO DE PUBLICACIÓN",
      file: "ARCHIVO DE LA PUBLICACIÓN",
    },
    15: {
      name: "NOMBRE DE LA PUBLICACIÓN",
      type: "TIPO DE PUBLICACIÓN",
      file: "ARCHIVO DE LA PUBLICACIÓN",
    },
    16: {
      name: "NOMBRE DE LA PUBLICACIÓN",
      type: "TIPO DE PUBLICACIÓN",
      file: "ARCHIVO DE LA PUBLICACIÓN",
    },
  }[
    brandInfo.type_right_id !== undefined ? brandInfo.type_right_id : serviceId
  ];

  const downloadTitularFile = async (fileName) => {
    const urlToDownload = await downloadFile(serviceOrderId, 2, fileName);

    if (urlToDownload.data !== undefined) {
      saveAs(urlToDownload.data.url, "titular_file.pdf");
    }
  };

  const downloadAutorFile = async (fileName) => {
    const urlToDownload = await downloadFile(serviceOrderId, 3, fileName);
    if (urlToDownload.data !== undefined) {
      saveAs(urlToDownload.data.url, "autor_file.pdf");
    }
  };

  const downloadCoauthorFile = async (fileName) => {
    const urlToDownload = await downloadFile(serviceOrderId, 4, fileName);
    if (urlToDownload.data !== undefined) {
      saveAs(urlToDownload.data.url, "coautor_file.pdf");
    }
  };

  const downloadBrandFile = async (fileName) => {
    const urlToDownload = await downloadFile(serviceOrderId, 1, fileName);
    if (urlToDownload.data !== undefined) {
      saveAs(urlToDownload.data.url);
    }
  };

  return (
    <div className={classes.detailBrandSection}>
      <div className={classes.sectionInfo}>
        <div className={classes.infoBrand}>
          {brandInfo.name && (
            <div className={classes.itemInfo}>
              <div className={classes.itemHead}>{labels?.name}</div>
              <div className={classes.itemData}>{brandInfo?.name}</div>
            </div>
          )}
          {brandInfo?.type_name && (
            <div className={classes.itemInfo}>
              <div className={classes.itemHead}>{labels?.type}</div>
              <div className={classes.itemData}>{brandInfo?.type_name}</div>
            </div>
          )}
        </div>
        <div className={classes.infoBrand}>
          {brandInfo?.prev_use_date && (
            <div className={classes.itemInfo}>
              <div className={classes.itemHead}>
                FECHA DE USO PREVIO DE LA MARCA
              </div>
              <div className={classes.itemData}>{brandInfo?.prev_use_date}</div>
            </div>
          )}
          {brandInfo?.registration_number.length > 0 && (
            <div className={classes.itemInfo}>
              <div className={classes.itemHead}>NÚMERO DE REGISTRO</div>
              {brandInfo?.registration_number.map((number) => (
                <div key={number} className={classes.itemData}>
                  {number}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={classes.infoBrandFull}>
          {brandInfo?.description && (
            <div>
              <div className={classes.itemHead}>DESCRIPCIÓN DE LA OBRA</div>
              <div className={classes.itemData}>{brandInfo?.description}</div>
            </div>
          )}
        </div>
      </div>

      {!!Object.keys(ownerInfo).length && (
        <CommentsColap title="DATOS DEL TITULAR">
          <div className={classes.entityData}>
            <div>
              <div className={classes.labelEntity}>NOMBRE</div>
              <div className={classes.itemEntity}>{ownerInfo.name}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>CALLE</div>
              <div className={classes.itemEntity}>{ownerInfo.street}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>NÚMERO EXTERIOR</div>
              <div className={classes.itemEntity}>{ownerInfo.ext_number}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>NÚMERO INTERIOR</div>
              <div className={classes.itemEntity}>{ownerInfo.street}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>CORREO ELECTRÓNICO</div>
              <div className={classes.itemEntity}>{ownerInfo.int_number}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>TELÉFONO</div>
              <div className={classes.itemEntity}>{ownerInfo.phone}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>ESTADO</div>
              <div className={classes.itemEntity}>{ownerInfo.state}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>CIUDAD</div>
              <div className={classes.itemEntity}>{ownerInfo.city}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>CÓDIGO POSTAL</div>
              <div className={classes.itemEntity}>{ownerInfo.postal_code}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>COLONIA</div>
              <div className={classes.itemEntity}>{ownerInfo.neighborhood}</div>
            </div>
            <div className={classes.fileItem}>
              {ownerInfo.file_status_id === 1 || titularFileName !== "" ? (
                <BtnCallToAction
                  onClick={() => downloadTitularFile(ownerInfo.file)}
                  icon="faDownload"
                  className="btnDownload"
                >
                  {titularFileName !== ""
                    ? getFileName(titularFileName)
                    : getFileName(ownerInfo.file)}
                </BtnCallToAction>
              ) : (
                <div className={classes.contentButton}>
                  <Button
                    onClick={() => {
                      openModalFile(
                        ownerInfo.id,
                        TYPE_FILE.TITULAR,
                        FORMAT_FILE.PDF
                      );
                    }}
                    className="btnConfirm"
                  >
                    ADJUNTAR ARCHIVOS
                  </Button>
                </div>
              )}
            </div>
          </div>
        </CommentsColap>
      )}

      {!!Object.keys(authorInfo).length && (
        <CommentsColap title="DATOS DEL AUTOR">
          <div className={classes.entityData}>
            <div>
              <div className={classes.labelEntity}>NOMBRE</div>
              <div className={classes.itemEntity}>{authorInfo.name}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>CALLE</div>
              <div className={classes.itemEntity}>{authorInfo.street}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>NÚMERO EXTERIOR</div>
              <div className={classes.itemEntity}>{authorInfo.ext_number}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>NÚMERO INTERIOR</div>
              <div className={classes.itemEntity}>{authorInfo.street}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>CORREO ELECTRÓNICO</div>
              <div className={classes.itemEntity}>{authorInfo.int_number}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>TELÉFONO</div>
              <div className={classes.itemEntity}>{authorInfo.phone}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>ESTADO</div>
              <div className={classes.itemEntity}>{authorInfo.state}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>CIUDAD</div>
              <div className={classes.itemEntity}>{authorInfo.city}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>CÓDIGO POSTAL</div>
              <div className={classes.itemEntity}>{authorInfo.postal_code}</div>
            </div>
            <div>
              <div className={classes.labelEntity}>COLONIA</div>
              <div className={classes.itemEntity}>
                {authorInfo.neighborhood}
              </div>
            </div>
            <div className={classes.fileItem}>
              {authorInfo.file_status_id === 1 || authorFileName !== "" ? (
                <BtnCallToAction
                  onClick={() => downloadAutorFile(authorInfo.file)}
                  icon="faDownload"
                  className="btnDownload"
                >
                  {authorFileName !== ""
                    ? getFileName(authorFileName)
                    : getFileName(authorInfo.file)}
                </BtnCallToAction>
              ) : (
                <div className={classes.contentButton}>
                  <Button
                    onClick={() => {
                      openModalFile(
                        authorInfo.id,
                        TYPE_FILE.AUTHOR,
                        FORMAT_FILE.PDF
                      );
                    }}
                    className="btnConfirm"
                  >
                    ADJUNTAR ARCHIVOS
                  </Button>
                </div>
              )}
            </div>
          </div>
        </CommentsColap>
      )}

      {!!coAuthorInfo.length &&
        coAuthorInfo.map((coauthor) => (
          <CommentsColap
            key={Math.random() + coauthor.curp}
            title="DATOS DEL CO-AUTOR"
          >
            <div className={classes.entityData}>
              <div>
                <div className={classes.labelEntity}>NOMBRE</div>
                <div className={classes.itemEntity}>{coauthor.name}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>CALLE</div>
                <div className={classes.itemEntity}>{coauthor.street}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>NÚMERO EXTERIOR</div>
                <div className={classes.itemEntity}>{coauthor.ext_number}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>NÚMERO INTERIOR</div>
                <div className={classes.itemEntity}>{coauthor.street}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>CORREO ELECTRÓNICO</div>
                <div className={classes.itemEntity}>{coauthor.int_number}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>TELÉFONO</div>
                <div className={classes.itemEntity}>{coauthor.phone}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>ESTADO</div>
                <div className={classes.itemEntity}>{coauthor.state}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>CIUDAD</div>
                <div className={classes.itemEntity}>{coauthor.city}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>CÓDIGO POSTAL</div>
                <div className={classes.itemEntity}>{coauthor.postal_code}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>COLONIA</div>
                <div className={classes.itemEntity}>
                  {coauthor.neighborhood}
                </div>
              </div>
              <div className={classes.fileItem}>
                {coauthor.file_status_id === 1 || coauthorFileName !== "" ? (
                  <BtnCallToAction
                    onClick={() => downloadCoauthorFile(coauthor.file)}
                    icon="faDownload"
                    className="btnDownload"
                  >
                    {coauthorFileName !== ""
                      ? getFileName(coauthorFileName)
                      : getFileName(coauthor.file)}
                  </BtnCallToAction>
                ) : (
                  <div className={classes.contentButton}>
                    <Button
                      onClick={() => {
                        openModalFile(
                          authorInfo.id,
                          TYPE_FILE.COAHUTOR,
                          FORMAT_FILE.PDF
                        );
                      }}
                      className="btnConfirm"
                    >
                      ADJUNTAR ARCHIVOS
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </CommentsColap>
        ))}

      {brandInfo.type_id !== 1 &&
        (brandFileName !== "" ? (
          <div className={classes.entityData}>
            <div className={classes.fileItem}>
              <div className={classes.itemHead}>{labels?.file}</div>
              <BtnCallToAction
                onClick={() => downloadBrandFile(brandInfo.file)}
                icon="faDownload"
                className="btnDownload"
              >
                {brandFileName !== ""
                  ? getFileName(brandFileName)
                  : getFileName(brandInfo.file)}
              </BtnCallToAction>
            </div>
          </div>
        ) : (
          <div className={classes.fileItem}>
            <div className={classes.contentButton}>
              <div className={classes.itemHead}>{labels?.file}</div>
              <Button
                onClick={() => {
                  openModalFile(null, TYPE_FILE.SERVISE, FORMAT_FILE.ZIP);
                }}
                className="btnConfirm"
              >
                ADJUNTAR ARCHIVOS
              </Button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default DetailBrand;
