import { createSlice } from "@reduxjs/toolkit";

const initialRightsState = {
  isRegisteredHolder: true,
  isRegisteredLicense: true,
  isRegisteredAssignee: true,
  isRegisteredRight: true,
  brand_info: {
    right_type: "-1",
    registration_number: [],
    number: "",
    name: "",
    initial_date: "",
    final_date: "",
    amount: 0,
    payment_period_amount: "-1",
    privacy: true,
  },
  owner_id: "-1",
  licensee_id: "-1",
  assignee_id: "-1",
  service_order_id: null,
  registerdRights: [],
  isValid: false,
  isValidAssignee: false,
  isValidRenewal: false,
};

const validRightsRenewal = (state) => {
  let valid = true;

  if (state.brand_info.right_type === "-1") {
    valid = false;
  }

  if (state.brand_info.number === "") {
    valid = false;
  }

  if (state.brand_info.name === "") {
    valid = false;
  }

  if (state.isRegisteredHolder) {
    if (state.owner_id === "-1") {
      valid = false;
    }
  }

  return valid;
};

const validRightsAssignee = (state) => {
  let valid = true;

  if (state.brand_info.right_type === "-1") {
    valid = false;
  }

  if (state.brand_info.registration_number.length === 0) {
    valid = false;
  }

  if (state.brand_info.amount === 0) {
    valid = false;
  }

  if (state.isRegisteredHolder) {
    if (state.owner_id === "-1") {
      valid = false;
    }
  }

  if (state.isRegisteredAssignee) {
    if (state.assignee_id === "-1") {
      valid = false;
    }
  }

  return valid;
};

const validRightsLicense = (state) => {
  let valid = true;

  if (state.brand_info.registration_number.length === 0) {
    valid = false;
  }

  if (state.isRegisteredHolder) {
    if (state.owner_id === "-1") {
      valid = false;
    }
  }

  if (state.isRegisteredLicense) {
    if (state.licensee_id === "-1") {
      valid = false;
    }
  }

  if (state.brand_info.initial_date === "") {
    valid = false;
  }

  if (state.brand_info.final_date === "") {
    valid = false;
  }

  if (state.brand_info.payment_period_amount === "-1") {
    valid = false;
  }

  if (state.brand_info.amount === 0) {
    valid = false;
  }

  return valid;
};

const rightsSlice = createSlice({
  name: "rights",
  initialState: initialRightsState,
  reducers: {
    cleanSotoreLicence(state) {
      state.brand_info = { ...initialRightsState.brand_info };
      state.isRegisteredHolder = true;
      state.isRegisteredLicense = true;
      state.isRegisteredAssignee = true;
      state.isRegisteredRight = true;
      state.owner_id = "-1";
      state.licensee_id = "-1";
      state.assignee_id = "-1";
      state.service_order_id = null;
      state.registerdRights = [];
      state.isValid = false;
      state.isValidAssignee = false;
      state.isValidRenewal = false;
    },
    createRightsLicense() {},
    createRightsAssignee() {},
    createRightsRenewal() {},
    setregisterdRights(state, action) {
      state.registerdRights = action.payload.registerdRights;
    },
    setBrandNumber(state, action) {
      state.brand_info.number = action.payload.number;
      state.isValidRenewal = validRightsRenewal(state);
    },
    setBrandName(state, action) {
      state.brand_info.name = action.payload.name;
      state.isValidRenewal = validRightsRenewal(state);
    },
    setIsRegisteredRight(state, action) {
      state.isRegisteredRight = action.payload.isRegisteredRight;
    },
    setServiceOrderId(state, action) {
      state.service_order_id = action.payload.service_order_id;
    },
    setIsRegisteredHolder(state, action) {
      state.isRegisteredHolder = action.payload.isRegisteredHolder;
      state.isValid = validRightsLicense(state);
      state.isValidAssignee = validRightsAssignee(state);
      state.isValidRenewal = validRightsRenewal(state);
    },
    setIsRegisteredLicense(state, action) {
      state.isRegisteredLicense = action.payload.isRegisteredLicense;
      state.isValid = validRightsLicense(state);
    },
    setIsRegisteredAssignee(state, action) {
      state.isRegisteredAssignee = action.payload.isRegisteredAssignee;
      state.isValidAssignee = validRightsAssignee(state);
    },
    setInitialDate(state, action) {
      state.brand_info.initial_date = action.payload.initial_date;
      state.isValid = validRightsLicense(state);
    },
    setFinalDate(state, action) {
      state.brand_info.final_date = action.payload.final_date;
      state.isValid = validRightsLicense(state);
    },
    setAmount(state, action) {
      state.brand_info.amount = action.payload.amount;
      state.isValid = validRightsLicense(state);
      state.isValidAssignee = validRightsAssignee(state);
    },
    setPeriod(state, action) {
      state.brand_info.payment_period_amount = action.payload.period;
      state.isValid = validRightsLicense(state);
    },
    setPrivacy(state, action) {
      state.brand_info.privacy = action.payload.privacy;
    },
    setBrandRegistrationNumber(state, action) {
      state.brand_info.registration_number.push(
        action.payload.registration_number
      );
      state.isValid = validRightsLicense(state);
      state.isValidAssignee = validRightsAssignee(state);
    },
    replaceRegistrationNumbers(state, action) {
      state.brand_info.registration_number = action.payload.registration_number;
      state.isValid = validRightsLicense(state);
      state.isValidAssignee = validRightsAssignee(state);
    },
    setOwnerId(state, action) {
      state.owner_id = action.payload.owner_id;
      state.isValid = validRightsLicense(state);
      state.isValidAssignee = validRightsAssignee(state);
      state.isValidRenewal = validRightsRenewal(state);
    },
    setLicenseeId(state, action) {
      state.licensee_id = action.payload.licensee_id;
      state.isValid = validRightsLicense(state);
    },
    setAssigneeId(state, action) {
      state.assignee_id = action.payload.assignee_id;
      state.isValidAssignee = validRightsAssignee(state);
    },
    setRightType(state, action) {
      state.brand_info.right_type = action.payload.right_type;
      state.isValidAssignee = validRightsAssignee(state);
      state.isValidRenewal = validRightsRenewal(state);
    },
    setIsValidRenewal(state, action) {
      state.isValidRenewal = action.payload.valid;
    }
  },
});

export const rightsActions = rightsSlice.actions;

export default rightsSlice;
