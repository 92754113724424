import { call, put, select } from "redux-saga/effects";
import { replace } from "connected-react-router";

import { paymentActions } from "../../slices/payment";
import { newspaperActions } from "../../slices/newspaper";
import {
  requestCreateNewspaperStudy,
  requestGetStudiesNewspaper,
  requestCreateNewspaper,
} from "../requests/newspaperRequest";
import { TICKECT } from "../../../constants/routes";

export function* handlerCreateNewspaper() {
  try {
    const storeNewspaper = yield select((store) => store.newspaper);
    const storePayment = yield select((store) => store.payment);
    const storeEntity = yield select((store) => store.entity);

    const response = yield call(
      requestCreateNewspaper,
      storeNewspaper,
      storePayment,
      storeEntity
    );

    if (response.status === 200) {
      yield put(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      yield put(replace(TICKECT));
      yield put(paymentActions.cleanStorePayment());
      yield put(newspaperActions.cleanStorageNewspaper());
    } else {
      // TODO: generar pantalla de error
    }
  } catch (error) {
    console.log("Error handlerCreateMusicalGroup" + error);
  }
}

export function* handlerCreateNewspaperStudy() {
  try {
    const storeNewspaper = yield select((store) => store.newspaper);
    const storePayment = yield select((store) => store.payment);
    const response = yield call(
      requestCreateNewspaperStudy,
      storeNewspaper,
      storePayment
    );

    if (response.status === 200) {
      yield put(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      yield put(replace(TICKECT));
      yield put(paymentActions.cleanStorePayment());
      yield put(newspaperActions.cleanStorageNewspaper());
    } else {
      // TODO: generar pantalla de error
    }
  } catch (error) {
    console.log("Errow HandlerCreateMusicalGroupStudy");
  }
}

/**
 * Get RegStudiesMusicalGroup
 */
export function* handlerGetRegStudiesNewspaper() {
  try {
    const studies = yield call(requestGetStudiesNewspaper, { service: "12" });
    if (studies.data.length) {
      yield put(newspaperActions.setRegStudies({ regStudies: studies.data }));
    } else {
      // yield put(replace("/newspaper/servicios"));
    }
  } catch (error) {
    console.log(error);
  }
}
