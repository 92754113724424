export const catObras = [
    {
        "id": "5",
        "name": "Literaria: Libro, Cuento, Poema, Guion Cinematográfico, Guión Teatral."
    },
    {
        "id": "6",
        "name": "Pictórica o Dibujo"
    },
    {
        "id": "7",
        "name": "Canción solo Música"
    },
    {
        "id": "8",
        "name": "Canción solo letra"
    },
    {
        "id": "9",
        "name": "Canción Música y Letra"
    },
    {
        "id": "10",
        "name": "Escultórica"
    },
    {
        "id": "11",
        "name": "Fotográfica"
    },
    {
        "id": "12",
        "name": "Caricatura o Historiet"
    },
    {
        "id": "13",
        "name": "Programa de Computo"
    }

];