import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import basicAuthRequest from "../../../../../utils/axios/basicAuthRequest";
import axios from "axios";
import { UPLOAD_FILE } from "../../../../../constants/atualcanceApi";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { registerActions } from "../../../../../store/slices/brandRegistrabilityStudy";
import { tradeMarkActions } from "../../../../../store/slices/tradeMark";
import { obrasActions } from "../../../../../store/slices/obras";
import { musicalGroupActions } from "../../../../../store/slices/musicalGroup";
import { magazinesActions } from "../../../../../store/slices/magazines";
import { newspaperActions } from "../../../../../store/slices/newspaper";
import { publicationsActions } from "../../../../../store/slices/publications";
import { entityActions } from "../../../../../store/slices/entity";
import { industrialDActions } from "../../../../../store/slices/industrialDesigns";
import { userProfileActions } from "../../../../../store/slices/userProfile";
import { FullModal, BackDropFull, Spinner } from "../../../..";
import classes from "./dragModal.module.css";

const getTockenUpload = async (fileName) => {
  return await basicAuthRequest.request({
    url: UPLOAD_FILE,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      file: fileName,
    },
  });
};

const uploadFile = async (token, file) => {
  var options = {
    headers: {
      "Content-Type": file.type,
    },
  };
  return axios
    .put(token, file, options)
    .then((success) => success)
    .catch((error) => error);
};

const ModalDrag = ({ scope, formatFile, closed, show, recordIndex }) => {
  const [loading, setLoading] = useState(false);

  const typeFormatFile = {
    pdf: "application/pdf",
    zip: "application/zip",
  }[formatFile];

  const dispatch = useDispatch();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length) {
        setLoading(true);
        const tockenUpload = await getTockenUpload(acceptedFiles[0].path);
        const fileName = tockenUpload.data?.file;
        await uploadFile(tockenUpload?.data?.token, acceptedFiles[0]);
        setLoading(false);

        switch (scope) {
          case "study-brand-register":
            dispatch(registerActions.setFile({ file: fileName }));
            break;
          case "brand-register":
            dispatch(tradeMarkActions.setFile({ file: fileName }));
            break;
          case "brand-owner-info":
            dispatch(tradeMarkActions.setHolderFile({ file: fileName }));
            break;
          case "obras-register":
            dispatch(obrasActions.setFile({ file: fileName }));
            break;
          case "obras-owner-info":
            dispatch(obrasActions.setOwnerInfoFile({ file: fileName }));
            break;
          case "obras-author-info":
            dispatch(obrasActions.setAuthorInfoFile({ file: fileName }));
            break;
          case "obras-coauthor-info":
            dispatch(
              obrasActions.setCoAuthorInfoFile({
                file: fileName,
                index: recordIndex,
              })
            );
            break;
          case "reg-std-musical-group":
            dispatch(musicalGroupActions.setFile({ file: fileName }));
            break;
          case "reg-std-magazines":
            dispatch(magazinesActions.setFile({ file: fileName }));
            break;
          case "reg-std-newspaper":
            dispatch(newspaperActions.setFile({ file: fileName }));
            break;
          case "reg-std-publications":
            dispatch(publicationsActions.setFile({ file: fileName }));
            break;
          case "entity-form":
            dispatch(
              entityActions.setFile({
                file: fileName,
                idEntity: recordIndex,
              })
            );
            break;
          case "reg-std-industrial":
            dispatch(industrialDActions.setFile({ file: fileName }));
            break;
          case "upload-file":
            dispatch(userProfileActions.setUploadedFiles({ file: fileName }));
            break;
          default:
            break;
        }
        closed();
      }
    },
    [closed, dispatch, recordIndex, scope]
  );

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: `${typeFormatFile}`,
    multiple: false,
  });

  const fileRejectionItems = fileRejections.length ? (
    <div className={classes.warning}>
      <span>
        {" "}
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="iconoInput"
        />{" "}
        Formato de archivo incorrecto
      </span>
    </div>
  ) : null;

  return (
    <React.Fragment>
      <FullModal show={show} closed={closed}>
        <div className="modalContent">
          <section className="drop-zone-files">
            {loading ? (
              <div className={classes.loadingContainer}>
                <Spinner />
              </div>
            ) : (
              <div {...getRootProps({ className: classes.dropzone })}>
                <input {...getInputProps()} />
                <p className={classes.dropzoneTitle}>
                  Adjuntar archivo {formatFile.toUpperCase()}
                </p>
                <p>Arrastre el archivo o presione aquí para seleccionarlo</p>
                {fileRejectionItems}
              </div>
            )}
          </section>
        </div>
      </FullModal>
      <BackDropFull show={show} />
    </React.Fragment>
  );
};

ModalDrag.propTypes = {
  /** Where we call this module */
  scope: PropTypes.string.isRequired,
  /** Format file to upload */
  formatFile: PropTypes.string.isRequired,
  /** function to close modal */
  closed: PropTypes.func.isRequired,
  /** index object to save  */
  recordIndex: PropTypes.number,
};

export default ModalDrag;
