import { RowBack, ChangePassword } from '../../../assets/images/icons/icons';
import { PROFILE } from '../../../constants/routes';
import './change-password.css';

const changePassword = (props) => {

  const goBack = () => {
    props.history.replace(PROFILE);
  }

  return (
    <div className="container-modulo">
      <div className="link-back" onClick={goBack}>
        <img src={RowBack} alt="regresar" />
      &nbsp;&nbsp;REGRESAR
      </div>

      <div className="section-title">
        <h2 className="subtitle">CAMBIAR CONTRASEÑA</h2>
        <img src={ChangePassword} alt="mail" className="item-servicios" />
      </div>

      <div className="bn-container-control">
        <br /><br />
        <p>En unos momentos recibirás un correo electrónico con las indicaciones para cambiar tu contraseña.</p>
      </div>

      <div className="section-title">
        <button className="btn-success">CONTINUAR</button>
      </div>
    </div>
  );
}

export default changePassword;