import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ButtonEdit, PaymentStripe } from "../../../../components";

const AnnuitiesCheckOut = ({ handleSubmit, handleEditInfo }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DEL DISEÑO INDUSTRIAL</div>
        <ButtonEdit onClick={handleEditInfo} icon="faEye">
        &nbsp;Ver&nbsp;&nbsp;&nbsp;&nbsp;
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>
      <div>
        <Elements stripe={stripePromise}>
          <PaymentStripe redirect="" onSubmit={handleSubmit} />
        </Elements>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </>
  );
};

AnnuitiesCheckOut.prototype = {
  handleSubmit: PropTypes.func.isRequired,
  handleEditInfo: PropTypes.func.isRequired,
};

export default AnnuitiesCheckOut;