import { USER_PDLC } from "../../../constants";
import basicAuthRequest from "../../../utils/axios/basicAuthRequest";

export async function requestCreateBrandRegStudyOrder(
  storeRegisterBrandStudy,
  storePayment
) {
  const user = JSON.parse(localStorage.getItem("user"));
  const prod_classes = storeRegisterBrandStudy.productClasses.map(
    (item) => +item.id
  );
  const serv_classes = storeRegisterBrandStudy.servicesClasses.map(
    (item) => +item.id
  );

  const bodyEDR = {
    user_id: user.user_id,
    service_id: 2,
    brand_info: {
      name: storeRegisterBrandStudy.name,
      type: storeRegisterBrandStudy.type,
      file: storeRegisterBrandStudy.file,
      product_classes: prod_classes,
      service_classes: serv_classes,
      prev_use: storeRegisterBrandStudy.isUsed === 1,
      prev_use_date:
        storeRegisterBrandStudy.dateUsed === ""
          ? null
          : storeRegisterBrandStudy.dateUsed,
    },
    billing_info: {
      payment_method_id:
        user.user_id === USER_PDLC
          ? "pm_1jbyzUyGRrBBY1dp8rWqpr0fp"
          : storePayment.paymentMethodid,
      price_total:
        storePayment.purchaseAmount === null ||
        isNaN(storePayment.purchaseAmount)
          ? 0
          : +storePayment.purchaseAmount,
    },
  };

  const result = await basicAuthRequest
    .request({
      url: "feed/createorder",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: bodyEDR,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return result;
}
