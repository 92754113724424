import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ButtonBack, PaymentStripe, ButtonEdit } from "../../../../components";
import {
  BRANDS_REGISTRABILITY_STUDY,
  BRANDS_REGISTRABILITY_STUDY_CONFIRMATION,
  DASHBOARD_SERVICES,
} from "../../../../constants/routes";
import classes from "./CheckOut.module.css";

const CheckOut = (props) => {
  const history = useHistory();
  const storeStudy = useSelector((state) => state.register);
  useEffect(() => {
    if (!storeStudy.isValid) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeStudy.isValid]);

  const cameBack = () => {
    props.history.replace(BRANDS_REGISTRABILITY_STUDY);
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <div className="container-modulo">
      <ButtonBack url={BRANDS_REGISTRABILITY_STUDY} text="REGRESAR" />

      <div className={classes.title}>
        <h2 className="subtitle">ESTUDIO DE REGISTRABILIDAD</h2>
      </div>

      <div className={classes.description}>
        <p>
          Proporciona la información para realizar el estudio de
          registrabilidad.
        </p>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE LA MARCA</div>
        <ButtonEdit onClick={cameBack} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>

      <div>
        <Elements stripe={stripePromise}>
          <PaymentStripe redirect={BRANDS_REGISTRABILITY_STUDY_CONFIRMATION} />
        </Elements>
      </div>

      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default CheckOut;
