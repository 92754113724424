import { all, takeLatest } from "redux-saga/effects";
import { userProfileActions } from "../slices/userProfile";

import { obrasActions } from "../slices/obras";
import { registerActions } from "../slices/brandRegistrabilityStudy";
import { tradeMarkActions } from "../slices/tradeMark";
import { musicalGroupActions } from "../slices/musicalGroup";
import { magazinesActions } from "../slices/magazines";
import { newspaperActions } from "../slices/newspaper";
import { publicationsActions } from "../slices/publications";
import { industrialDActions } from "../slices/industrialDesigns";
import { rightsActions } from "../slices/rights";
import {
  handlerUserGetServices,
  handlerGetServices,
  handleGetHolders,
  handleGetEntities,
} from "./handlers/profileHandler";
import { handlerCreateOrderObras } from "./handlers/obrasHandler";
import { handlerCreateBrandRegStudy } from "./handlers/brandRegStudyHandler";
import { handlerCreateTradeMark } from "./handlers/tradeMarkHandler";
import {
  handlerCreateMusicalGroupStudy,
  handlerGetRegStudiesMusicalGroup,
  handlerCreateMusicalGroup,
} from "./handlers/musicalGroupHandler";
import {
  handlerCreateMagazineStudy,
  handlerGetRegStudiesMagazine,
  handlerCreateMagazine,
} from "./handlers/magazineHandler.js";
import {
  handlerCreateNewspaperStudy,
  handlerGetRegStudiesNewspaper,
  handlerCreateNewspaper,
} from "./handlers/newspaperHandler";
import {
  handlerCreatePublicationStudy,
  handlerGetRegStudiesPublications,
  handlerCreatePublication,
} from "./handlers/publicationsHandler";
import { handlerCreateDesign } from "./handlers/industrialDesignHandler";
import {
  handleCreateRightsLicense,
  handleCreateRightsCession,
  handleCreateRightsRenewal,
} from "./handlers/rightsHandler";

/** Rights */
function* watcherCreateRightsLicense() {
  yield takeLatest(
    rightsActions.createRightsLicense.type,
    handleCreateRightsLicense
  );
}

function* watcherCreateRightsCession() {
  yield takeLatest(
    rightsActions.createRightsAssignee.type,
    handleCreateRightsCession
  );
}

function* watcherCreateRightsRenewal() {
  yield takeLatest(
    rightsActions.createRightsRenewal.type,
    handleCreateRightsRenewal
  );
}

/** Industrial Designs */
function* watcherCreateDesign() {
  yield takeLatest(
    industrialDActions.createIndustrialDesign.type,
    handlerCreateDesign
  );
}

/** Publications */
function* watcherPublicationsStudy() {
  yield takeLatest(
    publicationsActions.createPublicationsStudy.type,
    handlerCreatePublicationStudy
  );
}

function* watcherCreatePublication() {
  yield takeLatest(
    publicationsActions.createPublication.type,
    handlerCreatePublication
  );
}

function* watcherGetStdPublications() {
  yield takeLatest(
    publicationsActions.getRegistrabilityStudies.type,
    handlerGetRegStudiesPublications
  );
}

/** Newspaper */
function* watcherNewspaerStudy() {
  yield takeLatest(
    newspaperActions.createNewspaperStudy.type,
    handlerCreateNewspaperStudy
  );
}

function* watcherCreateNewspaer() {
  yield takeLatest(
    newspaperActions.createNewspaper.type,
    handlerCreateNewspaper
  );
}

function* watcherGetRegStudiesNewspaper() {
  yield takeLatest(
    newspaperActions.getRegistrabilityStudies.type,
    handlerGetRegStudiesNewspaper
  );
}

/** Magazines */
function* watcherMagazineStudy() {
  yield takeLatest(
    magazinesActions.createMagazineStudy.type,
    handlerCreateMagazineStudy
  );
}

function* watcherCreateMagazine() {
  yield takeLatest(magazinesActions.createMagazine.type, handlerCreateMagazine);
}

function* watcherGetRegStudiesMagazines() {
  yield takeLatest(
    magazinesActions.getRegistrabilityStudies.type,
    handlerGetRegStudiesMagazine
  );
}

/** Musical Groups */

function* watcherCreateMusicalGroupStudy() {
  yield takeLatest(
    musicalGroupActions.createMusicalGroupStudy.type,
    handlerCreateMusicalGroupStudy
  );
}

function* watcherCreateMusicalGroup() {
  yield takeLatest(
    musicalGroupActions.createMusicalGroup.type,
    handlerCreateMusicalGroup
  );
}

function* watcherGetRegStudiesMusicalGroup() {
  yield takeLatest(
    musicalGroupActions.getRegistrabilityStudies.type,
    handlerGetRegStudiesMusicalGroup
  );
}

/** Obras  */
function* watcherCreateOrderObras() {
  yield takeLatest(obrasActions.createOrderObras.type, handlerCreateOrderObras);
}

/**  registry study   */
function* watcherUserServices() {
  yield takeLatest(
    userProfileActions.getUserServices.type,
    handlerUserGetServices
  );
}

function* watcherCreateBrandStudy() {
  yield takeLatest(
    registerActions.brandRegistrabilityStudy.type,
    handlerCreateBrandRegStudy
  );
}

/** TradeMark */

function* watcherCreateTradeMark() {
  yield takeLatest(
    tradeMarkActions.createTradeMark.type,
    handlerCreateTradeMark
  );
}

/** Services of the company */
function* watcherServices() {
  yield takeLatest(userProfileActions.getServices.type, handlerGetServices);
}

function* watcherHolders() {
  yield takeLatest(userProfileActions.getHolders.type, handleGetHolders);
}

function* watcherEntities() {
  yield takeLatest(userProfileActions.getEntitiesObras.type, handleGetEntities);
}

export function* root() {
  yield all([
    watcherCreateRightsLicense(),
    watcherCreateRightsCession(),
    watcherCreateRightsRenewal(),
    watcherMagazineStudy(),
    watcherUserServices(),
    watcherServices(),
    watcherHolders(),
    watcherEntities(),
    watcherCreateOrderObras(),
    watcherCreateBrandStudy(),
    watcherCreateTradeMark(),
    watcherCreateMusicalGroup(),
    watcherCreateMusicalGroupStudy(),
    watcherGetRegStudiesMusicalGroup(),
    watcherGetRegStudiesMagazines(),
    watcherCreateMagazine(),
    watcherNewspaerStudy(),
    watcherGetRegStudiesNewspaper(),
    watcherCreateNewspaer(),
    watcherPublicationsStudy(),
    watcherCreatePublication(),
    watcherGetStdPublications(),
    watcherCreateDesign(),
  ]);
}
