import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import {
  ButtonBack,
  InputSimpleFloatLabel,
  BtnCallToAction,
  Button,
  ModalDrag,
  ErrorMessage,
} from "../../../components";
import { getFileName } from "../../../utils/scripts/file";
import { caculateAmountPrice } from "../../../utils/scripts/calculatePrice";
import { publicationsActions } from "../../../store/slices/publications";
import { userProfileActions } from "../../../store/slices/userProfile";
import { paymentActions } from "../../../store/slices/payment";
import {
  PUBLICATIONS_SERVICES,
  PUBLICATIONS_STUDY_CHECKOUT,
  PUBLICATIONS_STUDY_CONFIRMATION,
} from "../../../constants/routes";
import classes from "./RegStdPublications.module.css";
import { USER_PDLC } from "../../../constants";

const CHECKOUT = PUBLICATIONS_STUDY_CHECKOUT;
const URL_BACK = PUBLICATIONS_SERVICES;

const RegStdPublications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [uploadFile, setUploadFile] = useState(false);
  const [error, setError] = useState(false);
  const [userData, setUserData] = useState();

  const storePublications = useSelector((state) => state.publications);
  const storeUserProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
  }, []);

  useEffect(() => {
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getUserServices());
  }, [dispatch]);

  const getPrice = () => {
    const publications = storeUserProfile.services.services.filter(
      (item) => item.service_id === 14
    );
    const EdrMusicalGroups = publications[0].service_sub.filter(
      (item) => item.service_id === 15
    );

    const price =
      +EdrMusicalGroups[0].service_price +
      +EdrMusicalGroups[0].service_transact;

    const totalprice = caculateAmountPrice(price);
    return totalprice.toFixed(2);
  };

  const formPublications = useFormik({
    initialValues: {
      name: storePublications.brand_info.name,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("El campo nombre es requerido")
        .max(250, "El nombre debe contener máximo 250 caracteres")
        .matches(
          /^[,.@\-_' áéíóúÁÉÍÓÚñÑa-zA-Z0-9]+$/,
          "Nombre contiene caracteres inválidos"
        ),
    }),
    onSubmit: (values) => {
      if (storePublications.brand_info.file === "") {
        setError(true);
      } else {
        const price = getPrice();
        dispatch(publicationsActions.setBrandName({ name: values.name }));
        dispatch(paymentActions.setPurchaseAmount({ amount: price }));
        if (userData.user_id === USER_PDLC) {
          history.push(PUBLICATIONS_STUDY_CONFIRMATION);
        } else {
          history.push(CHECKOUT);
        }
      }
    },
  });

  const clearFileUploaded = () => {
    dispatch(publicationsActions.setFile({ file: "" }));
  };
  const closeUploadFile = () => {
    setUploadFile(false);
  };
  const openDragModal = () => {
    setError(false);
    setUploadFile(true);
  };

  return (
    <div className="container-modulo">
      {uploadFile && (
        <ModalDrag
          show={uploadFile}
          closed={closeUploadFile}
          scope="reg-std-publications"
          formatFile="zip"
        />
      )}
      <ButtonBack url={URL_BACK} text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">
          ESTUDIO DE REGISTRABILIDAD DE PUBLICACIONES PERIÓDICAS VÍA REDES DE
          CÓMPUTO
        </h2>
      </div>

      <div className="text-description">
        <p>
          Proporciona la información para realizar el estudio de
          registrabilidad.
        </p>
      </div>

      <div className="label-information">
        <span>INFORMACIÓN DE LA PUBLICACIÓN</span>
      </div>
      <div className="main-content-form">
        <form onSubmit={formPublications.handleSubmit}>
          <InputSimpleFloatLabel
            id="name"
            name="name"
            placeholder="NOMBRE DE LA PUBLICACIÓN"
            onChange={formPublications.handleChange}
            valid={typeof formPublications.errors.name === "undefined"}
            touched={formPublications.touched.name}
            defaultValue={storePublications.brand_info.name}
          />

          <ErrorMessage
            message={
              typeof formPublications.errors.name !== "undefined"
                ? formPublications.errors.name
                : ""
            }
          />

          <div className="updaload-file">
            {storePublications.brand_info.file === "" ? (
              <BtnCallToAction onClick={openDragModal} icon="faUpload">
                LOGOTIPO DE LA PUBLICACIÓN
              </BtnCallToAction>
            ) : (
              <BtnCallToAction
                type="showfile"
                onClick={clearFileUploaded}
                icon="faTimesCircle"
                className="btnGreen"
                fileName={getFileName(storePublications.brand_info.file)}
              >
                LOGOTIPO DE LA PUBLICACIÓN
              </BtnCallToAction>
            )}
            <p className="description-file">
              Esté archivo deberá ser formato ZIP.
            </p>
          </div>
          {error && <ErrorMessage message="Archivo requerido" />}
          <div className={classes.cntButton}>
            <Button
              disabled={!formPublications.isValid}
              type="submit"
              className={"btnConfirm"}
            >
              CONTINUAR
            </Button>
          </div>
        </form>
      </div>

      <div className="label-information">
        <span>INFORMACIÓN DE PAGO</span>
      </div>
      <div className="label-information">
        <span>CONFIRMACIÓN</span>
      </div>
    </div>
  );
};

export default RegStdPublications;
