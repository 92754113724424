import { call, put, select } from "redux-saga/effects";
import { userProfileActions } from "../../slices/userProfile";
import {
  requestUserGetServices,
  requestGetServices,
  requestGetEntity,
} from "../requests/profileRequest";


export function* handlerUserGetServices() {
  try {
    const response = yield call(requestUserGetServices, { service: "2" });
    yield put(
      userProfileActions.setUserServices({ userServices: response.data })
    );
  } catch (error) {
    console.log(error);
  }
}

export function* handlerGetServices() {
  try {
    const storeProfile = yield select((store) => store.userProfile);
    if (storeProfile.services.length === 0) {
      const response = yield call(requestGetServices);
      yield put(userProfileActions.setServices({ services: response.data }));
    }
  } catch (error) {
    console.log("profileHandler-getServices", error);
  }
}

export function* handleGetHolders() {
  try {
    const titular = yield call(requestGetEntity, { typeId: "1" });
    yield put(userProfileActions.setHolders({ holders: titular.data }));
  } catch (error) {
    console.log(error);
  }
}

export function* handleGetEntities() {
  try {
    const titular = yield call(requestGetEntity, { typeId: "1" });
    const author = yield call(requestGetEntity, { typeId: "2" });
    const coauthor = yield call(requestGetEntity, { typeId: "3" });
    const licensee = yield call(requestGetEntity, { typeId: "4" });
    const assignee = yield call(requestGetEntity, { typeId: "5" });
    yield put(userProfileActions.setHolders({ holders: titular.data }));
    yield put(userProfileActions.setAuthors({ authors: author.data }));
    yield put(userProfileActions.setCoauthors({ coauthors: coauthor.data }));
    yield put(userProfileActions.setLicensee({ licensee: licensee.data }));
    yield put(userProfileActions.setAssignee({ assignee: assignee.data }));
  } catch (error) {
    console.log("Error handleGetEntites saga", error);
  }
}
