import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { RowRightSmall, SignOff } from "../../../../assets/images/icons/icons";
import "./profile-menu.css";
import { useAuth } from "../../../../context/auth";
import { BackDropFull, Modal, Button } from "../../../../components";
import { DELETE_ACCOUNT } from "../../../../constants/atualcanceApi";
import basicAuthRequest from "../../../../utils/axios/basicAuthRequest";

const ProfileMenu = (props) => {
  const { signOut } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [classesModal, setClassesModal] = useState([
    "profile-menu",
    "close-profile",
  ]);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (props.open) {
      setClassesModal(["profile-menu", "open-profile"]);
    } else {
      setClassesModal(["profile-menu", "close-profile"]);
    }
  }, [props.open]);

  const signOutHandler = () => {
    signOut();
  };

  const deleteAcount = () => {
    setClassesModal(["profile-menu", "close-profile"]);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const cancelAccount = async () => {
    console.log(user);
    const response = await basicAuthRequest({
      url: `${DELETE_ACCOUNT}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        user_id: user.user_id,
        username: user.email,
      },
    });
    if (!response.error) {
      signOut();
    } else {
      signOut();
    }
  };

  return (
    <>
      <Modal show={openModal} closed={closeModal}>
        <div className="modal-head">
          <h1 className="modal-title">¿Realmente deseas eliminar la cuenta?</h1>
        </div>
        <div className="content-buttons-modal">
          <div>
            <Button onClick={closeModal} className={"btnCancel"}>
              CANCELAR
            </Button>
          </div>
          <div>
            <Button onClick={cancelAccount} className={"btnConfirm"}>
              ACEPTAR
            </Button>
          </div>
        </div>
      </Modal>
      <BackDropFull show={openModal} />
      <div className={classesModal.join(" ")}>
        <div className="container-menu-profile">
          <div className="menu-item-profile user-name" onClick={props.onClick}>
            <NavLink to="/">{user.full_name && user.full_name}</NavLink>
          </div>
          <div className="menu-item-profile" onClick={props.onClick}>
            <NavLink to="/perfil">MI PERFIL</NavLink>
            <img src={RowRightSmall} alt="next" />
          </div>
          <div
            className="menu-item-profile"
            style={{ cursor: "pointer" }}
            onClick={signOutHandler}
          >
            CERRAR SESIÓN
            <img src={SignOff} alt="next" />
          </div>
          <div className="menu-item-profile" onClick={deleteAcount}>
            ELIMINAR CUENTA
            <img src={RowRightSmall} alt="next" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileMenu;
