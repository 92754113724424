import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  MUSICAL_GROUP_STUDY,
  MUSICAL_GROUP_STUDY_CONFIRMATION,
  DASHBOARD_SERVICES,
} from "../../../../constants/routes";
import { ButtonBack, PaymentStripe, ButtonEdit } from "../../../../components";

const EDIT_INFO = MUSICAL_GROUP_STUDY;
const CONFIMATION = MUSICAL_GROUP_STUDY_CONFIRMATION;

const CheckOutStdMusicalGroup = (props) => {
  const urlBack = EDIT_INFO;
  const history = useHistory();
  const strMusicalGroup = useSelector((state) => state.musicalGroup);

  useEffect(() => {
    if (strMusicalGroup.brand_info.name === "") {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, strMusicalGroup.brand_info.name]);

  const cameBack = () => {
    props.history.replace(urlBack);
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <div className="container-modulo">
      <ButtonBack url={urlBack} text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">
          ESTUDIO DE REGISTRABILIDAD DE NOMBRE DE GRUPOS MUSICALES
        </h2>
      </div>
      <div className="text-description">
        <p>
          Proporciona la información para realizar el estudio de
          registrabilidad.
        </p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DEL GRUPO MUSICAL</div>
        <ButtonEdit onClick={cameBack} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>

      <div>
        <Elements stripe={stripePromise}>
          <PaymentStripe redirect={CONFIMATION} />
        </Elements>
      </div>

      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default CheckOutStdMusicalGroup;
