import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classes from './InputFloatLabel.module.css';

const InputFloatLabel = (props) => {
  const [focused, setFocused] = useState(props.value !== '');

  const handleOnFocus = () => {
    setFocused(true);
  }

  const handleOnBlur = (event) => {
    if (event.target.value === '') {
      setFocused(false);
    }
  }
  const handleOnChange = (event, fnc) => {
    event.target.value === ''
      ? setFocused(false)
      : setFocused(true);
    fnc(event);
  }

  return (
    <div className={classes.groupForm}>
      <label
        htmlFor={props.id}
        className={`${classes.floatLabel} ${focused ? classes.inputActive : null}`}
      >
        {props.placeholder}
      </label>

      <input
        id={props.id}
        name={props.name}
        type={props.type || 'text'}
        maxLength={props.maxLength || ''}
        className={`${classes.inputForm} ${props.className} ${props.valid ? classes.invalid : null}`}
        onFocus={handleOnFocus}
        onBlurCapture={handleOnBlur}
        onChange={event => handleOnChange(event, props.onChange)}
        value={props.value}
      />
    </div>
  )
};

InputFloatLabel.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  valid: PropTypes.bool
}

export default React.memo(InputFloatLabel);