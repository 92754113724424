import { call, put, select } from "redux-saga/effects";
import { replace } from "connected-react-router";

import { paymentActions } from "../../slices/payment";
import { magazinesActions } from "../../slices/magazines";
import {
  requestCreateMagazineStudy,
  requestGetStudiesMaagazines,
  requestCreateMagazine,
} from "../requests/magazinesRequest";
import { TICKECT } from "../../../constants/routes";

export function* handlerCreateMagazine() {
  try {
    const storeMagazine = yield select((store) => store.magazines);
    const storePayment = yield select((store) => store.payment);
    const storeEntity = yield select((store) => store.entity);

    const response = yield call(
      requestCreateMagazine,
      storeMagazine,
      storePayment,
      storeEntity
    );

    if (response.status === 200) {
      yield put(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      yield put(replace(TICKECT));
      yield put(paymentActions.cleanStorePayment());
      yield put(magazinesActions.cleanStorageMagzine());
    } else {
      // TODO: generar pantalla de error
    }
  } catch (error) {
    console.log("Error handlerCreateMusicalGroup" + error);
  }
}

export function* handlerCreateMagazineStudy() {
  try {
    const storeMagazine = yield select((store) => store.magazines);
    const storePayment = yield select((store) => store.payment);
    const response = yield call(
      requestCreateMagazineStudy,
      storeMagazine,
      storePayment
    );

    if (response.status === 200) {
      yield put(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      yield put(replace(TICKECT));
      yield put(paymentActions.cleanStorePayment());
      yield put(magazinesActions.cleanStorageMagzine());
    } else {
      // TODO: generar pantalla de error
    }
  } catch (error) {
    console.log("Errow HandlerCreateMusicalGroupStudy");
  }
}

/**
 * Get RegStudiesMusicalGroup
 */
export function* handlerGetRegStudiesMagazine() {
  try {
    const studies = yield call(requestGetStudiesMaagazines, { service: "9" });
    if (studies.data.length) {
      yield put(magazinesActions.setRegStudies({ regStudies: studies.data }));
    } else {
      //yield put(replace("/revistas/servicios"));
    }
  } catch (error) {
    console.log(error);
  }
}
