import { call, put, select } from "redux-saga/effects";
import { replace } from "connected-react-router";

import { paymentActions } from "../../slices/payment";
import {
  requestCreateMusicalGroupStudy,
  requestGetStudiesMusicalGroup,
  requestCreateMusicalGroup,
} from "../requests/musicalGroupsRequest";
import { musicalGroupActions } from "../../slices/musicalGroup";
import { TICKECT } from "../../../constants/routes";

export function* handlerCreateMusicalGroup() {
  try {
    const storeMusicalGroup = yield select((store) => store.musicalGroup);
    const storePayment = yield select((store) => store.payment);
    const storeEntity = yield select((store) => store.entity);

    const response = yield call(
      requestCreateMusicalGroup,
      storeMusicalGroup,
      storePayment,
      storeEntity
    );

    if (response.status === 200) {
      yield put(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      yield put(replace(TICKECT));
      yield put(musicalGroupActions.clearStoreMusicalGroup())
    } else {
      // TODO: generar pantalla de error
    }
  } catch (error) {
    console.log("Error handlerCreateMusicalGroup" + error);
  }
}

export function* handlerCreateMusicalGroupStudy() {
  try {
    const storeMusicalGroup = yield select((store) => store.musicalGroup);
    const storePayment = yield select((store) => store.payment);
    const response = yield call(
      requestCreateMusicalGroupStudy,
      storeMusicalGroup,
      storePayment
    );

    if (response.status === 200) {
      yield put(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      yield put(replace(TICKECT));
      yield put(musicalGroupActions.clearStoreMusicalGroup())
    } else {
      // TODO: generar pantalla de error
    }
  } catch (error) {
    console.log("Errow HandlerCreateMusicalGroupStudy");
  }
}

/**
 * Get RegStudiesMusicalGroup
 */
export function* handlerGetRegStudiesMusicalGroup() {
  try {
    const studies = yield call(requestGetStudiesMusicalGroup, { service: "6" });
    if (studies.data.length) {
      yield put(
        musicalGroupActions.setRegStudies({ regStudies: studies.data })
      );
    } else {
      // yield put(replace("/grupos-musicales/servicios"));
    }
  } catch (error) {
    console.log(error);
  }
}
