import React from 'react';
import PropTypes from 'prop-types';
import classes from './Button.module.css';

const Button = props => {
  return (
    <button
      type={props.type || 'button'}
      className={`${classes.button} ${classes[props.className]} ${props.disabled && classes['disabled']}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

export default React.memo(Button);