import defaultUser from "../utils/default-user";
import { SEND_CONFIRMATION } from "../constants/atualcanceApi";
import axios from "axios";

export async function validateUser(email) {
  await axios
    .request({
      url: process.env.REACT_APP_URL_BASE + SEND_CONFIRMATION,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: { email },
    })
    .then((result) => console.log(result))
    .catch((error) => console.log(error));
}

export async function signIn(email, password) {
  try {
    // Send request
    // console.log(email, password);

    return {
      isOk: true,
      data: defaultUser,
    };
  } catch {
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}

export async function getUser() {
  return {
    isOk: true,
    data: null,
  };
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to create account",
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to change password",
    };
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to reset password",
    };
  }
}
