import basicAuthRequest from "../../../utils/axios/basicAuthRequest";
import { USER_PDLC } from "../../../constants";

const getCoauthorList = (storeObras) => {
  let coauthor_id = { ...storeObras.coauthor_id };
  let coauthor_info = { ...storeObras.coauthor_info };
  let id_list = [],
    info_list = [];

  for (const id in coauthor_id) {
    console.log("ID Coauthor", coauthor_id[id]);
    if (coauthor_id[id] !== -1) {
      id_list.push(coauthor_id[id]);
    }
  }

  for (const id in coauthor_info) {
    console.log("Data Coauthor", coauthor_info[id]);
    if (!coauthor_info[id].deleted && coauthor_info[id].type !== -1) {
      info_list.push(coauthor_info[id]);
    }
  }

  return {
    coauthor_ids: id_list,
    coauthor_info: info_list,
  };
};

export function requestCreateOrderObras(storeObras, storePayment) {
  const user = JSON.parse(localStorage.getItem("user"));
  let coauthor = getCoauthorList(storeObras);
  const bodyObras = {
    user_id: user.user_id,
    service_id: 4,
    brand_info: {
      name: storeObras.brandInfo.name,
      type: storeObras.brandInfo.type,
      description: storeObras.brandInfo.description,
      file: storeObras.brandInfo.file,
    },
    owner_id: storeObras.isRegisteredHolder ? +storeObras.owner_id : null,
    owner_info: !storeObras.isRegisteredHolder
      ? { ...storeObras.owner_info }
      : {
          type: null,
          name: "",
          rfc: "",
          curp: "",
          street: "",
          ext_number: "",
          int_number: "",
          email: "",
          phone: "",
          state: "",
          city: "",
          postal_code: "",
          neighborhood: "",
          file: "",
        },
    is_author_same_owner: storeObras.is_author_same_owner,
    author_id: storeObras.authorSelect === "1" ? +storeObras.author_id : null,
    author_info:
      storeObras.authorSelect === "2"
        ? { ...storeObras.author_info }
        : {
            type: null,
            name: "",
            rfc: "",
            curp: "",
            street: "",
            ext_number: "",
            int_number: "",
            email: "",
            phone: "",
            state: "",
            city: "",
            postal_code: "",
            neighborhood: "",
            file: "",
          },
    coauthor_id: coauthor.coauthor_ids,
    coauthor_info: coauthor.coauthor_info,
    billing_info: {
      payment_method_id:
        user.user_id === USER_PDLC
          ? "pm_1jbyzUyGRrBBY1dp8rWqpr0fp"
          : storePayment.paymentMethodid,
      price_total: +storePayment.purchaseAmount,
    },
  };

  try {
    return basicAuthRequest
      .request({
        url: "feed/createorder",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: bodyObras,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => err);
  } catch (error) {
    console.log("Error payment", error);
  }
}
