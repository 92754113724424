import CurrencyFormat from "react-currency-format";
import PropTypes from "prop-types";

import { CURRENCY } from "../../constants";

const AtaCurrencyFormat = ({ value, currency, showCurrency }) => (
  <CurrencyFormat
    value={Number(value).toFixed(2)}
    displayType="text"
    prefix="$"
    decimalSeparator="."
    thousandSeparator=","
    renderText={(val) => (
      <>
        {val} {showCurrency ? <span className="currency"> {currency} </span> : ""}
      </>
    )}
  />
);

AtaCurrencyFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  showCurrency: PropTypes.bool,
};

AtaCurrencyFormat.defaultProps = {
  value: undefined,
  currency: CURRENCY.MXN,
  showCurrency: true,
};

export default AtaCurrencyFormat;
