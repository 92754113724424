import PropTypes from "prop-types";
import { BtnCallToAction } from "../../../components";
import { AtaCurrencyFormat } from "../../../components/Common";
import classes from "./MyRequest.module.css";

const RequestDetail = ({ requestDetail }) => {
  return (
    <div>
      <div className="parting-line" />
      <div className={classes.headerSectionComponent}>
        <h2 className="subtitle-section">INFORMACIÓN DE LA SOLICITUD</h2>
      </div>

      <div className={classes.sectionInfo}>
        <div className={classes.infoBrand}>
          <div className={classes.itemInfo}>
            <div className={classes.itemHead}>FECHA DE CREACIÓN</div>
            <div className={classes.itemData}>
              {requestDetail.creation_date}
            </div>
          </div>

          <div className={classes.itemInfo}>
            <div className={classes.itemHead}>
              CLAVE DE CONFIRMACIÓN DE LA SOLICITUD
            </div>
            <div className={classes.itemData}>{requestDetail.order_number}</div>
          </div>
        </div>

        <div className={classes.infoBrand}>
          <div className={classes.itemInfo}>
            <div className={classes.itemHead}>FECHA DE PRESENTACIÓN</div>
            <div className={classes.itemData}>
              {requestDetail.presentation_date
                ? requestDetail.presentation_date
                : "--"}
            </div>
          </div>

          <div className={classes.itemInfo}>
            <div className={classes.itemHead}>NÚMERO DE SOLICITUD</div>
            <div className={classes.itemData}>
              {requestDetail.request_number
                ? requestDetail.request_number
                : "--"}
            </div>
          </div>
        </div>
        <div className={classes.infoBrand}>
          <div className={classes.itemInfo}>
            <div className={classes.itemHead}>MONTO PAGADO</div>
            <div className={classes.itemData}>
              <AtaCurrencyFormat value={requestDetail.paid_amount} />
            </div>
          </div>
        </div>
      </div>
      {requestDetail.request_file && (
        <div className={classes.fileItem}>
          <div className={classes.itemHead}>
            DOCUMENTO DE SOLICITUD DE REGISTRO
          </div>
          <BtnCallToAction
            onClick={() => {}}
            icon="faDownload"
            className="btnDownload"
          >
            {requestDetail.request_file}
          </BtnCallToAction>
        </div>
      )}
    </div>
  );
};

RequestDetail.prototype = {
  requestDetail: PropTypes.array.isRequired,
};

export default RequestDetail;
