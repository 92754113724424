import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import classes from "./FileModal.module.css";

const FileModal = (props) => {
  return (
    <div
      className={`${classes.backDrop} ${
        props.show ? classes.openModal : classes.closeModal
      }`}
    >
      <div
        className={`${classes.customModal} ${
          props.size === "bigModal" ? classes.customModalBigger : ""
        }`}
      >
        <div className={classes.close} onClick={props.close}>
          <FontAwesomeIcon icon={faTimes} className={classes.btnIcon} />
        </div>
        {props.children}
      </div>
    </div>
  );
};

FileModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func,
  size: PropTypes.string,
};

export default React.memo(FileModal);
