import React, {useState} from 'react';

import classes from './EntityTemplate.module.css';
import PropTypes from 'prop-types';


const EntityTemplate = props => {
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  }

  return (
    <div className={classes.containerCollap}>
      <button className={`${classes.collapsible} ${ collapsed && classes.active} `} 
        onClick={handleCollapsed}>{props.title}</button>
      <div className={classes.content} style={collapsed ? { maxHeight: '0'}: {}}>
        {props.children}
      </div>
    </div>
  )
} 

EntityTemplate.propTypes = {
  title: PropTypes.string
}

export default React.memo(EntityTemplate);