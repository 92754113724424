import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  ButtonBack,
  InputSimpleFloatLabel,
  InputSelect,
  TextArea,
  BtnCallToAction,
  InputRadio,
  Button,
  ModalDrag,
  HolderForm,
  CoauthorTemplate,
} from "../../../components";
import { DASHBOARD_SERVICES, OBRAS_CHECKOUT, OBRAS_REGISTER_CONFIRMATION } from "../../../constants/routes";
import { catObras } from "../../../utils/cat-obras";
import { obrasActions } from "../../../store/slices/obras";
import { paymentActions } from "../../../store/slices/payment";
import { userProfileActions } from "../../../store/slices/userProfile";
import { getFileName } from "../../../utils/scripts/file";
import { caculateAmountPrice } from "../../../utils/scripts/calculatePrice";
import { services as SERVICES } from "../../../constants/services";
import classes from "./RegistrationObras.module.css";
import { USER_PDLC } from "../../../constants";

const Registration = (props) => {
  const dispatch = useDispatch();
  const storeObras = useSelector((state) => state.obras);
  const storeUserProfile = useSelector((state) => state.userProfile);

  const [dragModal, setDragModal] = useState(false);
  const [listCoauthorItems, setListCoauthorImtes] = useState(null);
  const [validLastCoauthor, setValidLastCoauthor] = useState(true);
  const [userData, setUserData] = useState();

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
  }, []);

  const openDragModal = () => setDragModal(true);
  const closeDragModal = () => setDragModal(false);

  const handleChangeName = (event) => {
    dispatch(obrasActions.setName({ name: event.target.value }));
  };
  const handleChangeType = (event) => {
    dispatch(obrasActions.setType({ type: event.target.value }));
  };
  const handleChangeDesc = (event) => {
    dispatch(obrasActions.setDescription({ description: event.target.value }));
  };
  const clearFileUploaded = () => {
    dispatch(obrasActions.setFile({ file: "" }));
  };
  const handleChangeRegisterHolder = (event) => {
    dispatch(
      obrasActions.setIsregisteredHolder({
        isRegisteredHolder: event.target.value === "si",
      })
    );
    dispatch(obrasActions.setOwnerId({ owner_id: "-1" }));
  };
  const hanldleChangeTitularSelect = (event) => {
    dispatch(obrasActions.setOwnerId({ owner_id: event.target.value }));
  };
  const handleIdAuthorSelect = (event) => {
    dispatch(obrasActions.setAuthorInfoId({ author_id: event.target.value }));
  };
  const handleAuthorSelect = (event) => {
    dispatch(
      obrasActions.setAuthorSelect({ authorSelect: event.target.value })
    );
    dispatch(obrasActions.setAuthorInfoId({ author_id: "-1" }));
  };

  const getPrice = () => {
    const obras = storeUserProfile.services.services.filter(
      (item) => item.service_id === SERVICES.OBRAS
    );

    const price = +obras[0]?.service_price + +obras[0]?.service_transact;

    const totalprice = caculateAmountPrice(price);
    return totalprice.toFixed(2);
  };

  const obrasNext = () => {
    const amount = getPrice();
    dispatch(
      paymentActions.setPurchaseAmount({ amount: Number(amount).toFixed(2) })
    );
    if (userData.user_id === USER_PDLC) {
      props.history.replace(OBRAS_REGISTER_CONFIRMATION);
    } else {
      props.history.replace(OBRAS_CHECKOUT);
    }
  };

  const handleAddCoautor = () => {
    dispatch(
      obrasActions.setCoAuthorSelect({
        coAuthorSelect: 1,
        index: storeObras.coauthor_info.length,
      })
    );
    dispatch(
      obrasActions.setCoAuthorInfoId({
        coauthor_id: -1,
        index: storeObras.coauthor_info.length,
      })
    );
  };

  useEffect(() => {
    if (
      typeof storeObras.coauthor_info[storeObras.coauthor_info.length - 1] ===
      "object"
    ) {
      setValidLastCoauthor(
        storeObras.coauthor_info[storeObras.coauthor_info.length - 1].isValid
      );
    } else {
      setValidLastCoauthor(true);
    }
  }, [storeObras.coauthor_info]);

  useEffect(() => {
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getUserServices());
    dispatch(userProfileActions.getEntitiesObras());
  }, [dispatch]);

  useEffect(() => {
    let i = 0;
    const listCoauthorItem = storeObras.coauthor_info.map(() => {
      i++;
      return (
        <CoauthorTemplate
          key={i}
          index={i}
          holders={storeUserProfile.coauthors}
          showDelete={i === storeObras.coauthor_info.length}
        />
      );
    });
    setListCoauthorImtes(listCoauthorItem);
  }, [storeObras.coauthor_info, storeUserProfile.coauthors]);

  return (
    <div className="container-modulo">
      {dragModal && (
        <ModalDrag
          show={dragModal}
          closed={closeDragModal}
          scope="obras-register"
          formatFile="zip"
        />
      )}
      <ButtonBack url={DASHBOARD_SERVICES} text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">REGISTRO DE OBRAS</h2>
      </div>
      <div className="text-description">
        <p>Protege tu Obra de manera práctica y sencilla</p>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DE LA OBRA</div>
      </div>

      <div className="container-desc-controls">
        <div className="form-informacion-marca">
          <div>
            <InputSelect
              id="typeBrand"
              name="typeBrand"
              placeholder="- TIPO DE OBRA -"
              onChange={handleChangeType}
              fieldIdOption="id"
              options={catObras}
              value={storeObras.brandInfo.type}
            />
          </div>
          <div>
            <InputSimpleFloatLabel
              id="name-obra"
              name="name-obra"
              placeholder="NOMBRE DE LA OBRA"
              onChange={handleChangeName}
              defaultValue={storeObras.brandInfo.name}
              valid={storeObras.brandInfo.name !== ""}
            />
          </div>
        </div>

        <TextArea
          id="obradesc"
          name="obradec"
          placeholder="DESCRIPCIÓN DE LA OBRA"
          onChange={handleChangeDesc}
          valid={storeObras.brandInfo.description !== ""}
        />
      </div>
      <div className="updaload-file">
        {storeObras.brandInfo.file === "" ? (
          <BtnCallToAction onClick={openDragModal} icon="faUpload">
            ARCHIVO DE LA OBRA
          </BtnCallToAction>
        ) : (
          <BtnCallToAction
            type="showfile"
            onClick={clearFileUploaded}
            icon="faTimesCircle"
            className="btnGreen"
            fileName={getFileName(storeObras.brandInfo.file)}
          >
            ARCHIVO DE LA OBRA
          </BtnCallToAction>
        )}
        <p className="description-file">Esté archivo deberá ser formato PDF.</p>
      </div>

      <div className={classes.containerModule}>
        <div className={classes.contentRadioSelect}>
          <h5 className={classes.titleModule}>TITULAR DE LA OBRA</h5>
          <InputRadio
            id="si"
            name="isRegisteredHolder"
            value="si"
            onChange={handleChangeRegisterHolder}
            label="SELECCIONAR UN TITULAR DE MI LISTA DE TITULARES"
            checked={storeObras.isRegisteredHolder}
          />
          {storeObras.isRegisteredHolder && (
            <InputSelect
              id="brands"
              name="brands"
              placeholder="- ELIGE UN TITULAR -"
              options={storeUserProfile.holders}
              fieldIdOption="id"
              value={storeObras.owner_id}
              onChange={hanldleChangeTitularSelect}
            />
          )}
          <InputRadio
            id="no"
            name="isRegisteredHolder"
            value="no"
            onChange={handleChangeRegisterHolder}
            label="REGISTRAR NUEVO TITULAR"
            checked={!storeObras.isRegisteredHolder}
          />
        </div>
        {!storeObras.isRegisteredHolder && (
          <div className={classes.contentForm}>
            <HolderForm
              entity="titular-obra"
              recordTypeFile="obras-owner-info"
              textTypeEntity="- TIPO DE TITULAR -"
            />
          </div>
        )}
      </div>
      <div className={classes.containerPLine}>
        <div className="parting-line" />
      </div>
      <div className={classes.contentRadioSelect}>
        <h5 className={classes.titleModule}>AUTOR DE LA OBRA</h5>
        <InputRadio
          id="autorList"
          name="authorSelect"
          value="1"
          onChange={handleAuthorSelect}
          label="SELECCIONAR UN AUTOR DE MI LISTA DE AUTORES"
          checked={storeObras.authorSelect === "1"}
        />
        {storeObras.authorSelect === "1" && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="- ELIGE UN AUTOR -"
            options={storeUserProfile.authors}
            fieldIdOption="id"
            value={storeObras.author_id}
            onChange={handleIdAuthorSelect}
          />
        )}
        <InputRadio
          id="autorNew"
          name="authorSelect"
          value="2"
          onChange={handleAuthorSelect}
          label="REGISTRAR UN NUEVO AUTOR"
          checked={storeObras.authorSelect === "2"}
        />
        <InputRadio
          id="autorSame"
          name="authorSelect"
          value="3"
          onChange={handleAuthorSelect}
          label="EL AUTOR ES EL MISMO QUE EL TITULAR DE LA OBRA"
          checked={storeObras.authorSelect === "3"}
        />
      </div>
      {storeObras.authorSelect === "2" && (
        <div className={classes.contentForm}>
          <HolderForm
            entity="author-obra"
            recordTypeFile="obras-author-info"
            textTypeEntity="- TIPO DE AUTOR -"
          />
        </div>
      )}
      <div className={classes.containerPLine}>
        <div className="parting-line" />
      </div>

      {listCoauthorItems}

      <div className={classes.descriptionSub}>
        <p>
          Proporciona la información complementaria requerida para realizar el
          registro del nombre de tu marca.
        </p>
      </div>
      {storeObras.coauthor_info.length < 4 && validLastCoauthor && (
        <div className="add-controls">
          <BtnCallToAction onClick={handleAddCoautor} icon="faPlusCircle">
            AGREGAR CO-AUTOR
          </BtnCallToAction>
        </div>
      )}

      <div className={classes.contentButton}>
        <div className={classes.btnNex}>
          <Button
            className="btnConfirm"
            onClick={obrasNext}
            disabled={!storeObras.isValidRegisterObras}
          >
            CONTINUAR
          </Button>
        </div>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default Registration;
