import { useState } from "react";
import { useParams } from "react-router-dom";
import ImpedimentData from "./ImpedimentData";
import ImpedimentCheckOut from "./ImpedimentCheckOut";
import ImpedimentConfirmation from "./ImpedimentConfirmation";
import useImpediments from "./useImpediments";

const screens = ["ImpedimentData", "CheckOut", "Confirmation"];

const Impediments = () => {
  let { orden } = useParams();
  const [currentScreen, setCurrentScreen] = useState(screens[0]);
  const { loading, price, savePayment, impediment, saveError, saveLoading } =
    useImpediments(orden);

  const handleEditInfo = () => {
    setCurrentScreen(screens[0]);
  };

  const handleCheckOut = () => {
    setCurrentScreen(screens[1]);
  };

  const handleConfirmation = () => {
    setCurrentScreen(screens[2]);
  };

  const screenObj = {
    [screens[0]]: (
      <ImpedimentData
        loading={loading}
        impediment={impediment}
        handleSubmit={handleCheckOut}
        price={price}
      />
    ),
    [screens[1]]: (
      <ImpedimentCheckOut
        handleSubmit={handleConfirmation}
        handleEditInfo={handleEditInfo}
      />
    ),
    [screens[2]]: (
      <ImpedimentConfirmation
        impediment={impediment}
        price={price}
        handleSubmit={savePayment}
        handleEditInfo={handleEditInfo}
        handleCheckOut={handleCheckOut}
        loading={saveLoading}
        error={saveError}
      />
    ),
  };
  return (
    <div className="container-modulo">
      <div className="section-title">
        <h2 className="subtitle">
          PAGO DE REQUERIMINETO POR <br /> IMPEDIMENTO DE REGISTRO
        </h2>
      </div>
      <div className="text-description">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
          finibus porta sapien ut accumsan. Morbi vel nisi consequat.
        </p>
      </div>

      {screenObj[currentScreen]}
    </div>
  );
};

export default Impediments;
