import { createSlice } from "@reduxjs/toolkit";

const initialMusicalGroupState = {
  user_id: null,
  isNewBrand: false,
  service_id: 6,
  regSudies: [],
  studyBelongsId: -1,
  isRegisteredHolder: true,
  titularId: -1,
  brand_info: {
    name: "",
    file: "",
  },
  billing_info: {
    payment_method_id: "",
    price_total: null,
  },
  /** Valid Registro Grupo Musical */
  isValidRGM: false,
};

const validateRGM = (state) => {
  let valid = true;

  if (state.brand_info.name === "") {
    valid = false;
  }

  if (state.isRegisteredHolder && state.titularId === "-1") {
    valid = false;
  }

  return valid;
};

const musicalGroupSlice = createSlice({
  name: "musicalGroup",
  initialState: initialMusicalGroupState,
  reducers: {
    /** Save new registrability studies musicalGroup */
    createMusicalGroupStudy() {},
    /** Save new register musical Group */
    createMusicalGroup() {},
    /** Obtain registrability studies  */
    getRegistrabilityStudies() {},

    clearStoreMusicalGroup(state) {
      state.brand_info = { ...initialMusicalGroupState.brand_info };
      state.isNewBrand = false;
      state.studyBelongsId = -1;
      state.isRegisteredHolder = true;
      state.titularId = -1;
      state.isValidRGM = false;
    },
    setTitularId(state, action) {
      state.titularId = action.payload.titularId;
      state.isValidRGM = validateRGM(state);
    },
    setIsRegisteredHolder(state, action) {
      state.isRegisteredHolder = action.payload.isRegisteredHolder;
      state.isValidRGM = validateRGM(state);
    },
    setRegStudies(state, action) {
      state.regSudies = action.payload.regStudies;
    },
    setStudyBelongsId(state, action) {
      state.studyBelongsId = action.payload.studyId;
    },
    setIsNewBrand(state, action) {
      state.isNewBrand = action.payload.isNewBrand;
    },
    setBrandName(state, action) {
      state.brand_info.name = action.payload.name;
      state.isValidRGM = validateRGM(state);
    },
    setFile(state, action) {
      state.brand_info.file = action.payload.file;
    },
    setServiceId(state, action) {
      state.service_id = action.payload.service_id;
    },
  },
});

export const musicalGroupActions = musicalGroupSlice.actions;

export default musicalGroupSlice;
