import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { RowBack } from '../../../../assets/images/icons/icons';
import classes from './ButtonBack.module.css';

const ButtonBack = props => {
  let history = useHistory();

  const handleClick = (url) => {
    history.push(url);
  }

  return (
    <div
      className={classes.linkBack}
      onClick={() => { handleClick(props.url) }}>
      <img src={RowBack} alt="regresar" className={classes.iconBack} />{props.text}
    </div>
  );
}

ButtonBack.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default ButtonBack;
