import * as Pages from "./pages";
import * as Routes from "./constants/routes";

const routes = [
  {
    id: "servicios",
    path: Routes.DASHBOARD_SERVICES,
    component: Pages.Dashboard,
  },
  {
    id: "servicios",
    path: Routes.BRANDS_SERVICES,
    component: Pages.Servicios,
  },
  {
    id: "estudio-registrabilidad",
    path: Routes.BRANDS_REGISTRABILITY_STUDY,
    component: Pages.EstudioRegistrabilidad,
  },
  {
    id: "checkout-estudio-registrabilidad",
    path: Routes.BRANDS_REGISTRABILITY_STUDY_CHECKOUT,
    component: Pages.CheckOutEstudioRegistrabilidad,
  },
  {
    id: "confirmation-estudio-registrabilidad",
    path: Routes.BRANDS_REGISTRABILITY_STUDY_CONFIRMATION,
    component: Pages.ConfirmationEstudioRegistrabilidad,
  },
  {
    id: "registro-marca",
    path: Routes.BRANDS_SELECTION_REGISTERED_MARK,
    component: Pages.RegistroMarca,
  },
  {
    id: "register-trademark",
    path: Routes.BRANDS_REGISTER_BRAND,
    component: Pages.RegisterTradeMark,
  },
  {
    id: "titular-marca",
    path: Routes.BRANDS_TITULAR,
    component: Pages.HolderSelect,
  },
  {
    id: "checkout-trade-mark",
    path: Routes.BRANDS_REGISTER_CHECKOUT,
    component: Pages.CheckOutTradeMark,
  },
  {
    id: "confirmation-trade-mark",
    path: Routes.BRANDS_REGISTER_CONFIRMATION,
    component: Pages.ConfirmationTradeMark,
  },
  {
    id: "perfil",
    path: Routes.PROFILE,
    component: Pages.Profile,
  },
  {
    id: "requests",
    path: Routes.MY_REQUEST,
    component: Pages.MyRequest,
  },
  {
    id: "business-name",
    path: Routes.BUSINESS_NAME,
    component: Pages.BussinesName,
  },
  {
    id: "form-business-name",
    path: Routes.BUSINESS_NAME_FORM,
    component: Pages.FormBussinesName,
  },
  {
    id: "change-password",
    path: Routes.PROFILE_CHANGE_PASS,
    component: Pages.ChangePassword,
  },
  {
    id: "receipt",
    path: Routes.TICKECT,
    component: Pages.Receipt,
  },
  {
    id: "regiter-obras",
    path: Routes.OBRAS_REGISTER,
    component: Pages.RegisterObras,
  },
  {
    id: "checkoutObras",
    path: Routes.OBRAS_CHECKOUT,
    component: Pages.CheckOutObras,
  },
  {
    id: "confirmationObras",
    path: Routes.OBRAS_REGISTER_CONFIRMATION,
    component: Pages.ConfirmationObras,
  },
  {
    id: "musical-group-services",
    path: Routes.MUSICAL_GROUP_SERVICES,
    component: Pages.ServicesMusicalGroup,
  },
  {
    id: "reg-std-musical-group",
    path: Routes.MUSICAL_GROUP_STUDY,
    component: Pages.RegStdMusicalGroups,
  },
  {
    id: "checkout-musical-group",
    path: Routes.MUSICAL_GROUP_STUDY_CHECKOUT,
    component: Pages.EDRCheckOutMusicalGroups,
  },
  {
    id: "confirm-musical-group",
    path: Routes.MUSICAL_GROUP_STUDY_CONFIRMATION,
    component: Pages.EDRConfirm,
  },
  {
    id: "musical-group-register",
    path: Routes.MUSICAL_GROUP_REGISTER,
    component: Pages.RegMusicalGroup,
  },
  {
    id: "musical-group-holder-select",
    path: Routes.MUSICAL_GROUP_TITULAR,
    component: Pages.HolderSelectMusicalGroup,
  },
  {
    id: "study-selection-musical-group",
    path: Routes.MUSICAL_GROUP_SELECT_STUDY,
    component: Pages.StudySelection,
  },
  {
    id: "muical-group-checkout-register",
    path: Routes.MUSICAL_GROUP_REGISTER_CHECKOUT,
    component: Pages.RegCheckOutMusicalGroups,
  },
  {
    id: "musical-group-confirm-register",
    path: Routes.MUSICAL_GROUP_REGISTER_CONFIRMATION,
    component: Pages.RegConfirmMusicalGroup,
  },
  {
    id: "magazines-services",
    path: Routes.MAGAZINES_SERVICES,
    component: Pages.ServicesMagazine,
  },
  {
    id: "magazines-std-reg",
    path: Routes.MAGAZINES_STUDY_REGISTER,
    component: Pages.RegStdMagazine,
  },
  {
    id: "magazine-std-checkout",
    path: Routes.MAGAZINES_STUDY_CHECKOUT,
    component: Pages.EDRCheckOutMagazine,
  },
  {
    id: "magazine-std-confirmation",
    path: Routes.MAGAZINES_STUDY_CONFIRMATION,
    component: Pages.EDRConfirmMagazine,
  },
  {
    id: "magazine-std-select",
    path: Routes.MAGAZINES_SELECT_STUDY,
    component: Pages.StudySelectionMagazine,
  },
  {
    id: "magazine-register",
    path: Routes.MAGAZINES_REGISTER,
    component: Pages.RegMamazine,
  },
  {
    id: "magazines-checkout-register",
    path: Routes.MAGAZINES_REGISTER_CHECKOUT,
    component: Pages.RegCheckOutMagazine,
  },
  {
    id: "magazines-confirm-register",
    path: Routes.MAGAZINES_REGISTER_CONFIRMATION,
    component: Pages.RegConfirmMagazine,
  },
  {
    id: "magazine-holder-select",
    path: Routes.MAGAZINES_REGISTER_TITULAR,
    component: Pages.HolderSelectMagazine,
  },
  {
    id: "newspaper-services",
    path: Routes.NEWSPAPERS_SERVICES,
    component: Pages.ServicesNewspaper,
  },
  {
    id: "newspaper-std-reg",
    path: Routes.NEWSPAPERS_STUDY,
    component: Pages.RegStdNewspaper,
  },
  {
    id: "newspaper-std-checkout",
    path: Routes.NEWSPAPERS_STUDY_CHECKOUT,
    component: Pages.EDRCheckOutNewspaper,
  },
  {
    id: "newspaper-std-confirmation",
    path: Routes.NEWSPAPERS_STUDY_CONFIRMATION,
    component: Pages.EDRConfirmNewspaper,
  },
  {
    id: "newspaper-std-select",
    path: Routes.NEWSPAPERS_SELECT_STUDY,
    component: Pages.StudySelectionNews,
  },
  {
    id: "newspaper-holder-select",
    path: Routes.NEWSPAPERS_REGISTER_TITULAR,
    component: Pages.HolderSelectNewspaper,
  },
  {
    id: "newspaper-confirm-register",
    path: Routes.NEWSPAPERS_REGISTER_CONFIRMATION,
    component: Pages.RegConfirmNewspaper,
  },
  {
    id: "newspaper-checkout-register",
    path: Routes.NEWSPAPERS_REGISTER_CHECKOUT,
    component: Pages.RegCheckOutNewspaper,
  },
  {
    id: "newspaper-register",
    path: Routes.NEWSPAPERS_REGISTER,
    component: Pages.RegNewspaper,
  },
  {
    id: "publications-services",
    path: Routes.PUBLICATIONS_SERVICES,
    component: Pages.ServicesPublications,
  },
  {
    id: "publications-std-reg",
    path: Routes.PUBLICATIONS_STUDY_REGISTER,
    component: Pages.RegStdPublications,
  },
  {
    id: "publications-std-checkout",
    path: Routes.PUBLICATIONS_STUDY_CHECKOUT,
    component: Pages.EDRCheckOutPublications,
  },
  {
    id: "publications-std-confirmation",
    path: Routes.PUBLICATIONS_STUDY_CONFIRMATION,
    component: Pages.EDRConfirmPublications,
  },
  {
    id: "publications-std-select",
    path: Routes.PUBLICATIONS_REGISTER_SELECT_STUDY,
    component: Pages.StudySelectionPublications,
  },
  {
    id: "publications-holder-select",
    path: Routes.PUBLICATIONS_REGISTER_TITULAR,
    component: Pages.HolderSelectPublications,
  },
  {
    id: "publications-checkout-register",
    path: Routes.PUBLICATIONS_REGISTER_CHECKOUT,
    component: Pages.RegCheckOutPublications,
  },
  {
    id: "publications-checkout-register",
    path: Routes.PUBLICATIONS_REGISTER_CONFIRMATION,
    component: Pages.RegConfirmPublications,
  },
  {
    id: "publications-register",
    path: Routes.PUBLICATIONS_REGISTER,
    component: Pages.RegPublications,
  },
  {
    id: "disenos-register",
    path: Routes.DESIGNS_REGISTER,
    component: Pages.RegDiseno,
  },
  {
    id: "disenos-checkout-register",
    path: Routes.DESIGNS_CHECKOUT,
    component: Pages.CheckOutID,
  },
  {
    id: "disenos-confirmation",
    path: Routes.DESIGNS_CONFIRMATION,
    component: Pages.ConfirmatioID,
  },
  {
    id: "rights-licence",
    path: Routes.RIGHTS_LICENSE,
    component: Pages.RightsLicence,
  },
  {
    id: "rights-licence-checkout",
    path: Routes.RIGHTS_LICENSE_CHECKOUT,
    component: Pages.CheckOutRightL,
  },
  {
    id: "rights-licence-confirm",
    path: Routes.RIGHTS_LICENSE_CONFIRMATION,
    component: Pages.ConfirmRightL,
  },
  {
    id: "rights-cession",
    path: Routes.RIGHTS_CESSION,
    component: Pages.cessionRights,
  },
  {
    id: "rights-cession-checkout",
    path: Routes.RIGHTS_CESSION_CHECKOUT,
    component: Pages.cessionCheckOut,
  },
  {
    id: "rights-cession-confirm",
    path: Routes.RIGHTS_CESSION_CONFIRMATION,
    component: Pages.cessionConfirmation,
  },
  {
    id: "rights-renewal-select-right",
    path: Routes.RIGHTS_RENOVATIIONS_SELECT,
    component: Pages.selectRight,
  },
  {
    id: "rights-renewal-register",
    path: Routes.RIGHTS_RENOVATIONS_REGISTER,
    component: Pages.renewalReg,
  },
  {
    id: "rights-renewal-checkout",
    path: Routes.RIGHTS_RENOVATIONS_CHECKOUT,
    component: Pages.renewalCheckOut,
  },
  {
    id: "rights-renewal-confirmation",
    path: Routes.RIGHTS_RENOVATIONS_CONFIRM,
    component: Pages.renewalConfirmation,
  },
  {
    id: "rights-list-of-rights",
    path: Routes.RIGHTS_LIST_OF_RIGHTS,
    component: Pages.ListOfRights,
  },
  {
    id: "request-payments-impediments",
    path: Routes.REQUEST_PAYMENTS_IMPEDIMENTS + "/:orden",
    component: Pages.Impediments,
  },
  {
    id: "request-declatation-trademark",
    path: Routes.REQUEST_DECLARATION_TRADEMARK + "/:orden",
    component: Pages.TradeMark,
  },
  {
    id: "request-payment-anuuity",
    path: Routes.REQUEST_PAYMENTS_ANNIUTY + "/:orden",
    component: Pages.Annuity
  },
  {
    id: "request-payment-renewal",
    path: Routes.REQUEST_PAYMENTS_RENEWAL + "/:orden",
    component: Pages.Renewal
  }
];

export default routes;
