import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonBack, ButtonEdit } from "../../../../components";
import PublicationsTemplate from "../../../../components/Templates/Confirmation/PublicationsTemplate";
import { publicationsActions } from "../../../../store/slices/publications";
import {
  PUBLICATIONS_REGISTER_TITULAR,
  PUBLICATIONS_REGISTER,
  PUBLICATIONS_REGISTER_CHECKOUT,
  DASHBOARD_SERVICES,
} from "../../../../constants/routes";

const ConfirmationPublications = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const storePublications = useSelector((state) => state.publications);
  const storePayment = useSelector((state) => state.payment);

  const EDITBRAND = PUBLICATIONS_REGISTER_TITULAR;
  const EDITBRAND_IS_NEW_BRAND = PUBLICATIONS_REGISTER;
  const EDITBRAND_CHECKOUT = PUBLICATIONS_REGISTER_CHECKOUT;

  useEffect(() => {
    if (
      !storePublications.isNewBrand &&
      storePublications.studyBelongsId === -1
    ) {
      history.replace(DASHBOARD_SERVICES);
    } else if (
      storePublications.isNewBrand &&
      storePublications.brand_info.name === ""
    ) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [
    history,
    storePublications.brand_info.name,
    storePublications.isNewBrand,
    storePublications.studyBelongsId,
  ]);

  const gotoBrandInfo = () => {
    if (storePublications.isNewBrand) {
      props.history.replace(EDITBRAND_IS_NEW_BRAND);
    } else {
      props.history.replace(EDITBRAND);
    }
  };

  const gotoPayment = () => {
    props.history.replace(EDITBRAND_CHECKOUT);
  };

  const redirectReceipt = useCallback(() => {
    dispatch(publicationsActions.createPublication());
  }, [dispatch]);

  return (
    <div className="container-modulo">
      <ButtonBack url={EDITBRAND_CHECKOUT} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">
          REGISTRO DE NOMBRE DE PUBLICACIONES PERIÓDICAS VÍA REDES DE CÓMPUTO
        </h2>
      </div>

      <div className="description-confirm">
        <p>Protege tu Publicación de manera práctica y sencilla.</p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE LA PUBLICACIÓN</div>
        <ButtonEdit onClick={gotoBrandInfo} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE PAGO</div>
        <ButtonEdit onClick={gotoPayment} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
      <div>
        <PublicationsTemplate
          registerData={storePublications}
          paymentData={storePayment}
          onConfirm={redirectReceipt}
          scope="reg-std-publication"
        />
      </div>
    </div>
  );
};

export default ConfirmationPublications;
