import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  DocumentBlue,
  KeyBlue,
  ProfileBlue,
  SignOffBlue,
} from "../../../assets/images/icons/icons";
import classes from "./ItemMenuLight.module.css";

const ItemMenuLight = ({ icon, iconAlt, textLink, path, onClick }) => {
  const history = useHistory();

  let iconItem =
    {
      SignOffBlue: SignOffBlue,
      ProfileBlue: ProfileBlue,
      KeyBlue: KeyBlue,
      DocumentBlue: DocumentBlue,
    }[icon] || DocumentBlue;

  const handleOnClick = () => {
    if (path === "") {
      onClick();
    } else {
      history.push(path);
    }
  };

  return (
    <button
      href="#"
      className={classes.serviciosButton}
      onClick={handleOnClick}
    >
      <div>
        <img className={classes.imgItem} src={iconItem} alt={iconAlt} />
      </div>
      {textLink}
    </button>
  );
};

ItemMenuLight.defaultProps = {
  path: "",
  onClick: () => {},
};

ItemMenuLight.propTypes = {
  icon: PropTypes.string.isRequired,
  iconAlt: PropTypes.string.isRequired,
  textLink: PropTypes.string.isRequired,
  path: PropTypes.string,
  onClick: PropTypes.func,
};

export default ItemMenuLight;
