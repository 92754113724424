import { Button, ButtonBack, Spinner } from "../../../components";
import BusinessNameTemplate from "../../../components/Templates/Entity/BusinessNameTemplate";
import { BUSINESS_NAME_FORM, PROFILE } from "../../../constants/routes";
import useBusinessName from "./useBusinessName";

const BusinessName = (props) => {
  const { loading, businessName } = useBusinessName();

  const handleNewBn = () => {
    props.history.replace(BUSINESS_NAME_FORM);
  };

  return (
    <div className="container-modulo">
      <ButtonBack url={PROFILE} text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">RAZONES SOCIALES</h2>
      </div>
      <div className="text-description">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et nulla
          massa. Nunc sodales dignissim enim eget efficitur.
        </p>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        businessName.business_info?.length > 0 &&
        businessName.business_info.map((item) => {
          return (
            <BusinessNameTemplate
              key={item.business_name_id}
              data={item}
              selected={
                businessName.business_info[0].business_name_id ===
                item.business_name_id
              }
            />
          );
        })
      )}

      <div className="contentainer-button">
        <Button onClick={handleNewBn} className="btnConfirm">
          NUEVA
        </Button>
      </div>
    </div>
  );
};

export default BusinessName;
