import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  PUBLICATIONS_STUDY_REGISTER,
  PUBLICATIONS_STUDY_CONFIRMATION,
  DASHBOARD_SERVICES,
} from "../../../../constants/routes";
import { ButtonBack, PaymentStripe, ButtonEdit } from "../../../../components";

const EDIT_INFO = PUBLICATIONS_STUDY_REGISTER;
const CONFIMATION = PUBLICATIONS_STUDY_CONFIRMATION;

const CheckOutStdPublications = (props) => {
  const history = useHistory();
  const storePublications = useSelector((state) => state.publications);

  useEffect(() => {
    if (storePublications.brand_info.name === "") {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storePublications.brand_info.name]);

  const cameBack = () => {
    props.history.replace(EDIT_INFO);
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <div className="container-modulo">
      <ButtonBack url={EDIT_INFO} text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">
          ESTUDIO DE REGISTRABILIDAD DE PUBLICACIONES PERIÓDICAS VÍA REDES DE
          CÓMPUTO
        </h2>
      </div>
      <div className="text-description">
        <p>
          Proporciona la información para realizar el estudio de
          registrabilidad.
        </p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE LA PUBLICACIÓN</div>
        <ButtonEdit onClick={cameBack} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>

      <div>
        <Elements stripe={stripePromise}>
          <PaymentStripe redirect={CONFIMATION} />
        </Elements>
      </div>

      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default CheckOutStdPublications;
