import { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  InputSimpleFloatLabel,
  InputSelect,
  Button,
  Spinner,
  ErrorMessage,
} from "../../../../components";
import { listStates } from "../../../../utils/states";
import { listCity } from "../../../../utils/states-city";
import { MESSAGES, REGEXP } from "../../../../constants/form";
import SimpleModal from "../../../../components/UI/Modal/SimpleModal";
import classes from "../MyRequest.module.css";

const NewBusinessNameModal = ({
  serviceId,
  closeModalNewBN,
  clearErrorMessageSaveBN,
  loadingSaveNewBN,
  errorSaveNewBN,
  saveNewBusinessName,
  openModalResultRequestInvoice,
}) => {
  const [cities, setCities] = useState([]);
  const [rfc, setRfc] = useState("");

  const initialValues = {
    name: "",
    rfc: "",
    street: "",
    ext_number: "",
    int_number: "",
    email: "",
    phone: "",
    state: "",
    city: "",
    postal_code: "",
    neighborhood: "",
    usoCfdi: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required(MESSAGES.REQUIRED)
      .max(200)
      .matches(REGEXP.RAZON_SOCIAL, MESSAGES.NOT_MATCH),
    rfc: Yup.string()
      .required(MESSAGES.REQUIRED)
      .min(12, MESSAGES.MIN)
      .max(13, MESSAGES.MAX)
      .matches(REGEXP.RFC, MESSAGES.NOT_MATCH_RFC),
    street: Yup.string()
      .required(MESSAGES.REQUIRED)
      .max(100, MESSAGES.MAX)
      .matches(REGEXP.STREET, MESSAGES.NOT_MATCH),
    ext_number: Yup.string()
      .required(MESSAGES.REQUIRED)
      .max(30)
      .matches(REGEXP.NUMBER, MESSAGES.NOT_MATCH),
    int_number: Yup.string()
      .max(30, MESSAGES.MAX)
      .matches(REGEXP.NUMBER, MESSAGES.NOT_MATCH),
    email: Yup.string()
      .required(MESSAGES.REQUIRED)
      .email(MESSAGES.EMAIL_INVALID)
      .max(70, MESSAGES.MAX),
    phone: Yup.string()
      .required(MESSAGES.REQUIRED)
      .max(10, MESSAGES.MAX)
      .matches(REGEXP.PHONE, MESSAGES.NOT_MATCH),
    state: Yup.string()
      .required(MESSAGES.REQUIRED)
      .min(2, MESSAGES.MIN)
      .max(3, MESSAGES.MAX)
      .matches(REGEXP.STATE, MESSAGES.REQUIRED),
    city: Yup.string()
      .required(MESSAGES.REQUIRED)
      .max(250, MESSAGES.MAX)
      .matches(REGEXP.CITY, MESSAGES.REQUIRED),
    postal_code: Yup.string()
      .required(MESSAGES.REQUIRED)
      .max(10, MESSAGES.MAX)
      .matches(REGEXP.POSTAL_CODE, MESSAGES.NOT_MATCH),
    neighborhood: Yup.string()
      .required(MESSAGES.REQUIRED)
      .max(220, MESSAGES.MAX)
      .matches(REGEXP.NEIGHBORHOOD, MESSAGES.NOT_MATCH),
  });

  const setCityValue = (event) => {
    event.target.value !== "-1"
      ? setCities(listCity[event.target.value])
      : setCities([]);
  };

  const mayusRfc = (event) => {
    setRfc(("", event.target.value).toUpperCase());
  };

  const onSubmit = (values) => {
    saveNewBusinessName(values, serviceId);
    if (errorSaveNewBN === undefined) {
      closeModalNewBN();
      clearErrorMessageSaveBN();
      openModalResultRequestInvoice();
    }
  };

  const onCancel = () => {
    closeModalNewBN();
    clearErrorMessageSaveBN();
  };

  return (
    <SimpleModal close={onCancel} title="NUEVA RAZÓN SOCIAL PARA FACTURACIÓN">
      <div className={classes.mainContentModal}>
        <div className={classes.descriptionModal}>
          Favor de ingresar la información de la nueva razón social para llevar
          a cabo la solicitud de facturación de esta solicitud de servicio.
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values, handleSubmit, handleChange }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formFields}>
                <div>
                  <InputSimpleFloatLabel
                    id="name"
                    name="name"
                    placeholder="RAZÓN SOCIAL"
                    onChange={handleChange}
                    defaultValue={values.rfc}
                    valid={!Boolean(errors.name && touched.name)}
                    touched={touched.name}
                    maxLength={200}
                  />
                  {errors.name && touched.name && (
                    <div className="erros-form">{errors.name}</div>
                  )}
                </div>
                <div>
                  <InputSimpleFloatLabel
                    id="rfc"
                    name="rfc"
                    placeholder="RFC"
                    onChange={(e) => {
                      mayusRfc(e);
                      handleChange(e);
                    }}
                    value={rfc}
                    valid={!Boolean(errors.rfc && touched.rfc)}
                    touched={touched.rfc}
                    maxLength={13}
                  />
                  {errors.rfc && touched.rfc && (
                    <div className="erros-form">{errors.rfc}</div>
                  )}
                </div>
              </div>
              <div className={classes.formFields}>
                <div>
                  <InputSimpleFloatLabel
                    id="street"
                    name="street"
                    placeholder="CALLE"
                    onChange={handleChange}
                    valid={!Boolean(errors.street && touched.street)}
                    touched={touched.street}
                    maxLength={100}
                  />
                  {errors.street && touched.street && (
                    <div className="erros-form">{errors.street}</div>
                  )}
                </div>
                <div>
                  <InputSimpleFloatLabel
                    id="ext_number"
                    name="ext_number"
                    placeholder="NÚMERO EXTERIOR"
                    onChange={handleChange}
                    valid={!Boolean(errors.ext_number && touched.ext_number)}
                    touched={touched.ext_number}
                    maxLength={30}
                  />
                  {errors.ext_number && touched.ext_number && (
                    <div className="erros-form">{errors.ext_number}</div>
                  )}
                </div>
              </div>
              <div className={classes.formFields}>
                <div>
                  <InputSimpleFloatLabel
                    id="int_number"
                    name="int_number"
                    placeholder="NÚMERO INTERIOR"
                    onChange={handleChange}
                    valid={!Boolean(errors.int_number && touched.int_number)}
                    touched={touched.int_number}
                    maxLength={30}
                  />
                  {errors.int_number && touched.int_number && (
                    <div className="erros-form">{errors.int_number}</div>
                  )}
                </div>
                <div>
                  <InputSimpleFloatLabel
                    id="email"
                    name="email"
                    placeholder="CORREO ELECTRÓNICO"
                    onChange={handleChange}
                    valid={!Boolean(errors.email && touched.email)}
                    touched={touched.email}
                    maxLength={70}
                  />
                  {errors.email && touched.email && (
                    <div className="erros-form">{errors.email}</div>
                  )}
                </div>
              </div>
              <div className={classes.formFields}>
                <div>
                  <InputSimpleFloatLabel
                    id="phone"
                    name="phone"
                    placeholder="TELÉFONO"
                    onChange={handleChange}
                    valid={!Boolean(errors.phone && touched.phone)}
                    touched={touched.phone}
                    maxLength={10}
                  />
                  {errors.phone && touched.phone && (
                    <div className="erros-form">{errors.phone}</div>
                  )}
                </div>
                <div>
                  <InputSelect
                    id="state"
                    name="state"
                    placeholder="- ESTADO -"
                    options={listStates}
                    fieldIdOption="id"
                    valid={Boolean(errors.state && touched.state)}
                    value={values.state}
                    onChange={(e) => {
                      setCityValue(e);
                      handleChange(e);
                    }}
                  />
                  {errors.state && touched.state && (
                    <div className="erros-form">{errors.state}</div>
                  )}
                </div>
              </div>
              <div className={classes.formFields}>
                <div>
                  <InputSelect
                    id="city"
                    name="city"
                    placeholder="- CIUDAD -"
                    options={cities}
                    optionsType="array"
                    fieldIdOption="id"
                    valid={Boolean(errors.city && touched.city)}
                    onChange={handleChange}
                  />
                  {errors.city && touched.city && (
                    <div className="erros-form">{errors.city}</div>
                  )}
                </div>
                <div>
                  <InputSimpleFloatLabel
                    id="postal_code"
                    name="postal_code"
                    placeholder="CÓDIGO POSTAL"
                    onChange={handleChange}
                    valid={!Boolean(errors.postal_code && touched.postal_code)}
                    touched={touched.postal_code}
                    maxLength={10}
                  />
                  {errors.postal_code && touched.postal_code && (
                    <div className="erros-form">{errors.postal_code}</div>
                  )}
                </div>
              </div>
              <div className={classes.formFields}>
                <div>
                  <InputSimpleFloatLabel
                    id="neighborhood"
                    name="neighborhood"
                    placeholder="COLONIA"
                    onChange={handleChange}
                    valid={
                      !Boolean(errors.neighborhood && touched.neighborhood)
                    }
                    touched={touched.neighborhood}
                    maxLength={220}
                  />
                  {errors.neighborhood && touched.neighborhood && (
                    <div className="erros-form">{errors.neighborhood}</div>
                  )}
                </div>
                <div>
                  <InputSelect
                    id="usoCfdi"
                    name="usoCfdi"
                    placeholder="USO CFDI"
                    onChange={handleChange}
                    value={values.usoCfdi}
                    fieldIdOption="value"
                    options={[
                      { value: "1", name: "Adquisición de mercancías" },
                      { value: "2", name: "Gastos en general" },
                      { value: "3", name: "Por definir" },
                    ]}
                  />
                </div>
              </div>
              {errorSaveNewBN !== undefined && (
                <ErrorMessage message={errorSaveNewBN} />
              )}
              {loadingSaveNewBN ? (
                <Spinner />
              ) : (
                <div className={classes.modalCtrl}>
                  <div className={classes.modalBtn}>
                    <Button className="btnConfirm" type="submit">
                      Aceptar
                    </Button>
                  </div>
                  <div className={classes.modalBtn}>
                    <Button className="btnCancel" onClick={onCancel}>
                      Cancelar
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </SimpleModal>
  );
};

NewBusinessNameModal.prototype = {
  serviceId: PropTypes.number.isRequired,
  closeModalNewBN: PropTypes.func.isRequired,
  saveNewBusinessName: PropTypes.func.isRequired,
  loadingSaveNewBN: PropTypes.bool.isRequired,
  errorSaveNewBN: PropTypes.string.isRequired,
  clearErrorMessageSaveBN: PropTypes.func.isRequired,
  openModalResultRequestInvoice: PropTypes.func.isRequired,
};

export default NewBusinessNameModal;
