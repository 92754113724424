import { useState, useEffect } from "react";
import {
  GET_USER_ORDER,
  GET_ORDER_DETAIL,
  BUSINESS_NAME,
  CREATE_INVOICE,
} from "../../../constants/atualcanceApi";
import { MESSAGES } from "../../../constants/form";
import {
  CREATE_BUSINESS_NAME,
  UPDATE_FILE,
} from "../../../constants/atualcanceApi";
import basicAuthRequest from "../../../utils/axios/basicAuthRequest";

const useMyRequest = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [loadingbn, setLoadingbn] = useState(false);
  const [loadingRequestInvoice, setLoadingRequestInvoice] = useState(false);
  const [loadingSaveNewBN, setLoadingSaveNewBN] = useState(false);
  const [errorSaveNewBN, setErrorSaveNewBN] = useState(undefined);
  const [loadingUpdateFile, setLoadingUpdateFile] = useState(false);
  const [errorUpdateFile, setErrorUpdateFile] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const getOrders = async () => {
      setLoading(true);
      try {
        const response = await basicAuthRequest({
          method: "GET",
          url: `${GET_USER_ORDER}${user.user_id}/filter/0`,
        }).catch((error) => {
          setOrders([]);
          setError(error);
        });

        if (response?.status === 200) {
          if (response.data.services.length) {
            setOrders(response.data.services);
          }
        } else {
          setError("Ocurrió un error en la comunicación con el servidor");
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getOrders();

    return () => {
      setOrders([]);
      setError("");
    };
  }, [user.user_id]);

  const getFilteredOrders = async (event) => {
    try {
      setLoading(true);
      const response = await basicAuthRequest({
        method: "GET",
        url: `${GET_USER_ORDER}${user.user_id}/filter/${event.target.value}`,
      });

      if (response?.status === 200) {
        if (response.data.services.length) {
          setOrders(response.data.services);
        }
      } else {
        setError("Ocurrió un error en la comunicación con el servidor");
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const getRequestDetail = async (request_id) => {
    try {
      const response = await basicAuthRequest({
        method: "GET",
        url: `${GET_ORDER_DETAIL}${user.user_id}/serviceorder/${request_id}`,
      }).catch((error) => console.log(error));
      return response;
    } catch (e) {
      console.log(e);
    }
  };

  const getBusinessName = async () => {
    try {
      setLoadingbn(true);
      return await basicAuthRequest({
        method: "GET",
        url: BUSINESS_NAME + user.user_id,
      });
    } catch (error) {
      return error;
    } finally {
      setLoadingbn(false);
    }
  };

  const requestInvoice = async (values, serviceId, bussinessNameId = -1) => {
    const data = {
      user_id: user.user_id,
      service_order_id: +serviceId,
      business_name_id:
        bussinessNameId !== -1 ? bussinessNameId : +values.businessName,
      cfdi_use: values.usoCfdi,
    };
    try {
      setLoadingRequestInvoice(true);
      return await basicAuthRequest({
        method: "POST",
        url: CREATE_INVOICE,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });
    } catch (error) {
    } finally {
      setLoadingRequestInvoice(false);
    }
  };

  const saveNewBusinessName = async (values, serviceId) => {
    setLoadingSaveNewBN(true);
    const user = JSON.parse(localStorage.getItem("user"));

    const body = {
      user_id: user.user_id,
      business_info: {
        name: values.name,
        rfc: values.rfc,
        street: values.street,
        ext_number: values.ext_number,
        int_number: values.int_number,
        email: values.email,
        phone: values.phone,
        state: values.state,
        city: values.city,
        postal_code: values.postal_code,
        neighborhood: values.neighborhood,
      },
    };

    try {
      setLoadingSaveNewBN(true);
      basicAuthRequest
        .request({
          url: CREATE_BUSINESS_NAME,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: body,
        })
        .then((response) => {
          if (response === undefined || response.status !== 200) {
            setErrorSaveNewBN(MESSAGES.BAD_REQUEST);
          }
          requestInvoice(values, serviceId, response.data.businessname_id);
          setLoadingSaveNewBN(false);
        })
        .catch((error) => {
          console.log("ERROR", error);
          setErrorSaveNewBN(MESSAGES.BAD_REQUEST);
        });
    } catch (error) {
      setErrorSaveNewBN(MESSAGES.BAD_REQUEST);
      setLoadingSaveNewBN(false);
    }
  };

  const clearErrorMessageSaveBN = () => {
    setErrorSaveNewBN(undefined);
  };

  const updateFile = async (data) => {
    setLoadingUpdateFile(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const body = {
      user_id: user.user_id,
      service_id: data.service_id,
      service_order_id: data.service_order_id,
      entity_id: data.entity_id,
      type: data.type,
      file: data.file,
    };
    try {
      await basicAuthRequest({
        url: UPDATE_FILE,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      }).then((response) => {
        if (response.error !== undefined) {
          setErrorUpdateFile(MESSAGES.BAD_REQUEST);
        }
      });
    } catch (error) {
    } finally {
      setLoadingUpdateFile(false);
    }
  };

  return {
    orders,
    loading,
    loadingbn,
    loadingRequestInvoice,
    loadingSaveNewBN,
    error,
    errorSaveNewBN,
    getFilteredOrders,
    getRequestDetail,
    getBusinessName,
    requestInvoice,
    saveNewBusinessName,
    clearErrorMessageSaveBN,
    updateFile,
    loadingUpdateFile,
    errorUpdateFile,
  };
};

export default useMyRequest;
