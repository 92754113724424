import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SimpleModal from "../../../../components/UI/Modal/SimpleModal";
import {
  InputRadio,
  InputSelect,
  Button,
  ButtonEdit,
  ErrorMessage,
  Spinner,
} from "../../../../components";
import classes from "../MyRequest.module.css";

const BusinessNameModal = ({
  serviceId,
  closeModalBN,
  openModalResultRequestInvoice,
  openModalNewBN,
  businessNameList,
  requestInvoice,
  loadingRequestInvoice,
}) => {
  const initialValues = {
    businessName: "",
    usoCfdi: "",
  };
  const validationSchema = Yup.object({
    businessName: Yup.string().required(
      "Favor de seleccionar una razón social para realizar la facturación."
    ),
    usoCfdi: Yup.string().required("Favor de seleccionar el uso de CFDI"),
  });

  const onSubmit = (values) => {
    requestInvoice(values, serviceId);
    closeModalBN();
    openModalResultRequestInvoice();
  };

  const newBusinesName = () => {
    closeModalBN();
    openModalNewBN();
  };

  return (
    <SimpleModal
      close={closeModalBN}
      title="SELECCIONE LA RAZÓN SOCIAL PARA FACTURACIÓN"
    >
      <div className={classes.mainContentModal}>
        <div className={classes.descriptionModal}>
          Seleccione la razón social a la cual requiere emitir la factura de
          esta solicitud de servicio, o bien ingrese los datos de una nueva si
          así lo requiere.
        </div>
        <div className={classes.modalConrolsHeader}>
          <div className={classes.subTitleModal}>
            RAZONES SOCIALES{" "}
            <span className={classes.headerLabel}>
              ({businessNameList.data.business_info.length})
            </span>
          </div>
          <ButtonEdit onClick={newBusinesName} icon="faPlus">
            Nueva
          </ButtonEdit>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values, handleSubmit, handleChange }) => (
            <Form onSubmit={handleSubmit}>
              <div>
                {businessNameList.data.business_info.map((item) => (
                  <InputRadio
                    key={Math.random() + item.business_name_id}
                    id={item.business_name_id}
                    name="businessName"
                    value={item.business_name_id}
                    onChange={handleChange}
                    checked={+item.business_name_id === +values.businessName}
                    label={`${item.name} (${item.rfc})`}
                  />
                ))}

                <InputSelect
                  id="cfdi"
                  name="usoCfdi"
                  onChange={handleChange}
                  fieldIdOption="value"
                  value={values.usoCfdi}
                  options={[
                    { value: "", name: "- USO DE CFDI -" },
                    { value: "1", name: "Adquisición de mercancías" },
                    { value: "2", name: "Gastos en general" },
                    { value: "3", name: "Por definir" },
                  ]}
                />
              </div>

              <div>
                {errors.businessName && touched.businessName && (
                  <ErrorMessage message={errors.businessName} />
                )}
                {errors.usoCfdi && touched.usoCfdi && (
                  <ErrorMessage message={errors.usoCfdi} />
                )}
              </div>
              {loadingRequestInvoice ? (
                <Spinner />
              ) : (
                <div className={classes.modalCtrl}>
                  <div className={classes.modalBtn}>
                    <Button className="btnConfirm" type="submit">
                      Aceptar
                    </Button>
                  </div>
                  <div className={classes.modalBtn}>
                    <Button className="btnCancel" onClick={closeModalBN}>
                      Cancelar
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </SimpleModal>
  );
};

BusinessNameModal.propTypes = {
  serviceId: PropTypes.number.isRequired,
  closeModalBN: PropTypes.func.isRequired,
  openModalResultRequestInvoice: PropTypes.func.isRequired,
  openModalNewBN: PropTypes.func.isRequired,
  businessNameList: PropTypes.object.isRequired,
  requestInvoice: PropTypes.func.isRequired,
  loadingRequestInvoice: PropTypes.bool.isRequired,
};

export default BusinessNameModal;
