import { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { RouterContext } from "./context/RouterProvider";
import routes from "./app-routers";
import { MainLayout } from "./layouts";
import { DASHBOARD_SERVICES } from "./constants/routes";

const Content = () => {
  const route = useContext(RouterContext);

  const goToPath =
    route?.from?.indexOf("login") !== "-1" ? DASHBOARD_SERVICES : route.from;
  
  return (
    <MainLayout>
      <Switch>
        {routes.map(({ id, path, component }) => (
          <Route exact key={id} path={path} component={component} />
        ))}
        <Redirect to={goToPath} />
      </Switch>
    </MainLayout>
  );
};

export default Content;
