import React from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import classes from './InputDayPicker.module.css';

const InputDayPicker = props => {

  const MONTHS = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  const WEEKDAYS_LONG = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];

  const WEEKDAYS_SHORT = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];

  const FORMAT = 'yyyy/MM/dd';

  return (
    <div className={classes.contentDatePicker}>
      <FontAwesomeIcon icon={faCalendarAlt} className={classes.styleIcon} />
      <DayPickerInput
        value={props.value}
        format={FORMAT}
        onDayChange={props.onDayChange}
        formatDate={props.formatDate}
        parseDate={props.parseDate}
        placeholder={props.placeholder}
        dayPickerProps={{
          locate: 'es',
          months: MONTHS,
          weekdaysLong: WEEKDAYS_LONG,
          weekdaysShort: WEEKDAYS_SHORT,
          firstDayOfWeek: 0
        }}
      />
    </div>
  );
}

InputDayPicker.propTypes = {
  value: PropTypes.string,
  onDayChange: PropTypes.func,
  formatDate: PropTypes.any,
  parseDate: PropTypes.any,
  placeholder: PropTypes.string
}

export default InputDayPicker;