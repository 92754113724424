import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userProfileActions } from "../../store/slices/userProfile";
import { tradeMarkActions } from "../../store/slices/tradeMark";
import { magazinesActions } from "../../store/slices/magazines";
import { newspaperActions } from "../../store/slices/newspaper";
import { publicationsActions } from "../../store/slices/publications";
import { obrasActions } from "../../store/slices/obras";
import { rightsActions } from "../../store/slices/rights";
import { industrialDActions } from "../../store/slices/industrialDesigns";
import { ItemMenu, Spinner } from "../../components";
import classes from "./Dashboard.module.css";
import {
  BRANDS_SERVICES,
  OBRAS_REGISTER,
  MUSICAL_GROUP_SERVICES,
  MAGAZINES_SERVICES,
  NEWSPAPERS_SERVICES,
  PUBLICATIONS_SERVICES,
  DESIGNS_REGISTER,
  RIGHTS_LICENSE,
  RIGHTS_CESSION,
  RIGHTS_RENOVATIIONS_SELECT,
} from "../../constants/routes";
import { services as SERVICES } from "../../constants/services";

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tradeMarkActions.cleanStoreTradeMark());
    dispatch(obrasActions.cleanStorageObras());
    dispatch(magazinesActions.cleanStorageMagzine());
    dispatch(newspaperActions.cleanStorageNewspaper());
    dispatch(publicationsActions.cleanStoragePublications());
    dispatch(industrialDActions.cleanStorageIndustrialDesign());
    dispatch(rightsActions.cleanSotoreLicence());
  }, [dispatch]);

  useEffect(() => {
    dispatch(userProfileActions.getServices());
  }, [dispatch]);

  const services = useSelector((state) => state.userProfile.services);
  const listServices = services?.services || [];

  return (
    <div className="container-simple">
      <h2 className="subtitle">SERVICIOS</h2>
      <p className="description">
        Elige a continuación el servicio legal que deseas contratar.
      </p>
      {services?.length === 0 && <Spinner />}
      <div className={classes.containerMenuDash}>
        {listServices.find((item) => item.service_id === SERVICES.MARCAS) && (
          <ItemMenu
            icon="Marcas"
            iconAlt="Registro de marcas"
            textLink="MARCAS"
            path={BRANDS_SERVICES}
          />
        )}
        {listServices.find((item) => item.service_id === SERVICES.OBRAS) && (
          <ItemMenu
            icon="Obras"
            iconAlt="Registro de obras"
            textLink="OBRAS"
            path={OBRAS_REGISTER}
          />
        )}
        {listServices.find(
          (item) => item.service_id === SERVICES.GRUPOS_MUSICALES
        ) && (
          <ItemMenu
            icon="Grupos"
            iconAlt="Registro de grupos musicales"
            textLink="GRUPOS MUSICALES"
            path={MUSICAL_GROUP_SERVICES}
          />
        )}

        {listServices.find((item) => item.service_id === SERVICES.REVISTAS) && (
          <ItemMenu
            icon="Revistas"
            iconAlt="Registro de revistas"
            textLink="REVISTAS"
            path={MAGAZINES_SERVICES}
          />
        )}
        {listServices.find(
          (item) => item.service_id === SERVICES.PERIODICOS
        ) && (
          <ItemMenu
            icon="Periodicos"
            iconAlt="Registro de periódicos"
            textLink="PERIÓDICOS"
            path={NEWSPAPERS_SERVICES}
          />
        )}

        {listServices.find(
          (item) => item.service_id === SERVICES.PUBLICACIONES
        ) && (
          <ItemMenu
            icon="Publicaciones"
            iconAlt="Registro de publicaciones periódicas vía redes de computo"
            textLink="PUBLICACIONES PERIÓDICAS VÍA REDES DE COMPUTO"
            path={PUBLICATIONS_SERVICES}
          />
        )}

        {listServices.find(
          (item) => item.service_id === SERVICES.DISENOS_INDUSTRIALES
        ) && (
          <ItemMenu
            icon="Disenos"
            iconAlt="Diseños industríales"
            textLink="DISEÑOS INDUSTRIALES"
            path={DESIGNS_REGISTER}
          />
        )}
        {listServices.find(
          (item) => item.service_id === SERVICES.DERECHOS_LICENCIA
        ) && (
          <ItemMenu
            icon="Licencia"
            iconAlt="Licencia de derechos"
            textLink="LICENCIA DE DERECHOS"
            path={RIGHTS_LICENSE}
          />
        )}
        {listServices.find(
          (item) => item.service_id === SERVICES.DERECHOS_CESION
        ) && (
          <ItemMenu
            icon="Cesion"
            iconAlt="Cesión de derechos"
            textLink="CESIÓN DE DERECHOS"
            path={RIGHTS_CESSION}
          />
        )}
        {listServices.find(
          (item) => item.service_id === SERVICES.DERECHOS_RENOVACION
        ) && (
          <ItemMenu
            icon="Renovaciones"
            iconAlt="Renovaciones"
            textLink="RENOVACIONES"
            path={RIGHTS_RENOVATIIONS_SELECT}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
