import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faArrowLeft,
  faTimes,
  faEye,
  faPlus,
  faHandPaper,
  faCheck,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";

import classes from "./ButtonEdit.module.css";

const ButtonEdit = ({ onClick, icon, size, children }) => {
  let icons =
    {
      faPen: faPen,
      faArrowLeft: faArrowLeft,
      faTimes: faTimes,
      faEye: faEye,
      faPlus: faPlus,
      faHandPaper: faHandPaper,
      faCheck: faCheck,
      faRedoAlt: faRedoAlt,
    }[icon] || faPen;

  return (
    <div>
      <button
        onClick={onClick}
        className={`${classes.btnEdit} ${size !== "small" && classes.btnLarge}`}
      >
        <FontAwesomeIcon icon={icons} className={classes.btnIcon} />
        <div>{children}</div>
      </button>
    </div>
  );
};

ButtonEdit.defaultProps = {
  size: "small",
};

ButtonEdit.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  size: PropTypes.string,
};

export default React.memo(ButtonEdit);
