
import './LoadPanel.css'

const LoadPanel = () => {
  return (
    <div className="loading show">
      <div className="spin"></div>
    </div>
  );
};

export default LoadPanel;
