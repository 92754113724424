import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonBack, ButtonEdit } from "../../../../components";
import PublicationsTemplate from "../../../../components/Templates/Confirmation/PublicationsTemplate";
import { publicationsActions } from "../../../../store/slices/publications";
import {
  PUBLICATIONS_STUDY_REGISTER,
  PUBLICATIONS_STUDY_CHECKOUT,
  DASHBOARD_SERVICES,
} from "../../../../constants/routes";

const STD_INFO = PUBLICATIONS_STUDY_REGISTER;
const STD_CHECKOUT = PUBLICATIONS_STUDY_CHECKOUT;

const ConfirmationPublications = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const storePublications = useSelector((state) => state.publications);
  const storePayment = useSelector((state) => state.payment);

  useEffect(() => {
    if (storePublications.brand_info.name === "") {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storePublications.brand_info.name]);

  const gotoBrandInfo = (_) => {
    props.history.replace(STD_INFO);
  };

  const gotoPayment = () => {
    props.history.replace(STD_CHECKOUT);
  };

  const redirectReceipt = useCallback(() => {
    dispatch(publicationsActions.createPublicationsStudy());
  }, [dispatch]);

  return (
    <div className="container-modulo">
      <ButtonBack url={STD_CHECKOUT} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">
          ESTUDIO DE REGISTRABILIDAD DE PUBLICACIONES PERIÓDICAS VÍA REDES DE
          CÓMPUTO
        </h2>
      </div>

      <div className="description-confirm">
        <p>
          Proporciona la información para realizar el estudio de
          registrabilidad.
        </p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE LA PUBLICACIÓN</div>
        <ButtonEdit onClick={gotoBrandInfo} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE PAGO</div>
        <ButtonEdit onClick={gotoPayment} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
      <div>
        <PublicationsTemplate
          registerData={storePublications}
          paymentData={storePayment}
          onConfirm={redirectReceipt}
          scope="reg-std-publication"
        />
      </div>
    </div>
  );
};

export default ConfirmationPublications;
