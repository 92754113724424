import React from 'react';

import { BtnHelp } from '../../../../assets/images/icons/icons';
import classes from './ButtonHelp.module.css';

const btnHelp = () => {
  return (
    <button className={classes.btnHelp}>
      <a className={classes.btnContent} target="_blank" href="/" rel="noreferrer">
        <img src={BtnHelp} alt="ayuda" className={classes.imgBtnHelp} />
        <div className={classes.labelIconHelp}>AYUDA</div>
      </a>
    </button>
  );
}

export default btnHelp;


