import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  ButtonBack,
  Button,
  InputSimpleFloatLabel,
  BtnCallToAction,
  InputRadio,
  ModalDrag,
  InputSelect,
  EntityForm,
  ErrorMessage,
} from "../../../components";
import { caculateAmountPrice } from "../../../utils/scripts/calculatePrice";
import { magazinesActions } from "../../../store/slices/magazines";
import { userProfileActions } from "../../../store/slices/userProfile";
import { paymentActions } from "../../../store/slices/payment";
import {
  DASHBOARD_SERVICES,
  MAGAZINES_REGISTER_CHECKOUT,
  MAGAZINES_REGISTER_CONFIRMATION,
  MAGAZINES_SELECT_STUDY,
} from "../../../constants/routes";
import classes from "./RegMagazines.module.css";
import { USER_PDLC } from "../../../constants";

const CHECKOUT_MG = MAGAZINES_REGISTER_CHECKOUT;
const SELECT_STD = MAGAZINES_SELECT_STUDY;

const RegMagazine = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dragModal, setDragModal] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [validfiles, setValidFiles] = useState(true);
  const [submited, setSubmited] = useState(false);
  const [userData, setUserData] = useState();

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
  }, []);

  const storeMagazine = useSelector((state) => state.magazines);
  const storeEntity = useSelector((state) => state.entity);
  const holders = useSelector((state) => state.userProfile.holders);
  const storeUserProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (!storeMagazine.isNewBrand) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeMagazine.isNewBrand]);

  useEffect(() => {
    dispatch(userProfileActions.getHolders());
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getUserServices());
  }, [dispatch]);

  useEffect(() => {
    if (storeMagazine.isRegisteredHolder) {
      if (storeMagazine.isValidRGM) {
        setValidForm(true);
      } else {
        setValidForm(false);
      }
    } else {
      if (storeMagazine.isValidRGM && storeEntity.titular.valid) {
        setValidForm(true);
      } else {
        setValidForm(false);
      }
    }
  }, [
    storeMagazine.isValidRGM,
    storeEntity.titular.valid,
    storeMagazine.isRegisteredHolder,
  ]);

  useEffect(() => {
    if (storeMagazine.isRegisteredHolder) {
      if (storeMagazine.brand_info.file === "") {
        setValidFiles(false);
      } else {
        setValidFiles(true);
      }
    } else {
      if (
        storeEntity.titular.file === "" ||
        storeMagazine.brand_info.file === ""
      ) {
        setValidFiles(false);
      } else {
        setValidFiles(true);
      }
    }
  }, [
    storeEntity.titular.file,
    storeMagazine.brand_info.file,
    storeMagazine.isRegisteredHolder,
  ]);

  const getPrice = () => {
    const musicalGrops = storeUserProfile.services.services.filter(
      (item) => item.service_id === 8
    );
    const EdrMusicalGroups = musicalGrops[0].service_sub.filter(
      (item) => item.service_id === 10
    );

    const price =
      +EdrMusicalGroups[0].service_price +
      +EdrMusicalGroups[0].service_transact;

    const totalprice = caculateAmountPrice(price);
    return totalprice.toFixed(2);
  };

  const registerNext = () => {
    setSubmited(true);
    if (validfiles) {
      const price = getPrice();
      dispatch(paymentActions.setPurchaseAmount({ amount: price }));
      if (userData.user_id === USER_PDLC) {
        history.replace(MAGAZINES_REGISTER_CONFIRMATION);
      } else {
        history.replace(CHECKOUT_MG);
      }
    }
  };
  const clearFileUploaded = () => {
    dispatch(magazinesActions.setFile({ file: "" }));
  };
  const handleChangeName = (event) => {
    dispatch(magazinesActions.setBrandName({ name: event.target.value }));
  };
  const hanldleChangeTitularSelect = (event) => {
    dispatch(
      magazinesActions.setTitularId({
        titularId: event.target.value,
      })
    );
  };
  const handleChangeRegisterHolder = (event) => {
    dispatch(
      magazinesActions.setIsRegisteredHolder({
        isRegisteredHolder: event.target.value === "si",
      })
    );
  };
  const openDragModal = () => {
    setDragModal(true);
  };
  const closeDragModal = () => {
    setDragModal(false);
  };
  return (
    <div className="container-modulo">
      {dragModal && (
        <ModalDrag
          show={dragModal}
          closed={closeDragModal}
          scope="reg-std-magazines"
          formatFile="zip"
        />
      )}
      <ButtonBack url={SELECT_STD} text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">REGRISTRO DE NOMBRE DE REVISTAS</h2>
      </div>
      <div className="text-description">
        <p>Protege tu Revista de manera práctica y sencilla.</p>
      </div>
      <div className="label-information">
        <div>SELECCIÓN DE LA REVISTA</div>
      </div>
      <div className={classes.contentRadioSelect}>
        <InputSimpleFloatLabel
          id="name"
          name="name"
          defaultValue={storeMagazine.brand_info.name}
          valid={storeMagazine.brand_info.name !== ""}
          placeholder="NOMBRE DE LA REVISTA"
          onChange={handleChangeName}
        />

        <div className="updaload-file">
          {storeMagazine.brand_info.file === "" ? (
            <BtnCallToAction onClick={openDragModal} icon="faUpload">
              LOGOTIPO DE LA REVISTA
            </BtnCallToAction>
          ) : (
            <BtnCallToAction
              type="showfile"
              onClick={clearFileUploaded}
              icon="faTimesCircle"
              className="btnGreen"
              fileName={storeMagazine.brand_info.file}
            >
              LOGOTIPO DE LA REVISTA
            </BtnCallToAction>
          )}
        </div>

        <InputRadio
          id="si"
          name="isRegisteredHolder"
          value="si"
          onChange={handleChangeRegisterHolder}
          label="SELECCIONAR UN TITULAR DE MI LISTA DE TITULARES"
          checked={storeMagazine.isRegisteredHolder}
        />
        {storeMagazine.isRegisteredHolder && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="- ELIGE UN TITULAR -"
            options={holders}
            fieldIdOption="id"
            value={storeMagazine.titularId}
            onChange={hanldleChangeTitularSelect}
          />
        )}
        <InputRadio
          id="no"
          name="isRegisteredHolder"
          value="no"
          onChange={handleChangeRegisterHolder}
          label="REGISTRAR NUEVO TITULAR"
          checked={!storeMagazine.isRegisteredHolder}
        />
      </div>
      {!storeMagazine.isRegisteredHolder && (
        <div className={classes.contentForm}>
          <EntityForm
            typeEntity="titular"
            entity="titular-musical-group"
            recordTypeFile="brand-owner-info"
          />
        </div>
      )}
      <div className={classes.contentRadioSelect}>
        {submited && !validfiles && (
          <ErrorMessage message="Sube los archivos solicitados" />
        )}
      </div>
      <div className={classes.contentButton}>
        <Button
          onClick={registerNext}
          className="btnConfirm"
          disabled={!validForm}
        >
          CONTINUAR
        </Button>
      </div>

      <div className="label-information">
        <div>INFORMACIÓN DE LA PAGO</div>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default RegMagazine;
