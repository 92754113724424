import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ConfirmationTemplate,
  ButtonBack,
  ButtonEdit,
} from "../../../../components";
import { BRANDS_REGISTER_BRAND, DASHBOARD_SERVICES } from "../../../../constants/routes";
import { tradeMarkActions } from "../../../../store/slices/tradeMark";

const ConfirmationTradeMark = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const storeTradeMark = useSelector((state) => state.tradeMark);
  const paymentData = useSelector((state) => state.payment);
  const holders = useSelector(state => state.userProfile.holders);
  const [urlBrandInfo, setUrlBrandInfo] = useState("");

  useEffect(() => {
    
    if (!storeTradeMark.statusFormBrandInfo) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeTradeMark.statusFormBrandInfo]);

  useEffect(() => {
    if (storeTradeMark.isAdditionalBrand) {
      setUrlBrandInfo(BRANDS_REGISTER_BRAND);
    } else {
      setUrlBrandInfo("/marcas/titular");
    }
  }, [storeTradeMark]);

  const gotoBrandInfo = () => {
    props.history.replace(urlBrandInfo);
  };
  const gotoPayment = () => {
    props.history.replace("/marcas/registro-marca/checkout");
  };
  const redirectReceipt = useCallback(() => {
    //Send Data To API
    //Clean global State
    dispatch(tradeMarkActions.createTradeMark());
  }, [dispatch]);

  return (
    <div className="container-modulo">
      <ButtonBack url="/marcas/registro-marca/checkout" text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">REGISTRO DE MARCAS</h2>
      </div>
      <div className="text-description">
        <p>
          Protege el nombre, sonido, logotipo y/o frase comercial con los que deseas distinguir tus productos o servicios.
        </p>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE LA MARCA</div>
        <ButtonEdit onClick={gotoBrandInfo} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DEL PAGO</div>
        <ButtonEdit onClick={gotoPayment} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
      <div>
        <ConfirmationTemplate
          registerData={storeTradeMark}
          holders={holders}
          paymentData={paymentData}
          onConfirm={redirectReceipt}
          scope="trademark"
        />
      </div>
    </div>
  );
};

export default ConfirmationTradeMark;
