import { useState } from "react";
import { NavLink } from "react-router-dom";
import { AtaCurrencyFormat } from "../../Common";
import { Button, Spinner, ErrorMessage } from "../../../components";
import EntityTemplate from "../Entity/EntityTemplate";
import classes from "./ConfirmationTemplate.module.css";

const RenewalTemplate = ({
  renewal,
  price,
  paymentData,
  onSubmit,
  loading,
  error,
}) => {
  const [accepted, setAccepted] = useState(false);
  return (
    <div className={classes.confirmation}>
      <div className="confirmation-item">
        <h3>NOMBRE DEL DERECHO</h3>
        <p>{renewal[0]?.brand_info.name}</p>
      </div>
      <div className="confirmation-item">
        <h3>NÚMERO DE REGISTRO</h3>
        {renewal[0]?.brand_info.registration_number.map((item) => (
          <p key={item}>{item}</p>
        ))}
      </div>

      <EntityTemplate title="DATOS DEL AUTOR">
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>NOMBRE</div>
            <div className={classes.itemEntity}>
              {renewal[0]?.owner_info.name}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>RFC</div>
            <div className={classes.itemEntity}>
              {renewal[0]?.owner_info.rfc}
            </div>
          </div>
        </div>
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>CURP</div>
            <div className={classes.itemEntity}>
              {renewal[0]?.owner_info.curp}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>CALLE</div>
            <div className={classes.itemEntity}>
              {renewal[0]?.owner_info.state}
            </div>
          </div>
        </div>
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>NÚMERO EXTERIOR</div>
            <div className={classes.itemEntity}>
              {renewal[0]?.owner_info.ext_number}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>NÚMERO INTERIOR</div>
            <div className={classes.itemEntity}>
              {renewal[0]?.owner_info.int_number}
            </div>
          </div>
        </div>
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>CORREO ELECTRÓNICO</div>
            <div className={classes.itemEntity}>
              {renewal[0]?.owner_info.email}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>TELÉFONO</div>
            <div className={classes.itemEntity}>
              {renewal[0]?.owner_info.phone}
            </div>
          </div>
        </div>
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>ESTADO</div>
            <div className={classes.itemEntity}>
              {renewal[0]?.owner_info.state}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>CIUDAD</div>
            <div className={classes.itemEntity}>
              {renewal[0]?.owner_info.city}
            </div>
          </div>
        </div>
        <div className="form-informacion-marca">
          <div>
            <div className={classes.labelEntity}>CÓDIGO POSTAL</div>
            <div className={classes.itemEntity}>
              {renewal[0]?.owner_info.postal_code}
            </div>
          </div>
          <div>
            <div className={classes.labelEntity}>COLONIA</div>
            <div className={classes.itemEntity}>
              {renewal[0]?.owner_info.neighborhood}
            </div>
          </div>
        </div>
      </EntityTemplate>

      <div className="confirmation-item">
        <h3>METÓDO DE PAGO</h3>
        <p>{paymentData.paymentMethod.toUpperCase()}</p>
      </div>
      <div className="confirmation-item">
        <h3>TOTAL</h3>
        <span className="total">
          <AtaCurrencyFormat value={price} />
        </span>
        <div className="description-text">(Incluye impuestos y comisiones)</div>
      </div>
      <div className="confirmation-item">
        <label className={classes.checkitem}>
          <label htmlFor="terms" className={classes.terms}>
            Acepto todos los &nbsp;
            <NavLink to="/terminos-y-condiciones" className={classes.linkText}>
              TÉRMINOS Y CONDICIONES
            </NavLink>
          </label>
          <input
            type="checkbox"
            id="terms"
            name="terms"
            onChange={(event) => setAccepted(event.target.checked)}
          />
          <span className={classes.checkmark}></span>
        </label>
      </div>
      <div className="confirmation-item">
        <p>
          Al presionar "confirmar solicitud" usted acepta todos los términos y
          condiciones de <b>A tu alcance.</b>
        </p>
        <p>El pago de esta solicitud no garantiza la aprobación de la misma.</p>
      </div>
      {error && (
        <div className="container-select">
          <ErrorMessage message="Ocurrio un problema al procesar la petición, intenta nuevamente" />
        </div>
      )}

      <div className={classes.btnConfirm}>
        {loading ? (
          <Spinner />
        ) : (
          <Button
            className={"btnConfirm"}
            onClick={onSubmit}
            disabled={!accepted}
          >
            CONFIRMAR SOLICITUD
          </Button>
        )}
      </div>
    </div>
  );
};

export default RenewalTemplate;
