import React from "react";
import { NavLink } from "react-router-dom";

import {
  RowRightSmall,
  Facebook,
  Instagram,
  IconX,
} from "../../../../assets/images/icons/icons";
import { BackDrop } from "../../../index";
import {
  PROFILE,
  DASHBOARD_SERVICES,
  MY_REQUEST,
  RIGHTS_LIST_OF_RIGHTS,
} from "../../../../constants/routes";
import "./side-bar.css";

const sideBar = (props) => {
  let attachedClasses = ["side-bar", "close"];

  if (props.open) {
    attachedClasses = ["side-bar", "open"];
  }

  return (
    <React.Fragment>
      <BackDrop show={props.open} onClick={props.onClick} />
      <div className={attachedClasses.join(" ")}>
        <div className="container-menu">
          <div className="menu-item" onClick={props.onClick}>
            <NavLink to={DASHBOARD_SERVICES}>SERVICIOS</NavLink>
            <img src={RowRightSmall} alt="next" />
          </div>
          <div className="menu-item" onClick={props.onClick}>
            <NavLink to={PROFILE}>MI PERFIL</NavLink>
            <img src={RowRightSmall} alt="next" />
          </div>
          <div className="menu-item" onClick={props.onClick}>
            <NavLink to={MY_REQUEST}>
              MIS SOLICITUDES
              {/* <span className="notification">2</span> */}
            </NavLink>
            <img src={RowRightSmall} alt="next" />
          </div>
          <div className="menu-item" onClick={props.onClick}>
            <NavLink to={RIGHTS_LIST_OF_RIGHTS}>RELACIÓN DE DERECHOS</NavLink>
            <img src={RowRightSmall} alt="next" />
          </div>
          <div className="menu-item" onClick={props.onClick}>
            <a
              href="https://todoatualcance.mx/faq.html"
              target="_blank"
              rel="noreferrer"
            >
              FAQ
            </a>
            <img src={RowRightSmall} alt="next" />
          </div>
          <div className="menu-item" onClick={props.onClick}>
            <a
              href="https://todoatualcance.mx/acerca_de.html"
              target="_blank"
              rel="noreferrer"
            >
              AYUDA
            </a>
            <img src={RowRightSmall} alt="next" />
          </div>
        </div>

        <div className="footer-menu-side">
          <div className="icons-footer-side">
            <a
              href="https://www.instagram.com/tumarcaatualcance/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Instagram} alt="twitter" width="25" height="25" />
            </a>
            <a
              href="https://www.facebook.com/marcasatualcancemx/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Facebook} alt="facebook" />
            </a>
          </div>

          <div className="text-footer-side">
            <a href="/">
              <p>TÉRMINOS Y CONDICIONES</p>
            </a>
            <a href="/">
              <p>AVISO DE PRIVACIDAD</p>
            </a>
          </div>
          <div className="btn-round-close" onClick={props.onClick}>
            <img className="iconx" src={IconX} alt="close menu" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default sideBar;
