import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import { Periodicos } from "../../../assets/images/icons/icons";
import { ButtonBack, Button, ButtonRound } from "../../../components";
import { newspaperActions } from "../../../store/slices/newspaper";
import { userProfileActions } from "../../../store/slices/userProfile";
import {
  NEWSPAPERS_STUDY,
  NEWSPAPERS_SELECT_STUDY,
  DASHBOARD_SERVICES,
} from "../../../constants/routes";
import { services } from "../../../constants/services";

const STUDY = NEWSPAPERS_STUDY;
const REGISTER = NEWSPAPERS_SELECT_STUDY;
const SERVICIOS = DASHBOARD_SERVICES;

const ServicesNewspaper = (props) => {
  const dispatch = useDispatch();
  const [newspaper, setNewspaper] = useState([]);
  const storeUserProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getUserServices());
  }, [dispatch]);

  useEffect(() => {
    const result = storeUserProfile?.services?.services?.filter(
      (item) => item.service_id === services.PERIODICOS
    );
    setNewspaper(result);
  }, [storeUserProfile?.services?.services]);

  const goBack = () => {
    props.history.replace(SERVICIOS);
  };

  const handleRegistrabilityStudy = () => {
    dispatch(newspaperActions.setServiceId({ service_id: services.PERIODICOS_ESTUDIO_REG }));
    props.history.replace(STUDY);
  };

  const handleSelectStudy = () => {
    dispatch(newspaperActions.setServiceId({ service_id: services.PERIODICOS_REGISTRO }));
    props.history.replace(REGISTER);
  };

  return (
    <div className="container-modulo">
      <ButtonBack url={SERVICIOS} text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">SERVICIOS</h2>
      </div>

      <div className="item-marcas">
        <img src={Periodicos} alt="icon-marcas" />
        &nbsp;&nbsp; PERIÓDICOS
      </div>

      <div className="parting-line" />

      <div className="text-description">
        <p>Registra el nombre de tu periodico</p>
        <h2 className="subtitle-modle">
          {newspaper && newspaper[0]?.service_sub[0]?.service_name}
        </h2>
        <p>{newspaper && newspaper[0]?.service_sub[0]?.service_description}</p>
        <div className="price">
          <CurrencyFormat
            value={
              newspaper &&
              Number(newspaper[0]?.service_sub[0]?.service_price).toFixed(2)
            }
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            renderText={(value) => (
              <div>
                {value} <span className="currency">MXM</span>
              </div>
            )}
          />
        </div>
        <div className="content-btn">
          <Button className="btnConfirm" onClick={handleRegistrabilityStudy}>
            SOLICITAR
          </Button>
        </div>
      </div>

      <br />
      <br />
      <div className="parting-line" />
      <br />
      <br />

      <div className="text-description">
        <h2 className="subtitle-modle">
          {newspaper && newspaper[0]?.service_sub[1]?.service_name}
        </h2>
        <p>{newspaper && newspaper[0]?.service_sub[1]?.service_description}</p>

        <div className="price">
          <CurrencyFormat
            value={
              newspaper &&
              Number(newspaper[0]?.service_sub[1]?.service_price).toFixed(2)
            }
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            renderText={(value) => (
              <div>
                {value} <span className="currency">MXM</span>
              </div>
            )}
          />
        </div>
        <div className="content-btn">
          <Button className="btnConfirm" onClick={handleSelectStudy}>
            SOLICITAR
          </Button>
        </div>
      </div>

      <br />
      <br />
      <div className="parting-line" />
      <div className="content-btn btn-back-margin">
        <ButtonRound onClick={goBack} icon="faArrowLeft">
          REGRESAR
        </ButtonRound>
      </div>
    </div>
  );
};

export default ServicesNewspaper;
