import { ConnectedRouter } from "connected-react-router";

import { history } from "./store";
import { AuthProvider, useAuth } from "./context/auth";
import RouterProvider from "./context/RouterProvider";
import NoAuthenticatedContent from "./NotAuthenticatedContent";
import Content from "./Content";
import LoadPanel from "./components/UI/Spinner/LoadPanel/LoadPanel";
import "./App.css";

function App() {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadPanel />;
  }

  if (user) {
    return <Content />;
  }

  return <NoAuthenticatedContent />;
}

const defaultApp = () => {
  return (
    <AuthProvider>
      <ConnectedRouter history={history}>
        <RouterProvider>
          <App />
        </RouterProvider>
      </ConnectedRouter>
    </AuthProvider>
  );
};

export default defaultApp;
