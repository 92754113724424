import { call, put, select } from "redux-saga/effects";
import { replace } from "connected-react-router";

import { paymentActions } from "../../slices/payment";
import { tradeMarkActions } from "../../slices/tradeMark";
import { requestCreateTradeMark } from "../requests/tradeMarkRequest";
import { TICKECT } from "../../../constants/routes";

export function* handlerCreateTradeMark() {
  try {
    const sotoreTradeMark = yield select((store) => store.tradeMark);
    const storePayment = yield select((store) => store.payment);

    const response = yield call(
      requestCreateTradeMark,
      sotoreTradeMark,
      storePayment
    );

    if (response.status === 200) {
      yield put(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      yield put(replace(TICKECT));
      yield put(paymentActions.cleanStorePayment());
      yield put(tradeMarkActions.cleanStoreTradeMark());
    }
  } catch (error) {
    console.log("Error handlerCreateTradeMark", error);
  }
}
