export const DASHBOARD_SERVICES = "/servicios";

export const PROFILE_CHANGE_PASS = "/cambiar-cotrasena";

export const BRANDS_SERVICES = "/marcas/servicios";
export const BRANDS_REGISTRABILITY_STUDY = "/marcas/estudio-registrabilidad";
export const BRANDS_REGISTRABILITY_STUDY_CHECKOUT =
  "/marcas/estudio-registrabilidad/checkout";
export const BRANDS_REGISTRABILITY_STUDY_CONFIRMATION =
  "/marcas/estudio-registrabilidad/confirmacion";
export const BRANDS_SELECTION_REGISTERED_MARK = "/marcas/registro-marca";
export const BRANDS_REGISTER_BRAND = "/marcas/registro-marca/registro-marca";
export const BRANDS_REGISTER_CHECKOUT = "/marcas/registro-marca/checkout";
export const BRANDS_REGISTER_CONFIRMATION =
  "/marcas/registro-marca/confirmacion";
export const BRANDS_TITULAR = "/marcas/titular";

export const OBRAS_CHECKOUT = "/obras/checkout";
export const OBRAS_REGISTER = "/obras/registro";
export const OBRAS_REGISTER_CONFIRMATION = "/obras/registro/confirmacion";

export const MUSICAL_GROUP_STUDY = "/grupos-musicales/estudio-registrabilidad";
export const MUSICAL_GROUP_STUDY_CHECKOUT =
  "/grupos-musicales/estudio-registrabilidad/checkout";
export const MUSICAL_GROUP_STUDY_CONFIRMATION =
  "/grupos-musicales/estudio-registrabilidad/confirmacion";
export const MUSICAL_GROUP_REGISTER = "/grupos-musicales/registro";
export const MUSICAL_GROUP_SERVICES = "/grupos-musicales/servicios";
export const MUSICAL_GROUP_TITULAR = "/grupos-musicales/registro/titular";
export const MUSICAL_GROUP_REGISTER_CHECKOUT =
  "/grupos-musicales/registro/checkout";
export const MUSICAL_GROUP_SELECT_STUDY = "/grupos-musicales/seleccion-estudio";
export const MUSICAL_GROUP_REGISTER_CONFIRMATION =
  "/grupos-musicales/registro/confirmacion";

export const MAGAZINES_SERVICES = "/revistas/servicios";
export const MAGAZINES_STUDY_REGISTER = "/revistas/estudio-registrabilidad";
export const MAGAZINES_STUDY_CHECKOUT =
  "/revistas/estudio-registrabilidad/checkout";
export const MAGAZINES_STUDY_CONFIRMATION =
  "/revistas/estudio-registrabilidad/confirmacion";
export const MAGAZINES_SELECT_STUDY = "/revistas/seleccion-estudio";
export const MAGAZINES_REGISTER = "/revistas/registro";
export const MAGAZINES_REGISTER_CHECKOUT = "/revistas/registro/checkout";
export const MAGAZINES_REGISTER_CONFIRMATION =
  "/revistas/registro/confirmacion";
export const MAGAZINES_REGISTER_TITULAR = "/revistas/registro/titular";

export const NEWSPAPERS_SERVICES = "/periodicos/servicios";
export const NEWSPAPERS_STUDY = "/periodicos/estudio-registrabilidad";
export const NEWSPAPERS_STUDY_CHECKOUT =
  "/periodicos/estudio-registrabilidad/checkout";
export const NEWSPAPERS_STUDY_CONFIRMATION =
  "/periodicos/estudio-registrabilidad/confirmacion";
export const NEWSPAPERS_SELECT_STUDY = "/periodicos/seleccion-estudio";
export const NEWSPAPERS_REGISTER_TITULAR = "/periodicos/registro/titular";
export const NEWSPAPERS_REGISTER_CONFIRMATION =
  "/periodicos/registro/confirmacion";
export const NEWSPAPERS_REGISTER_CHECKOUT = "/periodicos/registro/checkout";
export const NEWSPAPERS_REGISTER = "/periodicos/registro";

export const PUBLICATIONS_SERVICES = "/publicaciones/servicios";
export const PUBLICATIONS_STUDY_REGISTER =
  "/publicaciones/estudio-registrabilidad";
export const PUBLICATIONS_STUDY_CHECKOUT =
  "/publicaciones/estudio-registrabilidad/checkout";
export const PUBLICATIONS_STUDY_CONFIRMATION =
  "/publicaciones/estudio-registrabilidad/confirmacion";
export const PUBLICATIONS_REGISTER_SELECT_STUDY =
  "/publicaciones/seleccion-estudio";
export const PUBLICATIONS_REGISTER_TITULAR = "/publicaciones/registro/titular";
export const PUBLICATIONS_REGISTER_CHECKOUT =
  "/publicaciones/registro/checkout";
export const PUBLICATIONS_REGISTER_CONFIRMATION =
  "/publicaciones/registro/confirmacion";
export const PUBLICATIONS_REGISTER = "/publicaciones/registro";

export const DESIGNS_REGISTER = "/disenos/registro";
export const DESIGNS_CHECKOUT = "/disenos/checkout";
export const DESIGNS_CONFIRMATION = "/disenos/confirmacion";

export const PROFILE = "/perfil";
export const BUSINESS_NAME = "/razones-sociales";
export const BUSINESS_NAME_FORM = "/nueva-razon-social";
export const MY_REQUEST = "/mis-solicitudes";

export const RIGHTS_RENOVATIONS_REGISTER = "/derechos/renovaciones/registro";
export const RIGHTS_RENOVATIONS_CHECKOUT = "/derechos/renovaciones/checkout";
export const RIGHTS_RENOVATIONS_CONFIRM = "/derechos/renovaciones/confirmacion";
export const RIGHTS_RENOVATIIONS_SELECT =
  "/derechos/renovaciones/seleccion-derecho";

export const RIGHTS_LICENSE = "/derechos/licencia-derechos";
export const RIGHTS_LICENSE_CHECKOUT = "/derechos/licencia/checkout";
export const RIGHTS_LICENSE_CONFIRMATION = "/derechos/licencia/confirmacion";

export const RIGHTS_CESSION = "/derechos/cesion-derechos";
export const RIGHTS_CESSION_CHECKOUT = "/derechos/cesion/checkout";
export const RIGHTS_CESSION_CONFIRMATION = "/derechos/cesion/confirmacion";
export const RIGHTS_LIST_OF_RIGHTS = "/derechos/report/cesion-derechos";

export const REQUEST_PAYMENTS_IMPEDIMENTS = "/pago-impedimento/orden";
export const REQUEST_DECLARATION_TRADEMARK = "/declaracion-uso-marca/orden";
export const REQUEST_PAYMENTS_ANNIUTY = "/pago-anualidad/orden";
export const REQUEST_PAYMENTS_RENEWAL = "/pago-renovacion/orden";

export const TICKECT = "/ticket";
