import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "../../components";
import classes from "./receipt.module.css";
import { DASHBOARD_SERVICES, MY_REQUEST } from "../../constants/routes";

const Receipt = () => {
  const history = useHistory();
  const storePayment = useSelector((state) => state.payment);

  useEffect(() => {
    if (storePayment?.billing_result?.order_number === "") {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storePayment?.billing_result?.order_number]);

  const redirect = () => {
    history.replace(MY_REQUEST);
  };

  return (
    <div className="container-modulo">
      <div className="section-title">
        <h2 className="subtitle">¡MUCHAS GRACIAS POR TU SOLICITUD!</h2>
      </div>

      <div className="section-title">
        <h3 className="subtitle-medium">AGRADECEMOS TU PREFERENCIA</h3>
      </div>

      <div className="section-title">
        <div className={classes.textSubtitle}>
          TU NÚMERO DE CONFIRMACIÓN ES:
        </div>
        <div className={classes.confirmationNumber}>
          {storePayment?.billing_result?.order_number}
        </div>
      </div>

      <div className={classes.description}>
        <p>{storePayment?.billing_result?.confirmation_message}</p>
        <p>
          También podrás recibir actualizaciones del seguimiento de esta
          petición por correo electrónico y en esta aplicación vía push
          notifications y en el apartado de “Mis Solicitudes”.
        </p>
        <p>
          Si tienes alguna duda adicional no dudes en
          <span className={classes.textBlue}>CONTACTARTE CON NOSOTROS</span> y
          con gusto te atenderemos.
        </p>
      </div>

      <div className={classes.contentCenter}>
        <p className={classes.sincerely}>ATENTAMENTE</p>
        <p className={classes.team}>EL EQUIPO DE A TU ALCANCE</p>
        <div className={classes.btnSuccess}>
          <Button className={"btnConfirm"} onClick={redirect}>
            MIS SOLICITUDES
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Receipt;
