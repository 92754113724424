import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  BackDropFull,
  Button
} from '../../../../../components';

import ClassesList from './ClassesList/ClassesList';
import Search from './Search/Search';

import { registerActions } from '../../../../../store/slices/brandRegistrabilityStudy';
import { tradeMarkActions } from '../../../../../store/slices/tradeMark';
import './classesModal.css';

const ModalClases = (props) => {
  const dispatch = useDispatch();

  const productClassesRegister = useSelector(state => state.register.productClasses);
  const serviceClassesRegister = useSelector(state => state.register.servicesClasses);
  const productClassesTradeMark = useSelector(state => state.tradeMark.productClasses);
  const serviceClassesTradeMark = useSelector(state => state.tradeMark.servicesClasses);

  const productClass = (props.scope === 'registrabilityStudy')
    ? productClassesRegister
    : (props.scope === 'tradeMark')
      ? productClassesTradeMark
      : [];

  const serviceClass = (props.scope === 'registrabilityStudy')
    ? serviceClassesRegister
    : (props.scope === 'tradeMark')
      ? serviceClassesTradeMark
      : [];

  const [classesList, setClassesList] = useState([]);
  const [selectClases, setSelectClasses] = useState([]);

  const filteredClassesHandler = useCallback(filteredClasses => {
    setClassesList(filteredClasses);
  }, []);

  const selectedClassesHandler = useCallback(selectedCalsses => {
    setSelectClasses(selectedCalsses);
  }, []);


  const diffProducts = () => {
    const cselected = props.type === 'productos' ? [...productClass] : [...serviceClass];
    const pool = props.type === 'productos' ? [...productClass] : [...serviceClass];
    cselected.forEach(item => {
      let index = selectClases.unselected.findIndex(x => JSON.stringify(x) === JSON.stringify(item));
      if (index !== -1) {
        for (let i = 0; i < pool.length; i++) {
          if (pool[i].id === item.id) {
            pool.splice(i, 1);
          }
        }
      }
    });

    selectClases.selected.forEach(item => {
      let index = pool.findIndex(x => JSON.stringify(x) === JSON.stringify(item));
      if (index === -1) {
        pool.push(item);
      }
    });
    return pool;
  }

  const addHandler = () => {

    const pool = diffProducts();
    switch (props.type) {
      case "productos":
        if (props.scope === 'registrabilityStudy') {
          dispatch(registerActions.setProductClasses({ productClasses: pool }));
        } else if (props.scope === 'tradeMark') {
          dispatch(tradeMarkActions.setProductClasses({ productClasses: pool }));
        }
        break;
      case "servicios":
        if (props.scope === 'registrabilityStudy') {
          dispatch(registerActions.setServicesClasses({ servicesClasses: pool }));
        } else if (props.scope === 'tradeMark') {
          dispatch(tradeMarkActions.setServicesClasses({ servicesClasses: pool }));
        }
        break
      default:
        break;
    }
    setClassesList([]);
    props.closed();
  }

  const closeModal = () => {
    setClassesList([]);
    props.closed();
  }

  const modalHead = props.type === 'productos'
    ? (
      <div className="modal-head">
        <h1 className="modal-title">Agreaga clases de productos</h1>
        <p className="modal-description">Por favor escriba el nombre de cada producto individualmente para seleccionar las clases asociadas a cada uno de ellos.</p>
      </div>
    ) : props.type === 'servicios'
      ? (
        <div className="modal-head">
          <h1 className="modal-title">Agreaga clases de servicios</h1>
          <p className="modal-description">Por favor escriba el nombre de cada servicio individualmente para seleccionar las clases asociadas a cada uno de ellos.</p>
        </div>
      )
      : null;

  return (
    <React.Fragment>
      <Modal show={props.show} closed={props.closed}>
        <div className="ct-modal-content">
          {modalHead}
          <div className="modal-main-content">
            <div className="search-content">
              <Search
                onLoadClasses={filteredClassesHandler}
                type={props.type} />
              {
                classesList.length > 0
                  ? (
                    <>
                      <ClassesList
                        onSelectClasses={selectedClassesHandler}
                        classesList={classesList}
                        selectedItems={props.type === 'productos' ? productClass : serviceClass} />
                      <div className="button-modal">
                        <Button
                          onClick={addHandler}
                          className={'btnConfirm'}>Agregar</Button>
                      </div>
                    </>
                  )
                  : null
              }
              <div className="button-modal">
                <Button
                  onClick={closeModal}
                  className={'btnCancel'}>Cancelar</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <BackDropFull show={props.show} />
    </React.Fragment>
  );
}

export default React.memo(ModalClases);