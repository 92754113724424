import React from 'react';
import PropTypes from 'prop-types';

import classes from './InputSelect.module.css';

const InputSelect = (props) => {

  return (
    <select
      key={props.id}
      id={props.id}
      name={props.name}
      onChange={props.onChange}
      onBlur={props.onBlur}
      className={`${classes.inputSelect} ${classes[props.className]} ${props.valid && classes.invalid}`}
      value={props.value}
      defaultValue={props.defaultValue}
    >
      {props.placeholder ? <option key={-1} value={-1}>{props?.placeholder}</option> : null}
      {props?.options?.map(item => {
        return (
          props.optionsType === 'array'
            ? <option key={Math.random(2 * 100)} value={item} >{item}</option>
            : <option key={`${item[props.fieldIdOption]}_${Math.random(2 * 100)}`} value={item[props.fieldIdOption]}>{item.name}</option>
        )
      })}
    </select>
  );
}

InputSelect.propTypes = {
  options: PropTypes.array.isRequired, //array options
  fieldIdOption: PropTypes.string.isRequired, //name field id option
  optionsType: PropTypes.string,  //if options are single array
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  valid: PropTypes.bool
}

export default React.memo(InputSelect);
