export const formatDate = (date) => {
  if (typeof date !== "undefined") {
    const dateString = `${date.getFullYear()}-${
      date.getMonth() + 1 < 10 ? "0" : ""
    }${date.getMonth() + 1}-${date.getDate() < 10 ? "0" : ""}${date.getDate()}`;
    return dateString;
  } else {
    return "";
  }
};

export const validateDates = (initial_date, final_date) => {
  let valid = true
  
  if (Date.parse(initial_date) < Date.parse(final_date)){
    valid = false
  }

  return valid;
}
