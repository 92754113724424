import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import * as yup from "yup";

import InputSimpleFloatLabel from "../../UI/Input/InputSimpleFloatLabel/InputSimpleFloatLabel";
import { registerActions } from "../../../store/slices/brandRegistrabilityStudy";
import { tradeMarkActions } from "../../../store/slices/tradeMark";
import { obrasActions } from "../../../store/slices/obras";
import { InputSelect } from "../../../components";
import { listStates } from "../../../utils/states";
import { listCity } from "../../../utils/states-city";
import { typePerson } from "../../../utils/typePerson";
import { getFileName } from "../../../utils/scripts/file";
import { BtnCallToAction, ModalDrag } from "../../../components";

import classes from "./HolderForm.module.css";

const HolderForm = (props) => {
  const dispatch = useDispatch();
  const index = props.recordIndex;
  const storeHolder = useSelector((state) => state.tradeMark.owner_info);
  const storeObraOwner = useSelector((state) => state.obras.owner_info);
  const storeObraAuthor = useSelector((state) => state.obras.author_info);
  const storeObrasCoauthor = useSelector(
    (state) => state.obras.coauthor_info[index]
  );
  let globalStore;
  switch (props.entity) {
    case "author-obra":
      globalStore = storeObraAuthor;
      break;
    case "titular-obra":
      globalStore = storeObraOwner;
      break;
    case "titular-brand":
      globalStore = storeHolder;
      break;
    case "coauthor-obra-1":
    case "coauthor-obra-2":
    case "coauthor-obra-3":
    case "coauthor-obra-4":
      globalStore = storeObrasCoauthor;
      break;

    default:
      break;
  }

  const [stateListCities, setStateListCities] = useState([]);
  const [dragModal, setDragModal] = useState(false);
  const [dataTitular, setDataTitular] = useState([
    {
      type: { valid: false, touched: false, value: "" },
      rfc: { valid: false, touched: false, value: "" },
      curp: { valid: false, touched: false, value: "" },
      titularName: { valid: false, touched: false, value: "" },
      street: { valid: false, touched: false, value: "" },
      extNumber: { valid: false, touched: false, value: "" },
      intNumber: { valid: true, touched: false, value: "" },
      email: { valid: false, touched: false, value: "" },
      phone: { valid: false, touched: false, value: "" },
      state: { valid: false, touched: false, value: "" },
      city: { valid: false, touched: false, value: "" },
      postalCode: { valid: false, touched: false, value: "" },
      neighborhood: { valid: false, touched: false, value: "" },
      file: { valid: false, touched: false, value: "" },
    },
  ]);

  useEffect(() => {
    dataTitular[0].file.value = globalStore.file;
  }, [dataTitular, globalStore]);

  useEffect(() => {
    if (dataTitular[0].state.value !== -1) {
      setStateListCities(
        typeof listCity[globalStore.state] === "undefined"
          ? []
          : listCity[globalStore.state]
      );
    }
  }, [dataTitular, globalStore]);

  const openDragModal = () => {
    setDragModal(true);
  };

  const closeDragModal = () => {
    setDragModal(false);
  };

  const clearFileUploaded = () => {
    switch (props.recordTypeFile) {
      case "study-brand-register":
        dispatch(registerActions.setFile({ file: "" }));
        dataTitular[0].file.value = "";
        break;

      case "brand-register":
        dispatch(tradeMarkActions.setFile({ file: "" }));
        dataTitular[0].file.value = "";
        break;

      case "brand-owner-info":
        dispatch(tradeMarkActions.setHolderFile({ file: "" }));
        dataTitular[0].file.value = "";
        break;

      case "obras-register":
        dispatch(obrasActions.setFile({ file: "" }));
        dataTitular[0].file.value = "";
        break;

      case "obras-owner-info":
        dispatch(obrasActions.setOwnerInfoFile({ file: "" }));
        dataTitular[0].file.value = "";
        break;

      case "obras-author-info":
        dispatch(obrasActions.setAuthorInfoFile({ file: "" }));
        dataTitular[0].file.value = "";
        break;

      case "obras-coauthor-info":
        dispatch(obrasActions.setCoAuthorInfoFile({ file: "", index: index }));
        dataTitular[0].file.value = "";
        break;

      default:
        break;
    }
  };

  const setStorage = (entity, field, value) => {
    switch (field) {
      case `type_${props.entity}`:
        entity === "titular-brand" &&
          dispatch(tradeMarkActions.setHolderType({ type: value }));
        entity === "titular-obra" &&
          dispatch(obrasActions.setOwnerInfoType({ type: value }));
        entity === "author-obra" &&
          dispatch(obrasActions.setAuthorInfoType({ type: value }));
        entity === "coauthor-obra-1" &&
          dispatch(
            obrasActions.setCoAuthorInfoType({ type: value, index: index })
          );
        entity === "coauthor-obra-2" &&
          dispatch(
            obrasActions.setCoAuthorInfoType({ type: value, index: index })
          );
        entity === "coauthor-obra-3" &&
          dispatch(
            obrasActions.setCoAuthorInfoType({ type: value, index: index })
          );
        entity === "coauthor-obra-4" &&
          dispatch(
            obrasActions.setCoAuthorInfoType({ type: value, index: index })
          );

        break;
      case `curp_${props.entity}`:
        entity === "titular-brand" &&
          dispatch(tradeMarkActions.setHolderCurp({ curp: value }));
        entity === "titular-obra" &&
          dispatch(obrasActions.setOwnerInfoCurp({ curp: value }));
        entity === "author-obra" &&
          dispatch(obrasActions.setAuthorInfoCurp({ curp: value }));
        entity === "coauthor-obra-1" &&
          dispatch(
            obrasActions.setCoAuthorInfoCurp({ curp: value, index: index })
          );
        entity === "coauthor-obra-2" &&
          dispatch(
            obrasActions.setCoAuthorInfoCurp({ curp: value, index: index })
          );
        entity === "coauthor-obra-3" &&
          dispatch(
            obrasActions.setCoAuthorInfoCurp({ curp: value, index: index })
          );
        entity === "coauthor-obra-4" &&
          dispatch(
            obrasActions.setCoAuthorInfoCurp({ curp: value, index: index })
          );

        break;
      case `rfc_${props.entity}`:
        entity === "titular-brand" &&
          dispatch(tradeMarkActions.setHolderRfc({ rfc: value }));
        entity === "titular-obra" &&
          dispatch(obrasActions.setOwnerInfoRfc({ rfc: value }));
        entity === "author-obra" &&
          dispatch(obrasActions.setAuthorInfoRfc({ rfc: value }));
        entity === "coauthor-obra-1" &&
          dispatch(
            obrasActions.setCoAuthorInfoRfc({ rfc: value, index: index })
          );
        entity === "coauthor-obra-2" &&
          dispatch(
            obrasActions.setCoAuthorInfoRfc({ rfc: value, index: index })
          );
        entity === "coauthor-obra-3" &&
          dispatch(
            obrasActions.setCoAuthorInfoRfc({ rfc: value, index: index })
          );
        entity === "coauthor-obra-4" &&
          dispatch(
            obrasActions.setCoAuthorInfoRfc({ rfc: value, index: index })
          );
        break;
      case `titularName_${props.entity}`:
        entity === "titular-brand" &&
          dispatch(tradeMarkActions.setHolderName({ name: value }));
        entity === "titular-obra" &&
          dispatch(obrasActions.setOwnerInfoName({ name: value }));
        entity === "author-obra" &&
          dispatch(obrasActions.setAuthorInfoName({ name: value }));
        entity === "coauthor-obra-1" &&
          dispatch(
            obrasActions.setCoAuthorInfoName({ name: value, index: index })
          );
        entity === "coauthor-obra-2" &&
          dispatch(
            obrasActions.setCoAuthorInfoName({ name: value, index: index })
          );
        entity === "coauthor-obra-3" &&
          dispatch(
            obrasActions.setCoAuthorInfoName({ name: value, index: index })
          );
        entity === "coauthor-obra-4" &&
          dispatch(
            obrasActions.setCoAuthorInfoName({ name: value, index: index })
          );
        break;
      case `street_${props.entity}`:
        entity === "titular-brand" &&
          dispatch(tradeMarkActions.setHolderStreetName({ streetName: value }));
        entity === "titular-obra" &&
          dispatch(obrasActions.setOwnerInfoStreetName({ streetName: value }));
        entity === "author-obra" &&
          dispatch(obrasActions.setAuthorInfoStreetName({ streetName: value }));
        entity === "coauthor-obra-1" &&
          dispatch(
            obrasActions.setCoAuthorInfoStreetName({
              streetName: value,
              index: index,
            })
          );
        entity === "coauthor-obra-2" &&
          dispatch(
            obrasActions.setCoAuthorInfoStreetName({
              streetName: value,
              index: index,
            })
          );
        entity === "coauthor-obra-3" &&
          dispatch(
            obrasActions.setCoAuthorInfoStreetName({
              streetName: value,
              index: index,
            })
          );
        entity === "coauthor-obra-4" &&
          dispatch(
            obrasActions.setCoAuthorInfoStreetName({
              streetName: value,
              index: index,
            })
          );
        break;
      case `extNumber_${props.entity}`:
        entity === "titular-brand" &&
          dispatch(tradeMarkActions.setHolderExtNumber({ extNumber: value }));
        entity === "titular-obra" &&
          dispatch(obrasActions.setOwnerInfoExtNumber({ extNumber: value }));
        entity === "author-obra" &&
          dispatch(obrasActions.setAuthorInfoExtNumber({ extNumber: value }));
        entity === "coauthor-obra-1" &&
          dispatch(
            obrasActions.setCoAuthorInfoExtNumber({
              extNumber: value,
              index: index,
            })
          );
        entity === "coauthor-obra-2" &&
          dispatch(
            obrasActions.setCoAuthorInfoExtNumber({
              extNumber: value,
              index: index,
            })
          );
        entity === "coauthor-obra-3" &&
          dispatch(
            obrasActions.setCoAuthorInfoExtNumber({
              extNumber: value,
              index: index,
            })
          );
        entity === "coauthor-obra-4" &&
          dispatch(
            obrasActions.setCoAuthorInfoExtNumber({
              extNumber: value,
              index: index,
            })
          );
        break;
      case `intNumber_${props.entity}`:
        entity === "titular-brand" &&
          dispatch(tradeMarkActions.setHolderIntNumber({ intNumber: value }));
        entity === "titular-obra" &&
          dispatch(obrasActions.setOwnerInfoIntNumber({ intNumber: value }));
        entity === "author-obra" &&
          dispatch(obrasActions.setAuthorInfoIntNumber({ intNumber: value }));
        entity === "coauthor-obra-1" &&
          dispatch(
            obrasActions.setCoAuthorInfoIntNumber({
              intNumber: value,
              index: index,
            })
          );
        entity === "coauthor-obra-2" &&
          dispatch(
            obrasActions.setCoAuthorInfoIntNumber({
              intNumber: value,
              index: index,
            })
          );
        entity === "coauthor-obra-3" &&
          dispatch(
            obrasActions.setCoAuthorInfoIntNumber({
              intNumber: value,
              index: index,
            })
          );
        entity === "coauthor-obra-4" &&
          dispatch(
            obrasActions.setCoAuthorInfoIntNumber({
              intNumber: value,
              index: index,
            })
          );
        break;
      case `email_${props.entity}`:
        entity === "titular-brand" &&
          dispatch(tradeMarkActions.setHolderEmail({ email: value }));
        entity === "titular-obra" &&
          dispatch(obrasActions.setOwnerInfoEmail({ email: value }));
        entity === "author-obra" &&
          dispatch(obrasActions.setAuthorInfoEmail({ email: value }));
        entity === "coauthor-obra-1" &&
          dispatch(
            obrasActions.setCoAuthorInfoEmail({ email: value, index: index })
          );
        entity === "coauthor-obra-2" &&
          dispatch(
            obrasActions.setCoAuthorInfoEmail({ email: value, index: index })
          );
        entity === "coauthor-obra-3" &&
          dispatch(
            obrasActions.setCoAuthorInfoEmail({ email: value, index: index })
          );
        entity === "coauthor-obra-4" &&
          dispatch(
            obrasActions.setCoAuthorInfoEmail({ email: value, index: index })
          );
        break;
      case `phone_${props.entity}`:
        entity === "titular-brand" &&
          dispatch(tradeMarkActions.setHolderPhone({ phone: value }));
        entity === "titular-obra" &&
          dispatch(obrasActions.setOwnerInfoPhone({ phone: value }));
        entity === "author-obra" &&
          dispatch(obrasActions.setAuthorInfoPhone({ phone: value }));
        entity === "coauthor-obra-1" &&
          dispatch(
            obrasActions.setCoAuthorInfoPhone({ phone: value, index: index })
          );
        entity === "coauthor-obra-2" &&
          dispatch(
            obrasActions.setCoAuthorInfoPhone({ phone: value, index: index })
          );
        entity === "coauthor-obra-3" &&
          dispatch(
            obrasActions.setCoAuthorInfoPhone({ phone: value, index: index })
          );
        entity === "coauthor-obra-4" &&
          dispatch(
            obrasActions.setCoAuthorInfoPhone({ phone: value, index: index })
          );
        break;
      case `state_${props.entity}`:
        entity === "titular-brand" &&
          dispatch(tradeMarkActions.setHolderState({ state: value }));
        entity === "titular-obra" &&
          dispatch(obrasActions.setOwnerInfoState({ state: value }));
        entity === "author-obra" &&
          dispatch(obrasActions.setAuthorInfoState({ state: value }));
        entity === "coauthor-obra-1" &&
          dispatch(
            obrasActions.setCoAuthorInfoState({ state: value, index: index })
          );
        entity === "coauthor-obra-2" &&
          dispatch(
            obrasActions.setCoAuthorInfoState({ state: value, index: index })
          );
        entity === "coauthor-obra-3" &&
          dispatch(
            obrasActions.setCoAuthorInfoState({ state: value, index: index })
          );
        entity === "coauthor-obra-4" &&
          dispatch(
            obrasActions.setCoAuthorInfoState({ state: value, index: index })
          );
        break;
      case `city_${props.entity}`:
        entity === "titular-brand" &&
          dispatch(tradeMarkActions.setHolderCity({ city: value }));
        entity === "titular-obra" &&
          dispatch(obrasActions.setOwnerInfoCity({ city: value }));
        entity === "author-obra" &&
          dispatch(obrasActions.setAuthorInfoCity({ city: value }));
        entity === "coauthor-obra-1" &&
          dispatch(
            obrasActions.setCoAuthorInfoCity({ city: value, index: index })
          );
        entity === "coauthor-obra-2" &&
          dispatch(
            obrasActions.setCoAuthorInfoCity({ city: value, index: index })
          );
        entity === "coauthor-obra-3" &&
          dispatch(
            obrasActions.setCoAuthorInfoCity({ city: value, index: index })
          );
        entity === "coauthor-obra-4" &&
          dispatch(
            obrasActions.setCoAuthorInfoCity({ city: value, index: index })
          );
        break;
      case `postalCode_${props.entity}`:
        entity === "titular-brand" &&
          dispatch(tradeMarkActions.setHolderPostalCode({ postalCode: value }));
        entity === "titular-obra" &&
          dispatch(obrasActions.setOwnerInfoPostalCode({ postalCode: value }));
        entity === "author-obra" &&
          dispatch(obrasActions.setAuthorInfoPostalCode({ postalCode: value }));
        entity === "coauthor-obra-1" &&
          dispatch(
            obrasActions.setCoAuthorInfoPostalCode({
              postalCode: value,
              index: index,
            })
          );
        entity === "coauthor-obra-2" &&
          dispatch(
            obrasActions.setCoAuthorInfoPostalCode({
              postalCode: value,
              index: index,
            })
          );
        entity === "coauthor-obra-3" &&
          dispatch(
            obrasActions.setCoAuthorInfoPostalCode({
              postalCode: value,
              index: index,
            })
          );
        entity === "coauthor-obra-4" &&
          dispatch(
            obrasActions.setCoAuthorInfoPostalCode({
              postalCode: value,
              index: index,
            })
          );
        break;
      case `neighborhood_${props.entity}`:
        entity === "titular-brand" &&
          dispatch(
            tradeMarkActions.setHolderNeighborhood({ neighborhood: value })
          );
        entity === "titular-obra" &&
          dispatch(
            obrasActions.setOwnerInfoNeighborhood({ neighborhood: value })
          );
        entity === "author-obra" &&
          dispatch(
            obrasActions.setAuthorInfoNeighborhood({ neighborhood: value })
          );
        entity === "coauthor-obra-1" &&
          dispatch(
            obrasActions.setCoAuthorInfoNeighborhood({
              neighborhood: value,
              index: index,
            })
          );
        entity === "coauthor-obra-2" &&
          dispatch(
            obrasActions.setCoAuthorInfoNeighborhood({
              neighborhood: value,
              index: index,
            })
          );
        entity === "coauthor-obra-3" &&
          dispatch(
            obrasActions.setCoAuthorInfoNeighborhood({
              neighborhood: value,
              index: index,
            })
          );
        entity === "coauthor-obra-4" &&
          dispatch(
            obrasActions.setCoAuthorInfoNeighborhood({
              neighborhood: value,
              index: index,
            })
          );
        break;
      default:
        break;
    }
  };

  const onchangeHandler = async (event) => {
    let changeTitular = [...dataTitular];
    switch (event.target.id) {
      case `type_${props.entity}`:
        changeTitular[0].type.value = event.target.value;
        changeTitular[0].type.touched = true;
        changeTitular[0].type.valid = await yup
          .number()
          .required()
          .isValid(event.target.value);
        setStorage(props.entity, event.target.id, changeTitular[0].type.value);
        break;
      case `rfc_${props.entity}`:
        changeTitular[0].rfc.value = event.target.value;
        changeTitular[0].rfc.touched = true;
        changeTitular[0].rfc.valid = await yup
          .string()
          .required()
          .min(12)
          .max(13)
          .matches(
            /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
          )
          .isValid(event.target.value);
        setStorage(props.entity, event.target.id, changeTitular[0].rfc.value);
        break;
      case `curp_${props.entity}`:
        changeTitular[0].curp.value = event.target.value;
        changeTitular[0].curp.touched = true;
        changeTitular[0].curp.valid = await yup
          .string()
          .required()
          .min(18)
          .max(18)
          .matches(
            /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/
          )
          .isValid(event.target.value);
        setStorage(props.entity, event.target.id, changeTitular[0].curp.value);
        break;
      case `titularName_${props.entity}`:
        changeTitular[0].titularName.value = event.target.value;
        changeTitular[0].titularName.touched = true;
        changeTitular[0].titularName.valid = await yup
          .string()
          .required()
          .matches(/^[,.@\-_' áéíóúÁÉÍÓÚñÑa-zA-Z0-9]+$/)
          .isValid(event.target.value);
        setStorage(
          props.entity,
          event.target.id,
          changeTitular[0].titularName.value
        );
        break;
      case `street_${props.entity}`:
        changeTitular[0].street.value = event.target.value;
        changeTitular[0].street.touched = true;
        changeTitular[0].street.valid = await yup
          .string()
          .required()
          .matches(/^[,.@\-_'áéíóúÁÉÍÓÚñÑ a-zA-Z0-9]+$/)
          .isValid(event.target.value);
        setStorage(
          props.entity,
          event.target.id,
          changeTitular[0].street.value
        );
        break;
      case `extNumber_${props.entity}`:
        changeTitular[0].extNumber.value = event.target.value;
        changeTitular[0].extNumber.touched = true;
        changeTitular[0].extNumber.valid = await yup
          .string()
          .matches(/^[-# a-zA-Z0-9]+$/)
          .required()
          .isValid(event.target.value);
        setStorage(
          props.entity,
          event.target.id,
          changeTitular[0].extNumber.value
        );
        break;
      case `intNumber_${props.entity}`:
        changeTitular[0].intNumber.value = event.target.value;
        changeTitular[0].intNumber.touched = true;
        changeTitular[0].intNumber.valid = await yup
          .string()
          .isValid(event.target.value);
        setStorage(
          props.entity,
          event.target.id,
          changeTitular[0].intNumber.value
        );
        break;
      case `email_${props.entity}`:
        changeTitular[0].email.value = event.target.value;
        changeTitular[0].email.touched = true;
        changeTitular[0].email.valid = await yup
          .string()
          .email()
          .required()
          .isValid(event.target.value);
        setStorage(props.entity, event.target.id, changeTitular[0].email.value);
        break;
      case `phone_${props.entity}`:
        changeTitular[0].phone.value = event.target.value;
        changeTitular[0].phone.touched = true;
        changeTitular[0].phone.valid = await yup
          .string()
          .required()
          .matches(/^[0-9]+$/)
          .isValid(event.target.value);
        setStorage(props.entity, event.target.id, changeTitular[0].phone.value);
        break;
      case `state_${props.entity}`:
        changeTitular[0].state.value = event.target.value;
        changeTitular[0].state.touched = true;
        changeTitular[0].state.valid = await yup
          .string()
          .required()
          .matches("/^[a-zA-Z]+$/")
          .isValid(event.target.value);
        setStateListCities(
          typeof listCity[changeTitular[0].state.value] === "undefined"
            ? []
            : listCity[changeTitular[0].state.value]
        );
        setStorage(props.entity, event.target.id, changeTitular[0].state.value);
        break;
      case `city_${props.entity}`:
        changeTitular[0].city.value = event.target.value;
        changeTitular[0].city.touched = true;
        changeTitular[0].city.valid = await yup
          .string()
          .required()
          .matches(/^[áéíóúÁÉÍÓÚñÑ a-zA-Z-,.]+$/)
          .isValid(event.target.value);
        setStorage(props.entity, event.target.id, changeTitular[0].city.value);
        break;
      case `postalCode_${props.entity}`:
        changeTitular[0].postalCode.value = event.target.value;
        changeTitular[0].postalCode.touched = true;
        changeTitular[0].postalCode.valid = await yup
          .string()
          .required()
          .matches(/^[0-9]+$/)
          .max(5)
          .isValid(event.target.value);
        setStorage(
          props.entity,
          event.target.id,
          changeTitular[0].postalCode.value
        );
        break;
      case `neighborhood_${props.entity}`:
        changeTitular[0].neighborhood.value = event.target.value;
        changeTitular[0].neighborhood.touched = true;
        changeTitular[0].neighborhood.valid = await yup
          .string()
          .required()
          .matches(/^[áéíóúÁÉÍÓÚñÑ a-zA-Z]+$/)
          .isValid(event.target.value);
        setStorage(
          props.entity,
          event.target.id,
          changeTitular[0].neighborhood.value
        );
        break;
      default:
        break;
    }
    setDataTitular(changeTitular);
  };
  return (
    <div>
      {dragModal && (
        <ModalDrag
          show={dragModal}
          closed={closeDragModal}
          scope={props.recordTypeFile}
          formatFile="pdf"
          recordIndex={props.recordIndex}
        />
      )}
      <div className={classes.oneColumnForm}>
        <InputSelect
          id={`type_${props.entity}`}
          name={`type_${props.entity}`}
          placeholder={props.textTypeEntity}
          options={typePerson}
          fieldIdOption="id"
          value={globalStore.type}
          onChange={onchangeHandler}
        />

        <InputSimpleFloatLabel
          id={`titularName_${props.entity}`}
          name={`titularName_${props.entity}`}
          placeholder="NOMBRE DEL TITULAR O RAZÓN SOCIAL"
          onChange={onchangeHandler}
          defaultValue={globalStore.name}
          valid={dataTitular[0].titularName.valid}
          touched={dataTitular[0].titularName.touched}
        />
        {dataTitular[0].file.value === "" ? (
          <BtnCallToAction onClick={openDragModal} icon="faUpload">
            {globalStore.type === "2"
              ? "ACTA CONSTITUTIVA"
              : "IDENTIFICACIÓN OFICIAL"}
          </BtnCallToAction>
        ) : (
          <BtnCallToAction
            type="showfile"
            onClick={clearFileUploaded}
            icon="faTimesCircle"
            className="btnGreen"
            fileName={getFileName(dataTitular[0].file.value)}
          >
            {globalStore.type === "2"
              ? "ACTA CONSTITUTIVA"
              : "IDENTIFICACIÓN OFICIAL"}
          </BtnCallToAction>
        )}
        <div className={classes.formatTextDescription}>
          Esté archivo deberá ser en formato PDF
        </div>
        {globalStore.type === "2" && (
          <div
            className={classes.formatTextDescription}
            style={{ color: "red" }}
          >
            <b>IMPORTANTE:</b> Si eres una persona moral, en el campo de CURP
            por favor colocar el siguiente generico: XEXX010101HNEXXXA4
          </div>
        )}
      </div>
      <div className={classes.formFields}>
        <InputSimpleFloatLabel
          id={`rfc_${props.entity}`}
          name={`rfc_${props.entity}`}
          placeholder="RFC"
          onChange={onchangeHandler}
          defaultValue={globalStore.rfc}
          valid={dataTitular[0].rfc.valid}
          touched={dataTitular[0].rfc.touched}
        />

        <InputSimpleFloatLabel
          id={`curp_${props.entity}`}
          name={`curp_${props.entity}`}
          placeholder="CURP"
          onChange={onchangeHandler}
          defaultValue={globalStore.curp}
          valid={dataTitular[0].curp.valid}
          touched={dataTitular[0].curp.touched}
        />

        <InputSimpleFloatLabel
          id={`street_${props.entity}`}
          name={`street_${props.entity}`}
          placeholder="CALLE"
          onChange={onchangeHandler}
          defaultValue={globalStore.street}
          valid={dataTitular[0].street.valid}
          touched={dataTitular[0].street.touched}
        />

        <InputSimpleFloatLabel
          id={`extNumber_${props.entity}`}
          name={`extNumber_${props.entity}`}
          placeholder="NÚMERO EXTERIOR"
          onChange={onchangeHandler}
          defaultValue={globalStore.ext_number}
          valid={dataTitular[0].extNumber.valid}
          touched={dataTitular[0].extNumber.touched}
        />

        <InputSimpleFloatLabel
          id={`intNumber_${props.entity}`}
          name={`intNumber_${props.entity}`}
          placeholder="NÚMERO INTERIOR"
          onChange={onchangeHandler}
          defaultValue={globalStore.int_number}
          valid={dataTitular[0].intNumber.valid}
          touched={dataTitular[0].intNumber.touched}
        />

        <InputSelect
          id={`state_${props.entity}`}
          name={`state_${props.entity}`}
          placeholder="- ESTADO -"
          options={listStates}
          fieldIdOption="id"
          value={globalStore.state}
          onChange={onchangeHandler}
        />

        <InputSelect
          id={`city_${props.entity}`}
          name={`city_${props.entity}`}
          placeholder="- CIUDAD -"
          options={stateListCities}
          optionsType="array"
          fieldIdOption="id"
          onChange={onchangeHandler}
          value={globalStore.city}
        />

        <InputSimpleFloatLabel
          id={`neighborhood_${props.entity}`}
          name={`neighborhood_${props.entity}`}
          placeholder="COLONIA "
          onChange={onchangeHandler}
          defaultValue={globalStore.neighborhood}
          valid={dataTitular[0].neighborhood.valid}
          touched={dataTitular[0].neighborhood.touched}
        />

        <InputSimpleFloatLabel
          id={`postalCode_${props.entity}`}
          name={`postalCode_${props.entity}`}
          placeholder="CÓDIGO POSTAL"
          onChange={onchangeHandler}
          defaultValue={globalStore.postal_code}
          valid={dataTitular[0].postalCode.valid}
          touched={dataTitular[0].postalCode.touched}
        />

        <InputSimpleFloatLabel
          id={`phone_${props.entity}`}
          name={`phone_${props.entity}`}
          placeholder="TELÉFONO"
          onChange={onchangeHandler}
          defaultValue={globalStore.phone}
          valid={dataTitular[0].phone.valid}
          touched={dataTitular[0].phone.touched}
        />

        <InputSimpleFloatLabel
          id={`email_${props.entity}`}
          name={`email_${props.entity}`}
          type="email"
          placeholder="CORREO ELECTRÓNICO"
          onChange={onchangeHandler}
          defaultValue={globalStore.email}
          valid={dataTitular[0].email.valid}
          touched={dataTitular[0].email.touched}
        />
      </div>
    </div>
  );
};

HolderForm.propTypes = {
  /** Who is the entity we are registering */
  entity: PropTypes.string.isRequired,
  /** Where we need to save the file of this record */
  recordTypeFile: PropTypes.string.isRequired,
  /** Index of co-author (record) object */
  recordIndex: PropTypes.number,
  /** PLACE HOLDER to type of entity */
  textTypeEntity: PropTypes.string,
};

export default HolderForm;
