import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RowBack } from "../../../assets/images/icons/icons";
import {
  InputSelect,
  Button,
  InputRadio,
  ErrorMessage,
  Spinner,
} from "../../../components";
import { tradeMarkActions } from "../../../store/slices/tradeMark";
import { userProfileActions } from "../../../store/slices/userProfile";
import {
  BRANDS_REGISTER_BRAND,
  BRANDS_SERVICES,
  BRANDS_TITULAR,
} from "../../../constants/routes";
import classes from "./RegistroMarca.module.css";

const RegistroMarca = (props) => {
  const dispatch = useDispatch();
  const isAdditionalBrand = useSelector(
    (state) => state.tradeMark.isAdditionalBrand
  );
  const services = useSelector((state) => state.userProfile.userServices);
  const brandId = useSelector((state) => state.tradeMark.brandBelongsId);

  const [validForm, setValidFrom] = useState(true);

  useEffect(() => {
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getUserServices());
  }, [dispatch]);

  const goBack = () => {
    props.history.replace(BRANDS_SERVICES);
  };

  const handleChangeRadio = (event) => {
    dispatch(
      tradeMarkActions.setIsAdditionalBrand({
        isAdditionalBrand: event.target.value === "si",
      })
    );
  };

  const handleChangeBrand = (event) => {
    const { options, selectedIndex } = event.target;
    const nameBrand = options[selectedIndex].innerHTML;

    dispatch(tradeMarkActions.setBrandId({ brandBelongsId: event.target.value }));
    dispatch(tradeMarkActions.setName({ name: nameBrand }));
    const registrabilityStudy = services.filter(item =>  item.service_order_id === +event.target.value)
    dispatch(tradeMarkActions.setProductClasses({ productClasses: registrabilityStudy[0].product_classes }))
    dispatch(tradeMarkActions.setServicesClasses({ servicesClasses: registrabilityStudy[0].service_classes }))
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isAdditionalBrand) {
      setValidFrom(true);
      props.history.replace(BRANDS_REGISTER_BRAND);
    } else {
      if (event.target.brands.value === "-1") {
        setValidFrom(false);
      } else {
        setValidFrom(true);
        props.history.replace(BRANDS_TITULAR);
      }
    }
  };

  return (
    <>
      {services.length === 0 ? (
        <div className="container-modulo">
          <div className="link-back" onClick={goBack}>
            <img src={RowBack} alt="regresar" />
            &nbsp;&nbsp;REGRESAR
          </div>
          <div className="section-title">
            <h2 className="subtitle">REGISTRO MARCA</h2>
          </div>
          <Spinner />
        </div>
      ) : (
        <div className="container-modulo">
          <div className="link-back" onClick={goBack}>
            <img src={RowBack} alt="regresar" />
            &nbsp;&nbsp;REGRESAR
          </div>
          <div className="section-title">
            <h2 className="subtitle">REGISTRO MARCA</h2>
          </div>
          <div className="text-description">
            <p>
              Protege el nombre, sonido, logotipo o frase comercial con el que
              deseas distinguir tus productos o servicios.
            </p>
          </div>
          <div className="label-information">
            <div>SELECCIÓN DE LA MARCA</div>
          </div>

          <div className={classes.marginTextDescription}>
            <p>
              Hemos identificado que has realizado uno o más estudios de
              registrabilidad de nombre de marca previamente con nosotros.{" "}
            </p>
            <p>
              Por favor indica si la marca que deseas registrar corresponde a
              alguna de estas, o bien si es un nombre de marca adicional.
            </p>
          </div>

          <div className={classes.contentRadioSelect}>
            <form onSubmit={handleSubmit}>
              <InputRadio
                id="isAdditional"
                value="no"
                name="isAdditional"
                onChange={handleChangeRadio}
                checked={isAdditionalBrand === false}
                label="MI MARCA CORRESPONDE A:"
              />
              {isAdditionalBrand === false && (
                <div className={classes.formElement}>
                  <InputSelect
                    id="brands"
                    name="brands"
                    placeholder="-ELIGE TU MARCA-"
                    options={services}
                    fieldIdOption="service_order_id"
                    value={brandId}
                    onChange={handleChangeBrand}
                  />
                </div>
              )}
              <InputRadio
                id="isAditional"
                value="si"
                name="isAdditional"
                onChange={handleChangeRadio}
                checked={isAdditionalBrand === true}
                label="MI MARCA ES UNA MARCA ADICIONAL"
              />
              {!validForm && (
                <ErrorMessage message="Favor de llenar todos los campos correctamente" />
              )}
              <div className={classes.contentButtonNext}>
                <Button type="submit" className="btnConfirm">
                  CONTINUAR
                </Button>
              </div>
            </form>
          </div>

          <div className="label-information">
            <div>INFORMACIÓN DE LA MARCA</div>
          </div>
          <div className="label-information">
            <div>INFORMACIÓN DEL PAGO</div>
          </div>
          <div className="label-information">
            <div>CONFIRMACIÓN</div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegistroMarca;
