export const typeRights = [
  {
    id: "3",
    name: "Registro de Marca",
  },
  {
    id: "4",
    name: "Obras",
  },
  {
    id: "7",
    name: "Registro de Nombre de Grupos Musicales",
  },
  {
    id: "10",
    name: "Registro de Nombre de Revistas",
  },
  {
    id: "13",
    name: "Registro de Nombre de Periódicos",
  },
  {
    id: "16",
    name: "Registro de Nombre de Publicaciones Periódicas Vía Redes de Cómputo",
  },
  {
    id: "19",
    name: "Diseño Industriales",
  },
];
