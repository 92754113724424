import { useState } from "react";
import DetailBrand from "./DetailBrand";
import AttachFile from "./Files/AttachFile";

const BrandInfo = ({
  serviceOrderId,
  serviceId,
  brandInfo,
  ownerInfo,
  authorInfo,
  coAuthorInfo,
  updateFile,
  loadingUpdateFile,
  errorUpdateFile,
}) => {
  const [modalFile, setModalFile] = useState(false);
  const [entityId, setEntityId] = useState();
  const [typeFile, setTypeFile] = useState();
  const [formatFile, setFormatFile] = useState();
  const [brandFileName, setBrandFileName] = useState("");
  const [titularFileName, setTitularFileName] = useState("");
  const [authorFileName, setAuthorFileName] = useState("");
  const [coauthorFileName, setCoauthorFileName] = useState("");

  const openModalFile = (entity_id, type_file, format_file) => {
    setEntityId(entity_id);
    setTypeFile(type_file);
    setFormatFile(format_file);
    setModalFile(true);
  };

  const closeModalFile = () => {
    setModalFile(false);
  };

  const updateFileName = (name) => {
    switch (typeFile) {
      case 1:
        setBrandFileName(name);
        break;
      case 2:
        setTitularFileName(name);
        break;
      case 3:
        setAuthorFileName(name);
        break;
      case 4:
        setCoauthorFileName(name);
        break;
      default:
        setBrandFileName(name);
        break;
    }
  };

  let title =
    {
      1: "INFORMACIÓN DE LA MARCA",
      2: "INFORMACIÓN DEL ESTUDIO",
      3: "INFORMACIÓN DE LA MARCA",
      4: "INFORMACIÓN DE LA OBRA",
      5: "INFORMACIÓN DEL GRUPO MUSICAL",
      6: "INFORMACIÓN DEL ESTUDIO",
      7: "INFORMACIÓN DEL GRUPO MUSICAL",
      8: "INFORMACIÓN DE LA REVISTA",
      9: "INFORMACIÓN DEL ESTUDIO",
      10: "INFORMACIÓN DE LA REVISTA",
      11: "INFORMACIÓN DEL PERIÓDICO",
      12: "INFORMACIÓN DEL ESTUDIO",
      13: "INFORMACIÓN DEL PERIÓDICO",
      14: "INFORMACIÓN DE LA PUBLICACIÓN",
      15: "INFORMACIÓN DEL ESTUDIO",
      16: "INFORMACIÓN DE LA PUBLICACIÓN",
      17: "INFORMACIÓN DE LA RENOVACIÓN",
      18: "INFORMACIÓN DE LA CESIÓN DE DERECHOS",
      19: "INFORMACIÓN DEL DISEÑO INDUSTRIAL",
      20: "INFORMACIÓN DE LA LICENCIA DE DERECHOS",
      21: "INFORMACIÓN DEL PAGO DE ANUALIDAD DE DISEÑOS INDUSTRIALES",
      22: "INFORMACIÓN DEL PAGO DE REQUERIMIENTO",
      23: "INFORMACIÓN DE LA DECLARACIÓN DE USO DE MARCA",
    }[serviceId] || 1;

  return (
    <>
      <div className="section-title">
        <h2 className="subtitle-section">{title}</h2>
      </div>

      {modalFile && (
        <AttachFile
          closeModalFile={closeModalFile}
          entityId={entityId}
          serviceOrderId={serviceOrderId}
          serviceId={serviceId}
          typeFile={typeFile}
          formatFile={formatFile}
          updateFile={updateFile}
          loadingUpdateFile={loadingUpdateFile}
          errorUpdateFile={errorUpdateFile}
          updateFileName={updateFileName}
        />
      )}

      <DetailBrand
        serviceOrderId={serviceOrderId}
        brandInfo={brandInfo}
        ownerInfo={ownerInfo}
        authorInfo={authorInfo}
        coAuthorInfo={coAuthorInfo}
        serviceId={serviceId}
        openModalFile={openModalFile}
        brandFileName={brandFileName}
        titularFileName={titularFileName}
        authorFileName={authorFileName}
        coauthorFileName={coauthorFileName}
      />
    </>
  );
};

export default BrandInfo;
