import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  InputSimpleFloatLabel,
  InputRadio,
  InputSelect,
  EntityForm,
  Button,
  ItemList,
  ErrorMessage,
} from "../../../components";
import { caculateAmountPrice } from "../../../utils/scripts/calculatePrice";
import { userProfileActions } from "../../../store/slices/userProfile";
import { rightsActions } from "../../../store/slices/rights";
import { paymentActions } from "../../../store/slices/payment";
import { cataTipoDerecho } from "../../../utils/cat-tipo-derecho";
import {
  RIGHTS_CESSION_CHECKOUT,
  RIGHTS_CESSION_CONFIRMATION,
} from "../../../constants/routes";
import { USER_PDLC } from "../../../constants";

const CHECKOUT_RIGHTS_CESSION = RIGHTS_CESSION_CHECKOUT;

const Cession = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [registerNumber, setRegisterNumber] = useState("");
  const [showNumberError, setShowNumberError] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [validFiles, setValidFiles] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [userData, setUserData] = useState();

  const storeUserProfile = useSelector((state) => state.userProfile);
  const storeTitular = useSelector((state) => state.entity.titular);
  const storeAssignee = useSelector((state) => state.entity.cesionario);
  const storeRights = useSelector((state) => state.rights);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
  }, []);

  useEffect(() => {
    dispatch(userProfileActions.getEntitiesObras());
    dispatch(userProfileActions.getServices());
  }, [dispatch]);

  useEffect(() => {
    let valid = true;
    if (storeRights.isValidAssignee) {
      if (!storeRights.isRegisteredHolder) {
        if (!storeTitular.valid) {
          valid = false;
        }
      }

      if (!storeRights.isRegisteredAssignee) {
        if (!storeAssignee.valid) {
          valid = false;
        }
      }
      setValidForm(valid);
    } else {
      setValidForm(false);
    }
  }, [
    storeAssignee.valid,
    storeRights.isRegisteredHolder,
    storeRights.isRegisteredAssignee,
    storeRights.isValidAssignee,
    storeTitular.valid,
  ]);

  useEffect(() => {
    let valid = true;
    if (!storeRights.isRegisteredHolder) {
      if (storeTitular.file === "") {
        valid = false;
      }
    }
    if (!storeRights.isRegisteredAssignee) {
      if (storeAssignee.file === "") {
        valid = false;
      }
    }

    setValidFiles(valid);
  }, [
    storeAssignee.file,
    storeRights.isRegisteredHolder,
    storeRights.isRegisteredAssignee,
    storeTitular.file,
  ]);

  const hanldleChangeTypeRight = (event) => {
    dispatch(
      rightsActions.setRightType({
        right_type: event.target.value,
      })
    );
  };
  const handleChangeInputNumber = (event) => {
    setRegisterNumber(event.target.value);
  };

  const addNumber = () => {
    setShowNumberError(false);
    const existNumber = storeRights.brand_info.registration_number.find(
      (item) => item === +registerNumber
    );

    if (typeof existNumber === "undefined") {
      dispatch(
        rightsActions.setBrandRegistrationNumber({
          registration_number: +registerNumber,
        })
      );
    } else {
      setShowNumberError(true);
    }
    setRegisterNumber("");
  };

  const deleteNumber = (number) => {
    const items = { ...storeRights.brand_info.registration_number };
    const aItems = Object.values(items);
    const index = aItems.indexOf(number);
    if (index > -1) {
      aItems.splice(index, 1);
      setShowNumberError(false);
    }

    dispatch(
      rightsActions.replaceRegistrationNumbers({
        registration_number: aItems,
      })
    );
  };

  const handleChangeRegisterHolder = (event) => {
    dispatch(
      rightsActions.setIsRegisteredHolder({
        isRegisteredHolder: event.target.value === "si",
      })
    );
  };

  const hanldleChangeTitularSelect = (event) => {
    dispatch(
      rightsActions.setOwnerId({
        owner_id: event.target.value,
      })
    );
  };
  const handleChangeAmount = (event) => {
    dispatch(
      rightsActions.setAmount({
        amount: event.target.value,
      })
    );
  };

  // TODO: Cambiar por cesionario
  const handleChangeRegisterAssignee = (event) => {
    dispatch(
      rightsActions.setIsRegisteredAssignee({
        isRegisteredAssignee: event.target.value === "si",
      })
    );
  };

  const hanldleChangeAssigneeSelect = (event) => {
    dispatch(
      rightsActions.setAssigneeId({
        assignee_id: event.target.value,
      })
    );
  };

  const getPrice = () => {
    const rightsLicense = storeUserProfile.services.services.filter(
      (item) => item.service_id === 18
    );

    const price =
      +rightsLicense[0].service_transact +
      +rightsLicense[0].service_price *
        storeRights.brand_info.registration_number.length;

    const totalprice = caculateAmountPrice(price);

    return totalprice.toFixed(2);
  };

  const handleButtonNext = () => {
    setSubmittedForm(true);
    if (validFiles) {
      const price = getPrice();
      dispatch(paymentActions.setPurchaseAmount({ amount: price }));
      if (userData.user_id === USER_PDLC) {
        history.replace(RIGHTS_CESSION_CONFIRMATION);
      } else {
        history.replace(CHECKOUT_RIGHTS_CESSION);
      }
    }
  };

  return (
    <div className="container-modulo">
      <div className="section-title">
        <h2 className="subtitle">CESIÓN DE DERECHOS</h2>
      </div>
      <div className="text-description">
        <p>
          Elaboración e Inscripción ante el IMPI del Contrato de Cesión de
          derechos de Propiedad Intelectual, entre tu y el adquirente de tus
          derechos , de manera práctica y sencilla.
        </p>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DEL DERECHO</div>
      </div>
      <div className="content-form-main">
        <InputSelect
          id="typeRights"
          name="typeRights"
          placeholder="- TIPO DE DERECHO -"
          options={cataTipoDerecho}
          fieldIdOption="id"
          value={storeRights.brand_info.right_type}
          onChange={hanldleChangeTypeRight}
        />
        <InputSimpleFloatLabel
          id="number"
          name="number"
          value={registerNumber}
          valid={true}
          placeholder="NÚMERO DE REGISTRO"
          type="number"
          onChange={handleChangeInputNumber}
        />
        {showNumberError && (
          <ErrorMessage message="El número ya ha sido agregado" />
        )}

        {storeRights.brand_info.registration_number.map((item) => (
          <ItemList
            key={item}
            number={item}
            deleteItem={() => deleteNumber(item)}
          />
        ))}

        <div className="contentainer-button">
          <Button
            onClick={addNumber}
            className="btnConfirm"
            disabled={!registerNumber.length > 0}
          >
            ADICIONAR DERECHO
          </Button>
        </div>
        <div className="descripction-action">
          Todos los números de registro deberán pertenecer al mismo titular.
        </div>

        <InputRadio
          id="si"
          name="isRegisteredHolder"
          value="si"
          onChange={handleChangeRegisterHolder}
          label="SELECCIONAR UN TITULAR DE MI LISTA DE TITULARES"
          checked={storeRights.isRegisteredHolder}
        />
        {storeRights.isRegisteredHolder && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="- ELIGE UN TITULAR -"
            options={storeUserProfile.holders}
            fieldIdOption="id"
            value={storeRights.owner_id}
            onChange={hanldleChangeTitularSelect}
          />
        )}
        <InputRadio
          id="no"
          name="isRegisteredHolder"
          value="no"
          onChange={handleChangeRegisterHolder}
          label="REGISTRAR NUEVO TITULAR"
          checked={!storeRights.isRegisteredHolder}
        />
      </div>
      {!storeRights.isRegisteredHolder && (
        <EntityForm
          typeEntity="titular"
          entity="titular-musical-group"
          recordTypeFile="brand-owner-info"
        />
      )}
      <div className="content-form-main">
        <InputSimpleFloatLabel
          id="amount"
          name="amount"
          placeholder="CANTIDAD"
          onChange={handleChangeAmount}
          defaultValue={storeRights.brand_info.amount}
          valid={true}
        />
        <div className="descripction-action">
          Monto en pesos a pagar por parte del cesionario.
        </div>
      </div>
      <div className="section-title">
        <h2 className="subtitle">INFORMACIÓN DEL CESIONARIO</h2>
      </div>
      <div className="text-description">
        <p>
          El Cesionario es la persona física o razón social que adquiere el
          derecho.
        </p>
      </div>
      <div className="content-form-main">
        <InputRadio
          id="licenceSi"
          name="isRegisteredLicense"
          value="si"
          onChange={handleChangeRegisterAssignee}
          label="SELECCIONAR UN CESIONARIO"
          checked={storeRights.isRegisteredAssignee}
        />
        {storeRights.isRegisteredAssignee && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="- ELIGE UN CESIONARIO -"
            options={storeUserProfile.assignee}
            fieldIdOption="id"
            value={storeRights.assignee_id}
            onChange={hanldleChangeAssigneeSelect}
          />
        )}
        <InputRadio
          id="licenceNo"
          name="isRegisteredLicense"
          value="no"
          onChange={handleChangeRegisterAssignee}
          label="REGISTRAR UN NUEVO CESIONARIO"
          checked={!storeRights.isRegisteredAssignee}
        />
      </div>
      {!storeRights.isRegisteredAssignee && (
        <EntityForm
          typeEntity="cesionario"
          entity="cesionario-rights"
          recordTypeFile="brand-owner-info"
        />
      )}
      {submittedForm && !validFiles && (
        <ErrorMessage message="Archivo identificación oficial es requerido" />
      )}
      <div className="content-form-main">
        <div className="contentainer-button">
          <Button
            onClick={handleButtonNext}
            className="btnConfirm"
            disabled={!validForm}
          >
            CONTINUAR
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Cession;
