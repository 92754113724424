import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  MAGAZINES_REGISTER_CONFIRMATION,
  MAGAZINES_REGISTER,
  MAGAZINES_REGISTER_TITULAR,
  DASHBOARD_SERVICES,
} from "../../../../constants/routes";
import { ButtonBack, PaymentStripe, ButtonEdit } from "../../../../components";

const REDIRECT = MAGAZINES_REGISTER_CONFIRMATION;
const REGISTRO = MAGAZINES_REGISTER;
const REGISTRO_TITULAR = MAGAZINES_REGISTER_TITULAR;

const CheckOutMgz = (props) => {
  const history = useHistory();
  const storeMagazine = useSelector((state) => state.magazines);

  useEffect(() => {
    if (!storeMagazine.isNewBrand && storeMagazine.studyBelongsId === -1) {
      history.replace(DASHBOARD_SERVICES);
    } else if (
      storeMagazine.isNewBrand &&
      storeMagazine.brand_info.name === ""
    ) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [
    history,
    storeMagazine.brand_info.name,
    storeMagazine.isNewBrand,
    storeMagazine.studyBelongsId,
  ]);

  const urlBack = storeMagazine.isNewBrand ? REGISTRO : REGISTRO_TITULAR;

  const cameBack = () => {
    props.history.replace(urlBack);
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <div className="container-modulo">
      <ButtonBack url={urlBack} text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">REGRISTRO DE NOMBRE DE REVISTAS</h2>
      </div>
      <div className="text-description">
        <p>Protege tu Revista de manera práctica y sencilla.</p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE LA REVISTA</div>
        <ButtonEdit onClick={cameBack} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>

      <div>
        <Elements stripe={stripePromise}>
          <PaymentStripe redirect={REDIRECT} />
        </Elements>
      </div>

      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default CheckOutMgz;
