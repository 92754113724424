import { createSlice } from "@reduxjs/toolkit";

const initalUserProfileState = {
  userServices: [], // userServices
  services: [], // Servicios brindados
  holders: [], // Titulares
  authors: [], // Autores
  coauthors: [], // Coautores
  licensee: [], //Licenciatario,
  assignee: [], //Cesionario,
  dataRegister: [],
  uploadedFile: "",
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: initalUserProfileState,
  reducers: {
    getServices() {},
    setServices(state, action) {
      state.services = action.payload.services;
    },
    getUserServices() {},
    setUserServices(state, action) {
      state.userServices = action.payload.userServices;
    },
    getHolders() {},
    getEntitiesObras() {},
    setHolders(state, action) {
      state.holders = action.payload.holders;
    },
    setAuthors(state, action) {
      state.authors = action.payload.authors;
    },
    setCoauthors(state, action) {
      state.coauthors = action.payload.coauthors;
    },
    setDataRegisterUser(state, action) {
      state.dataRegister = action.payload.data;
    },
    setLicensee(state, action) {
      state.licensee = action.payload.licensee;
    },
    setAssignee(state, action) {
      state.assignee = action.payload.assignee;
    },
    setUploadedFiles(state, action) {
      state.uploadedFile = action.payload.file;
    },
  },
});

export const userProfileActions = userProfileSlice.actions;

export default userProfileSlice;
