import {useState, useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import {
  InputRadio,
  InputSelect,
  HolderForm,
  ButtonEdit,
} from "../../../components";

import { obrasActions } from "../../../store/slices/obras";

import classes from "./CoauthorTemplate.module.css";

const CoauthorTemplate = (props) => {
  const dispatch = useDispatch();
  const storeObras = useSelector((state) => state.obras);
  const [isRegisteredCoauthor, setIsRegisteredCoauthor] = useState(true)

  useEffect(() => {
    if (typeof storeObras.coauthor_info[props.index -1] !== 'undefined'){
      setIsRegisteredCoauthor(storeObras.coauthor_info[props.index -1].isRegistered)
    } else {
      setIsRegisteredCoauthor(true)
    }
  }, [props.index, storeObras.coauthor_info])

  const handleCoeAutorRadio = (event) => {
    dispatch(obrasActions.setIsRegisteredCoauthor({
      isRegistered: event.target.value === '1',
      index: props.index - 1
    }))
  };

  const handleIdsCoAuthorSelect = (event) => {
    dispatch(
      obrasActions.setCoAuthorInfoId({
        coauthor_id: event.target.value,
        index: props.index - 1,
      })
    );
  };

  const handleDeleteCoAuthor = (index) => {
    setIsRegisteredCoauthor(true);
    dispatch(obrasActions.deleteCoAuthor({ index }));
  };

  return (
    <div>
      <div className={classes.contentRadioSelect}>
        <h5 className="title-select">CO-AUTOR DE LA OBRA {props.index} </h5>
        <InputRadio
          id={`register_${props.index}`}
          name={`registeredCoauthor_${props.index}`}
          value="1"
          onChange={handleCoeAutorRadio}
          label="SELECCIONAR UN CO-AUTOR DE MI LISTA DE CO-AUTORES"
          checked={isRegisteredCoauthor}
        />
        {isRegisteredCoauthor && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="- ELIGE UN CO-AUTOR -"
            options={props.holders}
            fieldIdOption="id"
            value={storeObras.coauthor_id[props.index - 1]}
            onChange={handleIdsCoAuthorSelect}
          />
        )}
        <InputRadio
          id={`form_${props.index}`}
          name={`registeredCoauthor_${props.index}`}
          value="2"
          onChange={handleCoeAutorRadio}
          label="REGISTRAR UN NUEVO CO-AUTOR"
          checked={!isRegisteredCoauthor}
        />
      </div>
      {!isRegisteredCoauthor && (
        <div className={classes.contentForm}>
          <HolderForm
            entity={`coauthor-obra-${props.index}`}
            recordTypeFile="obras-coauthor-info"
            recordIndex={props.index - 1}
            textTypeEntity="- TIPO DE CO-AUTOR -"
          />
        </div>
      )}
      <div className={classes.contentBtnDelete}>
        {props.showDelete && (
          <ButtonEdit
            onClick={() => handleDeleteCoAuthor(props.index)}
            icon="faTimes"
          >
            ELIMINAR CO-AUTOR
          </ButtonEdit>
        )}
      </div>
      <div className={classes.containerPLine}>
        <div className="parting-line" />
      </div>
    </div>
  );
};

CoauthorTemplate.propTypes = {
  index: PropTypes.number.isRequired,
  showDelete: PropTypes.bool.isRequired,
  holders: PropTypes.array,
};

export default CoauthorTemplate;
