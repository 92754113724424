import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import InputFloatLabel from "../UI/Input/InputFloatLabel/InputFloatLabel";
import { Spinner, ErrorMessage, Button } from "../../components";
import logo from "../../assets/images/logo-aTuAlcance.svg";
import classes from "./resetPassword.module.css";

const ConfirmPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const storeUserProfile = useSelector(state => state.userProfile)

  const formConfirm = useFormik({
    initialValues: {
      password: '', 
      code: ''
    }, 
    validationSchema: Yup.object({
      code: Yup.string().required("Código es requerido"),
      password: Yup.string().required("Password es requerido"),
      confirmPassword: Yup.string()
        .required("Confimar password es requerido")
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "El password no coinside"
          ),
        }),
    }),
    onSubmit: async (values ) => {
      setLoading(true);

      const bodyConfirm = {
        username: storeUserProfile.dataRegister.username,
        newpassword: values.password,
        confirm_code: values.code       
      }

      await axios.request({
        url: process.env.REACT_APP_URL_BASE + 'auth/confirmpassword',
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        }, 
        data: bodyConfirm
      }).then (response => {
        if (response.data.code === 200) {
          history.push("/login");
        }
        setLoading(false);
      }).catch(_=> {
        setLoading(false);
        setErrorMsg(true);
      })
    }
  })

  return (
    <div className="container-simple">
      <img src={logo} className="logo" alt="A tu alcance" />
      <h2 className="subtitle">RECUPERAR CONTRASEÑA</h2>
      <p className={classes.description}>
      Por favo ingresa el código de verificación que recibiste en tu cuenta de correo electrónico y tu nueva contraseña.
      </p>
      <div className={classes.containerForm}>

      <form onSubmit={formConfirm.handleSubmit} >
        <div className="form-row-element">
        <InputFloatLabel
            id="code"
            name="code"
            placeholder="CÓDIGO DE VERIFICACIÓN"
            onChange={formConfirm.handleChange}
            valid={formConfirm.errors.code && formConfirm.touched.code}
          />
        </div>
        <div className="form-row-element">
            <InputFloatLabel
              id="password"
              name="password"
              type="password"
              placeholder="CONTRASEÑA"
              onChange={formConfirm.handleChange}
              valid={
                formConfirm.errors.password && formConfirm.touched.password
              }
            />
          </div>
          <div className="form-row-element">
            <InputFloatLabel
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              placeholder="CONFIRMAR CONTRASEÑA"
              onChange={formConfirm.handleChange}
              valid={
                formConfirm.errors.confirmPassword &&
                formConfirm.touched.confirmPassword
              }
            />
          </div>
        <div className={classes.containerError}>
          {errorMsg && <ErrorMessage message="Código no valido" />}
        </div>
        <div>
          {loading ? (
            <Spinner />
          ) : (
            <div>
              <Button type="submit" className="btnConfirm">ENVIAR</Button>
            </div>
          )}
        </div>
      </form>
      </div>
    </div>
  );
};

export default ConfirmPassword;
