import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ButtonBack, PaymentStripe, ButtonEdit } from "../../../../components";
import {
  BRANDS_REGISTER_BRAND,
  DASHBOARD_SERVICES,
  BRANDS_TITULAR,
  BRANDS_REGISTER_CONFIRMATION
} from "../../../../constants/routes";

const CheckOutTradeMark = (props) => {
  const history = useHistory();
  const storeTradeMark = useSelector((state) => state.tradeMark);
  const [urlBack, setUrlBack] = useState("");

  useEffect(() => {
    
    if (!storeTradeMark.statusFormBrandInfo) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeTradeMark.statusFormBrandInfo]);

  useEffect(() => {
    if (storeTradeMark.isAdditionalBrand) {
      setUrlBack(BRANDS_REGISTER_BRAND);
    } else {
      setUrlBack(BRANDS_TITULAR);
    }
  }, [storeTradeMark]);

  const cameBack = () => {
    props.history.replace(urlBack);
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <div className="container-modulo">
      <ButtonBack url={urlBack} text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">REGISTRO DE MARCA</h2>
      </div>
      <div className="text-description">
        <p>
          Protege el nombre, sonido, logotipo o frase comercial con el que
          deseas distinguir tus productos o servicios.
        </p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE LA MARCA</div>
        <ButtonEdit onClick={cameBack} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>

      <div>
        <Elements stripe={stripePromise}>
          <PaymentStripe redirect={BRANDS_REGISTER_CONFIRMATION} />
        </Elements>
      </div>

      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default CheckOutTradeMark;
