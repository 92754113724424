import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandPaper,
  faPlus,
  faMoneyCheckAlt,
  faFile,
  faCog,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import classes from "./StatusBox.module.css";

const StatusBox = ({ status }) => {
  let icon =
    {
      "Antes pago": faMoneyCheckAlt,
      Nueva: faPlus,
      "En Proceso": faCog,
      "Archivos Requeridos": faFile,
      "Con Impedimento": faHandPaper,
      Procesada: faCheck,
      Rechazada: faHandPaper,
    }[status] || "Nueva";

  let backgrownd =
    {
      "Antes pago": classes.new,
      Nueva: classes.new,
      "En Proceso": classes.inProcess,
      "Archivos Requeridos": classes.requiredFiles,
      "Con Impedimento": classes.impediment,
      Procesada: classes.processed,
      Rechazada: classes.impediment,
    }[status] || "Nueva";

  return (
    <div className={`${classes.statusContainer} ${backgrownd}`}>
      <div className={classes.title}>ESTATUS</div>
      <div className={classes.status}>
        <FontAwesomeIcon icon={icon} className={classes.icon} />
        {status}
      </div>
    </div>
  );
};

StatusBox.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusBox;
