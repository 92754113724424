import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classes from './TextArea.module.css';
const TextArea = props => {
  const [touched, setTouched] = useState(false);

  const handleOnBlur = () => {
      setTouched(true)
  }

  return (
    <div className={classes.contentTextArea}>
      <textarea
        className={`${classes.textArea} ${!props.valid && touched ? classes.invalid : ''}`}
        id={props.id}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlurCapture={handleOnBlur}>
        {props.value}
      </textarea>
    </div>
  )
};

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  valid: PropTypes.bool
  
}

export default React.memo(TextArea);
