import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonBack, ButtonEdit } from "../../../../components";
import MagazineTemplate from "../../../../components/Templates/Confirmation/MagazineTemplate";
import { magazinesActions } from "../../../../store/slices/magazines";
import {
  MAGAZINES_STUDY_REGISTER,
  MAGAZINES_STUDY_CHECKOUT,
  DASHBOARD_SERVICES,
} from "../../../../constants/routes";

const STD_INFO = MAGAZINES_STUDY_REGISTER;
const STD_CHECKOUT = MAGAZINES_STUDY_CHECKOUT;

const ConfirmatioMagazine = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const storeMagazine = useSelector((state) => state.magazines);
  const storePayment = useSelector((state) => state.payment);

  useEffect(() => {
    if (storeMagazine.brand_info.name === "") {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeMagazine.brand_info.name]);

  const gotoBrandInfo = (_) => {
    props.history.replace(STD_INFO);
  };

  const gotoPayment = () => {
    props.history.replace(STD_CHECKOUT);
  };

  const redirectReceipt = useCallback(() => {
    dispatch(magazinesActions.createMagazineStudy());
  }, [dispatch]);

  return (
    <div className="container-modulo">
      <ButtonBack url={STD_CHECKOUT} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">
          ESTUDIO DE REGISTRABILIDAD DE NOMBRE DE REVISTAS
        </h2>
      </div>

      <div className="description-confirm">
        <p>
          Proporciona la información para realizar el estudio de
          registrabilidad.
        </p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE LA REVISTA</div>
        <ButtonEdit onClick={gotoBrandInfo} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE PAGO</div>
        <ButtonEdit onClick={gotoPayment} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
      <div>
        <MagazineTemplate
          registerData={storeMagazine}
          paymentData={storePayment}
          onConfirm={redirectReceipt}
          scope="reg-std-magazine"
        />
      </div>
    </div>
  );
};

export default ConfirmatioMagazine;
