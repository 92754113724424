import PropTypes from "prop-types";
import { BtnCallToAction, Button } from "../../../components";
import classes from "./MyRequest.module.css";
import { downloadFile } from "../../../utils/axios/downloadFile";

import { saveAs } from "file-saver";
const Resolution = ({ resolutionInfo, serviceId }) => {
  
  const downloadFileImpediment = async (fileName) => {  
    const urlToDownload = await downloadFile(serviceId, 6, fileName);
    saveAs(urlToDownload.data.url);
  };

  return (
    <div>
      <div className="parting-line" />
      <div className={classes.headerSectionComponent}>
        <h2 className="subtitle-section">INFORMACIÓN DE LA RESOLUCIÓN</h2>
      </div>

      <div className={classes.sectionInfo}>
        <div className={classes.infoBrand}>
          <div className={classes.itemInfo}>
            <div className={classes.itemHead}>NÚMERO DE REGISTRO DE MARCA</div>
            <div className={classes.itemData}>
              {resolutionInfo.concession_info.register_numer[0]
                ? resolutionInfo.concession_info.register_numer[0]
                : '--'}
            </div>
          </div>

          <div className={classes.itemInfo}>
            <div className={classes.itemHead}>FECHA DE CONCESIÓN</div>
            <div className={classes.itemData}>
              {resolutionInfo.concession_info.concession_date
                ? resolutionInfo.concession_info.concession_date
                : "--"}
            </div>
          </div>
        </div>
        <div className={classes.infoBrand}>
          <div className={classes.itemInfo}>
            <div className={classes.itemHead}>FECHA DE VIGENCIA</div>
            <div className={classes.itemData}>
              {resolutionInfo.concession_info.due_date
                ? resolutionInfo.concession_info.due_date
                : "--"}
            </div>
          </div>
        </div>
      </div>
      
      <div className={classes.fileItem}>
        {resolutionInfo.file ? (
          <>
            {/* DOWNLOAD */}
            <div className={classes.itemHead}>DOCUMENTO DE LA RESOLUCIÓN</div>
            <BtnCallToAction
              onClick={() => downloadFileImpediment(resolutionInfo.file)}
              icon="faDownload"
              className="btnDownload"
            >
              {resolutionInfo.file}
            </BtnCallToAction>
          </>
        ) : null}

        <br />

        {resolutionInfo.requires_renewal.service_id ? (
          <div className={classes.contentButton}>
            <Button onClick={() => {}} className="btnConfirm">
              RENOVAR
            </Button>
          </div>
        ) : null}
        {resolutionInfo.requires_use_declaration.service_id ? (
          <div className={classes.contentButton}>
            <Button onClick={() => {}} className="btnConfirm">
              DECLARACIÓN DE USO
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

Resolution.prototype = {
  resolutionInfo: PropTypes.array.isRequired,
};

export default Resolution;
