import { call, put, select } from "redux-saga/effects";
import { replace } from "connected-react-router";

import { paymentActions } from "../../slices/payment";
import { rightsActions } from "../../slices/rights";
import { entityActions } from "../../slices/entity";
import {
  requestCreateRightsLicense,
  requestCreateRightsCession,
  requestCreateRightsRenewal,
} from "../requests/rightsRequest";
import { TICKECT } from "../../../constants/routes";

export function* handleCreateRightsRenewal() {
  try {
    const storeRights = yield select((store) => store.rights);
    const storePayment = yield select((store) => store.payment);
    const storeEntity = yield select((store) => store.entity);

    const response = yield call(
      requestCreateRightsRenewal,
      storeRights,
      storePayment,
      storeEntity
    );
    if (response.status === 200) {
      yield put(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      yield put(replace(TICKECT));
      yield put(rightsActions.cleanSotoreLicence());
      yield put(paymentActions.cleanStorePayment());
      yield put(entityActions.cleanStoreEntity());
    } else {
      // TODO: generar pantalla de error
    }
  } catch (error) {
    console.log("Error handleCreateRightsLicense" + error);
  }
}

export function* handleCreateRightsLicense() {
  try {
    const storeRights = yield select((store) => store.rights);
    const storePayment = yield select((store) => store.payment);
    const storeEntity = yield select((store) => store.entity);

    const response = yield call(
      requestCreateRightsLicense,
      storeRights,
      storePayment,
      storeEntity
    );
    if (response.status === 200) {
      yield put(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      yield put(replace(TICKECT));
      yield put(rightsActions.cleanSotoreLicence());
      yield put(paymentActions.cleanStorePayment());
      yield put(entityActions.cleanStoreEntity());
    } else {
      // TODO: generar pantalla de error
    }
  } catch (error) {
    console.log("Error handleCreateRightsLicense" + error);
  }
}

export function* handleCreateRightsCession() {
  try {
    const storeRights = yield select((store) => store.rights);
    const storePayment = yield select((store) => store.payment);
    const storeEntity = yield select((store) => store.entity);
    const response = yield call(
      requestCreateRightsCession,
      storeRights,
      storePayment,
      storeEntity
    );
    if (response.status === 200) {
      yield put(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      yield put(replace(TICKECT));
      yield put(rightsActions.cleanSotoreLicence());
      yield put(paymentActions.cleanStorePayment());
      yield put(entityActions.cleanStoreEntity());
    } else {
      // TODO: generar pantalla de error
    }
  } catch (error) {
    console.log("Error handleCreateRightsCession" + error);
  }
}
