import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import { Grupos } from "../../../assets/images/icons/icons";
import { ButtonBack, Button, ButtonRound } from "../../../components";
import { musicalGroupActions } from "../../../store/slices/musicalGroup";
import { services } from "../../../constants/services";
import {
  MUSICAL_GROUP_STUDY,
  MUSICAL_GROUP_SELECT_STUDY,
  DASHBOARD_SERVICES,
} from "../../../constants/routes";
import { userProfileActions } from "../../../store/slices/userProfile";

const STUDY = MUSICAL_GROUP_STUDY;
const REGISTER = MUSICAL_GROUP_SELECT_STUDY;
const SERVICIOS = DASHBOARD_SERVICES;

const ServicesMusicalGroup = (props) => {
  const dispatch = useDispatch();
  const [musicalGroup, setMusicalGroup] = useState([]);

  const storeUserProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getUserServices());
  }, [dispatch]);

  useEffect(() => {
    const result = storeUserProfile?.services?.services?.filter(
      (item) => item.service_id === services.GRUPOS_MUSICALES
    );
    setMusicalGroup(result);
  }, [storeUserProfile?.services?.services]);

  const goBack = () => {
    props.history.replace(SERVICIOS);
  };

  const handleSelectService = (service_id) => {
    dispatch(
      musicalGroupActions.setServiceId({
        service_id: service_id,
      })
    );

    if (service_id === services.GRUPOS_MUSICALES_ESTUDIO_REG) {
      props.history.replace(STUDY);
    }

    if (service_id === services.GRUPOS_MUSICALES_REGISTRO) {
      props.history.replace(REGISTER);
    }
  };
  return (
    <div className="container-modulo">
      <ButtonBack url={SERVICIOS} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">SERVICIOS</h2>
      </div>
      <div className="item-marcas">
        <img src={Grupos} alt="icon-marcas" />
        &nbsp;&nbsp; GRUPOS MUSICALES
      </div>

      <div className="parting-line" />

      {musicalGroup[0]?.service_sub.length > 0 && (
        <>
          <div className="text-description">
            <p>Registra el nombre de tu grupo musical</p>

            <h2 className="subtitle-modle">
              {musicalGroup?.length &&
                musicalGroup[0].service_sub[0].service_name}
            </h2>
            <p>
              {musicalGroup?.length &&
                musicalGroup[0].service_sub[0].service_description}
            </p>
            <div className="price">
              <CurrencyFormat
                value={
                  musicalGroup?.length
                    ? Number(
                        musicalGroup[0].service_sub[0].service_price
                      ).toFixed(2)
                    : 0
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value) => (
                  <div>
                    {value} <span className="currency">MXM</span>
                  </div>
                )}
              />
            </div>
            <div className="content-btn">
              <Button
                onClick={() =>
                  handleSelectService(
                    musicalGroup?.length &&
                      musicalGroup[0].service_sub[0].service_id
                  )
                }
                className="btnConfirm"
              >
                SOLICITAR
              </Button>
            </div>
          </div>
          <br />
          <br />
          <div className="parting-line" />
          <br />
          <br />
        </>
      )}

      {musicalGroup[0]?.service_sub.length > 1 && (
        <>
          <div className="text-description">
            <h2 className="subtitle-modle">
              {musicalGroup?.length &&
                musicalGroup[0]?.service_sub[1]?.service_name}
            </h2>
            <p>
              {musicalGroup?.length &&
                musicalGroup[0]?.service_sub[1]?.service_description}
            </p>
            <div className="price">
              <CurrencyFormat
                value={
                  musicalGroup?.length
                    ? Number(
                        musicalGroup[0]?.service_sub[1]?.service_price
                      ).toFixed(2)
                    : 0
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value) => (
                  <div>
                    {value} <span className="currency">MXM</span>
                  </div>
                )}
              />
            </div>
            <div className="content-btn">
              <Button
                onClick={() =>
                  handleSelectService(
                    musicalGroup?.length &&
                      musicalGroup[0].service_sub[1].service_id
                  )
                }
                className="btnConfirm"
              >
                SOLICITAR
              </Button>
            </div>
          </div>
          <br />
          <br />
          <div className="parting-line" />
        </>
      )}

      <div className="content-btn btn-back-margin">
        <ButtonRound onClick={goBack} icon="faArrowLeft">
          REGRESAR
        </ButtonRound>
      </div>
    </div>
  );
};

export default ServicesMusicalGroup;
