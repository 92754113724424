import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userProfileActions } from "../../../store/slices/userProfile";
import { Marcas } from "../../../assets/images/icons/icons";
import { ButtonBack, Button, ButtonRound, Spinner } from "../../../components";
import { AtaCurrencyFormat } from "../../../components/Common";
import {
  BRANDS_REGISTRABILITY_STUDY,
  BRANDS_SELECTION_REGISTERED_MARK,
  BRANDS_REGISTER_BRAND,
} from "../../../constants/routes";
import { services as SERVICES_DASH } from "../../../constants/services";
import "./Servicios.css";

const Servicios = (props) => {
  const dispatch = useDispatch();
  const [brands, setBrands] = useState([]);
  const storeUserProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getUserServices());
  }, [dispatch]);

  useEffect(() => {
    if (storeUserProfile.services?.services?.length) {
      const result = storeUserProfile.services.services.filter(
        (item) => item.service_id === SERVICES_DASH.MARCAS
      );
      setBrands(result);
    }
    return () => {
      setBrands([]);
    };
  }, [storeUserProfile?.services, storeUserProfile?.services?.services]);

  const services = useSelector((state) => state.userProfile.userServices);

  const estudioReg = () => {
    props.history.replace(BRANDS_REGISTRABILITY_STUDY);
  };

  const registroMarca = () => {
    if (services.length) {
      props.history.replace(BRANDS_SELECTION_REGISTERED_MARK);
    } else {
      props.history.replace(BRANDS_REGISTER_BRAND);
    }
  };

  const handleSaveClick = (serviceId) => {
    if (serviceId === 3) {
      registroMarca();
    } else {
      estudioReg();
    }
  };

  const goBack = () => {
    props.history.goBack();
  };

  return (
    <>
      {storeUserProfile.services?.services?.length === undefined ? (
        <div className="container-modulo">
          <ButtonBack url="/servicios" text="REGRESAR" />

          <div className="section-title">
            <h2 className="subtitle">SERVICIOS</h2>
          </div>
          <Spinner />
        </div>
      ) : (
        <div className="container-modulo">
          <ButtonBack url="/servicios" text="REGRESAR" />

          <div className="section-title">
            <h2 className="subtitle">SERVICIOS</h2>
          </div>

          <div className="item-marcas">
            <img src={Marcas} alt="icon-marcas" />
            &nbsp;&nbsp; MARCAS
          </div>

          <div className="parting-line" />
          {brands.length && brands[0]?.service_sub.length > 0 && (
            <>
              <div className="text-description">
                <p>
                  Protege el nombre, sonido, logotipo o frase comercial con el
                  que deseas distinguir tus productos o servicios
                </p>
                <h2 className="subtitle-modle">
                  {brands.length && brands[0].service_sub[0].service_name}
                </h2>
                <p>
                  {brands.length &&
                    brands[0].service_sub[0].service_description}
                </p>
                <p className="lablel-price">A partir de</p>
                <p className="price">
                  {brands.length && (
                    <>
                      <AtaCurrencyFormat
                        value={brands[0].service_sub[0].service_price}
                        showCurrency={false}
                      />
                      <span className="currency">MXN</span>
                    </>
                  )}
                </p>
                <div className="content-btn ">
                  <Button
                    onClick={() =>
                      handleSaveClick(brands[0]?.service_sub[0]?.service_id)
                    }
                    className="btnConfirm"
                  >
                    SOLICITAR
                  </Button>
                </div>
              </div>

              <div className="parting-line" />
            </>
          )}
          {brands.length && brands[0]?.service_sub.length > 1 && (
            <>
              <div className="text-description">
                <h2 className="subtitle-modle">
                  {brands.length && brands[0]?.service_sub[1]?.service_name}
                </h2>
                <p>Inicia el proceso de registro de tu signo distintivo.</p>
                <p>
                  {brands.length &&
                    brands[0]?.service_sub[1]?.service_description}
                </p>

                <p className="lablel-price">A partir de</p>
                <p className="price">
                  {brands.length && (
                    <>
                      <AtaCurrencyFormat
                        value={brands[0]?.service_sub[1]?.service_price}
                        showCurrency={false}
                      />
                      <span className="currency">MXN</span>
                      <span className="text-description"> (POR CLASE)</span>
                    </>
                  )}
                </p>
                <div className="content-btn ">
                  <Button
                    onClick={() =>
                      handleSaveClick(brands[0]?.service_sub[1]?.service_id)
                    }
                    className="btnConfirm"
                  >
                    SOLICITAR
                  </Button>
                </div>
              </div>

              <div className="parting-line" />
            </>
          )}

          <div className="content-btn content-btn-back">
            <ButtonRound onClick={goBack} icon="faArrowLeft">
              REGRESAR
            </ButtonRound>
          </div>
        </div>
      )}
    </>
  );
};

export default Servicios;
