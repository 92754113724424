import React, { useState, useEffect } from 'react';

import classes from './ClassesList.module.css';

const ClassesList = props => {

  const { onSelectClasses } = props;
  const [selected, setSelected] = useState([]);
  const [unselected, setUnselected] = useState([]);

  useEffect(() => {
    onSelectClasses({ selected, unselected });
  }, [onSelectClasses, selected, unselected]);

  const onClickHandler = (event) => {

    if (event.target.checked) {
      setSelected([...selected, {
        id: event.target.id,
        class_id: event.target.value,
        name: event.target.name
      }]);
      setUnselected(unselected.filter(item => {
        return item.id !== event.target.id;
      }))
    } else {
      setUnselected([...unselected, {
        id: event.target.id,
        class_id: event.target.value,
        name: event.target.name
      }])
      setSelected(selected.filter(item => {
        return item.id !== event.target.id;
      }));
    }
  }

  return (
    <section className={classes.classesList}>
      {props.classesList.map(item => {
        let index = props.selectedItems.findIndex(x => JSON.stringify(x) === JSON.stringify(item));
        return (
          <div key={item.id}>
            <label className={classes.checkitem}>
              {item.name}
              <input
                type="checkbox"
                name={item.name}
                value={item.class_id}
                id={item.id}
                onClick={onClickHandler}
                defaultChecked={index !== -1}
              />
              <span className={classes.checkmark}></span>
            </label>
          </div>
        );
      }
      )}
    </section>
  );
}

export default ClassesList;