import hambuger from "../../../../assets/images/icons/hamburger-icon.svg";
import "./drawer-toggle.css";

const drawerToggle = (props) => (
  <div className="DrawerToggle" onClick={props.onClick}>
    <img src={hambuger} alt=""></img>
  </div>
);

export default drawerToggle;
