import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { userProfileActions } from "../../store/slices/userProfile";
import { InputIcon, Spinner, ErrorMessage, Button } from "../../components";
import logo from "../../assets/images/logo-aTuAlcance.svg";
import classes from "./resetPassword.module.css";

const ResetPasswordForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const formReset = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Correo electrónico invalido")
        .required("Campo correo electrónico es requerido"),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      const bodyReset = {
        username: values.email,
      };

      await axios
        .request({
          url: process.env.REACT_APP_URL_BASE + "auth/forgotpassword",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: bodyReset,
        })
        .then((response) => {
          if (response.data.code === 200) {
            dispatch(
              userProfileActions.setDataRegisterUser({ data: bodyReset })
            );
            history.push("/confirmar-contrasena");
          }
          setLoading(false);
        })
        .catch((_) => {
          setLoading(false);
          setErrorMsg(true);
        });
    },
  });
  
  return (
    <div className="container-simple">
      <img src={logo} className="logo" alt="A tu alcance" />
      <h2 className="subtitle">RECUPERAR CONTRASEÑA</h2>
      <p className="description">
        Por favor ingresa el correo electrónico asociado a tu cuenta de usuario.
      </p>
      <div className={classes.containerForm}>
        <form onSubmit={formReset.handleSubmit}>
          <div className="form-row-element">
            <InputIcon
              id="email"
              name="email"
              type="email"
              placeholder="Correo electrónico"
              onChange={formReset.handleChange}
              icon="envelope"
              valid={formReset.errors.email && formReset.touched.email}
            />
          </div>
          <div className={classes.containerError}>
            {errorMsg && <ErrorMessage message="Correo no registrado" />}
          </div>

          <div>
            {loading ? (
              <Spinner />
            ) : (
              <Button type="submit" className="btnConfirm">
                ENVIAR
              </Button>
            )}
          </div>

          <div className={classes.containerButton}>
            <Button type="submit" className="btnCancel">
              CANCELAR
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
