import { useState } from "react";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import { BtnCallToAction, Button } from "../../../components";
import { AtaCurrencyFormat } from "../../../components/Common";
import classes from "./MyRequest.module.css";
import { REQUEST_PAYMENTS_IMPEDIMENTS } from "../../../constants/routes";
import PaymentImpediment from "./PaymentImpediment";
import basicAuthRequest from "../../../utils/axios/basicAuthRequest";
import { REJECT_IMPEDIMENT } from "../../../constants/atualcanceApi";
import RejectedImpediment from "./RejectedImpediment";
import { downloadFile } from "../../../utils/axios/downloadFile";

const Impediment = ({ dataImpediment, serviceId }) => {
  const [rejected, setRejected] = useState(false);
  const history = useHistory();

  const handlePaymentImpediment = () => {
    history.replace(`${REQUEST_PAYMENTS_IMPEDIMENTS}/${serviceId}`);
  };

  const handleRejectImpediment = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await basicAuthRequest({
      url: `${REJECT_IMPEDIMENT}/user/${user.user_id}/serviceorder/${serviceId}/servicerecurrent/${dataImpediment.service_recurrent_id}`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      setRejected(true);
    }
  };

  const downloadFileImpediment = async (fileName) => {
    const urlToDownload = await downloadFile(serviceId, 9, fileName);
    saveAs(urlToDownload.data.url);
  };

  return (
    <div className={classes.sectionInfo}>
      <div className="parting-line" />
      <div className={classes.headerSectionComponent}>
        <h2 className="subtitle-section">IMPEDIMENTO</h2>
      </div>

      <div className={classes.sectionInfo}>
        <div className={classes.infoBrand}>
          <div className={classes.itemInfo}>
            <div className={classes.itemHead}>MONTO REQUERIDO</div>
            <div className={classes.itemData}>
              <AtaCurrencyFormat value={dataImpediment.amount} />
            </div>
          </div>

          <div className={classes.itemInfo}>
            <div className={classes.itemHead}>FECHA LÍMITE DE PAGO</div>
            <div className={classes.itemData}>{dataImpediment.due_date}</div>
          </div>
        </div>
      </div>
      <div className={classes.fileItem}>
        {dataImpediment.file && (
          <>
            <div className={classes.itemHead}>
              DOCUMENTO DE SOLICITUD DE REGISTRO
            </div>
            <BtnCallToAction
              onClick={() => downloadFileImpediment(dataImpediment.file)}
              icon="faDownload"
              className="btnDownload"
            >
              {dataImpediment.file}
            </BtnCallToAction>
          </>
        )}
        {dataImpediment.status_id === 2 ? (
          <PaymentImpediment
            dudate={dataImpediment.due_date}
            amount={dataImpediment.amount}
            transaction={dataImpediment.transaction}
          />
        ) : dataImpediment.status_id === 3 || rejected ? (
          <RejectedImpediment dudate={dataImpediment.due_date} />
        ) : (
          <>
            <div className={classes.contentButton}>
              <Button onClick={handlePaymentImpediment} className="btnConfirm">
                PAGAR REQUERIMIENTO
              </Button>
            </div>
            <div className={classes.contentButton}>
              <Button className="btnCancel" onClick={handleRejectImpediment}>
                RECHAZAR REQUERIMIENTO
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Impediment.prototype = {
  dataImpediment: PropTypes.array.isRequired,
};

export default Impediment;
