import basicAuthRequest from "../../../utils/axios/basicAuthRequest";

/**
 * Get list of  services from the user
 * 2 Estudio de registrabilidad
 * @returns promise
 */
export function requestUserGetServices(params) {
  const user = JSON.parse(localStorage.getItem("user"));
  return basicAuthRequest.request({
    method: "GET",
    url: `feed/getuserservice/user/${user.user_id}/service/${params.service}`,
  });
}
/**
 * GetSerices of the company
 * @params {*} params
 */
export function requestGetServices() {
  return basicAuthRequest.request({
    method: "GET",
    url: "feed/getservices",
  });
}

/**
 * Request to get Entities
 * 1 Titular
 * 2 Autor
 * 3 Coautor
 * 4 Licenciatario
 * 5 Cesionario
 */
export function requestGetEntity(params) {
  const user = JSON.parse(localStorage.getItem("user"));
  return basicAuthRequest.request({
    method: "GET",
    url: `feed/getentities/user/${user.user_id}/entity/${params.typeId}`,
  });
}
