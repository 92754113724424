import { createSlice } from "@reduxjs/toolkit";

const initialObrasState = {
  isRegisteredHolder: true, // Seleccion de titular de la lista de titulares registrados
  authorSelect: "1", //Author de la orba, 1 = selecciona autor de lista de autores, 2 = registra nuevo autor, 3 El autor es el mismo que el titular
  brandInfo: {
    name: "",
    type: "-1",
    description: "",
    file: "",
  },
  owner_id: "-1",
  owner_info: {
    type: "-1", // PERSONA FISICA, PERSONA MORAL
    name: "",
    rfc: "",
    curp: "",
    street: "",
    ext_number: "",
    int_number: "",
    email: "",
    phone: "",
    state: "",
    city: "",
    postal_code: "",
    neighborhood: "",
    file: "",
  },
  is_author_same_owner: false,
  author_id: "-1",
  author_info: {
    type: -1,
    name: "",
    rfc: "",
    curp: "",
    street: "",
    ext_number: "",
    int_number: "",
    email: "",
    phone: "",
    state: "",
    city: "",
    postal_code: "",
    neighborhood: "",
    file: "",
  },
  coauthor_id: [],
  coauthor_info: [],
  billing_info: {
    payment_method_id: "",
    price_total: "",
  },
  billing_result: null,
  isValidRegisterObras: false,
};

const objectRecord = {
  is_new_coauthor: true,
  type: -1,
  name: "",
  rfc: "",
  curp: "",
  street: "",
  ext_number: "",
  int_number: "",
  email: "",
  phone: "",
  state: "",
  city: "",
  postal_code: "",
  neighborhood: "",
  file: "",
  isRegistered: true,
  deleted: false,
  isValid: false,
};

const validateEntity = (entity) => {
  let valid = true;

  if (entity.type === "-1") {
    valid = false;
  }
  if (entity.name === "") {
    valid = false;
  }
  if (entity.rfc === "") {
    valid = false;
  }
  if (entity.curp === "") {
    valid = false;
  }
  if (entity.street === "") {
    valid = false;
  }
  if (entity.ext_number === "") {
    valid = false;
  }
  if (entity.email === "") {
    valid = false;
  }
  if (entity.phone === "") {
    valid = false;
  }
  if (entity.state === "") {
    valid = false;
  }
  if (entity.city === "") {
    valid = false;
  }
  if (entity.postal_code === "") {
    valid = false;
  }
  if (entity.neighborhood === "") {
    valid = false;
  }
  if (entity.file === "") {
    valid = false;
  }

  return valid;
};

const validateRegisterObras = (state, index) => {
  console.log("isRegisteredHolder", state.isRegisteredHolder);
  console.log("authorSelect", state.authorSelect);
  let valid = true;

  if (state.isRegisteredHolder) {
    if (state.owner_id === "-1") {
      valid = false;
    }
  } else {
    valid = validateEntity(state.owner_info);
    console.log("valid validateEntity", valid);
  }

  if (state.authorSelect === "1") {
    if (state.author_id === "-1") {
      valid = false;
    }
  }

  if (typeof state.coauthor_id[index] !== "undefined") {
    if (state.coauthor_id[index] === -1) {
      valid = validateEntity(state.coauthor_info[index]);
    }
  }

  if (state.authorSelect === "2") {
    valid = validateEntity(state.author_info);
  }

  if (state.brandInfo.type === "-1") {
    valid = false;
  }

  if (state.brandInfo.name === "") {
    valid = false;
  }

  if (state.brandInfo.description === "") {
    valid = false;
  }

  if (state.brandInfo.file === "") {
    valid = false;
  }
  
  console.log("Valid Obras", valid);
  return valid;
};

const validateEntityIndex = (state, index) => {
  let valid = true;

  if (state.coauthor_info[index].type === "-1") {
    valid = false;
  }
  if (state.coauthor_info[index].name === "") {
    valid = false;
  }
  if (state.coauthor_info[index].rfc === "") {
    valid = false;
  }
  if (state.coauthor_info[index].curp === "") {
    valid = false;
  }
  if (state.coauthor_info[index].street === "") {
    valid = false;
  }
  if (state.coauthor_info[index].ext_number === "") {
    valid = false;
  }
  if (state.coauthor_info[index].email === "") {
    valid = false;
  }
  if (state.coauthor_info[index].phone === "") {
    valid = false;
  }
  if (state.coauthor_info[index].state === "") {
    valid = false;
  }
  if (state.coauthor_info[index].city === "") {
    valid = false;
  }
  if (state.coauthor_info[index].postal_code === "") {
    valid = false;
  }
  if (state.coauthor_info[index].neighborhood === "") {
    valid = false;
  }
  if (state.coauthor_info[index].file === "") {
    valid = false;
  }

  return valid;
};

const obrasSlice = createSlice({
  name: "obras",
  initialState: initialObrasState,
  reducers: {
    cleanStorageObras(state) {
      state.isRegisteredHolder = true;
      state.authorSelect = "1";
      state.brandInfo = {
        name: "",
        type: "-1",
        description: "",
        file: "",
      };
      state.owner_id = "-1";
      state.owner_info = {
        type: "-1",
        name: "",
        rfc: "",
        curp: "",
        street: "",
        ext_number: "",
        int_number: "",
        email: "",
        phone: "",
        state: "",
        city: "",
        postal_code: "",
        neighborhood: "",
        file: "",
      };
      state.is_author_same_owner = false;
      state.author_id = "-1";
      state.author_info = {
        type: -1,
        name: "",
        rfc: "",
        curp: "",
        street: "",
        ext_number: "",
        int_number: "",
        email: "",
        phone: "",
        state: "",
        city: "",
        postal_code: "",
        neighborhood: "",
        file: "",
      };
      state.coauthor_id = [];
      state.coauthor_info = [];
      state.billing_info = {
        payment_method_id: "",
        price_total: "",
      };
      state.billing_result = null;
      state.isValidRegisterObras = false;
    },
    setIsValidObras(state, action) {
      state.isValidObras = action.payload.isValidObras;
    },
    setName(state, action) {
      state.brandInfo.name = action.payload.name;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setType(state, action) {
      state.brandInfo.type = action.payload.type;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setDescription(state, action) {
      state.brandInfo.description = action.payload.description;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setFile(state, action) {
      state.brandInfo.file = action.payload.file;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setIsregisteredHolder(state, action) {
      state.isRegisteredHolder = action.payload.isRegisteredHolder;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setOwnerId(state, action) {
      state.owner_id = action.payload.owner_id;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setAuthorSelect(state, action) {
      state.authorSelect = action.payload.authorSelect;

      if (action.payload.authorSelect === "3") {
        state.is_author_same_owner = true;
      }

      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setCoAuthorSelect(state, action) {
      state.coauthor_info[action.payload.index] = objectRecord;
    },
    deleteCoAuthor(state, action) {
      state.coauthor_info.splice(action.payload.index - 1, 1);
      state.coauthor_id.splice(action.payload.index - 1, 1);
    },
    setIsRegisteredCoauthor(state, action) {
      state.coauthor_info[action.payload.index].isRegistered =
        action.payload.isRegistered;
    },
    /** billing operations */
    setBillingResult(state, action) {
      state.billing_result = action.payload.billing_result;
    },

    createOrderObras() {},
    /** CoAuthor Info */

    setCoAuthorInfoId(state, action) {
      state.coauthor_id[action.payload.index] = +action.payload.coauthor_id;
      state.isValidRegisterObras = validateRegisterObras(
        state,
        action.payload.index
      );

      // validamos al co-author si es seleccionado de la lista de couathores lo marcamos como valido
      if (+action.payload.coauthor_id !== -1) {
        state.coauthor_info[action.payload.index].isValid = true;
      } else {
        state.coauthor_info[action.payload.index].isValid = validateEntityIndex(
          state,
          action.payload.index
        );
      }
    },
    setCoAuthorInfoType(state, action) {
      state.coauthor_info[action.payload.index].type = action.payload.type;
      state.isValidRegisterObras = validateRegisterObras(
        state,
        action.payload.index
      );
      state.coauthor_info[action.payload.index].isValid = validateEntityIndex(
        state,
        action.payload.index
      );
    },
    setCoAuthorInfoRfc(state, action) {
      state.coauthor_info[action.payload.index].rfc = action.payload.rfc;
      state.isValidRegisterObras = validateRegisterObras(
        state,
        action.payload.index
      );
      state.coauthor_info[action.payload.index].isValid = validateEntityIndex(
        state,
        action.payload.index
      );
    },
    setCoAuthorInfoCurp(state, action) {
      state.coauthor_info[action.payload.index].curp = action.payload.curp;
      state.isValidRegisterObras = validateRegisterObras(
        state,
        action.payload.index
      );
      state.coauthor_info[action.payload.index].isValid = validateEntityIndex(
        state,
        action.payload.index
      );
    },
    setCoAuthorInfoName(state, action) {
      state.coauthor_info[action.payload.index].name = action.payload.name;
      state.isValidRegisterObras = validateRegisterObras(
        state,
        action.payload.index
      );
      state.coauthor_info[action.payload.index].isValid = validateEntityIndex(
        state,
        action.payload.index
      );
    },
    setCoAuthorInfoStreetName(state, action) {
      state.coauthor_info[action.payload.index].street =
        action.payload.streetName;
      state.isValidRegisterObras = validateRegisterObras(
        state,
        action.payload.index
      );
      state.coauthor_info[action.payload.index].isValid = validateEntityIndex(
        state,
        action.payload.index
      );
    },
    setCoAuthorInfoExtNumber(state, action) {
      state.coauthor_info[action.payload.index].ext_number =
        action.payload.extNumber;
      state.isValidRegisterObras = validateRegisterObras(
        state,
        action.payload.index
      );
      state.coauthor_info[action.payload.index].isValid = validateEntityIndex(
        state,
        action.payload.index
      );
    },
    setCoAuthorInfoIntNumber(state, action) {
      state.coauthor_info[action.payload.index].int_number =
        action.payload.intNumber;
      state.isValidRegisterObras = validateRegisterObras(
        state,
        action.payload.index
      );
      state.coauthor_info[action.payload.index].isValid = validateEntityIndex(
        state,
        action.payload.index
      );
    },
    setCoAuthorInfoEmail(state, action) {
      state.coauthor_info[action.payload.index].email = action.payload.email;
      state.isValidRegisterObras = validateRegisterObras(
        state,
        action.payload.index
      );
      state.coauthor_info[action.payload.index].isValid = validateEntityIndex(
        state,
        action.payload.index
      );
    },
    setCoAuthorInfoPhone(state, action) {
      state.coauthor_info[action.payload.index].phone = action.payload.phone;
      state.isValidRegisterObras = validateRegisterObras(
        state,
        action.payload.index
      );
      state.coauthor_info[action.payload.index].isValid = validateEntityIndex(
        state,
        action.payload.index
      );
    },
    setCoAuthorInfoState(state, action) {
      state.coauthor_info[action.payload.index].state = action.payload.state;
      state.isValidRegisterObras = validateRegisterObras(
        state,
        action.payload.index
      );
      state.coauthor_info[action.payload.index].isValid = validateEntityIndex(
        state,
        action.payload.index
      );
    },
    setCoAuthorInfoCity(state, action) {
      state.coauthor_info[action.payload.index].city = action.payload.city;
      state.isValidRegisterObras = validateRegisterObras(
        state,
        action.payload.index
      );
      state.coauthor_info[action.payload.index].isValid = validateEntityIndex(
        state,
        action.payload.index
      );
    },
    setCoAuthorInfoPostalCode(state, action) {
      state.coauthor_info[action.payload.index].postal_code =
        action.payload.postalCode;
      state.isValidRegisterObras = validateRegisterObras(
        state,
        action.payload.index
      );
      state.coauthor_info[action.payload.index].isValid = validateEntityIndex(
        state,
        action.payload.index
      );
    },
    setCoAuthorInfoNeighborhood(state, action) {
      state.coauthor_info[action.payload.index].neighborhood =
        action.payload.neighborhood;
      state.isValidRegisterObras = validateRegisterObras(
        state,
        action.payload.index
      );
      state.coauthor_info[action.payload.index].isValid = validateEntityIndex(
        state,
        action.payload.index
      );
    },
    setCoAuthorInfoFile(state, action) {
      state.coauthor_info[action.payload.index].file = action.payload.file;
      state.isValidRegisterObras = validateRegisterObras(
        state,
        action.payload.index
      );
      state.coauthor_info[action.payload.index].isValid = validateEntityIndex(
        state,
        action.payload.index
      );
    },

    /** Author Info */
    setAuthorInfoId(state, action) {
      state.author_id = action.payload.author_id;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setAuthorInfoType(state, action) {
      state.author_info.type = action.payload.type;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setAuthorInfoRfc(state, action) {
      state.author_info.rfc = action.payload.rfc;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setAuthorInfoCurp(state, action) {
      state.author_info.curp = action.payload.curp;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setAuthorInfoName(state, action) {
      state.author_info.name = action.payload.name;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setAuthorInfoStreetName(state, action) {
      state.author_info.street = action.payload.streetName;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setAuthorInfoExtNumber(state, action) {
      state.author_info.ext_number = action.payload.extNumber;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setAuthorInfoIntNumber(state, action) {
      state.author_info.int_number = action.payload.intNumber;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setAuthorInfoEmail(state, action) {
      state.author_info.email = action.payload.email;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setAuthorInfoPhone(state, action) {
      state.author_info.phone = action.payload.phone;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setAuthorInfoState(state, action) {
      state.author_info.state = action.payload.state;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setAuthorInfoCity(state, action) {
      state.author_info.city = action.payload.city;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setAuthorInfoPostalCode(state, action) {
      state.author_info.postal_code = action.payload.postalCode;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setAuthorInfoNeighborhood(state, action) {
      state.author_info.neighborhood = action.payload.neighborhood;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setAuthorInfoFile(state, action) {
      state.author_info.file = action.payload.file;
      state.isValidRegisterObras = validateRegisterObras(state);
    },

    //** Owner Info */
    setOwnerInfoType(state, action) {
      state.owner_info.type = action.payload.type;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setOwnerInfoRfc(state, action) {
      state.owner_info.rfc = action.payload.rfc;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setOwnerInfoCurp(state, action) {
      state.owner_info.curp = action.payload.curp;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setOwnerInfoName(state, action) {
      state.owner_info.name = action.payload.name;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setOwnerInfoStreetName(state, action) {
      state.owner_info.street = action.payload.streetName;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setOwnerInfoExtNumber(state, action) {
      state.owner_info.ext_number = action.payload.extNumber;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setOwnerInfoIntNumber(state, action) {
      state.owner_info.int_number = action.payload.intNumber;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setOwnerInfoEmail(state, action) {
      state.owner_info.email = action.payload.email;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setOwnerInfoPhone(state, action) {
      state.owner_info.phone = action.payload.phone;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setOwnerInfoState(state, action) {
      state.owner_info.state = action.payload.state;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setOwnerInfoCity(state, action) {
      state.owner_info.city = action.payload.city;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setOwnerInfoPostalCode(state, action) {
      state.owner_info.postal_code = action.payload.postalCode;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setOwnerInfoNeighborhood(state, action) {
      state.owner_info.neighborhood = action.payload.neighborhood;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
    setOwnerInfoFile(state, action) {
      state.owner_info.file = action.payload.file;
      state.isValidRegisterObras = validateRegisterObras(state);
    },
  },
});

export const obrasActions = obrasSlice.actions;

export default obrasSlice;
