import PropTypes from "prop-types";
import { Spinner, Button } from "../../../../components";
import { AtaCurrencyFormat } from "../../../../components/Common";

const TradeMarkData = ({ loading, tradeMark, handleSubmit }) => (
  <>
    <div className="label-information">
      <div>INFORMACIÓN DE LA MARCA</div>
    </div>
    {loading ? (
      <Spinner />
    ) : (
      <>
        <div className="confirmation-item">
          <h3>NOMBRE DE LA MARCA</h3>
          <p>{tradeMark[0]?.name_brand}</p>
        </div>
        <div className="confirmation-item">
          <h3>NÚMERO DE REGISTRO</h3>
          <p>{tradeMark[0]?.register_number}</p>
        </div>
        <div className="confirmation-item">
          <h3>NOMBRE DEL TITULAR</h3>
          <p>{tradeMark[0]?.name_owner}</p>
        </div>
        <div className="confirmation-item">
          <h3>FECHA LIMITE DE DECLARACIÓN</h3>
          <p>{tradeMark[0]?.final_date}</p>
        </div>
        <div className="confirmation-item">
          <h3>COSTO DEL TRAMITE</h3>
          <span className="ammount">
            <AtaCurrencyFormat value={tradeMark[0]?.transact_amount} />
          </span>
        </div>
        <div className="confirmation-item">
          <h3>HONORARIOS POR DECLARACIÓN</h3>
          <span className="ammount">
            <AtaCurrencyFormat value={tradeMark[0]?.honorarium_amount} />
          </span>
        </div>

        <div className="contentainer-button">
          <Button className="btnConfirm" onClick={handleSubmit}>
            CONTINUAR
          </Button>
        </div>
      </>
    )}
     <div className="label-information">
        <div>INFORMACIÓN DEL PAGO</div>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
  </>
);

TradeMarkData.prototype = {
  loading: PropTypes.bool.isRequired,
  tradeMark: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default TradeMarkData;
