import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ConfirmationTemplate,
  ButtonBack,
  ButtonEdit,
} from "../../../../components";
import { registerActions } from "../../../../store/slices/brandRegistrabilityStudy";
import {
  BRANDS_REGISTRABILITY_STUDY,
  BRANDS_REGISTRABILITY_STUDY_CHECKOUT,
} from "../../../../constants/routes";
import { DASHBOARD_SERVICES } from "../../../../constants/routes";
import classes from "./Confirmation.module.css";

const Confirmation = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const registerdata = useSelector((state) => state.register);
  const paymentData = useSelector((state) => state.payment);
  const storeStudy = useSelector((state) => state.register);

  useEffect(() => {
    if (!storeStudy.isValid) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeStudy.isValid]);

  const gotoBrandInfo = (_) => {
    props.history.replace(BRANDS_REGISTRABILITY_STUDY);
  };

  const gotoPayment = () => {
    props.history.replace(BRANDS_REGISTRABILITY_STUDY_CHECKOUT);
  };

  const redirectReceipt = useCallback(() => {
    dispatch(registerActions.brandRegistrabilityStudy());
  }, [dispatch]);

  return (
    <div className="container-modulo">
      <ButtonBack url={BRANDS_REGISTRABILITY_STUDY_CHECKOUT} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">ESTUDIO DE REGISTRABILIDAD </h2>
      </div>

      <div className={classes.description}>
        <p>
          Proporciona la información para realizar el estudio de
          registrabilidad.
        </p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE LA MARCA</div>
        <ButtonEdit onClick={gotoBrandInfo} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DEL PAGO</div>
        <ButtonEdit onClick={gotoPayment} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
      <div>
        <ConfirmationTemplate
          registerData={registerdata}
          paymentData={paymentData}
          onConfirm={redirectReceipt}
          scope="study-registry"
          error={registerdata.errorMessage}
        />
      </div>
    </div>
  );
};

export default Confirmation;
