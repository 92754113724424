import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonBack, ButtonEdit } from "../../../../components";
import RightsLicenseTemplate from "../../../../components/Templates/Confirmation/RightsLicenseTemplate";
import { rightsActions } from "../../../../store/slices/rights";
import { DASHBOARD_SERVICES, RIGHTS_LICENSE, RIGHTS_LICENSE_CHECKOUT } from "../../../../constants/routes";

const ConfirmationLicense = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const storeRights = useSelector((state) => state.rights);
  const storePayment = useSelector((state) => state.payment);

  useEffect(() => {
    if (storeRights.brand_info.registration_number.length === 0) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeRights.brand_info.registration_number.length]);

  const EDITRIGHTDLICENSE = RIGHTS_LICENSE;
  const EDITBRAND_CHECKOUT = RIGHTS_LICENSE_CHECKOUT;

  const gotoBrandInfo = () => {
    props.history.replace(EDITRIGHTDLICENSE);
  };

  const gotoPayment = () => {
    props.history.replace(EDITBRAND_CHECKOUT);
  };

  const redirectReceipt = useCallback(() => {
    dispatch(rightsActions.createRightsLicense());
  }, [dispatch]);

  return (
    <div className="container-modulo">
      <ButtonBack url={EDITBRAND_CHECKOUT} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">LICENCIA DE DERECHOS</h2>
      </div>

      <div className="description-confirm">
        <p>
          Elaboración e Inscripción ante el IMPI del Contrato de Licencia de Uso
          de derechos de Propiedad Intelectual, entre tu y tu socio comercial,
          de manera práctica y sencilla.
        </p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DEL DERECHO</div>
        <ButtonEdit onClick={gotoBrandInfo} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE PAGO</div>
        <ButtonEdit onClick={gotoPayment} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
      <div>
        <RightsLicenseTemplate
          registerData={storeRights}
          paymentData={storePayment}
          onConfirm={redirectReceipt}
          scope="reg-std-rights"
        />
      </div>
    </div>
  );
};

export default ConfirmationLicense;
