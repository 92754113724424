import { call, put, select } from "redux-saga/effects";
import { replace } from "connected-react-router";

import { paymentActions } from "../../slices/payment";
import { registerActions } from "../../slices/brandRegistrabilityStudy";
import { requestCreateBrandRegStudyOrder } from "../requests/brandRegStudyRequest";
import { TICKECT } from "../../../constants/routes";

export function* handlerCreateBrandRegStudy() {
  const storeRegisterBrandStudy = yield select((store) => store.register);
  const storePayment = yield select((store) => store.payment);

  const response = yield call(
    requestCreateBrandRegStudyOrder,
    storeRegisterBrandStudy,
    storePayment
  );

  if (response.status === 200) {
    yield put(
      paymentActions.setBillingResult({ billing_result: response.data })
    );
    yield put(replace(TICKECT));
    yield put(paymentActions.cleanStorePayment());
    yield put(registerActions.cleanStoreStudy());
    yield put(registerActions.setError(""));
    yield put(registerActions.setError({ error: "" }));
  } else {
    yield put(registerActions.setError({ error: "Ocurrio un error" }));
  }
}
