import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import AllPdfPages from "../../components/UI/PdfViewer/AllPdfPages";
import sampleDocument from "../../assets/ejemploDisenio.pdf";
import {
  ButtonBack,
  Button,
  InputSimpleFloatLabel,
  BtnCallToAction,
  InputRadio,
  ModalDrag,
  InputSelect,
  EntityForm,
  ErrorMessage,
  ButtonRound,
} from "../../components";
import { getFileName } from "../../utils/scripts/file";
import FileModal from "../../components/UI/Modal/FileModal/FileModal";
import { caculateAmountPrice } from "../../utils/scripts/calculatePrice";
import { industrialDActions } from "../../store/slices/industrialDesigns";
import { userProfileActions } from "../../store/slices/userProfile";
import { paymentActions } from "../../store/slices/payment";
import { services } from "../../constants/services";
import {
  DESIGNS_CHECKOUT,
  DESIGNS_CONFIRMATION,
  DASHBOARD_SERVICES,
} from "../../constants/routes";
import classes from "./IndustrialDesigns.module.css";
import { USER_PDLC } from "../../constants";

const CHECKOUT_MG = DESIGNS_CHECKOUT;
const SELECT_STD = DASHBOARD_SERVICES;

const IndustrialDesigns = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dragModal, setDragModal] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [validfiles, setValidFiles] = useState(true);
  const [submited, setSubmited] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [userData, setUserData] = useState();

  const storeIndustrialDesigns = useSelector((state) => state.industrialDesign);
  const storeEntity = useSelector((state) => state.entity);
  const holders = useSelector((state) => state.userProfile.holders);
  const storeUserProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
  }, []);

  useEffect(() => {
    dispatch(userProfileActions.getServices());
    dispatch(userProfileActions.getUserServices());
    dispatch(userProfileActions.getHolders());
  }, [dispatch]);

  useEffect(() => {
    if (storeIndustrialDesigns.isRegisteredHolder) {
      if (storeIndustrialDesigns.isValidRGM) {
        setValidForm(true);
      } else {
        setValidForm(false);
      }
    } else {
      if (storeIndustrialDesigns.isValidRGM && storeEntity.titular.valid) {
        setValidForm(true);
      } else {
        setValidForm(false);
      }
    }
  }, [
    storeIndustrialDesigns.isValidRGM,
    storeEntity.titular.valid,
    storeIndustrialDesigns.isRegisteredHolder,
  ]);

  useEffect(() => {
    if (storeIndustrialDesigns.isRegisteredHolder) {
      if (storeIndustrialDesigns.brand_info.file === "") {
        setValidFiles(false);
      } else {
        setValidFiles(true);
      }
    } else {
      if (
        storeEntity.titular.file === "" ||
        storeIndustrialDesigns.brand_info.file === ""
      ) {
        setValidFiles(false);
      } else {
        setValidFiles(true);
      }
    }
  }, [
    storeEntity.titular.file,
    storeIndustrialDesigns.brand_info.file,
    storeIndustrialDesigns.isRegisteredHolder,
  ]);

  const handleCloseFileModal = () => setShowFileModal(false);
  const handleOpenFileModal = () => setShowFileModal(true);

  const getPrice = () => {
    const industrialDesigns = storeUserProfile.services.services.filter(
      (item) => item.service_id === services.DISENOS_INDUSTRIALES
    );

    const price =
      +industrialDesigns[0].service_price +
      +industrialDesigns[0].service_transact;

    const totalprice = caculateAmountPrice(price);
    return totalprice.toFixed(2);
  };

  const registerNext = () => {
    setSubmited(true);
    if (validfiles) {
      const price = getPrice();
      dispatch(paymentActions.setPurchaseAmount({ amount: price }));
      if (userData.user_id === USER_PDLC) {
        history.replace(DESIGNS_CONFIRMATION);
      } else {
        history.replace(CHECKOUT_MG);
      }
    }
  };
  const clearFileUploaded = () => {
    dispatch(industrialDActions.setFile({ file: "" }));
  };
  const handleChangeName = (event) => {
    dispatch(industrialDActions.setBrandName({ name: event.target.value }));
  };
  const hanldleChangeTitularSelect = (event) => {
    dispatch(
      industrialDActions.setTitularId({
        titularId: event.target.value,
      })
    );
  };
  const handleChangeRegisterHolder = (event) => {
    dispatch(
      industrialDActions.setIsRegisteredHolder({
        isRegisteredHolder: event.target.value === "si",
      })
    );
  };
  const openDragModal = () => {
    setDragModal(true);
  };
  const closeDragModal = () => {
    setDragModal(false);
  };

  return (
    <div className="container-modulo">
      <FileModal show={showFileModal} close={handleCloseFileModal}>
        <AllPdfPages pdf={sampleDocument} />
      </FileModal>

      {dragModal && (
        <ModalDrag
          show={dragModal}
          closed={closeDragModal}
          scope="reg-std-industrial"
          formatFile="pdf"
        />
      )}
      <ButtonBack url={SELECT_STD} text="REGRESAR" />
      <div className="section-title">
        <h2 className="subtitle">REGRISTRO DE DISEÑOS INDUSTRIALES</h2>
      </div>
      <div className="text-description">
        <p>Protege tu Diseño Industrial de manera práctica y sencilla.</p>
      </div>
      <div className="label-information">
        <div>INFORMACIÓN DEL DISEÑO INDUSTRIAL</div>
      </div>
      <div className={classes.contentRadioSelect}>
        <InputSimpleFloatLabel
          id="name"
          name="name"
          defaultValue={storeIndustrialDesigns.brand_info.name}
          valid={storeIndustrialDesigns.brand_info.name !== ""}
          placeholder="NOMBRE DEL DISEÑO INDUSTRIAL"
          onChange={handleChangeName}
        />

        <div className="updaload-file">
          {storeIndustrialDesigns.brand_info.file === "" ? (
            <BtnCallToAction onClick={openDragModal} icon="faUpload">
              ARCHIVO DEL DISEÑO INDUSTRIAL
            </BtnCallToAction>
          ) : (
            <BtnCallToAction
              type="showfile"
              onClick={clearFileUploaded}
              icon="faTimesCircle"
              className="btnGreen"
              fileName={getFileName(storeIndustrialDesigns.brand_info.file)}
            >
              ARCHIVO DEL DISEÑO INDUSTRIAL
            </BtnCallToAction>
          )}
          <p className="description-file">
            Esté archivo deberá ser formato PDF.
          </p>
        </div>

        <div className="descripction-text">
          Para mejor referencia, selecciona la opción de “ver ejemplo”, para
          visualizar como debes compartirnos los dibujos de tu diseño
          industrial.
        </div>
        <div className={classes.contentButton}>
          <ButtonRound onClick={handleOpenFileModal} icon="faEye">
            VER EJEMPLO
          </ButtonRound>
        </div>

        <InputRadio
          id="si"
          name="isRegisteredHolder"
          value="si"
          onChange={handleChangeRegisterHolder}
          label="SELECCIONAR UN TITULAR DE MI LISTA DE TITULARES"
          checked={storeIndustrialDesigns.isRegisteredHolder}
        />
        {storeIndustrialDesigns.isRegisteredHolder && (
          <InputSelect
            id="brands"
            name="brands"
            placeholder="- ELIGE UN TITULAR -"
            options={holders}
            fieldIdOption="id"
            value={storeIndustrialDesigns.titularId}
            onChange={hanldleChangeTitularSelect}
          />
        )}
        <InputRadio
          id="no"
          name="isRegisteredHolder"
          value="no"
          onChange={handleChangeRegisterHolder}
          label="REGISTRAR NUEVO TITULAR"
          checked={!storeIndustrialDesigns.isRegisteredHolder}
        />
      </div>
      {!storeIndustrialDesigns.isRegisteredHolder && (
        <div className={classes.contentForm}>
          <EntityForm
            typeEntity="titular"
            entity="titular-musical-group"
            recordTypeFile="brand-owner-info"
          />
        </div>
      )}
      <div className={classes.contentRadioSelect}>
        {submited && !validfiles && (
          <ErrorMessage message="Sube los archivos solicitados" />
        )}
      </div>
      <div className={classes.contentButtonNext}>
        <Button
          onClick={registerNext}
          className="btnConfirm"
          disabled={!validForm}
        >
          CONTINUAR
        </Button>
      </div>

      <div className="label-information">
        <div>INFORMACIÓN DE LA PAGO</div>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
    </div>
  );
};

export default IndustrialDesigns;
