import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonBack, ButtonEdit } from "../../../../components";
import NewspaperTemplate from "../../../../components/Templates/Confirmation/NewspaperTemplate";
import { newspaperActions } from "../../../../store/slices/newspaper";
import {
  NEWSPAPERS_STUDY,
  NEWSPAPERS_STUDY_CHECKOUT,
  DASHBOARD_SERVICES,
} from "../../../../constants/routes";

const STD_INFO = NEWSPAPERS_STUDY;
const STD_CHECKOUT = NEWSPAPERS_STUDY_CHECKOUT;

const ConfirmationNewspaper = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const storeNewspaper = useSelector((state) => state.newspaper);
  const storePayment = useSelector((state) => state.payment);

  useEffect(() => {
    if (storeNewspaper.brand_info.name === "") {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeNewspaper.brand_info.name]);

  const gotoBrandInfo = (_) => {
    props.history.replace(STD_INFO);
  };

  const gotoPayment = () => {
    props.history.replace(STD_CHECKOUT);
  };

  const redirectReceipt = useCallback(() => {
    dispatch(newspaperActions.createNewspaperStudy());
  }, [dispatch]);

  return (
    <div className="container-modulo">
      <ButtonBack url={STD_CHECKOUT} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">
          ESTUDIO DE REGISTRABILIDAD DE NOMBRE DE PERIÓDICOS
        </h2>
      </div>

      <div className="description-confirm">
        <p>
          Proporciona la información para realizar el estudio de
          registrabilidad.
        </p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DEL PERIÓDICO</div>
        <ButtonEdit onClick={gotoBrandInfo} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE PAGO</div>
        <ButtonEdit onClick={gotoPayment} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
      <div>
        <NewspaperTemplate
          registerData={storeNewspaper}
          paymentData={storePayment}
          onConfirm={redirectReceipt}
          scope="reg-std-newspaper"
        />
      </div>
    </div>
  );
};

export default ConfirmationNewspaper;
