import { useState, useEffect } from "react";

import basicAuthRequest from "../../../utils/axios/basicAuthRequest";
import { BUSINESS_NAME } from "../../../constants/atualcanceApi";

const useBusinessName = () => {
  const [businessName, setBusinessName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const getBusnessName = async () => {
      try {
        setLoading(true);

        const response = await basicAuthRequest({
          method: "GET",
          url: BUSINESS_NAME + user.user_id,
        }).catch((error) => {
          setLoading(false);
          setError({ fail: error });
        });

        if (response?.status === 200) {
          setBusinessName(response.data);
        }
      } catch (err) {
        setError({ fail: error });
      } finally {
        setLoading(false);
      }
    };

    getBusnessName();
    return () => {
      setBusinessName([]);
    };
  }, [error, user.user_id]);

  return {
    businessName,
    loading,
    error,
  };
};

export default useBusinessName;
