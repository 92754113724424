import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonBack, ButtonEdit } from "../../../../components";
import MusicalGroupTemplate from "../../../../components/Templates/Confirmation/MusicalGroupTemplate";
import {
  MUSICAL_GROUP_STUDY,
  MUSICAL_GROUP_STUDY_CHECKOUT,
  DASHBOARD_SERVICES
} from "../../../../constants/routes";
import { musicalGroupActions } from "../../../../store/slices/musicalGroup";

const STD_INFO = MUSICAL_GROUP_STUDY;
const STD_CHECKOUT = MUSICAL_GROUP_STUDY_CHECKOUT;

const Confirmation = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const storeMusicalGroup = useSelector((state) => state.musicalGroup);
  const storePayment = useSelector((state) => state.payment);

  useEffect(() => {
    if (storeMusicalGroup.brand_info.name === "") {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeMusicalGroup.brand_info.name]);

  const gotoBrandInfo = (_) => {
    props.history.replace(STD_INFO);
  };

  const gotoPayment = () => {
    props.history.replace(STD_CHECKOUT);
  };

  const redirectReceipt = useCallback(() => {
    dispatch(musicalGroupActions.createMusicalGroupStudy());
  }, [dispatch]);

  return (
    <div className="container-modulo">
      <ButtonBack url={STD_CHECKOUT} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">
          ESTUDIO DE REGISTRABILIDAD DE NOMBRE DE GRUPOS MUSICALES
        </h2>
      </div>

      <div className="description-confirm">
        <p>
          Proporciona la información para realizar el estudio de
          registrabilidad.
        </p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DEL GRUPO MUSICAL</div>
        <ButtonEdit onClick={gotoBrandInfo} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE PAGO</div>
        <ButtonEdit onClick={gotoPayment} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
      <div>
        <MusicalGroupTemplate
          registerData={storeMusicalGroup}
          paymentData={storePayment}
          onConfirm={redirectReceipt}
          scope="reg-std-musical-group"
        />
      </div>
    </div>
  );
};

export default Confirmation;
