import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import PropTypes from "prop-types";
import { getFileName } from "../../../utils/scripts/file";
import { Button, Spinner } from "../../../components";
import EntityTemplate from "../Entity/EntityTemplate";
import classes from "./ConfirmationTemplate.module.css";

const Confirmation = (props) => {
  const [accepted, setAccepted] = useState(false);
  const [sendConfirm, setSendConfirm] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const [titular, setTitular] = useState([]);

  // useEffect(() => {
  //   setErrorMessage(props.error);
  // }, [props.error]);

  useEffect(() => {
    if (props.registerData.isRegisteredHolder) {
      const dataTitular = props.holders.filter(
        (element) => element.id === +props.registerData.titularId
      );
      setTitular(dataTitular[0]);
    } else {
      setTitular(props.registerData.owner_info);
    }
  }, [
    props.holders,
    props.registerData.isRegisteredHolder,
    props.registerData.owner_info,
    props.registerData.titularId,
  ]);

  const typeBrand =
    {
      1: "NOMINATIVA",
      2: "MIXTA",
      3: "SONORA",
      4: "DISEÑO",
    }[props.registerData.type] || "NOMINATIVA";

  const confirmation = (callback) => {
    setSendConfirm(true);
    callback();
  };

  return (
    <div className={classes.confirmation}>
      <div className={classes.confirmationItem}>
        <h3>NOMBRE DE LA MARCA</h3>
        <p>{props.registerData.name}</p>
      </div>
      <div className={classes.confirmationItem}>
        <h3>TIPO DE MARCA</h3>
        <p> {typeBrand}</p>
      </div>
      {props.registerData.file !== "" && (
        <div className={classes.confirmationItem}>
          <h3>LOGOTIPO</h3>
          <p> {getFileName(props.registerData.file)} </p>
        </div>
      )}
      {titular !== undefined && (
        <div className={classes.confirmationItem}>
          <h3>TITULAR</h3>

          <EntityTemplate title="DATOS DEL TILUAR">
            <div className="form-informacion-marca">
              <div>
                <div className={classes.labelEntity}>NOMBRE</div>
                <div className={classes.itemEntity}>{titular.name}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>CALLE</div>
                <div className={classes.itemEntity}>{titular.street}</div>
              </div>
            </div>
            <div className="form-informacion-marca">
              <div>
                <div className={classes.labelEntity}>NÚMERO EXTERIOR</div>
                <div className={classes.itemEntity}>{titular.ext_number}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>NÚMERO INTERIOR</div>
                <div className={classes.itemEntity}>{titular.int_number}</div>
              </div>
            </div>
            <div className="form-informacion-marca">
              <div>
                <div className={classes.labelEntity}>CORREO ELECTRÓNICO</div>
                <div className={classes.itemEntity}>{titular.email}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>TELÉFONO</div>
                <div className={classes.itemEntity}>{titular.phone}</div>
              </div>
            </div>
            <div className="form-informacion-marca">
              <div>
                <div className={classes.labelEntity}>ESTADO</div>
                <div className={classes.itemEntity}>{titular.state}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>CIUDAD</div>
                <div className={classes.itemEntity}>{titular.city}</div>
              </div>
            </div>
            <div className="form-informacion-marca">
              <div>
                <div className={classes.labelEntity}>CÓDIGO POSTAL</div>
                <div className={classes.itemEntity}>{titular.postal_code}</div>
              </div>
              <div>
                <div className={classes.labelEntity}>COLONIA</div>
                <div className={classes.itemEntity}>{titular.neighborhood}</div>
              </div>
            </div>
          </EntityTemplate>
        </div>
      )}

      <div className={classes.confirmationItem}>
        <h3>
          CLASES DE PRODUCTOS ASOCIADAS (
          {props.registerData.productClasses.length})
        </h3>
        {props.registerData.productClasses.map((item) => (
          <li key={item.id}>{item.name}</li>
        ))}
      </div>
      <div className={classes.confirmationItem}>
        <h3>
          TIPOS DE SERVICIOS ASOCIADOS (
          {props.registerData.servicesClasses.length})
        </h3>
        {props.registerData.servicesClasses.map((item) => (
          <li key={item.id}>{item.name}</li>
        ))}
      </div>
      <div className={classes.confirmationItem}>
        <h3>METODO DE PAGO</h3>
        <p style={{ textTransform: "uppercase" }}>
          {props.paymentData.paymentMethod}
        </p>
      </div>
      <div className={classes.confirmationItem}>
        <h3>TOTAL</h3>
        <div>
          <span className={classes.total}>
            <CurrencyFormat
              value={props.paymentData.purchaseAmount}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              renderText={(value) => (
                <div>
                  {value} <span className={classes.currency}>MXM</span>
                </div>
              )}
            />
          </span>
          <label htmlFor="terms" className={classes.terms}>
            (Incluye impuestos y comisiones)
          </label>
        </div>
      </div>

      <div>
        <label className={classes.checkitem}>
          <label htmlFor="terms" className={classes.terms}>
            Acepto todos los &nbsp;
            <NavLink to="/terminos-y-condiciones" className={classes.linkText}>
              TÉRMINOS Y CONDICIONES
            </NavLink>
          </label>
          <input
            type="checkbox"
            id="terms"
            name="terms"
            onChange={(event) => setAccepted(event.target.checked)}
          />
          <span className={classes.checkmark}></span>
        </label>
      </div>
      <div>
        <p>
          Al presionar "CONFIRMAR SOLICITUD" usted acepta todos los términos y
          condiciones de <b>A tu alcance.</b>
        </p>
        <p>El pago de esta solicitud no garantiza la aprobación de la misma.</p>
      </div>

      <div className={classes.btnConfirm}>
        {/* {sendConfirm && errorMessage === "" ? (
          <Spinner />
        ) : errorMessage === "" ? (
          <Button
            className={"btnConfirm"}
            onClick={() => confirmation(props.onConfirm)}
            disabled={!accepted}
          >
            CONFIRMAR SOLICITUD
          </Button>
        ) : (
          <p>
            Ocurrio un error intente nuevamente o contacte a un administrador
          </p>
        )} */}
        {sendConfirm ? (
          <Spinner />
        ) : (
          <Button
            className={"btnConfirm"}
            onClick={() => confirmation(props.onConfirm)}
            disabled={!accepted}
          >
            CONFIRMAR SOLICITUD
          </Button>
        )}
      </div>
    </div>
  );
};

Confirmation.propTypes = {
  registerData: PropTypes.object.isRequired,
  paymentData: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  scope: PropTypes.string.isRequired,
};

export default React.memo(Confirmation);
