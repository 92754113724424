import { call, put, select } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { paymentActions } from "../../slices/payment";
import { obrasActions } from "../../slices/obras";
import { requestCreateOrderObras } from "../requests/obrasRequest";
import { TICKECT } from "../../../constants/routes";

export function* handlerCreateOrderObras() {
  try {
    const storeObras = yield select((store) => store.obras);
    const storePayment = yield select((store) => store.payment);
    const response = yield call(
      requestCreateOrderObras,
      storeObras,
      storePayment
    );
    if (response.status === 200) {
      yield put(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      yield put(replace(TICKECT));
      yield put(paymentActions.cleanStorePayment());
      yield put(obrasActions.cleanStorageObras());
    } else {
      // TODO: generar pantalla de error
    }
  } catch (error) {
    console.log("Error handlerCreateOrderObras", error);
  }
}
