import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SimplePage } from './layouts';
import { LoginForm, ResetPassword, Register, Verification, NoticeOfPrivacy, ConfirmPassword } from './components'

const noAuthenticatedContent = _ => (
  <Switch>
    <Route exact path="/login">
      <SimplePage>
        <LoginForm />
      </SimplePage>
    </Route>
    <Route exact path="/resetear-contrasena">
      <SimplePage>
        <ResetPassword />
      </SimplePage>
    </Route>
    <Route exact path="/confirmar-contrasena">
      <SimplePage>
        <ConfirmPassword />
      </SimplePage>
    </Route>
    <Route exact path="/registro">
      <SimplePage>
        <Register />
      </SimplePage>
    </Route>
    <Route exact path="/verificacion">
      <SimplePage>
        <Verification />
      </SimplePage>
    </Route>
    <Route exact path="/aviso-privacidad">
      <SimplePage>
        <NoticeOfPrivacy />
      </SimplePage>
    </Route>
    <Redirect to={'/login'} />
  </Switch>
);


export default noAuthenticatedContent;