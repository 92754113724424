import React from 'react';

import logoWite from '../../../../assets/images/logo-white.svg';
import classes from './WideFooter.module.css'

const wideFooter = () => {
  return (
    <footer className={classes.footerWide}>
      <div className={classes.mainContentFooter}>
        <img src={logoWite} className={classes.logoFooter} alt="logo a tu alcance" />
        <div>&copy; 2021 A tu alcance.</div>
        <div>Todos los derechos reservados.</div>
      </div>
    </footer>
  );
}

export default wideFooter;