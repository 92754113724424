import { useState } from "react";
import MyRequestList from "./MyRequestList";
import MyRequestDetail from "./MyRequestDetail";
import useMyRequest from "./useMyRequest";

const screens = ["RequestList", "RequestDetail"];

const MyRequest = () => {
  const [currentScreen, setCurrentScreen] = useState(screens[0]);
  const [requestDetail, setRequestDetail] = useState([]);
  const [serviceID, setServiceId] = useState();
  const {
    loading,
    loadingbn,
    loadingRequestInvoice,
    loadingSaveNewBN,
    orders,
    errorSaveNewBN,
    getFilteredOrders,
    getRequestDetail,
    getBusinessName,
    requestInvoice,
    saveNewBusinessName,
    clearErrorMessageSaveBN,
    updateFile,
    loadingUpdateFile,
    errorUpdateFile
  } = useMyRequest();

  const habdleShowDetail = async (service_id) => {
    const result = await getRequestDetail(service_id);
    setServiceId(service_id);
    setRequestDetail(result);
    setCurrentScreen(screens[1]);
  };

  const screenObj = {
    [screens[0]]: (
      <MyRequestList
        loading={loading}
        orders={orders}
        handleShowDetail={habdleShowDetail}
        filterOrders={getFilteredOrders}
      />
    ),
    [screens[1]]: (
      <MyRequestDetail
        serviceId={serviceID}
        requestDetail={requestDetail}
        getBusinessName={getBusinessName}
        requestInvoice={requestInvoice}
        saveNewBusinessName={saveNewBusinessName}
        loadingbn={loadingbn}
        loadingRequestInvoice={loadingRequestInvoice}
        loadingSaveNewBN={loadingSaveNewBN}
        errorSaveNewBN={errorSaveNewBN}
        clearErrorMessageSaveBN={clearErrorMessageSaveBN}
        updateFile={updateFile}
        loadingUpdateFile={loadingUpdateFile}
        errorUpdateFile={errorUpdateFile}
      />
    ),
  };
  return screenObj[currentScreen];
};

export default MyRequest;
