import basicAuthRequest from "./basicAuthRequest";
import { DONLOAD_FILE } from "../../constants/atualcanceApi";

export const downloadFile = async (serviceOrderId, type, file) => {
  const user = JSON.parse(localStorage.getItem("user"));

  return await basicAuthRequest.request({
    url: DONLOAD_FILE,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      service_order_id: serviceOrderId,
      user_id: user.user_id,
      type_file: type,
      file: file,
    },
  });
};
