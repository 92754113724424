import PropTypes from "prop-types";
import classes from "./SimpleModal.module.css";

const SimpleModal = ({children, title, close}) => (
  <div id="myModal" className={classes.modal}>
    <div className={classes.modalContent}>
      <div className={classes.modalHeader}>
        <span className={classes.close} onClick={close}>
          &times;
        </span>
        <h2>{title}</h2>
      </div>
      <div className={classes.modalBody}>
        {children}
      </div>
    </div>
  </div>
);

SimpleModal.propTypes = {
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};


export default SimpleModal;
