import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import classes from "./ErrorMessage.module.css";

const ErrorMessage = (props) => {
  return (
    <>
      {props.message !== "" && (
        <div className={classes.msgError}>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            style={{ fontSize: "19px" }}
          />{" "}
          &nbsp;
          {props.message}
        </div>
      )}
    </>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default React.memo(ErrorMessage);
