import React, { useState } from 'react';

import {
  Header,
  WideFooter,
  BtnHelp,
  SideBar,
  ProfileMenu
} from '../../components';
import './MainLayout.css';


const MainLayout = ({ children }) => {

  const [sideBar, setSideBar] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);

  const handlerClickToggle = () => {
    setSideBar(!sideBar);
  }

  const handleShowProfileMenu = () => {
    setProfileMenu(!profileMenu);
  }

  return (
    <React.Fragment>
      <div className="main-container">
        <div className="main">
          <Header onClick={handlerClickToggle} showProfile={handleShowProfileMenu} />
          <SideBar open={sideBar} onClick={handlerClickToggle} />
          <ProfileMenu open={profileMenu} onClick={handleShowProfileMenu} />
          <BtnHelp />
          {children}
        </div>
      </div>
      <WideFooter />
    </React.Fragment>
  );
}

export default MainLayout;
