import { call, put, select } from "redux-saga/effects";
import { replace } from "connected-react-router";

import { paymentActions } from "../../slices/payment";
import { industrialDActions } from "../../slices/industrialDesigns";
import { requestCreateDesign } from "../requests/industrialDesignRequest";
import { TICKECT } from "../../../constants/routes";

export function* handlerCreateDesign() {
  try {
    const storeDesign = yield select((store) => store.industrialDesign);
    const storePayment = yield select((store) => store.payment);
    const storeEntity = yield select((store) => store.entity);

    const response = yield call(
      requestCreateDesign,
      storeDesign,
      storePayment,
      storeEntity
    );

    if (response.status === 200) {
      yield put(
        paymentActions.setBillingResult({ billing_result: response.data })
      );
      yield put(replace(TICKECT));
      yield put(paymentActions.cleanStorePayment());
      yield put(industrialDActions.cleanStorageIndustrialDesign());
    } else {
      // TODO: generar pantalla de error
    }
  } catch (error) {
    console.log("Error handlerCreateMusicalGroup" + error);
  }
}
