import { NavLink } from 'react-router-dom';

import { useAuth } from '../../../context/auth';
import logo from '../../../assets/images/a-tu-alcance_logo.svg';
import { Profile } from '../../../assets/images/icons/icons';
import './header.css';
import DrawerToggle from './drawer-toggle/drawer-toggle';


const Header = (props) => {
  const { user } = useAuth();

  return (
    <header className="header-component">
      <div className="logo">
        <NavLink to="/servicios">
          <img src={logo} alt="a tu alcance" className="img-log" />
        </NavLink>
      </div>
      <div className="menu">
        {user ? <img src={Profile} alt="profile" onClick={props.showProfile} style={{ cursor: 'pointer' }} /> : <div />}
        <DrawerToggle onClick={props.onClick} />
      </div>
    </header>
  );
};

export default Header;