import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonBack, ButtonEdit } from "../../../components";
import DisenosTemplate from "../../../components/Templates/Confirmation/DisenosTemplate";
import {
  DASHBOARD_SERVICES,
  DESIGNS_REGISTER,
  DESIGNS_CHECKOUT,
} from "../../../constants/routes";
import { industrialDActions } from "../../../store/slices/industrialDesigns";

const STD_INFO = DESIGNS_REGISTER;
const STD_CHECKOUT = DESIGNS_CHECKOUT;

const ConfirmatioID = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const storeIndustrialDesigns = useSelector((state) => state.industrialDesign);
  const storePayment = useSelector((state) => state.payment);

  useEffect(() => {
    if (storeIndustrialDesigns.brand_info.name === "") {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [history, storeIndustrialDesigns.brand_info.name]);

  const gotoBrandInfo = (_) => {
    props.history.replace(STD_INFO);
  };

  const gotoPayment = () => {
    props.history.replace(STD_CHECKOUT);
  };

  const redirectReceipt = useCallback(() => {
    dispatch(industrialDActions.createIndustrialDesign());
  }, [dispatch]);

  return (
    <div className="container-modulo">
      <ButtonBack url={STD_CHECKOUT} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">
          REGRISTRO DE DISEÑOS <br /> INDUSTRIALES
        </h2>
      </div>

      <div className="description-confirm">
        <p>Protege tu Diseño Industrial de manera práctica y sencilla.</p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DEL DISEÑO INDUSTRIAL</div>
        <ButtonEdit onClick={gotoBrandInfo} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE PAGO</div>
        <ButtonEdit onClick={gotoPayment} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
      <div>
        <DisenosTemplate
          registerData={storeIndustrialDesigns}
          paymentData={storePayment}
          onConfirm={redirectReceipt}
          scope="reg-std-magazine"
        />
      </div>
    </div>
  );
};

export default ConfirmatioID;
