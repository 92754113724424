import { useState, useEffect, useRef } from "react";

import basicAuthRequest from "../../../../../../utils/axios/basicAuthRequest";
import { Spinner } from "../../../../..";
import classes from "./Search.module.css";

const Serach = (props) => {
  const { onLoadClasses } = props;
  const [enteredFilter, setEnteredFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    const url_class = "feed/getclasses/";

    if (inputRef.current.value !== "") {
      const timer = setTimeout(() => {
        setLoading(true);
        const query = enteredFilter.length === 0 ? "" : inputRef.current.value;

        let url =
          props.type === "productos"
            ? `${url_class}${query}/products`
            : `${url_class}${query}/services`;
        basicAuthRequest
          .request({
            url: url,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((responseData) => {
            const loadClasses = [];
            responseData.data.forEach((responseData) => {
              loadClasses.push({
                id: responseData.id,
                class_id: responseData.class_id,
                name: responseData.name,
              });
            });
            setLoading(false);
            onLoadClasses(loadClasses);
          })
          .catch((error) => error);
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [enteredFilter, onLoadClasses, inputRef, props.type]);

  return (
    <section className={classes.search}>
      <div>
        <input
          ref={inputRef}
          type="text"
          className={classes.inputModalSearch}
          value={enteredFilter.toLowerCase()}
          onChange={(event) => setEnteredFilter(event.target.value)}
          placeholder="BUSQUEDA"
        />
      </div>

      {loading && <Spinner />}
    </section>
  );
};

export default Serach;
