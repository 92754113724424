import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  BtnCallToAction,
  Button,
  ModalDrag,
  Spinner,
  ErrorMessage,
} from "../../../../components";
import { getFileName } from "../../../../utils/scripts/file";
import { userProfileActions } from "../../../../store/slices/userProfile";
import SimpleModal from "../../../../components/UI/Modal/SimpleModal";
import classes from "../MyRequest.module.css";

const AttachFile = ({
  closeModalFile,
  entityId,
  serviceOrderId,
  serviceId,
  typeFile,
  formatFile,
  updateFile,
  loadingUpdateFile,
  errorUpdateFile,
  updateFileName,
}) => {
  const dispatch = useDispatch();
  const [dragModal, setDragModal] = useState(false);
  const [emptyFile, setEmptyFile] = useState(false);
  const uploadedFile = useSelector((state) => state.userProfile.uploadedFile);

  const openDragModal = () => {
    setDragModal(true);
  };

  const closeDragModal = () => {
    setDragModal(false);
  };

  const clearFileUploaded = () => {
    dispatch(userProfileActions.setUploadedFiles({ file: "" }));
  };

  const hideModalFile = () => {
    dispatch(userProfileActions.setUploadedFiles({ file: "" }));
    closeModalFile();
  };

  const updateFileHandler = async () => {
    if (uploadedFile !== "") {
      const data = {
        service_id: serviceId,
        service_order_id: entityId === null ? serviceOrderId : null,
        entity_id: entityId,
        type: typeFile,
        file: uploadedFile,
      };
      await updateFile(data);
      setEmptyFile(false);
      //@TODO: no cerrar la venta modal cuando hay un error
      if (errorUpdateFile === "") {
        updateFileName(uploadedFile);
        dispatch(userProfileActions.setUploadedFiles({ file: "" }));
        closeModalFile();
      }
    } else {
      setEmptyFile(true);
    }
  };

  return (
    <SimpleModal close={hideModalFile} title="ADJUNTAR NUEVO ARCHIVO">
      {dragModal && (
        <ModalDrag
          scope="upload-file"
          formatFile={formatFile}
          closed={closeDragModal}
          show={dragModal}
        />
      )}
      <div className={classes.mainContentModal}>
        <div className={classes.descriptionModal}>
          Favor de asociar los archivos requeridos para continuar con el proceso
          de su solicitud.
        </div>
        {loadingUpdateFile ? (
          <Spinner />
        ) : (
          <>
            <div className={classes.fileItem}>
              {uploadedFile === "" ? (
                <BtnCallToAction onClick={openDragModal} icon="faUpload">
                  SELECCIONAR ARCHIVO
                </BtnCallToAction>
              ) : (
                <BtnCallToAction
                  type="showfile"
                  onClick={clearFileUploaded}
                  icon="faTimesCircle"
                  className="btnGreen"
                  fileName={getFileName(uploadedFile)}
                >
                  ARCHIVO DE DISEÑO DE LA MARCA
                </BtnCallToAction>
              )}
            </div>
            {emptyFile && <ErrorMessage message="SELECCIONA UN ARCHIVO" />}
            {errorUpdateFile !== "" && (
              <ErrorMessage message={errorUpdateFile} />
            )}
            <div className={classes.modalCtrl}>
              <div className={classes.modalBtn}>
                <Button
                  className="btnConfirm"
                  type="submit"
                  onClick={updateFileHandler}
                >
                  Aceptar
                </Button>
              </div>
              <div className={classes.modalBtn}>
                <Button className="btnCancel" onClick={hideModalFile}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </SimpleModal>
  );
};

AttachFile.propTypes = {
  closeModalFile: PropTypes.func.isRequired,
};

export default AttachFile;
