import { createSlice } from "@reduxjs/toolkit";

const TYPEENTITY = {
  1: "titular",
  2: "author",
  3: "coauthor",
  4: "licenciatario",
  5: "cesionario",
};

const person = {
  type: { valid: false, touched: false, value: "" },
  rfc: { valid: false, touched: false, value: "" },
  curp: { valid: false, touched: false, value: "" },
  titularName: { valid: false, touched: false, value: "" },
  street: { valid: false, touched: false, value: "" },
  extNumber: { valid: false, touched: false, value: "" },
  intNumber: { valid: false, touched: false, value: "" },
  email: { valid: false, touched: false, value: "" },
  phone: { valid: false, touched: false, value: "" },
  state: { valid: false, touched: false, value: "" },
  city: { valid: false, touched: false, value: "" },
  postalCode: { valid: false, touched: false, value: "" },
  neighborhood: { valid: false, touched: false, value: "" },
  file: "",
  valid: false,
};

/**
 * List entities
 * 1 Titular
 * 2 Autor
 * 3 Coautor
 * 4 Licenciatario
 * 5 Cesionario
 */

const initialEntityState = {
  titular: { ...person },
  author: { ...person },
  licenciatario: { ...person },
  cesionario: { ...person },
  coauthor: [],
  dataEntity: [],
};

const entitySlice = createSlice({
  name: "entity",
  initialState: initialEntityState,
  reducers: {
    cleanStoreEntity(state) {
      state.titular = { ...person };
      state.author = { ...person };
      state.licenciatario = { ...person };
      state.coauthor = [];
      state.dataEntity = [];
    },
    setDataEntity(state, action) {
      state.dataEntity = action.payload.dataEntity;
    },
    setIsValid(state, action) {
      state[TYPEENTITY[action.payload.idEntity]].valid = action.payload.valid;
    },
    setType(state, action) {
      state[TYPEENTITY[action.payload.idEntity]].type.value =
        action.payload.value;
      state[TYPEENTITY[action.payload.idEntity]].type.touched =
        action.payload.touched;
      state[TYPEENTITY[action.payload.idEntity]].type.valid =
        action.payload.valid;
    },
    setRfc(state, action) {
      state[TYPEENTITY[action.payload.idEntity]].rfc.value =
        action.payload.value;
      state[TYPEENTITY[action.payload.idEntity]].rfc.touched =
        action.payload.touched;
      state[TYPEENTITY[action.payload.idEntity]].rfc.valid =
        action.payload.valid;
    },
    setName(state, action) {
      state[TYPEENTITY[action.payload.idEntity]].titularName.value =
        action.payload.value;
      state[TYPEENTITY[action.payload.idEntity]].titularName.touched =
        action.payload.touched;
      state[TYPEENTITY[action.payload.idEntity]].titularName.valid =
        action.payload.valid;
    },
    setCurp(state, action) {
      state[TYPEENTITY[action.payload.idEntity]].curp.value =
        action.payload.value;
      state[TYPEENTITY[action.payload.idEntity]].curp.touched =
        action.payload.touched;
      state[TYPEENTITY[action.payload.idEntity]].curp.valid =
        action.payload.valid;
    },
    setStreet(state, action) {
      state[TYPEENTITY[action.payload.idEntity]].street.value =
        action.payload.value;
      state[TYPEENTITY[action.payload.idEntity]].street.touched =
        action.payload.touched;
      state[TYPEENTITY[action.payload.idEntity]].street.valid =
        action.payload.valid;
    },
    setExtNumber(state, action) {
      state[TYPEENTITY[action.payload.idEntity]].extNumber.value =
        action.payload.value;
      state[TYPEENTITY[action.payload.idEntity]].extNumber.touched =
        action.payload.touched;
      state[TYPEENTITY[action.payload.idEntity]].extNumber.valid =
        action.payload.valid;
    },
    setIntNumber(state, action) {
      state[TYPEENTITY[action.payload.idEntity]].intNumber.value =
        action.payload.value;
      state[TYPEENTITY[action.payload.idEntity]].intNumber.touched =
        action.payload.touched;
      state[TYPEENTITY[action.payload.idEntity]].intNumber.valid =
        action.payload.valid;
    },
    setEmail(state, action) {
      state[TYPEENTITY[action.payload.idEntity]].email.value =
        action.payload.value;
      state[TYPEENTITY[action.payload.idEntity]].email.touched =
        action.payload.touched;
      state[TYPEENTITY[action.payload.idEntity]].email.valid =
        action.payload.valid;
    },
    setPhone(state, action) {
      state[TYPEENTITY[action.payload.idEntity]].phone.value =
        action.payload.value;
      state[TYPEENTITY[action.payload.idEntity]].phone.touched =
        action.payload.touched;
      state[TYPEENTITY[action.payload.idEntity]].phone.valid =
        action.payload.valid;
    },
    setState(state, action) {
      state[TYPEENTITY[action.payload.idEntity]].state.value =
        action.payload.value;
      state[TYPEENTITY[action.payload.idEntity]].state.touched =
        action.payload.touched;
      state[TYPEENTITY[action.payload.idEntity]].state.valid =
        action.payload.valid;
    },
    setCity(state, action) {
      state[TYPEENTITY[action.payload.idEntity]].city.value =
        action.payload.value;
      state[TYPEENTITY[action.payload.idEntity]].city.touched =
        action.payload.touched;
      state[TYPEENTITY[action.payload.idEntity]].city.valid =
        action.payload.valid;
    },
    setPostalaCode(state, action) {
      state[TYPEENTITY[action.payload.idEntity]].postalCode.value =
        action.payload.value;
      state[TYPEENTITY[action.payload.idEntity]].postalCode.touched =
        action.payload.touched;
      state[TYPEENTITY[action.payload.idEntity]].postalCode.valid =
        action.payload.valid;
    },
    setNeighborhood(state, action) {
      state[TYPEENTITY[action.payload.idEntity]].neighborhood.value =
        action.payload.value;
      state[TYPEENTITY[action.payload.idEntity]].neighborhood.touched =
        action.payload.touched;
      state[TYPEENTITY[action.payload.idEntity]].neighborhood.valid =
        action.payload.valid;
    },
    setFile(state, action) {
      state[TYPEENTITY[action.payload.idEntity]].file = action.payload.file;
    },
  },
});

export const entityActions = entitySlice.actions;

export default entitySlice;
