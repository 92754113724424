import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonBack, ButtonEdit } from "../../../../components";
import MagazineTemplate from "../../../../components/Templates/Confirmation/MagazineTemplate";
import { magazinesActions } from "../../../../store/slices/magazines";
import { DASHBOARD_SERVICES } from "../../../../constants/routes";

const ConfirmationMgz = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const storeMagazine = useSelector((state) => state.magazines);
  const storePayment = useSelector((state) => state.payment);

  const EDITBRAND = "/revistas/registro/titular";
  const EDITBRAND_IS_NEW_BRAND = "/revistas/registro";
  const EDITBRAND_CHECKOUT = "/revistas/registro/checkout";

  useEffect(() => {
    if (!storeMagazine.isNewBrand && storeMagazine.studyBelongsId === -1) {
      history.replace(DASHBOARD_SERVICES);
    } else if (
      storeMagazine.isNewBrand &&
      storeMagazine.brand_info.name === ""
    ) {
      history.replace(DASHBOARD_SERVICES);
    }
  }, [
    history,
    storeMagazine.brand_info.name,
    storeMagazine.isNewBrand,
    storeMagazine.studyBelongsId,
  ]);

  const gotoBrandInfo = () => {
    if (storeMagazine.isNewBrand) {
      props.history.replace(EDITBRAND_IS_NEW_BRAND);
    } else {
      props.history.replace(EDITBRAND);
    }
  };

  const gotoPayment = () => {
    props.history.replace(EDITBRAND_CHECKOUT);
  };

  const redirectReceipt = useCallback(() => {
    dispatch(magazinesActions.createMagazine());
  }, [dispatch]);

  return (
    <div className="container-modulo">
      <ButtonBack url={EDITBRAND_CHECKOUT} text="REGRESAR" />

      <div className="section-title">
        <h2 className="subtitle">REGRISTRO DE NOMBRE DE REVISTAS</h2>
      </div>

      <div className="description-confirm">
        <p>Protege tu Revista de manera práctica y sencilla.</p>
      </div>
      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE LA REVISTA</div>
        <ButtonEdit onClick={gotoBrandInfo} icon="faPen">
          Editar
        </ButtonEdit>
      </div>

      <div className="label-information" style={{ padding: "0.5rem 1rem" }}>
        <div>INFORMACIÓN DE PAGO</div>
        <ButtonEdit onClick={gotoPayment} icon="faPen">
          Editar
        </ButtonEdit>
      </div>
      <div className="label-information">
        <div>CONFIRMACIÓN</div>
      </div>
      <div>
        <MagazineTemplate
          registerData={storeMagazine}
          paymentData={storePayment}
          onConfirm={redirectReceipt}
          scope="reg-std-magazine"
        />
      </div>
    </div>
  );
};

export default ConfirmationMgz;
