import { useState } from "react";
import { NavLink } from "react-router-dom";
import { AtaCurrencyFormat } from "../../Common";
import { Button, Spinner, ErrorMessage } from "../../../components";
import classes from "./ConfirmationTemplate.module.css";

const TradeMarkTemplate = ({
  tradeMark,
  price,
  paymentData,
  onSubmit,
  loading,
  error,
}) => {
  const [accepted, setAccepted] = useState(false);
  return (
    <div className={classes.confirmation}>
      <div className="confirmation-item">
        <h3>NOMBRE DE LA MARCA</h3>
        <p>{tradeMark[0]?.name_brand}</p>
      </div>
      <div className="confirmation-item">
        <h3>NÚMERO DE REGISTRO</h3>
        <p>{tradeMark[0]?.register_number}</p>
      </div>
      <div className="confirmation-item">
        <h3>NOMBRE DEL TITULAR</h3>
        <p>{tradeMark[0]?.name_owner}</p>
      </div>
      <div className="confirmation-item">
        <h3>FECHA LIMITE DE DECLARACIÓN</h3>
        <p>{tradeMark[0]?.final_date}</p>
      </div>
      <div className="confirmation-item">
        <h3>COSTO DEL TRAMITE</h3>
        <span className="ammount">
          <AtaCurrencyFormat value={tradeMark[0]?.transact_amount} />
        </span>
      </div>
      <div className="confirmation-item">
        <h3>HONORARIOS POR DECLARACIÓN</h3>
        <span className="ammount">
          <AtaCurrencyFormat value={tradeMark[0]?.honorarium_amount} />
        </span>
      </div>
      <div className="confirmation-item">
        <h3>METÓDO DE PAGO</h3>
        <p>{paymentData.paymentMethod.toUpperCase()}</p>
      </div>
      <div className="confirmation-item">
        <h3>TOTAL</h3>
        <span className="total">
          <AtaCurrencyFormat value={price} />
        </span>
        <div className="description-text">(Incluye impuestos y comisiones)</div>
      </div>
      <div className="confirmation-item">
        <label className={classes.checkitem}>
          <label htmlFor="terms" className={classes.terms}>
            Acepto todos los &nbsp;
            <NavLink to="/terminos-y-condiciones" className={classes.linkText}>
              TÉRMINOS Y CONDICIONES
            </NavLink>
          </label>
          <input
            type="checkbox"
            id="terms"
            name="terms"
            onChange={(event) => setAccepted(event.target.checked)}
          />
          <span className={classes.checkmark}></span>
        </label>
      </div>
      <div className="confirmation-item">
        <p>
          Al presionar "confirmar solicitud" usted acepta todos los términos y
          condiciones de <b>A tu alcance.</b>
        </p>
        <p>El pago de esta solicitud no garantiza la aprobación de la misma.</p>
      </div>
      {error && (
        <div className="container-select">
          <ErrorMessage message="Ocurrio un problema al procesar la petición, intenta nuevamente" />
        </div>
      )}

      <div className={classes.btnConfirm}>
        {loading ? (
          <Spinner />
        ) : (
          <Button
            className={"btnConfirm"}
            onClick={onSubmit}
            disabled={!accepted}
          >
            CONFIRMAR SOLICITUD
          </Button>
        )}
      </div>
    </div>
  );
};

export default TradeMarkTemplate;
